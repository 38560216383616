import api from '../../../services/api';

export async function getList() {
  const token = localStorage.getItem('token');

  try {
    const response = await api.get('/alerts/', {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });

    return response.data;
  } catch (e) {
    if (e.response.status === 401) {
      window.location.href = '/';
    }
  }
}

export async function createAlerts(data) {
  const token = localStorage.getItem('token');
  await api.post('/alerts/', data, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
}
