import api from './api';

export const testVtexAccountAccess = async (vtexApiKey, vtexToken, vtexAccount)  => {
  const token = localStorage.getItem('token');

  const params = {
      vtex_api_key: vtexApiKey,
      vtex_token : vtexToken,
      vtex_account : vtexAccount
  }

  const validation = await api.post('/proxy/vtex', params, {
    headers: {
      Authorization: `Bearer ${token}`
    }
  });
  if (validation.status === 200) {
    return true;
  } else {
    return false;
  }
}

export const testGoogleAnalytics4AccountAccess = async (accountId, oAuthToken)  => {
  const token = localStorage.getItem('token');

  const params = {
    account_id: `${accountId}`,
    token: oAuthToken
  }

  const validation = await api.post('/proxy/google/analytics', params, {
    headers: {
      Authorization: `Bearer ${token}`
    }
  });
  if (validation.status === 200) {
    return true;
  } else {
    return false;
  }
}

export const testGoogleAdsAccountAccess = async (accountId, oAuthToken)  => {
  const token = localStorage.getItem('token');

  const params = {
      account_id: accountId,
      token: oAuthToken
  }

  const validation = await api.post('/proxy/google/ads', params, {
    headers: {
      Authorization: `Bearer ${token}`
    }
  });
  if (validation.status === 200) {
    return true;
  } else {
    return false;
  }
}

export const testFacebookAdsAccountAccess = async (accountId, oAuthToken)  => {
  const token = localStorage.getItem('token');

  const params = {
      account_id: accountId,
      token: oAuthToken
  }

  const validation = await api.post('/proxy/facebook/ads', params, {
    headers: {
      Authorization: `Bearer ${token}`
    }
  });
  if (validation.status === 200) {
    return true;
  } else {
    return false;
  }
}
