import React from "react";

import { useTranslation } from 'react-i18next';
import '../../i18n';

import Loader from '../../components/Loader';
import { RiInformationLine } from 'react-icons/ri';
import { BisoTooltip } from '../../components/Tooltip';
import { ImArrowDown, ImArrowUp } from 'react-icons/im';

import { CardGroupContainer, Card } from './styles';

import { 
    formatValueToMoney, 
    formatValueToPercentage, 
    formatValueToNumber 
} from "../../utils/metricsUtils";

const CardIndicator = ({
    data,
    currency,
    isLoading
}) => {
    const { t } = useTranslation();

    const renderComparativeRow = (value, text) => {
        return (
            <span className='comparativeValue'>
                {value >= 0 ? <ImArrowUp className='arrowCardValue' color="#1A1D1F" size={10}/> : <ImArrowDown className='arrowCardValue' color="#1A1D1F" size={10}/>}
                {formatValueToPercentage(value)}
                <span className='comparativeText'>{text}</span>
            </span>
        )
    }

    const renderCardsData = (cardData) => {
        const { 
            keys, 
            titles, 
            types,
            data, 
            displayFlexSpaceOcupation,
            maxHeights,
            minWidths,
            metricInfoConfig,
            comparativeValues
        } = cardData;

        const formattersByType = {
            currency: value => formatValueToMoney(value, currency),
            decimal: formatValueToNumber,
            percent: formatValueToPercentage
        } 
        
        return keys.map((key, index) => {
            let value = data ? data[key] : 0;
            
            return (
                <Card 
                    key={index} 
                    style={{ 
                        flex: displayFlexSpaceOcupation[key], 
                        maxHeight: maxHeights ? maxHeights[key] : null,
                        minWidth: minWidths ? minWidths[key] : null
                    }}
                >
                    <p className='cardTitle'>
                        {titles[key]}
                        {metricInfoConfig && metricInfoConfig[key]["visible"] && (
                            <BisoTooltip 
                                title={t('common.understand_how_metric_is_calculated')}
                                placement='right-start'
                            >
                            <span className='metricInfoIcon' onClick={metricInfoConfig[key]["onClick"]}><RiInformationLine size={16} color="#f90c68"/></span>
                          </BisoTooltip>
                        )}
                    </p>
                    <p className='totalValue'>
                        {formattersByType[types[key]](value)}
                    </p>
                    {comparativeValues &&
                        <div className='comparativeGroup'>
                            {renderComparativeRow(comparativeValues[key].previous_year, t('common.previous_year'))}
                            {renderComparativeRow(comparativeValues[key].previous_period, t('common.previous_period'))}
                        </div>
                    }
                </Card>
            )
        });
    }

    return (
        <CardGroupContainer>
            <div className='cardGroupContent'>
                {isLoading && <Loader />}
                {!isLoading && data && renderCardsData(data)}
            </div>
        </CardGroupContainer>
    )
}

export default CardIndicator;