import React, { useEffect, useState, useCallback, useContext } from "react";
import { toast } from 'react-toastify';

import { useTranslation } from 'react-i18next';
import '../../i18n';

import CardGroup from '../../components/CardGroup';
import BackgroundNew from '../../components/BackgroundNew';

import { DataSyncTable } from './styles';

import { getConnectorsLastSyncDate } from '../../services/dashboardApiService';

import { AuthContext } from '../../contexts';

const DataSyncInfo = () => {
    const { t } = useTranslation();
    const { timezone } = useContext(AuthContext);

    const [data, setData] = useState(null);

    const getData = useCallback(async () => {
        try {
            const response = await getConnectorsLastSyncDate(timezone);
            setData(response);
        } catch (error) {
            toast.error(error.message);
            setData(null);
        }
    }, [timezone]);

    useEffect(() => {
        getData();
    }, [getData]);

    return (
        <BackgroundNew 
        titlePage={t('appBar.data_update')}
        subTitlePage={t('dataSyncInfo.check_data_updated')}
        >
            <CardGroup
                title={t('dataSyncInfo.connections')}
                isLoading={!data}
            >
                {data && data.length > 0 && (
                    <DataSyncTable>
                        <thead>
                            <tr>
                                <th>{t('dataSyncInfo.connection')}</th>
                                <th>{t('dataSyncInfo.date_last_update')}</th>
                            </tr>
                        </thead>
                        <tbody>
                            {data && data.map((item, index) => (
                                <tr key={index}>
                                    <td>{item.connector_module_name}</td>
                                    <td>{item.last_sync_date}</td>
                                </tr>
                            ))}
                            {data && data.length === 0 && (
                                <tr>
                                    <td colSpan="2">{t('common.no_data_to_display')}</td>
                                </tr>
                            )}
                        </tbody>
                    </DataSyncTable>
                )}
            </CardGroup>
        </BackgroundNew>
    );
};


export default DataSyncInfo;
