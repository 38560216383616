import styled from 'styled-components';
import { darken } from 'polished';

export const TagWrapperControllerPage = styled.button`
  padding: 6px 8px;
  margin-left: 10px;
  color: #fff;
  font-size: 10px;
  text-transform: uppercase;
  background: #ff0068;
  border-radius: 50px;
  border: none;

  &:hover {
    background-color: ${darken(0.05, '#ff0068')};
    border-color: ${darken(0.05, '#ff0068')};
    color: #fff;
  }
`;

export const TagContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin-right: 30px;
  margin-top: 20px;

  .disabled {
    background-color: #999;
    border-color: #999;
    color: #fff;
    cursor: not-allowed;
    box-shadow: none;

    &:hover {
      background-color: #999;
      border-color: #999;
      color: #fff;
    }
  }
`;
