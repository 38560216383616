import React, { useEffect, useCallback, useState, useContext } from 'react';

import { toast } from 'react-toastify';

import { useTranslation } from 'react-i18next';
import '../../i18n';

import CardGroups from './CardGroups';
import BackgroundNew from '../../components/BackgroundNew';
import FilterContainer from '../../components/FilterContainer';
import ModalAnomalyExplanation from '../../components/CustomModal/ModalAnomalyExplanation';

import { getSelectedInitialDateRange, formatDate } from '../../utils/dateUtils';

import { getGoogleAnalyticsDailyMetricsWithAnomalies } from '../../services/dashboardApiService';

import { AuthContext } from '../../contexts';

const Anomalies = () => {
  const { t } = useTranslation();
  const { currency } = useContext(AuthContext);

  const [dateRange, setDateRange] = useState();
  const [isLoading, setIsLoading] = useState(true);

  const [graphsData, setGraphsData] = useState({});

  const [openModalInformation, setOpenModalInformation] = useState(false);

  const handleApplyFilter = async () => {
    const [startDate, endDate] = dateRange;
    await getAnomalies(formatDate(startDate), formatDate(endDate));
  };

  const getAnomalies = useCallback(async (initialDate, finalDate) => {
    setIsLoading(true);

    try {
      const responses = await getGoogleAnalyticsDailyMetricsWithAnomalies({ initialDate, finalDate });

      setGraphsData(responses || {});
    } catch (error) {
      toast.error(error.message);
      setGraphsData({});
    } finally {
      setIsLoading(false);
    }
  }, []);

  const onRenderComponent = useCallback(async () => {
    const { initialDateFilter, finalDateFilter } = getSelectedInitialDateRange();
    setDateRange([initialDateFilter, finalDateFilter]);

    await getAnomalies(formatDate(initialDateFilter), formatDate(finalDateFilter));
  }, [getAnomalies]);

  useEffect(() => {
    onRenderComponent();
  }, [onRenderComponent]);

  return (
    <BackgroundNew 
      titlePage={t('menu.anomaly_detection')}
      showInformation={true}
      informationTooltipTitle={t('common.click_to_learn_more')}
      informationOnClick={() => setOpenModalInformation(true)}
    >
      <FilterContainer
        selectedDateRange={dateRange}
        setDateRange={setDateRange}
        onClickFilter={handleApplyFilter}
        limitDateRangeUntilToday={false}
      />
      <CardGroups isLoading={isLoading} graphsData={graphsData} currency={currency} />
      <ModalAnomalyExplanation open={openModalInformation} handleClose={() => setOpenModalInformation(false)} />
    </BackgroundNew>
  );
};

export default Anomalies;
