import React, { useState } from "react";

import { useTranslation } from 'react-i18next';
import "../../../../i18n";

import { ContainerGraph, CustomTooltipContainer } from "./styles";

import {
  ComposedChart,
  Line,
  XAxis,
  YAxis,
  Tooltip,
  Area,
  ResponsiveContainer,
  CartesianGrid,
} from "recharts";

import { RiInformationLine } from "react-icons/ri";

import { BisoTooltip } from "../../../../components/Tooltip";
import GraphCustomTooltip from "../../../../components/GraphCustomTooltip";
import GraphController from "../../../../components/GraphController";
import ModalPredictionExplanation from "../../../../components/CustomModal/ModalPredictionExplanation";

const CustomTooltip = ({ active, payload, label }) => {
  if (active) {
    return (
      <CustomTooltipContainer>
        <p className="label">{label}</p>
        {payload.map((item, index) => (
          <p className="item" key={index}>
            {`${item.name}: `}
            <span style={{ color: item.color, fontWeight: "bold" }}>{item.formatter(item.value)}</span>
          </p>
        ))}
      </CustomTooltipContainer>
    );
  }

  return null;
};

const GraphPrediction = ({ graphData, formatter }) => {
  const { t } = useTranslation();

  const [openModal, setOpenModal] = useState(false);
  const [fullScreenMode, setFullScreenMode] = useState(false);

  const filteredData = graphData.map((item) => {
    let result = { date: item.date };

    if (item.has_prediction) {
      result["predicted_value"] = item.predicted_value;
      result["deviations"] = [item.predicted_value_lower_bound, item.predicted_value_upper_bound];
    }

    if (item.real_value > 0) {
      result["real_value"] = item.real_value;
    }
    result["predicted_value_lower_bound"] = item.predicted_value_lower_bound;
    result["predicted_value_upper_bound"] = item.predicted_value_upper_bound;

    return result;
  });

  const today = new Date();
  const lastGraphDate = new Date(filteredData[filteredData.length - 1].date);
  const daysDiff = Math.ceil((lastGraphDate - today) / (1000 * 60 * 60 * 24));

  const minValue = Math.min(...filteredData.map((item) => item.predicted_value_lower_bound));
  const maxValue = Math.max(...filteredData.map((item) => item.predicted_value_upper_bound));

  const fullScreenStyles = {
    container: {
      position: "fixed",
      top: 0,
      left: 0,
      width: "100%",
      height: "100vh",
      backgroundColor: "#F4F4F4",
      zIndex: 99999,
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      alignItems: "end",
      margin: 0,
    },
    graph: {
      height: "80vh",
      backgroundColor: "#F4F4F4",
      width: "100%",
    },
    graphLegend: {
      width: "100%",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
    },
  };

  return (
    <ContainerGraph style={fullScreenMode ? fullScreenStyles.container : null}>
      {fullScreenMode && (
        <GraphController
          showDimensionController={false}
          showFullScreenOption={true}
          fullScreenEnabled={fullScreenMode}
          setFullScreenEnabled={setFullScreenMode}
        />
      )}
      {!fullScreenMode && (
        <div className="graphTitle">
          {daysDiff > 0 ? `${t('goalsOverview.forecast_next_x_days_part1')} ${daysDiff} ${t('goalsOverview.forecast_next_x_days_part2')}` : t('goalsOverview.no_forecast_available')}
          <BisoTooltip title={t('goalsOverview.understand_forecasting')} placement="right-start">
            <span className="graphInfoIcon" onClick={(_) => setOpenModal(true)}>
              <RiInformationLine size={13} color="#f90c68" />
            </span>
          </BisoTooltip>
        </div>
      )}
      <div
        className="graph"
        style={fullScreenMode ? fullScreenStyles.graph : null}
        onClick={(_) => setFullScreenMode(true)}
      >
        <ResponsiveContainer width="100%" height="100%">
          <ComposedChart
            data={filteredData}
            margin={fullScreenMode ? { left: 60, right: 35 } : {}}
            style={!fullScreenMode ? { cursor: "pointer" } : {}}
          >
            {fullScreenMode && <CartesianGrid strokeDasharray="3 3" />}
            <XAxis dataKey="date" hide={!fullScreenMode} scale="band" />
            <YAxis
              yAxisId="line"
              hide={!fullScreenMode}
              domain={fullScreenMode ? null : [minValue, maxValue]}
              tickFormatter={formatter}
            />
            <Tooltip
              content={fullScreenMode ? GraphCustomTooltip : CustomTooltip}
              position={fullScreenMode ? {} : { y: -75 }}
            />
            <Area
              yAxisId="line"
              type="monotone"
              dataKey="deviations"
              fill="rgba(255, 0, 104, 0.2)"
              stroke="none"
              formatter={(value) => {
                return `${formatter(value[0])} | ${formatter(value[1])}`;
              }}
              name={t('goalsOverview.deviation')}
            />
            <Line
              yAxisId="line"
              strokeDasharray="5 5"
              type="monotone"
              dataKey="predicted_value"
              stroke="#FF0068"
              name={t('goalsOverview.forecasted')}
              formatter={formatter}
              dot={false}
              strokeWidth={2}
            />
            <Line
              yAxisId="line"
              type="monotone"
              dataKey="real_value"
              stroke="#00CCAE"
              name={t('common.achieved')}
              formatter={formatter}
              dot={false}
              strokeWidth={2}
            />
          </ComposedChart>
        </ResponsiveContainer>
      </div>
      <div className="graphLegend" style={fullScreenMode ? fullScreenStyles.graphLegend : null}>
        <div className="legendItem">
          <div className="legendColor" style={{ backgroundColor: "#00CCAE" }} />
          <span className="legendText">{t('common.achieved')}</span>
          <div className="legendColor" style={{ backgroundColor: "#FF0068", marginLeft: "10px" }} />
          <span className="legendText">{t('goalsOverview.forecasted')}</span>
        </div>
      </div>
      <ModalPredictionExplanation open={openModal} handleClose={(_) => setOpenModal(false)} />
    </ContainerGraph>
  );
};

export default GraphPrediction;
