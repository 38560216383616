import Select from 'react-select';
import CreatableSelect from 'react-select/creatable'
import styled from 'styled-components';

export const Option = styled(Select)`
  font-size: 14px;
  color: #2e3039;

  @media screen and (max-width: 1200px) {
    font-size: 14px;
  }
`;

export const CreatableOption = styled(CreatableSelect)`
  font-size: 14px;
  color: #2e3039;

  @media screen and (max-width: 1200px) {
    font-size: 14px;
  }
`;