import React, { useEffect, useCallback, useState, useRef, useContext } from 'react';

import { toast } from 'react-toastify';

import { useTranslation } from 'react-i18next';
import '../../i18n';

import CardGroup from '../../components/CardGroup';
import CardIndicator from '../../components/CardIndicator';
import BackgroundNew from '../../components/BackgroundNew';
import FilterContainer from '../../components/FilterContainer';
import GraphBarHorizontal from '../../components/GraphBarHorizontal';
import ComposedGraphBarLine from '../../components/ComposedGraphBarLine';
import ContainerTwoCardGroup from '../../components/ContainerTwoCardGroup';
import LTVModalExplanation from '../../components/CustomModal/ModalLTVExplanation';
import ModalPageInformation from '../../components/CustomModal/ModalPageInformation';

import { isValidAvailableFilter } from '../../utils/validationUtils';
import { getSelectedInitialDateRange, formatDate } from '../../utils/dateUtils';
import { formatValueToMoney, formatValueToNumber, formatValueToPercentage } from '../../utils/metricsUtils';

import {
  getCRMBigNumbersMetrics,
  getCRMAverageTimeBetweenPurchasesMetrics,
  getCRMDecilesMetrics
} from '../../services/dashboardApiService';

import { AuthContext } from '../../contexts';

const Decil = () => {
    const { t } = useTranslation();
    const { timezone, currency } = useContext(AuthContext);

    const [dateRange, setDateRange] = useState();
    const [isLoading, setIsLoading] = useState(true);
    const [bigNumbersData, setBigNumbersData] = useState(null);
    const [decileMetricsData, setDecileMetricsData] = useState(null);
    const [originsOptions, setOriginsOptions] = useState([]);
    const [salesChannelsOptions, setSalesChannelsOptions] = useState([]);
    const [vtexAffiliatesOptions, setVtexAffiliatesOptions] = useState([]);
    const [selectedOriginsOptions, setSelectedOriginsOptions] = useState([]);
    const [selectedSalesChannelsOptions, setSelectedSalesChannelsOptions] = useState([]);
    const [selectedVtexAffiliatesOptions, setSelectedVtexAffiliatesOptions] = useState([]);
    const [showLTVModalExplanation, setShowLTVModalExplanation] = useState(false);
    const [openModalInformation, setOpenModalInformation] = useState(false);

    const [amountOrdersDecileGraphFullScreen, setAmountOrdersDecileGraphFullScreen] = useState(false);
    const [avgTicketDecileGraphFullScreen, setAvgTicketDecileGraphFullScreen] = useState(false);
    const [revenueDecileGraphFullScreen, setRevenueDecileGraphFullScreen] = useState(false);
    
    const cardRevenueDecileReg = useRef(null);
    const cardAmountOrdersDecileRef = useRef(null);
    const cardAvgTicketDecileRef = useRef(null);

    const handleApplyFilter = async () => {
        const [startDate, endDate] = dateRange;
        const origins = selectedOriginsOptions.length > 0 ? [...selectedOriginsOptions] : null;
        const salesChannels = selectedSalesChannelsOptions.length > 0 ? [...selectedSalesChannelsOptions] : null;
        const vtexAffiliates = selectedVtexAffiliatesOptions.length > 0 ? [...selectedVtexAffiliatesOptions] : null;

        await getCRMDataMetrics(
            formatDate(startDate),
            formatDate(endDate),
            origins,
            salesChannels,
            vtexAffiliates
        );
    };

    const getCRMDataMetrics = useCallback(async (
      initialDate,
      finalDate,
      origins = null,
      salesChannels = null,
      vtexAffiliates = null
    ) => {
        setIsLoading(true);

        try {
            const responses = await Promise.all([
              getCRMAverageTimeBetweenPurchasesMetrics(
                initialDate,
                finalDate,
                timezone,
                origins,
                salesChannels,
                vtexAffiliates
              ),
              getCRMBigNumbersMetrics(
                initialDate,
                finalDate,
                timezone,
                origins,
                salesChannels,
                vtexAffiliates
              ),
              getCRMDecilesMetrics(
                initialDate,
                finalDate,
                timezone,
                origins,
                salesChannels,
                vtexAffiliates
              )
            ]);

            const responseAverageTimeBetweenPurchases = responses[0];
            const responseBigNumbers= responses[1];
            const responseDecileMetrics = responses[2];

            if (!responseAverageTimeBetweenPurchases || !responseBigNumbers || !responseDecileMetrics) {
              setBigNumbersData(null);
              setDecileMetricsData(null);
              setOriginsOptions([]);
              setSalesChannelsOptions([]);
              setVtexAffiliatesOptions([]);

              return;
            }

            const allBigNumbers = {...responseBigNumbers, ...responseAverageTimeBetweenPurchases.big_numbers}
            setBigNumbersData(allBigNumbers)

            setOriginsOptions(responseDecileMetrics.available_origins_to_filter);
            setSalesChannelsOptions(responseDecileMetrics.available_marketplace_channels_to_filter);

            if (isValidAvailableFilter(responseDecileMetrics.available_affiliates_to_filter)) {
              setVtexAffiliatesOptions(responseDecileMetrics.available_affiliates_to_filter);
            } else {
                setVtexAffiliatesOptions([])
            }

            delete responseDecileMetrics.available_origins_to_filter;
            delete responseDecileMetrics.available_marketplace_channels_to_filter;
            delete responseDecileMetrics.available_affiliates_to_filter;

            setDecileMetricsData(responseDecileMetrics)
        } catch (error) {
            toast.error(error.message);
            setBigNumbersData(null);
            setDecileMetricsData(null)
            setOriginsOptions([]);
            setSalesChannelsOptions([]);
            setVtexAffiliatesOptions([]);
        } finally {
          setIsLoading(false);
        }
    }, [timezone]);

    const getDecileMetricsDataList = (data) => {
      if (!data) return null

      const decileList = Object.keys(data).map((key) => {
        return {
          decile: key,
          amountOrders: data[key].amount_orders,
          approvedAvgTicket: data[key].approved_avg_ticket,
          approvedRevenue: data[key].approved_revenue
        }
      })

      const totalRevenue = decileList.reduce((acc, curr) => acc + curr.approvedRevenue, 0)

      return decileList.map((decile) => {
        return {
          ...decile,
          revenuePercentage: totalRevenue > 0 ? Number(decile.approvedRevenue / totalRevenue * 100).toFixed(2) : 0
        }
      })
    }

    const getCardIndicatorData = (data) => {
        return {
          keys: ["average_time_between_purchases", "total_new_customers_count", "total_rebuying_customers_count", "total_ltv"],
          titles: {
            average_time_between_purchases: t('common.average_time_between_purchases'),
            total_new_customers_count: t('common.new_customers'),
            total_rebuying_customers_count: t('common.returning_customers'),
            total_ltv: t('common.ltv')
          },
          types: {
            average_time_between_purchases: 'decimal',
            total_new_customers_count: 'decimal',
            total_rebuying_customers_count: 'decimal',
            total_ltv: 'currency'
          },
          displayFlexSpaceOcupation: {
            average_time_between_purchases: 1,
            total_new_customers_count: 1,
            total_rebuying_customers_count: 1,
            total_ltv: 1
          },
          metricInfoConfig: {
            average_time_between_purchases: {
              visible: false,
              onClick: null
            },
            total_new_customers_count: {
              visible: false,
              onClick: null
            },
            total_rebuying_customers_count: {
                visible: false,
                onClick: null
              },
            total_ltv: {
              visible: true,
              onClick: () => setShowLTVModalExplanation(true)
            }
          },
          data: data
        }
    }

    const getSecondaryFilters = () => {
      return [
          {
              title: `${t('common.source')}`,
              options: originsOptions,
              value: selectedOriginsOptions,
              setValue: setSelectedOriginsOptions
          },
          {
              title: t('common.sales_channel'),
              options: salesChannelsOptions,
              value: selectedSalesChannelsOptions,
              setValue: setSelectedSalesChannelsOptions
          },
          {
              title: `${t('common.affiliates_vtex')}`,
              options: vtexAffiliatesOptions,
              value: selectedVtexAffiliatesOptions,
              setValue: setSelectedVtexAffiliatesOptions
          }
      ]
    }

    const onRenderComponent = useCallback(async () => {
      const { initialDateFilter, finalDateFilter } = getSelectedInitialDateRange();
      setDateRange([initialDateFilter, finalDateFilter]);

      await getCRMDataMetrics(
        formatDate(initialDateFilter),
        formatDate(finalDateFilter)
      );
    }, [getCRMDataMetrics]);

    useEffect(() => {
      onRenderComponent();
    }, [onRenderComponent]);

    return (
        <BackgroundNew
          titlePage={t('menu.decile')}
          showInformation={true}
          informationTooltipTitle={t('common.click_to_learn_more')}
          informationOnClick={() => setOpenModalInformation(true)}
        >
            <FilterContainer
                selectedDateRange={dateRange}
                setDateRange={setDateRange}
                onClickFilter={handleApplyFilter}
                showSecondaryFilters={true}
                secondaryFilters={getSecondaryFilters()}
            />
            <CardIndicator
                data={getCardIndicatorData(bigNumbersData)}
                currency={currency}
                isLoading={isLoading}
            />
            <CardGroup
                title={t('paidMediaCampaign.approved_revenue_platform')}
                tagIdentifierColor="#00CCAE"
                isLoading={isLoading}
                ref={cardRevenueDecileReg}
            >
                <ComposedGraphBarLine
                    xDataKey="decile"
                    data={getDecileMetricsDataList(decileMetricsData)}
                    showPrimaryBarYAxis={true}
                    primaryBarYAxisConfig={{
                        name: t('paidMediaCampaign.approved_revenue_platform'),
                        dataKey: "revenuePercentage"
                    }}
                    formatData={(data) => data}
                    formaterBarValue={formatValueToPercentage}
                    graphMarginConfig={{
                        top: 20,
                        right: -40,
                        left:0,
                        bottom: 20
                    }}
                    showDimensionController={false}
                    showFullScreenOption={true}
                    disabledDimensionController={decileMetricsData === null}
                    fullScreenEnabled={revenueDecileGraphFullScreen}
                    setFullScreenEnabled={setRevenueDecileGraphFullScreen}
                    hiddenLegend={true}
                    showExportPDFButton={true}
                    graphComponentRefForPDF={cardRevenueDecileReg}
                />
            </CardGroup>
            <ContainerTwoCardGroup>
              <CardGroup
                  title={t('geolocation.approved_orders_platform')}
                  tagIdentifierColor="#00CCAE"
                  isLoading={isLoading}
                  customClassName="cardGroupContainerFirst"
                  ref={cardAmountOrdersDecileRef}
              >
                <GraphBarHorizontal
                    data={getDecileMetricsDataList(decileMetricsData)}
                    YAxisDataKey="decile"
                    showSecondaryBarXAxis={true}
                    secondaryBarXAxisConfig={{
                        name: t('geolocation.approved_orders_platform'),
                        dataKey: "amountOrders"
                    }}
                    formatData={(data) => data}
                    formaterBarValue={formatValueToNumber}
                    graphMarginConfig={{
                      top: 20,
                      right: 26,
                      left:-110,
                      bottom: 20
                    }}
                    showDimensionController={true}
                    disabledDimensionController={decileMetricsData === null}
                    showFullScreenOption={true}
                    fullScreenEnabled={amountOrdersDecileGraphFullScreen}
                    setFullScreenEnabled={setAmountOrdersDecileGraphFullScreen}
                    maxDataToRender={10}
                    hiddenLegend={true}
                    showExportPDFButton={true}
                    graphComponentRefForPDF={cardAmountOrdersDecileRef}
                />
              </CardGroup>
              <CardGroup
                  title={t('decil.approved_average_ticket_platform')}
                  tagIdentifierColor="#00CCAE"
                  isLoading={isLoading}
                  customClassName="cardGroupContainerSecond"
                  ref={cardAvgTicketDecileRef}
              >
                <GraphBarHorizontal
                    data={getDecileMetricsDataList(decileMetricsData)}
                    YAxisDataKey="decile"
                    showSecondaryBarXAxis={true}
                    secondaryBarXAxisConfig={{
                        name: t('decil.approved_average_ticket_platform'),
                        dataKey: "approvedAvgTicket"
                    }}
                    formatData={(data) => data}
                    formaterBarValue={value => formatValueToMoney(value, currency)}
                    graphMarginConfig={{
                        top: 20,
                        right: 26,
                        left:-110,
                        bottom: 20
                    }}
                    showDimensionController={true}
                    disabledDimensionController={decileMetricsData === null}
                    showFullScreenOption={true}
                    fullScreenEnabled={avgTicketDecileGraphFullScreen}
                    setFullScreenEnabled={setAvgTicketDecileGraphFullScreen}
                    maxDataToRender={10}
                    hiddenLegend={true}
                    showExportPDFButton={true}
                    graphComponentRefForPDF={cardAvgTicketDecileRef}
                />
              </CardGroup>
            </ContainerTwoCardGroup>
            <LTVModalExplanation
              open={showLTVModalExplanation}
              handleClose={() => setShowLTVModalExplanation(false)}
              ltvDetailCalculation={bigNumbersData ? bigNumbersData.detail_calculation_indicators.ltv : null}
            />
            <ModalPageInformation
                title={t('decil.understand_decile_analysis')}
                open={openModalInformation}
                handleClose={() => setOpenModalInformation(false)}
                videoUrl={"https://www.youtube.com/embed/-7bUFBya7KI?si=SAf8dejFix11tYSH"}
            />
        </BackgroundNew>
    );
}

export default Decil;
