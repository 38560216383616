import styled from 'styled-components';

export const InputCustomized = styled.div`
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  margin-bottom: 10px;
  width: 100%;

  label {
    font-size: 15px;
    color: #000;
    margin-bottom: 5px;
    font-weight: 600;
    @media screen and (max-width: 1200px) {
      font-size: 16px;
    }
  }

  input {
    width: 100%;
    border: 1px solid #ccc;
    border-radius: 4px;
    padding: 10px 7px;
    color: #000;
    font-size: 15px;
    @media screen and (max-width: 1200px) {
      font-size: 16px;
    }
  }
`;