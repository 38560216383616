import React from "react";
import { v4 as uuidv4 } from "uuid";

import { useTranslation } from 'react-i18next';
import "../../../i18n";

import { TransparentButton } from "../styles";

import {
  Container,
  IndicatorLabel,
  IndicatorValue,
  BigNumbersContainer,
  BigNumbersIndicatorsContainer,
  BigNumbersIndicator,
  BigNumbersIndicatorLabel,
  BigNumbersIndicatorValue,
  BigNumbersTitleContainer,
  BigNumbersTitleMonth,
  BigNumbersTitleYear,
  BigNumbersActionsContainer,
  GoalsChannelsListContainer,
  GoalsChannelContainer,
  GoalsChannelTitle,
  GoalsChannelContent
} from "./styles";

export const CompanyGoalsView = ({
  data,
  formatValueToMoney,
  formatValueToNumber,
  getMonthName,
  handleEdit,
  showEditButton
}) => {
  const { t } = useTranslation();

  const getGoalsByMonth = (data) => {
    let goalsByMonth = {};

    data.forEach((goal) => {
      if (goalsByMonth.hasOwnProperty(goal.month)) {
        goalsByMonth[goal.month].push(goal);
      } else {
        goalsByMonth[goal.month] = [goal];
      }
    });

    return goalsByMonth;
  };

  const getGoalsByChannel = (goals) => {
    return goals.map((goal) => (
      <GoalsChannelContainer key={uuidv4()}>
        <GoalsChannelTitle>{goal.channel}</GoalsChannelTitle>
        <GoalsChannelContent>
          <IndicatorLabel>{t('common.investment')}</IndicatorLabel>
          <IndicatorValue> {formatValueToMoney(goal.investment)}</IndicatorValue>
          <IndicatorLabel>{t('common.sessions')}</IndicatorLabel>
          <IndicatorValue>{formatValueToNumber(goal.sessions)}</IndicatorValue>
          <IndicatorLabel>{t('common.conversion_rate')}</IndicatorLabel>
          <IndicatorValue>{goal.conversion_rate}%</IndicatorValue>
          <IndicatorLabel>{t('common.transactions')}</IndicatorLabel>
          <IndicatorValue>{formatValueToNumber(goal.transactions)}</IndicatorValue>
          <IndicatorLabel>{t('common.average_ticket')}</IndicatorLabel>
          <IndicatorValue>{formatValueToMoney(goal.avg_ticket)}</IndicatorValue>
          <IndicatorLabel>{t('common.revenue')}</IndicatorLabel>
          <IndicatorValue> {formatValueToMoney(goal.revenue)}</IndicatorValue>
        </GoalsChannelContent>
      </GoalsChannelContainer>
    ));
  };

  const getBigNumbersComponentByGoals = (goals, year, month) => {
    const revenueTotal = Math.round(goals.reduce((acc, goal) => acc + goal.revenue, 0));
    const transactionsTotal = goals.reduce((acc, goal) => acc + goal.transactions, 0);
    const investmentTotal = goals.reduce((acc, goal) => acc + goal.investment, 0);
    const sessionsTotal = goals.reduce((acc, goal) => acc + goal.sessions, 0);
    const avgTicket =  revenueTotal ? revenueTotal / transactionsTotal : 0;
    const conversionRate = sessionsTotal ? (transactionsTotal / sessionsTotal) * 100 : 0;

    return (
      <BigNumbersContainer key={uuidv4()}>
        <BigNumbersTitleContainer>
          <BigNumbersTitleMonth>{month}</BigNumbersTitleMonth>
          <BigNumbersTitleYear>{year}</BigNumbersTitleYear>
        </BigNumbersTitleContainer>
        <BigNumbersIndicatorsContainer>
          <BigNumbersIndicator>
            <BigNumbersIndicatorLabel>{t('common.revenue')}</BigNumbersIndicatorLabel>
            <BigNumbersIndicatorValue> {formatValueToMoney(revenueTotal)}</BigNumbersIndicatorValue>
          </BigNumbersIndicator>
          <BigNumbersIndicator>
            <BigNumbersIndicatorLabel>{t('common.average_ticket')}</BigNumbersIndicatorLabel>
            <BigNumbersIndicatorValue>{formatValueToMoney(avgTicket)}</BigNumbersIndicatorValue>
          </BigNumbersIndicator>
          <BigNumbersIndicator>
            <BigNumbersIndicatorLabel>{t('common.transactions')}</BigNumbersIndicatorLabel>
            <BigNumbersIndicatorValue>{formatValueToNumber(transactionsTotal)}</BigNumbersIndicatorValue>
          </BigNumbersIndicator>
          <BigNumbersIndicator>
            <BigNumbersIndicatorLabel>{t('common.conversion_rate')}</BigNumbersIndicatorLabel>
            <BigNumbersIndicatorValue>
              {Number.isInteger(conversionRate) ? conversionRate : conversionRate.toPrecision(3)}%
            </BigNumbersIndicatorValue>
          </BigNumbersIndicator>
          <BigNumbersIndicator>
            <BigNumbersIndicatorLabel>{t('common.sessions')}</BigNumbersIndicatorLabel>
            <BigNumbersIndicatorValue>{formatValueToNumber(sessionsTotal)}</BigNumbersIndicatorValue>
          </BigNumbersIndicator>
          <BigNumbersIndicator>
            <BigNumbersIndicatorLabel>{t('common.investment')}</BigNumbersIndicatorLabel>
            <BigNumbersIndicatorValue>{formatValueToMoney(investmentTotal)}</BigNumbersIndicatorValue>
          </BigNumbersIndicator>
        </BigNumbersIndicatorsContainer>
        {showEditButton && (
          <BigNumbersActionsContainer>
            <TransparentButton onClick={() => handleEdit(goals[0].month)}>{t('goalsManager.edit_goals')}</TransparentButton>
          </BigNumbersActionsContainer>
        )}
      </BigNumbersContainer>
    );
  };

  const renderGoalsMonths = (data) => {
    const goalsByMonth = getGoalsByMonth(data);

    const rows = [];

    Object.keys(goalsByMonth).forEach((month) => {
      rows.push(
        <Container key={uuidv4()}>
          {getBigNumbersComponentByGoals(goalsByMonth[month], goalsByMonth[month][0].year, getMonthName(month))}
          <GoalsChannelsListContainer>{getGoalsByChannel(goalsByMonth[month])}</GoalsChannelsListContainer>
        </Container>
      );
    });

    return (
      <>
        {rows}
      </>
    );
  };

  return renderGoalsMonths(data);
}
