import styled from 'styled-components';

export const RowContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  box-shadow: 0 0 20px rgba(215, 222, 227, 0.39);
  border-radius: 8px;
  border: 1px solid transparent;
  background-color: #ffffff;
  padding: 11px 20px 20px 20px;
  margin-bottom: 20px;

  .metric {
    &__name {
      font-size: 20px;
      font-weight: 300;
      color: #2e3039;
    }

    &__name-wrapper {
      width: 100%;
      display: flex;
      justify-content: flex-start;
      margin-bottom: 10px;
    }

    &__container {
      display: flex;
      width: 100%;

      @media screen and (max-width: 768px) {
        flex-direction: column;
      }

      .sessions_value {
        font-weight: bold;
        color: #ff0068;
      }
    }

    &__wrapper {
      padding: 5px 5px 5px 5px;
      flex-grow: 1;
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      box-shadow: 0 0 20px rgba(215, 222, 227, 0.39);
      border-radius: 8px;
      background-color: #ffffff;
      border: 1px solid #ffffff;

      &:not(:first-child) {
        margin-left: 27px;
      }

      @media screen and (max-width: 768px) {
        width: 100%;
        &:not(:first-child) {
          margin-left: 0px;
          margin-top: 16px;
          width: 100%;
        }
      }
    }

    &__range {
      color: #2e3039;
      font-size: 20px;
      font-weight: 300;
      color: #2e3039;
      text-align: left;
      margin-bottom: 9px;
      line-height: 1;
    }

    &__value-wrapper {
      width: 100%;
      display: flex;
      flex-direction: column;
      @media screen and (max-width: 768px) {
        flex-wrap: wrap;
      }
    }

    &__value {
      text-align: left;
      display: flex;
      justify-content: flex-start;
      flex-direction: column;
      font-weight: 700;
      margin-bottom: 12px;
      color: #2e3039;
    }

    &__label {
      color: #54617a;
    }

    &__value-suffix {
      opacity: 0.5;
      color: #54617a;
      font-size: 12px;
      font-weight: 400;
    }

    &__container {
      overflow: auto;

      /* width */
      ::-webkit-scrollbar {
        width: 20px;
        height: 6px;
      }

      /* Track */
      ::-webkit-scrollbar-track {
        box-shadow: #f1f1f1; 
        border-radius: 10px;
        width: 5px;
      }
      
      /* Handle */
      ::-webkit-scrollbar-thumb {
        background: #DEDEDE; 
        border-radius: 10px;
      }

      /* Handle on hover */
      ::-webkit-scrollbar-thumb:hover {
        background: #C8C8C8; 
      }
    }
  }

  .big__numbers {
    &__container {
      margin-bottom: 20px;
      width: 100%;
    }

    &__content {
      flex-direction: row;
      box-shadow: none;
      overflow: auto;
      justify-content: space-between;
      box-shadow: 0 0 20px rgba(215, 222, 227, 0.39);

      /* width */
      ::-webkit-scrollbar {
        width: 20px;
        height: 6px;
      }

      /* Track */
      ::-webkit-scrollbar-track {
        box-shadow: #f1f1f1; 
        border-radius: 10px;
        width: 5px;
      }
      
      /* Handle */
      ::-webkit-scrollbar-thumb {
        background: #DEDEDE; 
        border-radius: 10px;
      }

      /* Handle on hover */
      ::-webkit-scrollbar-thumb:hover {
        background: #C8C8C8; 
      }

      @media screen and (max-width: 768px) {
        flex-direction: column;
        box-shadow: none;
      }
    }

    &__row {
      font-size: 20px;
      padding: 20px 20px 10px 20px;
      border-radius: 8px;

      :not(:last-child) {
        margin-right: 26px;
      }

      @media screen and (max-width: 768px) {
        width: 100%;
        margin-right: 0px !important;
        margin-top: 16px;
        box-shadow: 0 0 20px rgba(215, 222, 227, 0.39);
      }
    }
  }
`;

export const MessageProjectedGoalsNotValid = styled.div`
  display: flex;
  padding: 20px;
  background-color: #ffd119;
  margin-bottom: 20px;
  font-size: 15px;
  color: #2e3039;
  font-weight: 500;
`