import styled from 'styled-components';
import { darken } from 'polished';

export const Container = styled.div`
    font-size: 15px;
    font-weight: 400;
    width: 100%;

    .whatAnalysis, .whyCustomers, .conclusion {
        background-color: #F4F4F4;
        border-radius: 12px;
        margin-bottom: 20px;
        padding: 20px;
        page-break-inside: avoid;
        page-break-before: avoid;

        p {
            font-weight: lighter;
            margin-bottom: 10px;
        }
    }

    .conclusion {
        background-color: #ff0068;
        color: #fff;
        
        p {
            font-weight: 400;
        }
    }

    .whyCampaignsAnalysis, .whyCategoriesAndProductsAnalysis, .whyChannels {
        margin-bottom: 20px;
        
        p {
            font-weight: lighter;
            margin-bottom: 10px;
        }

        .whyCampaignsAnalysisTitle, .whyCategoriesAndProductsAnalysisTitle, .whyChannelsTitle {
            font-size: 20px;
        }

        .whyCampaignsAnalysisExplanation, .whyCategoriesAndProductsAnalysisExplanation, .whyChannelsExplanation {
            padding: 20px;
            background-color: #F4F4F4;
            border-radius: 12px;
            page-break-inside: avoid;
            page-break-before: avoid;
        }
    }

    .tableInsightsContainer {
        overflow-x: auto;
        margin-bottom: 20px;
        padding: 0px 20px;

        .tableInsights {
            width: 100%;
            border-collapse: collapse;
            border-spacing: 0;
            font-size: 13px;
    
            thead {
                tr {
                    th {
                        padding: 10px;
                        padding-left: 0px;
                        text-align: left;
                        border-bottom: 3px solid rgb(0, 204, 174);
                        color: #4C4C4C;
                        font-size: 13px;
                        font-weight: 500;
                    }
                }
            }
            tbody {
                tr {
                    border-bottom: 1px solid #F4F4F4;
                    td {
                        padding: 12px;
                        padding-left: 0px;
                        font-size: 15px;
                        font-weight: lighter;

                        .tableTdTag {
                            padding: 5px;
                            border-radius: 5px;
                            background-color: #DEDEDE;
                        }
                    }
                }
            }
        }
    }

    .channelSuggestions {
        margin-bottom: 20px;

        .channelSuggestion {
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            margin-bottom: 20px;
            border-top: 1px solid #F4F4F4;
            padding-top: 20px;
            page-break-inside: avoid;
            page-break-before: avoid;

            .channelSuggestionHeader {
                display: flex;
                align-items: center;
                justify-content: space-between;
                width: 100%;
                margin-bottom: 10px;
                border-bottom: 1px solid #000;
                padding-bottom: 10px;

                @media (max-width: 415px) {
                    flex-direction: column;
                    justify-content: center;

                    .channelSuggestionHeaderActions {
                        margin-top: 20px;
                    }
                }

                .channelSuggestionNumber {
                    font-size: 20px;
                    width: 100%;
                    font-weight: lighter;
                    flex: 1;

                    .tagIdentifier {
                        background-color: #ff0068;
                        border-radius: 4px;
                        padding: 2px 6px;
                        height: 50px;
                        margin-right: 10px;
                    }
                }

                .channelSuggestionHeaderActions {
                    display: flex;
                    align-items: center;
                    flex: 1;
                    justify-content: flex-end;

                    button {
                        background-color: #F4F4F4;
                        border: none;
                        color: #ff0068;
                        font-size: 13px;
                        font-weight: 500;
                        padding: 10px 15px;
                        border-radius: 20px;
                        cursor: pointer;
                        transition: background-color 0.3s;
                        display: flex;
                        align-items: center;
                        margin-right: 10px;
                        border: 2px solid #ff0068;

                        &:hover {
                            background-color: ${darken(0.1, '#ff0068')};
                            color: #ffffff;
                            border-color: transparent;
                        }
                    }

                    button + button {
                        margin-left: 10px;
                    }
                }
            }

            .channelSuggestionContent {
                padding: 20px;
                border-radius: 12px;
                background-color: #f2f2f2;
                margin-top: 10px;
                page-break-inside: avoid;
                page-break-before: avoid;

                p {
                    margin-bottom: 10px;
                    font-weight: lighter;
                }

                .crmSuggestedCampaignContentProducts {
                    margin-bottom: 10px;
                    margin-left: 30px;

                    li {
                        margin-bottom: 2px;
                        font-weight: lighter;
                    }
                }

                .channelSuggestionContentExplanation {
                    padding: 20px;
                    border-radius: 12px;
                    background-color: #ff0068;
                    color: #fff;
                    font-weight: 400;
                }
            }
        }
    }

    .usersFeedback {
        margin-bottom: 20px;
        margin-left: 20px;
        margin-right: 20px;
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 10px;
        border-radius: 5px;
        border: 1px solid #e6e6e6;

        .usersFeedbackActions {
            display: flex;
            align-items: center;
            margin-left: 10px;

            button {
                padding: 5px 10px;
                border: none;
                border-radius: 5px;
                background-color: #ff0068;
                color: #fff;
                font-size: 14px;
                font-weight: bold;
                cursor: pointer;
                transition: background-color 0.3s;

                &:hover {
                    background-color: ${darken(0.1, '#ff0068')};
                }
            }

            button + button {
                margin-left: 10px;
            }
        }
    }
`;