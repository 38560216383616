import api from './api';

export const getAlerts = async (options) => {
  const token = localStorage.getItem('token');
  const alerts = await api.get('/alerts/', {
    headers: {
      Authorization: `Bearer ${token}`,
    },
    ...options,
  });

  return alerts.data;
};

export const getModules = async () => {
  const token = localStorage.getItem('token');
  
  const accountModules = await api.get('/alerts/modules', {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });

  return accountModules.data;
};

export const getUserModules = async () => {
  const token = localStorage.getItem('token');
  
  const userModules = await api.get('/alerts/user_modules', {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });

  return userModules.data;
};

export const createUserModule = async (id, data) => {
  const token = localStorage.getItem('token');
  
  const response = await api.post(`/alerts/user_module/${id}`, data, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });

  if (response.status !== 200) {
    throw new Error();
  }

  return response.data;
};