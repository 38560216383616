import React from 'react';

import { useTranslation } from 'react-i18next';
import '../../../i18n';

import * as S from './styled';

function CustomerModalDeleteGoals(props) {
  const { t } = useTranslation();

  return (
    <S.CustomerModalDeleteGoals open={props.open}>
      <div className="modal">
        <div className="modal__header">
          <p className="modal__title">{t('mediaPlanner.delete_planning')}</p>
          <div className="line" />
        </div>

        <div className="modal__body">
            <p>
              {t('mediaPlanner.are_you_sure_delete_planning')} <br />
              <strong>{t('common.this_action_cannot_be_undone')}</strong>
            </p>
        </div>

        <div className="modal__footer">
          <button
            className="modal__form-btn is--cancel"
            type="button"
            onClick={props.handleClose}
          >
            {t('common.cancel')}
          </button>
          <button
            className={`modal__form-btn is--save`}
            type="button"
            onClick={props.deleteCustomerGoals}
          >
            {t('common.confirm')}
          </button>
        </div>
      </div>
    </S.CustomerModalDeleteGoals>
  );
}

export default CustomerModalDeleteGoals;
