import styled from 'styled-components';

export const AuthenticateMessageContainer = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100%;
    color: #6F767E;
`;

export const AuthenticateMessage = styled.p`
    font-size: 16px;
    font-weight: 500;
    margin-left: 10px;
`;

export const AuthenticateMessageExplanation = styled.p`
    font-size: 14px;
    font-weight: 400;
    margin-top: 10px;
    color: #6F767E;
`;

export const AuthenticateMessageIcon = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 50px;
    color: ${({ isSuccess }) => (isSuccess ? '#00CCAE' : '#AA3966')};
    margin-bottom: 10px;
`;







