import styled from 'styled-components';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';

export const AlertMenuButton = styled.button`
    display: flex;
    align-items: center;
    color: rgba(129, 129, 129, 0.5);
    font-size: 12px;
    background: transparent;
    border: none;
`;

export const AlertMenu = styled(Menu)``;

export const AlertMenuItem = styled(MenuItem)``;