import React, { useEffect, useCallback, useState, useRef, useContext } from 'react';

import { toast } from 'react-toastify';

import { useTranslation } from 'react-i18next';
import '../../i18n';

import CardGroup from '../../components/CardGroup';
import BackgroundNew from '../../components/BackgroundNew';
import FilterContainer from '../../components/FilterContainer';
import ComposedGraphBarLine from '../../components/ComposedGraphBarLine';
import CustomModalLogisticCallToAction from '../../components/CustomModal/CustomModalLogisticCallToAction';
import IntelipostIcon from '../../assets/intelipost_icon.webp';

import { DIMENSIONS_OPTONS} from '../../options/filterOptions';
import { getSelectedInitialDateRange, formatDate, getAgregatedKeyByDimension } from '../../utils/dateUtils';
import { formatValueToNumber, formatValueToPercentage } from '../../utils/metricsUtils';
import { getTranslatedShippingTypeOptionsList, reverseTranslatedShippingTypeOptionsList } from '../../utils/logisticPagesUtils';

import { getLogisticShippingMethodEvolutionMetric } from '../../services/dashboardApiService';

import { AuthContext } from '../../contexts';

const LogisticShippingMethodEvolution = () => {
    const { t } = useTranslation();
    const { timezone } = useContext(AuthContext);

    const [dateRange, setDateRange] = useState();
    const [selectedDimension, setSelectedDimension] = useState(DIMENSIONS_OPTONS.day);

    const [shippingMethodsOptions, setShippingMethodsOptions] = useState([]);
    const [citiesOptions, setCitiesOptions] = useState ([]);
    const [statesOptions, setStatesOptions] = useState ([])
    const [originsOptions, setOriginsOptions] = useState([]);
    const [salesChannelsOptions, setSalesChannelsOptions] = useState([]);
    const [shippingTypesOptions, setShippingTypesOptions] = useState([]);
    const [selectedShippingTypesOptions, setSelectedShippingTypesOptions] = useState([]);
    const [selectedShippingMethodsOptions, setSelectedShippingMethodsOptions] = useState([]);
    const [selectedStatesOptions, setSelectedStatesOptions] = useState([]);
    const [selectedCitiesOptions, setSelectedCitiesOptions] = useState([]);
    const [selectedOriginsOptions, setSelectedOriginsOptions] = useState([]);
    const [selectedSalesChannelsOptions, setSelectedSalesChannelsOptions] = useState([]);

    const [isLoading, setIsLoading] = useState(true);
    const [shippingMethodEvolutionMetricsData, setShippingMethodEvolutionMetricsData] = useState(null);

    const [shippingMethodShareGraphFullScreen, setShippingMethodShareGraphFullScreen] = useState(false);
    const [shippingMethodOrderQuantityGraphFullScreen, setShippingMethodOrderQuantityGraphFullScreen] = useState(false);
    const [openCustomModalLogisticCallToAction, setOpenCustomModalLogisticCallToAction] = useState(false);

    const cardShippingMethodShareRef = useRef(null);
    const cardShippingMethodOrderQuantityRef = useRef(null);


    const handleApplyFilter = async () => {
        const [startDate, endDate] = dateRange;
        const shippingMethods = selectedShippingMethodsOptions.length > 0 ? [...selectedShippingMethodsOptions] : null;
        const shippingTypes = selectedShippingTypesOptions.length > 0 ? reverseTranslatedShippingTypeOptionsList([...selectedShippingTypesOptions]) : null;
        const states = selectedStatesOptions.length > 0 ? [...selectedStatesOptions] : null;
        const cities = selectedCitiesOptions.length > 0 ? [...selectedCitiesOptions] : null;
        const origins = selectedOriginsOptions.length > 0 ? [...selectedOriginsOptions] : null;
        const salesChannels = selectedSalesChannelsOptions.length > 0 ? [...selectedSalesChannelsOptions] : null;

        await getLogisticShippingMethodEvolutionMetricsData(
            formatDate(startDate),
            formatDate(endDate),
            shippingMethods,
            states,
            cities,
            origins,
            salesChannels,
            shippingTypes
        );
    };

    const getLogisticShippingMethodEvolutionMetricsData = useCallback(async (
        initialDate,
        finalDate,
        shippingMethods,
        states,
        cities,
        origins,
        salesChannels,
        shippingTypes
    ) => {
        setIsLoading(true);

        try {
            const response = await getLogisticShippingMethodEvolutionMetric({
                initialDate,
                finalDate,
                timezone,
                shippingMethods,
                cities,
                states,
                origins,
                salesChannels,
                shippingTypes
            });

            if (!response) {
                setShippingMethodEvolutionMetricsData(null);
                setShippingMethodsOptions([]);
                setShippingTypesOptions([]);
                setCitiesOptions([]);
                setStatesOptions([]);
                setOriginsOptions([]);
                setSalesChannelsOptions([]);

                return;
            }

            setShippingMethodEvolutionMetricsData(response.results)
            setShippingMethodsOptions(response.available_shipping_methods_to_filter)
            setShippingTypesOptions(getTranslatedShippingTypeOptionsList(response.available_shipping_types_to_filter))
            setCitiesOptions(response.available_cities_to_filter)
            setStatesOptions(response.available_states_to_filter)
            setOriginsOptions(response.available_origins_to_filter)
            setSalesChannelsOptions(response.available_marketplace_channels_to_filter)
        } catch (error) {
            toast.error(error.message);
            setShippingMethodEvolutionMetricsData(null)
            setShippingMethodsOptions([]);
            setShippingTypesOptions([]);
            setCitiesOptions([]);
            setStatesOptions([]);
            setOriginsOptions([]);
            setSalesChannelsOptions([]);
        } finally {
          setIsLoading(false);
        }

    }, [timezone]);

    const generateShippingMethodsKey = (shippingMethod, complementKey) => {
        return `${shippingMethod}_${complementKey}`;
    }

    const getShippingMethodGraphConfigs = (shippingMethods, complementKey) => {
        if (!shippingMethods) { return []; }

        const configs = [];

        shippingMethods.forEach(shippingMethod => {
            const shippingMethodDataKey = generateShippingMethodsKey(shippingMethod, complementKey);

            configs.push({
                name: shippingMethod || " ",
                dataKey: shippingMethodDataKey
            });
        })

        return configs;
    }

    const formatDataByDateAndShippingMethods = (data, dimension) => {
        if(!data) { return null}

        const aggregatedData = {};

        const shippingMethods = new Set();

        let totalOrderQuantityByKey = {};

        data.forEach(entry => {
            const key = getAgregatedKeyByDimension(entry.date, dimension);

            const shippingMethodShareKey = generateShippingMethodsKey(entry.shipping_method, "share");
            const shippingMethodOrdersQuantityKey = generateShippingMethodsKey(entry.shipping_method, "ordersQuantity");

            shippingMethods.add(entry.shipping_method);

            if (!aggregatedData[key]) {
                aggregatedData[key] = {
                    date: key
                };
            }

            if (!aggregatedData[key][shippingMethodOrdersQuantityKey]) {
                aggregatedData[key][shippingMethodShareKey] = 0;
                aggregatedData[key][shippingMethodOrdersQuantityKey] = 0;
            }

            aggregatedData[key][shippingMethodOrdersQuantityKey] += entry.orders_quantity;
            aggregatedData[key][shippingMethodShareKey] = entry.share;

            if (!totalOrderQuantityByKey[key]) {
                totalOrderQuantityByKey[key] = 0;
            }

            totalOrderQuantityByKey[key] += entry.orders_quantity;
        });

        Object.keys(aggregatedData).forEach(key => {
            let shippingMethodSharePercentagePreviousTotal = 0.0;
            let shippingMethodShareLastKey = null;

            shippingMethods.forEach(shippingMethod => {
                const shippingMethodShareKey = generateShippingMethodsKey(shippingMethod, "share");
                const shippingMethodOrdersQuantityKey = generateShippingMethodsKey(shippingMethod, "ordersQuantity");

                if (!aggregatedData[key][shippingMethodOrdersQuantityKey]) { return }

                aggregatedData[key][shippingMethodShareKey] = totalOrderQuantityByKey[key] > 0 ? Number(((aggregatedData[key][shippingMethodOrdersQuantityKey] / totalOrderQuantityByKey[key]) * 100).toFixed(2)) : 0;

                shippingMethodSharePercentagePreviousTotal += aggregatedData[key][shippingMethodShareKey];
                shippingMethodShareLastKey = shippingMethodShareKey;
            });

            // Adjusting the last marketplace approved revenue percentage to be 100%
            if (shippingMethodSharePercentagePreviousTotal !== 100) {
                const diff = 100 - (shippingMethodSharePercentagePreviousTotal);
                aggregatedData[key][shippingMethodShareLastKey] += diff;
                aggregatedData[key][shippingMethodShareLastKey] = Number((aggregatedData[key][shippingMethodShareLastKey]).toFixed(2));
            }
        })

        return Object.values(aggregatedData);
    };

    const getSecondaryFilters = () => {
        return [
            {
                title: t('logisticShippingMethodEvolution.shipping_method'),
                options: shippingMethodsOptions,
                value: selectedShippingMethodsOptions,
                setValue: setSelectedShippingMethodsOptions
            },
            {
                title: t('logisticShippingMethodEvolution.shipping_type'),
                options: shippingTypesOptions,
                value: selectedShippingTypesOptions,
                setValue: setSelectedShippingTypesOptions
            },
            {
                title: t('logisticShippingMethodEvolution.cities'),
                options: citiesOptions,
                value: selectedCitiesOptions,
                setValue: setSelectedCitiesOptions
            },
            {
                title: t('common.states'),
                options: statesOptions,
                value: selectedStatesOptions,
                setValue: setSelectedStatesOptions
            },
            {
                title: `${t('common.source')}`,
                options: originsOptions,
                value: selectedOriginsOptions,
                setValue: setSelectedOriginsOptions
            },
            {
                title: t('common.sales_channel'),
                options: salesChannelsOptions,
                value: selectedSalesChannelsOptions,
                setValue: setSelectedSalesChannelsOptions
            }
        ]
      }

      const onRenderComponent = useCallback(async () => {
        const { initialDateFilter, finalDateFilter } = getSelectedInitialDateRange();
        setDateRange([initialDateFilter, finalDateFilter]);

        await getLogisticShippingMethodEvolutionMetricsData(
          formatDate(initialDateFilter),
          formatDate(finalDateFilter)
        );
      }, [getLogisticShippingMethodEvolutionMetricsData]);

      useEffect(() => {
        onRenderComponent();
      }, [onRenderComponent]);


    return (
        <BackgroundNew
          titlePage={t('menu.shipping_methods')}
          showButtonAction={true}
          buttonActionOnClick={_ => setOpenCustomModalLogisticCallToAction(true)}
          buttonActionTitle={t('logisticShippingMethodEvolution.integrate')}
          showButtonActionComplement={true}
          buttonActionComplement={
            <img
                alt='Intelipost'
                src={IntelipostIcon}
                style={{ width: 'auto', height: '35px', borderRadius: '50%'}}
            />
          }
        >
            <FilterContainer
                selectedDateRange={dateRange}
                setDateRange={setDateRange}
                onClickFilter={handleApplyFilter}
                showSecondaryFilters={true}
                secondaryFilters={getSecondaryFilters()}
            />
                <CardGroup
                    title={t('logisticShippingMethodEvolution.share_of_approved_orders_by_shipping_method')}
                    tagIdentifierColor="#00CCAE"
                    isLoading={isLoading}
                    customClassName="cardGroupContainerFirst"
                    ref={cardShippingMethodShareRef}
                >
                    <ComposedGraphBarLine
                        data={shippingMethodEvolutionMetricsData}
                        dimension={selectedDimension}
                        showMultiBarStacked={true}
                        multiBarStackedConfigs={getShippingMethodGraphConfigs(shippingMethodsOptions, "share")}
                        formatData={formatDataByDateAndShippingMethods}
                        formaterBarValue={formatValueToPercentage}
                        graphMarginConfig={{
                            top: 20,
                            right: -30,
                            left: 5,
                            bottom: 20
                        }}
                        showDimensionController={true}
                        setSelectedDimension={setSelectedDimension}
                        disabledDimensionController={shippingMethodEvolutionMetricsData === null || shippingMethodEvolutionMetricsData.length === 0}
                        showFullScreenOption={true}
                        fullScreenEnabled={shippingMethodShareGraphFullScreen}
                        setFullScreenEnabled={setShippingMethodShareGraphFullScreen}
                        domainBarYAxis={[0, 100]}
                        showExportPDFButton={true}
                        graphComponentRefForPDF={cardShippingMethodShareRef}
                    />
                </CardGroup>
                <CardGroup
                    title={t('logisticShippingMethodEvolution.quantity_of_approved_orders_by_shipping_method')}
                    tagIdentifierColor="#00CCAE"
                    isLoading={isLoading}
                    customClassName="cardGroupContainerFirst"
                    ref={cardShippingMethodOrderQuantityRef}
                >
                    <ComposedGraphBarLine
                        data={shippingMethodEvolutionMetricsData}
                        dimension={selectedDimension}
                        showMultiBarStacked={true}
                        multiBarStackedConfigs={getShippingMethodGraphConfigs(shippingMethodsOptions, "ordersQuantity")}
                        formatData={formatDataByDateAndShippingMethods}
                        formaterBarValue={formatValueToNumber}
                        graphMarginConfig={{
                            top: 20,
                            right: -30,
                            left:0,
                            bottom: 20
                        }}
                        showDimensionController={true}
                        setSelectedDimension={setSelectedDimension}
                        disabledDimensionController={shippingMethodEvolutionMetricsData === null || shippingMethodEvolutionMetricsData.length === 0}
                        showFullScreenOption={true}
                        fullScreenEnabled={shippingMethodOrderQuantityGraphFullScreen}
                        setFullScreenEnabled={setShippingMethodOrderQuantityGraphFullScreen}
                        showExportPDFButton={true}
                        graphComponentRefForPDF={cardShippingMethodOrderQuantityRef}
                    />
                </CardGroup>
            <CustomModalLogisticCallToAction
                open={openCustomModalLogisticCallToAction}
                handleClose={_ => setOpenCustomModalLogisticCallToAction(false)}
            />
        </BackgroundNew>
    );

}

export default LogisticShippingMethodEvolution
