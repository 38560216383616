import styled from 'styled-components';
import { darken } from 'polished';

export const SidebarContainer = styled.div`
  display: flex;
  flex-direction: column;
  height: 100vh;
  width: ${({ isExpanded, expandedWidth, normalWith }) => (isExpanded ? `${expandedWidth}px` : `${normalWith}px`)};
  background-color: #FAFAFA;
  position: fixed;
  top: 0;
  left: 0;
  transition: width 0.3s ease-in-out;
  z-index: 1000;
  border-right: ${({ isExpanded }) => (isExpanded ? '2px solid #E7E5E5' : 'none')};
`;

export const SidebarHeader = styled.div`
  padding: 20px;
  color: #000;
  font-size: 1.5rem;
  background-color: #FAFAFA;
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: ${({ isMobile }) => (isMobile ? '60px' : 'auto')};
`;

export const SidebarMenu = styled.ul`
  list-style-type: none;
  padding: 0;
  margin: 0;
  margin-top: ${({ isMobile }) => (isMobile ? '0px' : '32px')};
  display: flex;
  flex-direction: column;
  align-items: ${({ isExpanded }) => (isExpanded ? 'flex-start' : 'center')};
`;

export const SidebarItem = styled.li`
  width: 100%;
  padding: 15px 20px;
  color: ${({ isSelected }) => (isSelected ? '#ff0068' : '#1A1D1F')};
  cursor: pointer;
  display: flex;
  align-items: center;
  font-size: 14px;
  font-weight: ${({ isSelected }) => (isSelected ? '700' : '300')};
  line-height: 20px;
  background-color: ${({ isSelected }) => (isSelected ? '#F2F2F2' : 'transparent')};
  transition: background-color 0.2s ease-in-out;
  &:hover {
    background-color: #F2F2F2;
  }
`;

export const MenuIcon = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: ${({ isExpanded }) => (isExpanded ? '12px' : '0')};
  transition: margin-right 0.3s ease-in-out;
  color: ${({ isSelected }) => (isSelected ? '#ff0068' : '#6F767E')};
`;

export const Logo = styled.img`
  width: auto;
  height: ${({ isExpanded }) => (isExpanded ? '35px' : '40px')};
  margin-top: ${({ isExpanded }) => (isExpanded ? '10px' : '4px')};
`;

export const ButtonOpenMenu = styled.button`
  position: absolute;
  width: 30px;
  height: 30px;
  background-color: #FFFFFF;
  border-radius: 8px;
  color: #6F767E;
  cursor: pointer;
  border: none;
  top: 32px;
  right: -20px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const ButtonCloseMenu = styled.button`
  margin-left: 5px;
  padding-top: 10px;
  border: none;
  color: #6F767E;
  background-color: transparent;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 15px;
`;

export const SubMenuContainer = styled.div`
  position: absolute;
  left: 100.5%;
  top: 0;
  bottom: 0;
  margin-top: ${({ isMobile }) => (isMobile ? '60px' : '94px')};
  background-color: #F2F2F2;
  z-index: 1000;
  display: flex;
  flex-direction: column;
  width: 280px;
  cursor: default;
  overflow-y: auto;
  border-right: 2px solid #E7E5E5;
`;

export const SubMenuItemContainer = styled.div`
  position: relative;
  padding: 10px 20px;
  background-color: ${({ isSelected }) => (isSelected ? '#FF0068' : 'transparent')};
  display: flex;
  align-items: center;
  justify-content: space-between;

  &:hover {
    background-color: ${({ isSelected }) => (isSelected ? '#FF0068' : '#E7E5E5')};
  }
`;

export const SubMenuItem = styled.button`
  cursor: pointer;
  width: 100%;
  background-color: transparent;
  border: none;
  display: flex;
  align-items: center;
  font-size: 14px;
  font-weight: ${({ isSelected }) => (isSelected ? '700' : '300')};
  color: ${({ isSelected }) => (isSelected ? '#FFFFFF' : '#1A1D1F')};
  line-height: 32px;
`;

export const SidebarActions = styled.div`
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
`;

export const ButtonLogout = styled.button`
  width: 100%;
  height: 68px;
  cursor: pointer;
  font-size: 14px;
  color: #FFFFFF;
  line-height: 32px;
  background-color: #D5236A;
  border: none;
  display: flex;
  align-items: center;
  padding: 10px 20px;
  margin-top: 16px;

  &:hover {
    background-color: ${darken(0.05, '#D5236A')};
  }
`;

export const MenuItemTag = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: auto;
  height: 14px;
  background-color: #6F767E;
  padding: 4px;
  color: #FFFFFF;
  font-size: 8px;
  font-weight: 400;
  line-height: 10px;
  border-radius: 8px;
`;

export const MenuItemTagNewFeature = styled(MenuItemTag)`
  background-color: #00CCAE;
  margin-left: 10px;
`;

export const MenuItemTagDeprecated = styled(MenuItemTag)`
  background-color: #AA3966;
  margin-left: 10px;
`;

export const SubMenuItemTag = styled(MenuItemTag)`
  position: absolute;
  top: 2px;
`;

export const SubMenuItemTagNewFeature = styled(SubMenuItemTag)`
  background-color: #00CCAE;
`;

export const SubMenuItemTagDeprecated = styled(SubMenuItemTag)`
  background-color: #AA3966;
`;

export const ButtonFavorite = styled.button`
  background-color: transparent;
  border: none;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: auto;
  color: ${({ isSelected }) => (isSelected ? '#FFFFFF' : '#6F767E')};
  width: 24px;
  height: 24px;
`;

export const NoFavorites = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  color: #6F767E;
`;
  