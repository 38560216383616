import React, { useEffect, useCallback, useState, useRef, useContext } from 'react';

import { toast } from 'react-toastify';

import { useTranslation } from 'react-i18next';
import '../../i18n';

import BackgroundNew from '../../components/BackgroundNew';
import FilterContainer from '../../components/FilterContainer';
import CardGroup from '../../components/CardGroup';
import CustomTable from '../../components/CustomTable';
import AddressesWithDeliveryFailuresTable from './AddressesWithDeliveryFailuresTable';
import DeliveryFailuresQuarterModal from './DeliveryFailuresQuarterModal';
import CustomModalLogisticCallToAction from '../../components/CustomModal/CustomModalLogisticCallToAction';
import IntelipostIcon from '../../assets/intelipost_icon.webp';

import { getSelectedInitialDateRange, formatDate } from '../../utils/dateUtils';

import {
    getIntelipostAddressesWithDeliveryFailuresByShippingCompany,
    getIntelipostAddressesWithDeliveryFailuresByQuarters,
    getIntelipostShipmentOrdersWithDeliveryProblem,
    getIntelipostShipmentOrdersWithPlpProblem
} from '../../services/dashboardApiService';

import { AuthContext } from '../../contexts';

const LogisticIntelipostAddressesWithDeliveryFailures = () => {
    const { t } = useTranslation();
    const { timezone } = useContext(AuthContext);

    const [dateRange, setDateRange] = useState();
    const [isLoading, setIsLoading] = useState(true);
    const [isLoadingQuarters, setIsLoadingQuarters] = useState(false);

    const [shippingCompaniesOptions, setShippingCompaniesOptions] = useState([]);
    const [statesOptions, setStatesOptions] = useState([]);
    const [citiesOptions, setCitiesOptions] = useState([]);
    const [quartersOptions, setQuartersOptions] = useState([]);
    const [selectedShippingCompanyOptions, setSelectedShippingCompanyOptions] = useState([]);
    const [selectedStatesOptions, setSelectedStatesOptions] = useState([]);
    const [selectedCitiesOptions, setSelectedCitiesOptions] = useState([]);
    const [selectedQuartersOptions, setSelectedQuartersOptions] = useState([]);

    const [addressesWithDeliveryFailuresData, setAddressesWithDeliveryFailuresData] = useState([]);
    const [addressesWithDeliveryFailuresTableFullScreen, setAddressesWithDeliveryFailuresFullScreen] = useState(false);
    const [shipmentOrdersWithProblemData, setShipmentOrdersWithProblemData] = useState([]);
    const [shipmentOrdersWithProblemTableFullScreen, setShipmentOrdersWithProblemFullScreen] = useState(false);

    const [shipmentOrdersWithPlpProblemData, setShipmentOrdersWithPlpProblemData] = useState([]);
    const [shipmentOrdersWithPlpProblemTableFullScreen, setShipmentOrdersWitPlphProblemFullScreen] = useState(false);

    const [addressesWithDeliveryFailuresQuartersData, setAddressesWithDeliveryFailuresQuartersData] = useState([]);
    const [openAddressesWithDeliveryFailuresQuartersModal, setOpenAddressesWithDeliveryFailuresQuartersModal] = useState(false);
    const [openCustomModalLogisticCallToAction, setOpenCustomModalLogisticCallToAction] = useState(false);

    const addressesWithDeliveryProblemsRef = useRef(null);
    const ordersWithDeliveryProblemsRef = useRef(null);
    const ordersWithDeliveryProblemsPLPRef = useRef(null);

    const getDataByAppliedFilter = async () => {
        const filter = getActiveFilters()

        await getDataMetrics(filter);
    };

    const getActiveFilters = () => {
        const [startDate, endDate] = dateRange;
        const shippingCompanies = selectedShippingCompanyOptions.length > 0 ? [...selectedShippingCompanyOptions] : null;
        const states = selectedStatesOptions.length > 0 ? [...selectedStatesOptions] : null;
        const cities = selectedCitiesOptions.length > 0 ? [...selectedCitiesOptions] : null;
        const quarters = selectedQuartersOptions.length > 0 ? [...selectedQuartersOptions] : null;

        const filter = {
            initialDate: formatDate(startDate),
            finalDate: formatDate(endDate),
            timezone,
            shippingCompanies,
            states,
            cities,
            quarters
        }

        return filter;
    }

    const handleApplyFilter = async () => {
        getDataByAppliedFilter();
    }

    const handleClickShowQuarters = async (shippingCompany, state, city) => {
        setIsLoadingQuarters(true);
        setOpenAddressesWithDeliveryFailuresQuartersModal(true);

        const quarters = selectedQuartersOptions.length > 0 ? [...selectedQuartersOptions] : null;

        const filter = {
            initialDate: formatDate(dateRange[0]),
            finalDate: formatDate(dateRange[1]),
            shippingCompanies: [shippingCompany],
            states: [state],
            cities:[city],
            quarters
        }

        try {
            const response = await getIntelipostAddressesWithDeliveryFailuresByQuarters(filter);

            if (!response) {
                setAddressesWithDeliveryFailuresQuartersData([]);
                return;
            }

            setAddressesWithDeliveryFailuresQuartersData(response);
        } catch (error) {
            toast.error(error.message);
            setAddressesWithDeliveryFailuresQuartersData([]);
        } finally {
            setIsLoadingQuarters(false);
        }
    }

    const handleCloseMostCommunDeliveryTimesQuartersModal = () => {
        setOpenAddressesWithDeliveryFailuresQuartersModal(false);
        setAddressesWithDeliveryFailuresQuartersData([]);
    }

    const combineFiltersWithoutDuplicates = (filters) => {
        return Array.from(new Set(filters));
    }

    const getDataMetrics = useCallback(async (filter) => {
        setIsLoading(true);

        try {
            const response = await Promise.all([
                getIntelipostAddressesWithDeliveryFailuresByShippingCompany(filter),
                getIntelipostShipmentOrdersWithDeliveryProblem(filter),
                getIntelipostShipmentOrdersWithPlpProblem(filter)
            ]);

            if (!response[0] || !response[1]|| !response[2]) {
                setAddressesWithDeliveryFailuresData([]);
                setShipmentOrdersWithProblemData([]);
                setShipmentOrdersWithPlpProblemData([]);
                setShippingCompaniesOptions([]);
                setStatesOptions([]);
                setCitiesOptions([]);
                setQuartersOptions([]);

                return;
            }

            setAddressesWithDeliveryFailuresData(response[0].results);
            setShipmentOrdersWithProblemData(response[1].results);
            setShipmentOrdersWithPlpProblemData(response[2].results);
            setShippingCompaniesOptions(combineFiltersWithoutDuplicates([
                ...response[0].available_shipping_companies_to_filter,
                ...response[1].available_shipping_companies_to_filter,
                ...response[2].available_shipping_companies_to_filter
            ]));
            setStatesOptions(combineFiltersWithoutDuplicates([
                ...response[0].available_states_to_filter,
                ...response[1].available_states_to_filter,
                ...response[2].available_states_to_filter
            ]));
            setCitiesOptions(combineFiltersWithoutDuplicates([
                ...response[0].available_cities_to_filter,
                ...response[1].available_cities_to_filter,
                ...response[2].available_cities_to_filter
            ]));
            setQuartersOptions(combineFiltersWithoutDuplicates([
                ...response[0].available_quarters_to_filter,
                ...response[1].available_quarters_to_filter,
                ...response[2].available_quarters_to_filter,
            ]));
        } catch (error) {
            toast.error(error.message);
            setAddressesWithDeliveryFailuresData([]);
            setShippingCompaniesOptions([]);
            setStatesOptions([]);
            setCitiesOptions([]);
            setQuartersOptions([]);
        } finally {
          setIsLoading(false);
        }
    }, []);

    const getSecondaryFilters = () => {
        return [
            {
                title: t('logisticIntelipostMostCommunDeliveryTime.shipping_company'),
                options: shippingCompaniesOptions,
                value: selectedShippingCompanyOptions,
                setValue:  setSelectedShippingCompanyOptions,
            },
            {
                title: `${t('common.states')}`,
                options: statesOptions,
                value: selectedStatesOptions,
                setValue: setSelectedStatesOptions,
            },
            {
                title: t('logisticShippingMethodEvolution.cities'),
                options: citiesOptions,
                value: selectedCitiesOptions,
                setValue: setSelectedCitiesOptions,
            },
            {
                title: t('logisticIntelipostMostCommunDeliveryTime.neighborhoods'),
                options: quartersOptions,
                value: selectedQuartersOptions,
                setValue: setSelectedQuartersOptions,
            }
        ]
    }

    const getFormattedData = (data) => {
        return {
            headers: [
                {name: t('logisticIntelipostAddressesWithDeliveryFailures.order_number'), value: "order_number", minWidth: 150, format: (value) => value},
                {name: t('logisticIntelipostAddressesWithDeliveryFailures.sales_order_number'), value: "sales_order_number", minWidth: 150, format: (value) => value},
                {name: t('logisticIntelipostMostCommunDeliveryTime.shipping_company'), value: "shipping_company", minWidth: 50, format: (value) => value},
                {name: t('logisticIntelipostAddressesWithDeliveryFailures.status_description'), value: "state_description", minWidth: 150, format: (value) => value},
                {name: t('logisticIntelipostAddressesWithDeliveryFailures.reason_for_the_problem'), value: "reason", minWidth: 250, format: (value) => <span dangerouslySetInnerHTML={{ __html: value.replace('\n', "</br>")}}/>},
                {name: t('logisticIntelipostAddressesWithDeliveryFailures.delay_time'), value: "delivery_time_delay", minWidth: 150, format: (value) => value},
                {name: t('logisticIntelipostAddressesWithDeliveryFailures.estimated_delivery_date'), value: "estimated_delivery_date", minWidth: 200, format: (value) => value},
                {name: t('logisticIntelipostAddressesWithDeliveryFailures.state_destination'), value: "shipping_state", minWidth: 50, format: (value) => value},
                {name: t('logisticIntelipostAddressesWithDeliveryFailures.city_destination'), value: "shipping_city", minWidth: 50, format: (value) => value},
                {name: t('logisticIntelipostAddressesWithDeliveryFailures.neighborhood_destination'), value: "shipping_quarter", minWidth: 50, format: (value) => value},

            ],
            rows: data
        }
    }

    const getOrdersPlpFormattedData = (data) => {
        return {
            headers: [
                {name: t('logisticIntelipostAddressesWithDeliveryFailures.order_number'), value: "order_number", minWidth: 150, format: (value) => value},
                {name: t('logisticIntelipostAddressesWithDeliveryFailures.sales_order_number'), value: "sales_order_number", minWidth: 150, format: (value) => value},
                {name: t('logisticIntelipostMostCommunDeliveryTime.shipping_company'), value: "shipping_company", minWidth: 50, format: (value) => value},
                {name: t('logisticIntelipostAddressesWithDeliveryFailures.status_description'), value: "state_description", minWidth: 150, format: (value) => value},
                {name: t('logisticIntelipostAddressesWithDeliveryFailures.plp_status'), value: "shipment_order_volume_pre_shipment_list_state", minWidth: 150, format: (value) => value},
                {name: t('logisticIntelipostAddressesWithDeliveryFailures.state_destination'), value: "shipping_state", minWidth: 50, format: (value) => value},
                {name: t('logisticIntelipostAddressesWithDeliveryFailures.city_destination'), value: "shipping_city", minWidth: 50, format: (value) => value},
                {name: t('logisticIntelipostAddressesWithDeliveryFailures.neighborhood_destination'), value: "shipping_quarter", minWidth: 50, format: (value) => value},
                {name: t('logisticIntelipostAddressesWithDeliveryFailures.state_origin'), value: "origin_state", minWidth: 50, format: (value) => value},
                {name: t('logisticIntelipostAddressesWithDeliveryFailures.city_origin'), value: "origin_city", minWidth: 50, format: (value) => value},
                {name: t('logisticIntelipostAddressesWithDeliveryFailures.neighborhood_origin'), value: "origin_quarter", minWidth: 50, format: (value) => value},
            ],
            rows: data
        }
    }

    const onRenderComponent = useCallback(async () => {
        const { initialDateFilter, finalDateFilter } = getSelectedInitialDateRange();
        setDateRange([initialDateFilter, finalDateFilter]);

        await getDataMetrics({
          initialDate: formatDate(initialDateFilter),
          finalDate: formatDate(finalDateFilter)
        });
      }, [getDataMetrics]);

    useEffect(() => {
        onRenderComponent();
    }, [onRenderComponent]);

    return (
        <BackgroundNew
          titlePage={t('menu.delivery_issues')}
          showButtonAction={true}
          buttonActionOnClick={_ => setOpenCustomModalLogisticCallToAction(true)}
          buttonActionTitle={t('logisticShippingMethodEvolution.integrate')}
          showButtonActionComplement={true}
          buttonActionComplement={
            <img
                alt='Intelipost'
                src={IntelipostIcon}
                style={{ width: 'auto', height: '35px', borderRadius: '50%'}}
            />
          }
        >
            <FilterContainer
                selectedDateRange={dateRange}
                setDateRange={setDateRange}
                onClickFilter={handleApplyFilter}
                showSecondaryFilters={true}
                secondaryFilters={getSecondaryFilters()}
            />
            <CardGroup
                title={t('logisticIntelipostAddressesWithDeliveryFailures.addresses_with_delivery_problems')}
                tagIdentifierColor="#00CCAE"
                isLoading={isLoading}
                ref={addressesWithDeliveryProblemsRef}
            >
                <AddressesWithDeliveryFailuresTable
                    data={addressesWithDeliveryFailuresData}
                    fullScreenEnabled={addressesWithDeliveryFailuresTableFullScreen}
                    setFullScreenEnabled={setAddressesWithDeliveryFailuresFullScreen}
                    onClickShowQuarters={handleClickShowQuarters}
                    cardGroupRef={addressesWithDeliveryProblemsRef}
                />
            </CardGroup>
            <CardGroup
                title={t('logisticIntelipostAddressesWithDeliveryFailures.orders_with_delivery_problems_and_delay')}
                tagIdentifierColor="#00CCAE"
                isLoading={isLoading}
                ref={ordersWithDeliveryProblemsRef}
            >
                <CustomTable
                    data={getFormattedData(shipmentOrdersWithProblemData)}
                    disabledDimensionController={shipmentOrdersWithProblemData.length === 0}
                    showFullScreenOption={true}
                    fullScreenEnabled={shipmentOrdersWithProblemTableFullScreen}
                    setFullScreenEnabled={setShipmentOrdersWithProblemFullScreen}
                    cardGroupRef={ordersWithDeliveryProblemsRef}
                />
            </CardGroup>
            <CardGroup
                title={t('logisticIntelipostAddressesWithDeliveryFailures.orders_with_plp_issues')}
                tagIdentifierColor="#00CCAE"
                isLoading={isLoading}
                ref={ordersWithDeliveryProblemsPLPRef}
            >
                <CustomTable
                    data={getOrdersPlpFormattedData(shipmentOrdersWithPlpProblemData)}
                    disabledDimensionController={shipmentOrdersWithPlpProblemData.length === 0}
                    showFullScreenOption={true}
                    fullScreenEnabled={shipmentOrdersWithPlpProblemTableFullScreen}
                    setFullScreenEnabled={setShipmentOrdersWitPlphProblemFullScreen}
                    cardGroupRef={ordersWithDeliveryProblemsPLPRef}
                />
            </CardGroup>
            <DeliveryFailuresQuarterModal
                open={openAddressesWithDeliveryFailuresQuartersModal}
                handleClose={handleCloseMostCommunDeliveryTimesQuartersModal}
                isLoading={isLoadingQuarters}
                data={addressesWithDeliveryFailuresQuartersData}
            />
            <CustomModalLogisticCallToAction
                open={openCustomModalLogisticCallToAction}
                handleClose={_ => setOpenCustomModalLogisticCallToAction(false)}
            />
        </BackgroundNew>
    );
}

export default LogisticIntelipostAddressesWithDeliveryFailures;
