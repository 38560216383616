import React, { useState } from 'react';

import { useTranslation } from 'react-i18next';
import '../../../i18n';

import { BsThreeDotsVertical } from 'react-icons/bs';
import * as S from './styled';

function AlertMenu(props) {
    const { t } = useTranslation();
    const [anchorEl, setAnchorEl] = useState(null);
    const open = Boolean(anchorEl);
    
    const handleEdit = () => {
        setAnchorEl(null);
        props.show(props.alert, 'edit');
    };
    
    const handleDuplicate = () => {
        setAnchorEl(null);
        props.show(props.alert, 'duplicate');
    };
    
    const handleDelete = () => {
        setAnchorEl(null);
        props.show(props.alert, 'delete');
    };

    return (
        <>
            <S.AlertMenuButton onClick={(ev) => setAnchorEl(ev.currentTarget)}>
                {t('alerts.options')} <BsThreeDotsVertical size={20} color="#2C3149" />
            </S.AlertMenuButton>
            <S.AlertMenu
                anchorEl={anchorEl}
                open={open}
                onClose={() => setAnchorEl(null)}
            >
                <S.AlertMenuItem onClick={handleEdit}>{t('alerts.edit')}</S.AlertMenuItem>
                <S.AlertMenuItem onClick={handleDuplicate}>{t('alerts.duplicate')}</S.AlertMenuItem>
                <S.AlertMenuItem onClick={handleDelete}>{t('alerts.delete')}</S.AlertMenuItem>
            </S.AlertMenu>
        </>
    );
}

export default AlertMenu;
