export const setCompanyPreferencesToLocalStorage = (lang, timezone, currency) => {
    localStorage.setItem('companyPreferences', JSON.stringify({
        lang,
        timezone,
        currency
    }));
}

export const getCompanyPreferencesFromLocalStorage = () => {
    return JSON.parse(localStorage.getItem('companyPreferences'));
}

export const setUserLanguagePreferenceToLocalStorage = (lang) => {
    localStorage.setItem('userLanguagePreference', lang);
}

export const getUserLanguagePreferenceFromLocalStorage = () => {
    return localStorage.getItem('userLanguagePreference');
}
