import React from 'react';

import { useTranslation } from 'react-i18next';
import '../../../i18n';

import * as S from './styles';

export default function CustomerGoalsRevenueYearRow({ revenueYear }) {
    const { t } = useTranslation();

    return (
        <S.RowContainer>
            <div className='goal__year__container'>
                <h3 className="goal__year__title">{t('mediaPlanner.annual_revenue_goal')}</h3>
                <div className="goal__year__revenue">
                    {revenueYear}
                </div>
            </div>
        </S.RowContainer>
    )
}