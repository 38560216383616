import * as FBAdsOptions from './FacebookAdsV2';
import * as GoogleAdsOptions from './GoogleAdsV2';
import * as VTEXOptions from './Vtex';
import * as GoogleAnalytics4Options from './GoogleAnalytics4';

export const types = {
  FA: "FA",
  AW: "AW",
  GAWA: "GAWA",
  VTEX: "VTEX"
};

export const alertModuleFilterAddtionalConfigDictonary = {
  "FA": FBAdsOptions.additionalConfig,
  "GAWA": GoogleAnalytics4Options.additionalConfig,
  "AW": GoogleAdsOptions.additionalConfig,
  "VTEX": null
}

export const alertModuleOptionsDictonary = {
  "FA": FBAdsOptions,
  "AW": GoogleAdsOptions,
  "GAWA": GoogleAnalytics4Options,
  "VTEX": VTEXOptions
}