import { getCompanyFeatures } from '../services/featureService';


export const featureType = {
    AD_TEXT_CREATOR: "640a082c8d77e326b7bf69a4",
    ECOMMERCE_CONSULTANT: "64370338d56aa91851d2975b",
    SMART_REPORT_MINIMUM_STOCK: "6463e02cb5b5a7e700353074",
};

export const featureIsEnabled = (featureId) => {
    const features = localStorage.getItem('features');

    if (!features) {
        return false;
    }

    const feature = JSON.parse(features).find(f => f.feature_id === featureId);

    if (!feature) {
        return false;
    }

    return feature.enabled;
}

export const setCompanyFeatures = async () => {
    try {
      const response = await getCompanyFeatures();

      if (response) {
        localStorage.setItem('features', JSON.stringify(response));
      }
      else {
        localStorage.setItem('features', JSON.stringify([]));
      }
    } catch (error) {
      localStorage.setItem('features', JSON.stringify([]));
    }
};