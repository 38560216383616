import api from '../../../services/api';

export async function updateAlert(id, data) {
  const token = localStorage.getItem('token');
  await api.put(`/alerts/${id}`, data, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
};

export async function deleteAlert(id) {
  const token = localStorage.getItem('token');
  await api.delete(`/alerts/${id}`, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
};
