import React, { useEffect, useState, useCallback } from "react";

import { toast } from 'react-toastify';

import { useTranslation } from 'react-i18next';
import '../../../i18n';

import Input from '../../../components/Input';
import CustomModal from '../../../components/CustomModal/CustomModal';

import { editUserPassword } from '../../../services/UserService';
import { useMemo } from "react";

const ChangePasswordModal = ({ isOpen, onClose }) => {
    const { t } = useTranslation();

    const CHANGE_PASSWORD_ERROR_MESSAGE_DICT = useMemo(() => ({
        'Old password is incorrect': t('profile.old_password_incorrect'),
        'The password must not contain spaces, quotes, backticks or backslashes': t('profile.password_restrictions'),
        'The password must have at least 8 characters, 1 uppercase letter, 1 number and 1 special character': t('profile.password_requirements'),
        'The new password and the confirm password are different': t('profile.passwords_do_not_match')
    }), [t]);

    const [oldPassword, setOldPassword] = useState('');
    const [newPassword, setNewPassword] = useState('');
    const [confirmNewPassword, setConfirmNewPassword] = useState('');
    const [loading, setLoading] = useState(false);

    const validatePassword = useCallback(_ => {
        let isValid = true;

        if (!oldPassword || !newPassword || !confirmNewPassword) {
            toast.error(t('toast.fill_all_fields'));
            isValid = false;

            return isValid;
        }

        if (newPassword !== confirmNewPassword) {
            toast.error(t('toast.password_mismatch'));
            isValid = false;
        }

        const regexInvalidCharacters = /['"\s`\\]/
        if (regexInvalidCharacters.test(newPassword)) {
            toast.error(t('toast.password_invalid_chars'));
            isValid = false;
        }

        const regexStrongPassword = /^(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/
        if (!regexStrongPassword.test(newPassword)) {
            toast.error(t('toast.password_requirements'));
            isValid = false;
        }

        return isValid;
    }, [newPassword, confirmNewPassword, oldPassword, t]);

    const handleSave = useCallback(async () => {
        if (!validatePassword()) return;

        setLoading(true);
        try {
            await editUserPassword({ 
                old_password: oldPassword,
                new_password: newPassword,
                confirm_password: confirmNewPassword
            });

            toast.success(t('toast.password_changed_success'));
            onClose();
        } catch (error) {
            toast.error(CHANGE_PASSWORD_ERROR_MESSAGE_DICT[error.message] || error.message);
        }
        setLoading(false);
    }, [oldPassword, newPassword, confirmNewPassword, onClose, validatePassword, CHANGE_PASSWORD_ERROR_MESSAGE_DICT, t]);

    useEffect(() => {
        setOldPassword('');
        setNewPassword('');
        setConfirmNewPassword('');
    }, [isOpen]);

    return (
        <CustomModal
            open={isOpen}
            handleClose={onClose}
            title={t('profile.change_password')}
            handleConfirm={handleSave}
            confirmButtonLoading={loading}
            handleConfirmStatus={loading}
        >
            <Input
                label={t('profile.current_password')}
                type="password"
                value={oldPassword}
                onChange={e => setOldPassword(e.target.value)}
            />
            <Input
                label={t('profile.new_password')}
                type="password"
                value={newPassword}
                onChange={e => setNewPassword(e.target.value)}
            />
            <Input
                label={t('profile.confirm_new_password')}
                type="password"
                value={confirmNewPassword}
                onChange={e => setConfirmNewPassword(e.target.value)}
            />
        </CustomModal>
    );
}

export default ChangePasswordModal;


    