import React, { useState, useEffect, useCallback, useMemo } from "react";

import Slide from "@mui/material/Slide";

import { FiArrowLeft, FiTrash2, FiInfo } from "react-icons/fi";

import { toast } from 'react-toastify';

import { useTranslation } from 'react-i18next';
import "../../../i18n";

import {
  deleteCustomCompanyGoals,
  upsertCustomCompanyGoals,
  getCustomCompanyGoalsDaily
} from "../../../services/customerService";

import { 
  getNumberOfDaysInMonth,
  isLeapYear,
  months 
} from "../../../utils/dateUtils";
import { 
  extractNumbersFromString, 
  customRound,
  getSymbolFromCurrency 
} from "../../../utils/metricsUtils";

import Loader from '../../../components/Loader';
import SelectOption from '../../../components/SelectOption';
import CustomModal from '../../../components/CustomModal/CustomModal';
import { BisoTooltip } from '../../../components/Tooltip';

import { Button, TransparentButton } from "../styles";

import { 
  Container, 
  ActionsContainer,
  HeaderSessionContainer,
  HeaderSessionTitle,
  HeaderSessionSubtitle,
  ConfigOptionsContainer,
  ConfigOptionGroup,
  ConfigLabel,
  GoalsDailyContainer,
  GoalsDailyTablesContainer,
  GoalsDailyTable,
  GoalsDailyTableHead,
  GoalsDailyTableBody,
  GoalsDailyTableTr,
  GoalsDailyTableTh,
  GoalsDailyTableTd,
  GoalsDailyTableInput,
  Input,
  GoalsActionsButtonsContainer,
  DeleteButton,
  GoalsDailyMetricControllerContainer,
  GoalsDailyMetricControllerOption,
  InfoAutomaticGoalsContainer
} from "./styles";

const CHANNEL_KEYS_MAP = {
  google_ads : "Google",
  facebook_ads : "Facebook",
  mail : "E-mail",
  seo : "SEO",
  direct : "Direto",
  referral : "Referral",
  other : "Outros"
};

export const CreateOrEditCompanyGoals = ({
  handleCancel,
  getCompanyGoals,
  year, 
  month = null, 
  companyGoalsGroupByMonth = null,
  currency, 
  editMode = false 
}) => {
  const { t } = useTranslation();

  const METRIC_MAP = useMemo(_ => ({
    revenue: {
      label: t('common.revenue'),
      decimalScale: 2,
      prefix: getSymbolFromCurrency(currency),
      suffix: '',
      key: 'revenue'
    },
    averageTicket: {
      label: t('common.average_ticket'),
      decimalScale: 2,
      prefix: getSymbolFromCurrency(currency),
      suffix: '',
      key: 'averageTicket'
    },
    transactions: {
      label: t('common.transactions'),
      decimalScale: 0,
      prefix: '',
      suffix: '',
      key: 'transactions'
    },
    conversionRate: {
      label: t('common.conversion_rate'),
      decimalScale: 2,
      prefix: '',
      suffix: '%',
      key: 'conversionRate'
    },
    sessions: {
      label: t('common.sessions'),
      decimalScale: 0,
      prefix: '',
      suffix: '',
      key: 'sessions'
    },
    investment: {
      label: t('common.investment'),
      decimalScale: 2,
      prefix: getSymbolFromCurrency(currency),
      suffix: '',
      key: 'investment'
    }
  }), [t, currency]);
  
  const AUTOMATIC_METRICS = useMemo(_ => [METRIC_MAP.transactions.key, METRIC_MAP.sessions.key], [METRIC_MAP]);
  
  const METRIC_ORDER = useMemo(_ => Object.keys(METRIC_MAP), [METRIC_MAP]);
  
  const getMonthOptions = useCallback(() => {
    const options = Object.keys(months).map((month) => {
        return {
            value: parseInt(month),
            label: months[month],
        };
    });
  
    return [{ label: `${t('common.month')}`, options }];
  }, [t]);
  
  const MONTHS_OPTIONS = useMemo(_ => getMonthOptions(), [getMonthOptions]);

  const [isLoading, setIsLoading] = useState(false);
  const [isDeleting, setIsDeleting] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [currentMetric, setCurrentMetric] = useState(METRIC_ORDER[0]);

  const [selectedMonth, setSelectedMonth] = useState(MONTHS_OPTIONS[0].options[0]);
  const [daysInSelectedMonth, setDaysInSelectedMonth] = useState(0);

  const [availableChannelsOptions, setAvailableChannelsOptions] = useState([]);
  const [selectedChannel, setSelectedChannel] = useState(null);
  
  const [investmentGoalsDaily, setInvestmentGoalsDaily] = useState([]);
  const [sessionsGoalsDaily, setSessionsGoalsDaily] = useState([]);
  const [conversionRateGoalsDaily, setConversionRateGoalsDaily] = useState([]);
  const [transactionsGoalsDaily, setTransactionsGoalsDaily] = useState([]);
  const [averageTicketGoalsDaily, setAverageTicketGoalsDaily] = useState([]);
  const [revenueGoalsDaily, setRevenueGoalsDaily] = useState([]);

  const [companyGoalsDaily, setCompanyGoalsDaily] = useState(null);

  const calculateTransactionsByRevenueAndAverageTicket = (revenue, averageTicket) => {
    return revenue === 0 ? 0 : parseInt(customRound(revenue / averageTicket));
  };

  const calculateSessionsByTransactionsAndConversionRate = (transactions, conversionRate) => {
    return transactions === 0 ? 0 : parseInt(customRound(transactions / (conversionRate / 100)));
  };

  const formatDate = (dateString) => {
    const [year, month, day] = dateString.split('-');
    return `${year}-${month.padStart(2, '0')}-${day.padStart(2, '0')}`;
  }

  const splitDaysIntoGroups = (daysInMonth) => {
    const GROUP_SIZE = 6;

    const days = Array.from({ length: daysInMonth }, (_, index) => index + 1);
  
    const numberOfGroups = Math.ceil(days.length / GROUP_SIZE);
    const groups = [];
  
    for (let i = 0; i < numberOfGroups; i++) {
      groups.push(days.slice(i * GROUP_SIZE, (i + 1) * GROUP_SIZE));
    }
  
    return groups;
  };  

  const getAvailableChannelsOptions = useCallback(() => {
    let availableChannels = Object.values(CHANNEL_KEYS_MAP);
    const selectedMonthNumber = parseInt(selectedMonth.value);

    if (companyGoalsGroupByMonth) {
      const channels = companyGoalsGroupByMonth.map((goal) => goal.month  === selectedMonthNumber ? goal.channel : null);
      availableChannels = availableChannels.filter((channel) => editMode ? channels.includes(channel) : !channels.includes(channel));
    }

    const options = availableChannels.map((channel) => {
      return {
          value: channel,
          label: channel,
      };
    });

    return options.length === 0 ? [] : [{ label: t('common.channel'), options }];
  }, [companyGoalsGroupByMonth, selectedMonth, editMode, t]);

  const getRequestData = () => {
    const dateKeys = investmentGoalsDaily.map((data) => data.date);
    const dataGroupedByDate = dateKeys.map((date) => {
      const investment = investmentGoalsDaily.find((data) => data.date === date).value;
      const sessions = sessionsGoalsDaily.find((data) => data.date === date).value;
      const conversionRate = conversionRateGoalsDaily.find((data) => data.date === date).value;
      const transactions = transactionsGoalsDaily.find((data) => data.date === date).value;
      const averageTicket = averageTicketGoalsDaily.find((data) => data.date === date).value;
      const revenue = revenueGoalsDaily.find((data) => data.date === date).value;

      return {
        date,
        channel: selectedChannel.value,
        weight: 0,
        investment: investment || 0,
        sessions: sessions || 0,
        conversion_rate: conversionRate || 0,
        transactions: transactions || 0,
        avg_ticket: averageTicket || 0,
        revenue: revenue || 0
      };
    });

    return dataGroupedByDate;
  };

  const handleGoalDailyInputChange = (metric, index, value) => {
    const stateSetterMap = {
      investment: setInvestmentGoalsDaily,
      sessions: setSessionsGoalsDaily,
      conversionRate: setConversionRateGoalsDaily,
      transactions: setTransactionsGoalsDaily,
      averageTicket: setAverageTicketGoalsDaily,
      revenue: setRevenueGoalsDaily,
    };

    stateSetterMap[metric](prev => {
      const updatedGoals = [...prev];
      updatedGoals[index] = { 
        date: updatedGoals[index]?.date, 
        value: extractNumbersFromString(value) 
      };
      return updatedGoals;
    });
  };

  const onChangeMonth = useCallback(() => {
    const monthValue = parseInt(selectedMonth.value);
    const daysInMonth = getNumberOfDaysInMonth(monthValue, isLeapYear(year));
    setDaysInSelectedMonth(daysInMonth);
    setAvailableChannelsOptions(getAvailableChannelsOptions());
    
    setCurrentMetric(METRIC_ORDER[0]);
    setSelectedChannel(null);

    const initializeEmptyGoals = Array.from({ length: daysInMonth }, (_, index) => ({
      date: formatDate(`${year}-${selectedMonth.value}-${index + 1}`),
      value: 0,
    }));

    setInvestmentGoalsDaily(initializeEmptyGoals);
    setSessionsGoalsDaily(initializeEmptyGoals);
    setConversionRateGoalsDaily(initializeEmptyGoals);
    setTransactionsGoalsDaily(initializeEmptyGoals);
    setAverageTicketGoalsDaily(initializeEmptyGoals);
    setRevenueGoalsDaily(initializeEmptyGoals);
  }, [year, selectedMonth, getAvailableChannelsOptions, METRIC_ORDER]);

  const onChangeChannelOnEditMode = useCallback(() => {
    const goals = companyGoalsDaily.filter((goal) => goal.channel === selectedChannel.value);
    const investmentGroupedByDate = goals.map((goal) => ({ date: goal.date, value: goal.investment }));
    const sessionsGroupedByDate = goals.map((goal) => ({ date: goal.date, value: goal.sessions }));
    const conversionRateGroupedByDate = goals.map((goal) => ({ date: goal.date, value: goal.conversion_rate }));
    const transactionsGroupedByDate = goals.map((goal) => ({ date: goal.date, value: goal.transactions }));
    const averageTicketGroupedByDate = goals.map((goal) => ({ date: goal.date, value: goal.avg_ticket }));
    const revenueGroupedByDate = goals.map((goal) => ({ date: goal.date, value: goal.revenue }));

    setInvestmentGoalsDaily(investmentGroupedByDate);
    setSessionsGoalsDaily(sessionsGroupedByDate);
    setConversionRateGoalsDaily(conversionRateGroupedByDate);
    setTransactionsGoalsDaily(transactionsGroupedByDate);
    setAverageTicketGoalsDaily(averageTicketGroupedByDate);
    setRevenueGoalsDaily(revenueGroupedByDate);
  }, [companyGoalsDaily, selectedChannel]);

  const calculateAutomaticTransactions = () => {
    const revenueGroupedByDate = {}
    revenueGoalsDaily.forEach((data) => {
      revenueGroupedByDate[data.date] = data.value;
    });

    const averageTicketGroupedByDate = {};
    averageTicketGoalsDaily.forEach((data) => {
      averageTicketGroupedByDate[data.date] = data.value;
    });

    const transactions = Object.keys(revenueGroupedByDate).map((date) => {
      const revenue = revenueGroupedByDate[date];
      const averageTicket = averageTicketGroupedByDate[date];
      return { date, value: calculateTransactionsByRevenueAndAverageTicket(revenue, averageTicket) };
    });

    setTransactionsGoalsDaily(transactions);
  }

  const calculateAutomaticSessions = () => {
    const transactionsGroupedByDate = {};
    transactionsGoalsDaily.forEach((data) => {
      transactionsGroupedByDate[data.date] = data.value;
    });

    const conversionRateGroupedByDate = {};
    conversionRateGoalsDaily.forEach((data) => {
      conversionRateGroupedByDate[data.date] = data.value;
    });

    const sessions = Object.keys(transactionsGroupedByDate).map((date) => {
      const transactions = transactionsGroupedByDate[date];
      const conversionRate = conversionRateGroupedByDate[date];
      return { date, value: calculateSessionsByTransactionsAndConversionRate(transactions, conversionRate) };
    });

    setSessionsGoalsDaily(sessions);
  }

  const handleNextMetric = () => {
    const currentMetricIndex = METRIC_ORDER.indexOf(currentMetric);

    if (METRIC_ORDER[currentMetricIndex + 1] === METRIC_MAP.transactions.key) {
      calculateAutomaticTransactions();
    } else if (METRIC_ORDER[currentMetricIndex + 1] === METRIC_MAP.sessions.key) {
      calculateAutomaticSessions();
    }

    if (currentMetricIndex < METRIC_ORDER.length - 1) {
      setCurrentMetric(METRIC_ORDER[currentMetricIndex + 1]);
    }
  };

  const handleBackMetric = () => {
    const currentMetricIndex = METRIC_ORDER.indexOf(currentMetric);
    if (currentMetricIndex > 0) {
      setCurrentMetric(METRIC_ORDER[currentMetricIndex - 1]);
    }
  };

  const handleUpsertGoals = async () => {
    setIsLoading(true);

    try {
      const data = getRequestData()
      await upsertCustomCompanyGoals(data);

      await getCompanyGoals(year);

      toast.success(`${t('toast.goals')} ${editMode ? t('toast.edited') : t('toast.registered')} ${t('toast.successfully')}`);

      handleCancel();
    } catch (error) {
      toast.error(`${t('toast.unable_to')} ${editMode ? t('toast.edit') : t('toast.register')} ${t('toast.your_goals_try_again')}`);
    } finally {
      setIsLoading(false);
    }
  };

  const getCompanyGoalsDaily = useCallback(async (year, month) => {
    setIsLoading(true);

    try {
      const response = await getCustomCompanyGoalsDaily(year, month);

      if (!response) {
        setCompanyGoalsDaily(null);
        return;
      }

      setCompanyGoalsDaily(response);
    } catch (error) {
      toast.error(error.message);

      setCompanyGoalsDaily(null);
    } finally {
      setIsLoading(false);
    }
  }, []);

  const handleDeleteGoals = async () => {
    setIsDeleting(true);

    try {
      await deleteCustomCompanyGoals(year, selectedMonth.value, selectedChannel.value);

      await getCompanyGoals(year);

      toast.success(t('toast.goals_deleted_success'));

      handleCancel();
    } catch (error) {
      toast.error(error.message);
    } finally {
      setIsDeleting(false);
    }
  }

  useEffect(() => {
    if (editMode && month) {
      getCompanyGoalsDaily(year, month);
      setSelectedMonth(MONTHS_OPTIONS[0].options.find(option => option.value === month));
    }
  }, [editMode, year, month,  getCompanyGoalsDaily, MONTHS_OPTIONS]);

  useEffect(() => {
    if (selectedMonth) {
      onChangeMonth();
    }
  }, [selectedMonth, onChangeMonth]);

  useEffect(() => {
    if (selectedChannel && editMode) {
      onChangeChannelOnEditMode()
    }
  }, [selectedChannel, editMode, onChangeChannelOnEditMode]);

  return (
    <>
      <Slide direction="left" in mountOnEnter unmountOnExit>
        <Container>
          <ActionsContainer>
            <TransparentButton onClick={handleCancel} disabled={isLoading || isDeleting}>
              <FiArrowLeft className="backButtonIcon" size={20} style={{marginRight: 5}}/> 
              {t('goalsManager.back')}
            </TransparentButton>
          </ActionsContainer>

          <HeaderSessionContainer>
            <HeaderSessionTitle>{editMode ? t('goalsManager.edit') : t('goalsManager.register')} {t('goalsManager.edit_or_register_monthly_goals')}</HeaderSessionTitle>
            <HeaderSessionSubtitle>
              {editMode && t('goalsManager.editing_goals_instruction')}
              {!editMode && t('goalsManager.set_goals_instruction')}
            </HeaderSessionSubtitle>
          </HeaderSessionContainer>

          <ConfigOptionsContainer>
            <ConfigOptionGroup>
              <ConfigLabel>{t('common.year')}</ConfigLabel>
              <Input value={year} disabled />
            </ConfigOptionGroup>
            <ConfigOptionGroup>
              <ConfigLabel>{t('common.month')}</ConfigLabel>
              <SelectOption
                placeholder="Selecione o mês"
                options={MONTHS_OPTIONS}
                value={selectedMonth}
                onChange={setSelectedMonth}
                isDisabled={isLoading || editMode}
              />
            </ConfigOptionGroup>
            <ConfigOptionGroup>
              <ConfigLabel>{t('common.channel')}</ConfigLabel>
              <SelectOption
                placeholder={availableChannelsOptions.length === 0 ? t('goalsManager.no_channel_available') : t('goalsManager.select_channel')}
                options={availableChannelsOptions}
                value={selectedChannel}
                onChange={select => setSelectedChannel(select)}
                isDisabled={availableChannelsOptions.length === 0 || isLoading || isDeleting}
              />
            </ConfigOptionGroup>
          </ConfigOptionsContainer>

          <HeaderSessionContainer>
            <HeaderSessionTitle>{t('goalsManager.daily_goals')}</HeaderSessionTitle>
            <HeaderSessionSubtitle>{t('goalsManager.manage_daily_goals')}</HeaderSessionSubtitle>
          </HeaderSessionContainer>

          <GoalsDailyContainer>
            <GoalsDailyMetricControllerContainer>
              {METRIC_ORDER && METRIC_ORDER.map((metric, index) => (
                <GoalsDailyMetricControllerOption
                  key={metric}
                  isSelected={currentMetric === metric}
                  hasBorderLeft={index !== 0}
                >
                  {METRIC_MAP[metric].label}
                </GoalsDailyMetricControllerOption>
              ))}
            </GoalsDailyMetricControllerContainer>

            <GoalsDailyTablesContainer>
              {splitDaysIntoGroups(daysInSelectedMonth).map((group, groupIndex) => (
                <GoalsDailyTable key={groupIndex}>
                  <GoalsDailyTableHead>
                    <GoalsDailyTableTr>
                      <GoalsDailyTableTh>{t('common.date')}</GoalsDailyTableTh>
                      <GoalsDailyTableTh 
                        style={{display: "flex", alignItems: "center", justifyContent: "center"}}
                      >
                        {t('goalsManager.value')}
                        {AUTOMATIC_METRICS.includes(currentMetric) && (
                          <BisoTooltip 
                            title={`${t('goalsManager.auto_calculated_value')} ${
                              currentMetric === METRIC_MAP.transactions.key 
                                ? t('goalsManager.revenue_and_average_ticket') : t('goalsManager.transactions_and_conversion_rate')
                            }.`}
                          >
                            <InfoAutomaticGoalsContainer>
                                <FiInfo size={15}/>
                            </InfoAutomaticGoalsContainer>
                          </BisoTooltip>
                        )}
                      </GoalsDailyTableTh>
                    </GoalsDailyTableTr>
                  </GoalsDailyTableHead>
                  <GoalsDailyTableBody>
                    {group.map(day => (
                      <GoalsDailyTableTr key={day}>
                        <GoalsDailyTableTd>{`${day}/${selectedMonth.value}`}</GoalsDailyTableTd>
                        <GoalsDailyTableTd>
                          <GoalsDailyTableInput 
                            id={`input-value-${day}`} 
                            type="text" 
                            disabled={!selectedMonth || !selectedChannel || isLoading || isDeleting || AUTOMATIC_METRICS.includes(currentMetric)}
                            thousandSeparator="."
                            decimalSeparator=","
                            decimalScale={METRIC_MAP[currentMetric].decimalScale}
                            prefix={METRIC_MAP[currentMetric].prefix}
                            suffix={METRIC_MAP[currentMetric].suffix}
                            value={(() => {
                              const metricStateMap = {
                                investment: investmentGoalsDaily,
                                sessions: sessionsGoalsDaily,
                                conversionRate: conversionRateGoalsDaily,
                                transactions: transactionsGoalsDaily,
                                averageTicket: averageTicketGoalsDaily,
                                revenue: revenueGoalsDaily,
                              };
                              return metricStateMap[currentMetric][day-1]?.value || 0;
                            })()}
                            onChange={(e) => handleGoalDailyInputChange(currentMetric, day-1, e.target.value)}
                          />
                        </GoalsDailyTableTd>
                      </GoalsDailyTableTr>
                    ))}
                  </GoalsDailyTableBody>
                </GoalsDailyTable>
              ))}
            </GoalsDailyTablesContainer>
          </GoalsDailyContainer>

          <GoalsActionsButtonsContainer>
            <Button 
              onClick={handleBackMetric} 
              style={{marginRight: 10}} 
              disabled={!selectedMonth || !selectedChannel || isLoading || currentMetric === METRIC_ORDER[0] || isDeleting}
            >
              {t('common.previous')} 
            </Button>
            <Button 
              onClick={METRIC_ORDER[METRIC_ORDER.length -1] === currentMetric ? handleUpsertGoals : handleNextMetric} 
              style={{marginRight: 10}} 
              disabled={!selectedMonth || !selectedChannel || isLoading || isDeleting}
            >
              {METRIC_ORDER[METRIC_ORDER.length -1] === currentMetric ? t('goalsManager.save_goals') : t('goalsManager.next_metric')}
              {isLoading && <Loader 
                  size={17}
                  color='#fff'
                  containerStyles={{width: "auto", height: "auto", marginLeft: 5}}
              />}
            </Button>
            {editMode && selectedChannel && (
              <DeleteButton
                onClick={() => setShowDeleteModal(true)}
                disabled={isLoading || isDeleting}
              >
                {t('goalsManager.delete_goals')}
                <FiTrash2 size={17} style={{marginLeft: 5}}/>
              </DeleteButton>
            )}
          </GoalsActionsButtonsContainer>
        </Container>
      </Slide>
      <CustomModal
        open={showDeleteModal}
        title="Excluir metas"
        handleClose={() => setShowDeleteModal(false)}
        handleConfirm={handleDeleteGoals}
        confirmButtonLabel={t('goalsManager.delete')}
        confirmButtonLoading={isDeleting}
        handleConfirmStatus={isDeleting}
      >
        <p>{t('goalsManager.confirm_delete_goals')} <strong>{selectedChannel?.value}</strong> {t('goalsManager.the_month')} <strong>{selectedMonth?.label}</strong>?</p>
      </CustomModal>
    </>
  );
};
