import styled from 'styled-components';
import { darken } from 'polished';
import { Modal } from '@mui/material';

export const CustomModalProfileLogin = styled(Modal)`
  display: flex;
  justify-content: center;

  .modal {
    position: fixed;
    top: 10%;
    display: flex;
    flex-direction: column;
    width: 60vw;
    max-width: 750px;
    padding: 16px 24px;
    background-color: #f9f9f9;
    border-radius: 8px;

    @media screen and (max-width: 1200px) {
      top: 15px;
      width: calc(100vw - 30px);
      overflow: auto;
      margin-top: 15px;
      padding: 16px;
    }

    &__header {
      margin-bottom: 32px;
    }

    &__footer {
      display: flex;
      justify-content: center;
      margin-top: 16px;
    }

    &__body {
      ul {
        font-size: 16px;
        list-style: none;
        li {
          margin-bottom: 8px;
          a {
            color: #f90c68  ;
          }
        }
      }
    }

    &__title {
      font-size: 20px;
      font-weight: 600;
      color: #455a64;

      &:after {
        content: '';
        display: block;
        width: 60px;
        height: 4px;
        margin-top: 16px;
        background: #f90c68;
      }
    }

    &__form {
      &-group {
        padding: 24px 16px;
        background: #fff;
        border-radius: 8px;
        box-shadow: 0px 1px 3px 0px rgba(142, 142, 142, 0.2),
          0px 1px 1px 0px rgba(243, 243, 243, 0.14),
          0px 2px 1px -1px rgba(204, 204, 204, 0.12);
      }

      &-label {
        display: block;
        margin-bottom: 8px;
        color: rgba(0, 0, 0, 0.75);
        font-size: 14px;
        font-weight: 700;
        text-transform: uppercase;
      }

      &-input {
        margin-top: 8px;
        width: 100%;
        height: 38px;
        font-size: 14px;
        font-weight: 400;
        padding: 10px;
        background-color: #fff;
        border: 1px solid #ccc;
        border-radius: 4px;
        @media screen and (max-width: 1200px) {
          font-size: 16px;
        }
      }
      
      &-btn {
        margin: 0 8px;
        font-size: 13px;
        font-weight: 500;
        border: 0;

        &.is--save {
          height: 35px;
          padding: 0 16px;
          color: #fff;
          line-height: 35px;
          background: #f90c68;
          border-radius: 20px;
          &:hover {
            background: ${darken(0.08, '#f90c68')};
          }
          &:disabled {
            opacity: 0.5;
            pointer-events: none;
          }
        }

        &.is--cancel {
          height: 35px;
          color: #455a64;
          line-height: 35px;
          text-decoration: underline;
          background: transparent;
        }
      }
    }
  }
`;