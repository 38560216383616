import React, { useEffect, useState, useContext, useCallback } from "react";

import { toast } from 'react-toastify';

import { useTranslation } from 'react-i18next';
import '../../i18n';

import Button from '../../components/Button';
import CardGroup from '../../components/CardGroup';
import SelectOption from '../../components/SelectOption';
import BackgroundNew from '../../components/BackgroundNew';

import { Container } from './styles';

import { 
    timezonesOptions, 
    currencyOptions, 
    languageOptions 
} from '../../options/filterOptions';

import { updateOrCreateCompanyPreferences } from '../../services/preferencesService';

import { AuthContext } from '../../contexts';

const Preference = () => {
    const { t } = useTranslation();
    const { 
        timezone, 
        currency, 
        lang, 
        user,
        configureCompanyPreferences,
        setUserLanguagePreference 
    } = useContext(AuthContext);

    const [editMode, setEditMode] = useState(false);
    const [isCompanyUser, setIsCompanyUser] = useState(false);
    const [loading, setLoading] = useState(false);
    
    const [currentTimezoneOption, setCurrentTimezoneOption] = useState(null);
    const [currentCurrencyOption, setCurrentCurrencyOption] = useState(null);
    const [currentLangOption, setCurrentLangOption] = useState(null);

    const resetFields = useCallback(() => {
        setCurrentTimezoneOption(timezonesOptions[0].options.find(option => option.value === timezone))
        setCurrentCurrencyOption(currencyOptions[0].options.find(option => option.value === currency));
        setCurrentLangOption(languageOptions[0].options.find(option => option.value === lang));
        setEditMode(false);
    }, [timezone, currency, lang]);

    const handleUpdateOrCreateCompanyPreferences = async _ => {
        try {
            setLoading(true);

            await updateOrCreateCompanyPreferences(
                currentTimezoneOption.value,
                currentCurrencyOption.value,
                currentLangOption.value
            )

            await configureCompanyPreferences(true, isCompanyUser);

            toast.success(t("toast.preferences_updated_success"));

            setEditMode(false);
        } catch (error) {
            toast.error(error.message);
        } finally {
            setLoading(false);
        }
    }

    const handleUpdateUserLanguagePreference =  _ => {
        setLoading(true);

        setUserLanguagePreference(currentLangOption.value);
        toast.success(t("toast.preferences_updated_success"));

        setEditMode(false);
        setLoading(false);
    }

    useEffect(() => {
        resetFields();
        setIsCompanyUser(user?.type === 'company');
    }, [resetFields, user]);

    return (
        <BackgroundNew 
            titlePage={t('appBar.preferences')}
            subTitlePage={t('preferences.manage_preferences')}
        >
            <Container> 
                <CardGroup
                    title={t('appBar.preferences')}
                >
                    <div className="preferences-config-group">
                        <div className='preferences-field'>
                            <p className='field-label'>{t('common.timezone')}</p>
                            <SelectOption
                                options={timezonesOptions}
                                onChange={(option) => setCurrentTimezoneOption(option)}
                                value={currentTimezoneOption}
                                placeholder={t('common.timezone')}
                                isDisabled={!editMode || !isCompanyUser}
                                isClearable={false}
                                isSearchable={true}
                            />
                        </div>
                        <div className='preferences-field'>
                            <p className='field-label'>{t('common.currency')}</p>
                            <SelectOption
                                options={currencyOptions}
                                onChange={(option) => setCurrentCurrencyOption(option)}
                                value={currentCurrencyOption}
                                placeholder={t('common.currency')}
                                isDisabled={!editMode || !isCompanyUser}
                                isClearable={false}
                                isSearchable={true}
                            />
                        </div>
                        <div className='preferences-field'>
                            <p className='field-label'>{t('common.language')}</p>
                            <SelectOption
                                options={languageOptions}
                                onChange={(option) => setCurrentLangOption(option)}
                                value={currentLangOption}
                                placeholder={t('common.language')}
                                isDisabled={!editMode}
                                isClearable={false}
                                isSearchable={true}
                            />
                        </div>
                    </div>
                    {!isCompanyUser &&
                        <div className="preferences-user-notification">
                            <p>{t('preferences.user_notification')}</p>
                        </div>
                    }
                    <div className="preferences-actions">
                        {!editMode && <Button
                            text={t('alerts.edit')}
                            onClick={() => setEditMode(true)}
                            disabled={loading}
                        />}
                        {editMode && <Button
                            text={t('common.cancel')}
                            customClass="cancel-button"
                            onClick={resetFields}
                            disabled={loading}
                        />}
                        {editMode && <Button
                            text={t('common.save')}
                            onClick={isCompanyUser 
                                ? handleUpdateOrCreateCompanyPreferences
                                : handleUpdateUserLanguagePreference
                            }
                            disabled={loading}
                            enableLoading={true}
                            isLoading={loading}
                        />}
                    </div>
                </CardGroup>
            </Container>
        </BackgroundNew>
    );
};


export default Preference;
