import React, { useEffect, useCallback, useState, useRef, useContext } from 'react';

import { toast } from 'react-toastify';

import { useTranslation } from 'react-i18next';
import '../../i18n';

import BackgroundNew from '../../components/BackgroundNew';
import FilterContainer from '../../components/FilterContainer';
import CardGroup from '../../components/CardGroup';
import GraphBarHorizontal from '../../components/GraphBarHorizontal';
import GraphPie from '../../components/GraphPie';
import CardIndicator from '../../components/CardIndicator';
import TableCampaign from '../../components/TableCampaign';
import ModalPageInformation from '../../components/CustomModal/ModalPageInformation';

import { HiCursorClick } from 'react-icons/hi';
import { AiFillDislike } from  'react-icons/ai';
import { RiMoneyDollarCircleFill } from 'react-icons/ri';

import {
    Container,
    ContainerConversionFunnel,
    ConversionFunnel,
    ConversionFunnelComplement
} from './styles';

import { paidMediaChannelsOptions, DIMENSIONS_OPTONS, GOOGLE_ANALYTICS_CAMPAIGN_REPORT_TYPE} from '../../options/filterOptions';
import { getSelectedInitialDateRange, formatDate } from '../../utils/dateUtils';
import { formatValueToMoney, formatValueToPercentage } from '../../utils/metricsUtils';

import {
  getMediaOverviewMetrics,
  getMediaCampaignsMetrics,
  sendReportToEmailMediaCampaignsMetrics,
} from '../../services/dashboardApiService';
import GraphController from '../../components/GraphController';

import { AuthContext } from '../../contexts';

const PaidMediaCampaign = () => {
    const { t } = useTranslation();
    const { timezone, currency } = useContext(AuthContext);

    const [dateRange, setDateRange] = useState();
    const [isLoading, setIsLoading] = useState(true);
    const [selectedChannelsOptions, setSelectedChannelsOptions] = useState();
    const [campaignOptions, setCampaignOptions] = useState([]);
    const [selectedCampaignOptions, setSelectedCampaignOptions] = useState([]);
    const [selectedDimension, setSelectedDimension] = useState(DIMENSIONS_OPTONS.day);
    const [bigNumbersData, setBigNumbersData] = useState(null);
    const [campaignsData, setCampaignsData] = useState(null);
    const [openModalInformation, setOpenModalInformation] = useState(false);

    const [revenueAndInvestmentGraphFullScreen, setRevenueAndInvestmentGraphFullScreen] = useState(false);
    const [roasGraphFullScreen, setRoasGraphFullScreen] = useState(false);
    const [revenueShareGraphFullScreen,setRevenueShareGraphFullScreen] = useState(false);
    const [campaignsTableFullScreen, setCampaignsTableFullScreen] = useState(false);

    const [exportReportLoading, setExportReportLoading] = useState(false);
    
    const conversionFunnelRef = useRef()
    const cardCapturedRevenueShareRef = useRef()
    const cardROASRef = useRef()
    const capturedRevenueAndInvestmentRef = useRef()

  const handleApplyFilter = async () => {
        const [startDate, endDate] = dateRange;
        const channels = selectedChannelsOptions
            ? selectedChannelsOptions.map(channel => channel.value)
            : paidMediaChannelsOptions[0].options.map(channel => channel.value);
        const campaigns = selectedCampaignOptions.length > 0 ? [...selectedCampaignOptions] : null;

        await getMediaDataMetrics(
            formatDate(startDate),
            formatDate(endDate),
            channels,
            campaigns
        );
    };

    const getMediaCampaignsMetricsAllPages = async (filter) => {
        try {
            const response = await getMediaCampaignsMetrics(
                filter.initialDate,
                filter.finalDate,
                filter.timezone,
                filter.channels,
                filter.campaigns,
                filter.sourcesMedium,
                true,
                1
            );

            if (!response) { return null; }

            const totalPages = response.pagination.total_pages;

            const campaignsData = [...response.results];
            for (let i = 2; i <= totalPages; i++) {
                const response = await getMediaCampaignsMetrics(
                    filter.initialDate,
                    filter.finalDate,
                    filter.timezone,
                    filter.channels,
                    filter.campaigns,
                    null,
                    true,
                    i
                );

                if (!response) { continue; }

                campaignsData.push(...response.results);
            }

            return campaignsData
        } catch(error) {
            throw new Error();
        }
    }

    const handleExportReport = async() => {
        setExportReportLoading(true);
        try {
            toast.info(t('toast.wait_for_report'))

            const [startDate, endDate] = dateRange;
            const channels = selectedChannelsOptions
                ? selectedChannelsOptions.map(channel => channel.value)
                : paidMediaChannelsOptions[0].options.map(channel => channel.value);
            const campaigns = selectedCampaignOptions.length > 0 ? [...selectedCampaignOptions] : null;

            await sendReportToEmailMediaCampaignsMetrics(
                formatDate(startDate),
                formatDate(endDate),
                GOOGLE_ANALYTICS_CAMPAIGN_REPORT_TYPE.PAID_MEDIA,
                timezone,
                channels,
                campaigns
            )
            toast.success(t('toast.report_preparation'));
        } catch (error) {
            toast.error(t('toast.report_generation_failed'));
        } finally {
            setExportReportLoading(false);
        }
    }

    const getMediaDataMetrics = useCallback(async (initialDate, finalDate, channels, campaigns) => {
        setIsLoading(true);

        try {
            const responses = await Promise.all([
                getMediaOverviewMetrics(
                    initialDate,
                    finalDate,
                    timezone,
                    channels,
                    campaigns
                ),
                getMediaCampaignsMetricsAllPages({
                    initialDate,
                    finalDate,
                    timezone,
                    channels,
                    campaigns
                })
            ]);

            const responseOverview = responses[0];
            const responseCampaigns = responses[1];

            if (!responseOverview || !responseCampaigns) {
                setBigNumbersData(null);
                setCampaignOptions([]);
                setCampaignsData(null);

                return;
            }

            setBigNumbersData(responseOverview.big_numbers)
            setCampaignOptions(responseOverview.available_campaigns_to_filter)
            setCampaignsData(responseCampaigns);
        } catch (error) {
            toast.error(error.message);
            setBigNumbersData(null);
            setCampaignOptions([])
            setCampaignsData(null)
        } finally {
          setIsLoading(false);
        }
    }, [timezone]);

    const formatCampaignDataForGraph = (data) => {
        if(!data) { return null}

        const aggregatedData = {};

        data.forEach(entry => {
            const key = entry.campaign;
            if (!aggregatedData[key]) {
                aggregatedData[key] = {
                    campaign: key,
                    investment: 0.0,
                    capturedRevenue: 0.0,
                    roas: 0.0
                };
            }

            aggregatedData[key].investment += entry.investment;
            aggregatedData[key].capturedRevenue += entry.google_analytics_captured_revenue;
        });

        Object.values(aggregatedData).forEach(entry => {
            if (entry.investment > 0) {
                entry.roas =  Number(((entry.capturedRevenue / entry.investment) * 100).toFixed(2));
            }
        });

        return Object.values(aggregatedData).sort((a, b) => b.capturedRevenue - a.capturedRevenue);
    };

    const formatRoasDataForGraph = (data) => {
        if(!data) { return null}

        const aggregatedData = formatCampaignDataForGraph(data);

        return aggregatedData.sort((a, b) => b.roas - a.roas);
    }

    const getCardIndicatorData = (data) => {
        return {
          keys: ["investment", "cpt", "roas_percentage", "cac", "ctr", "transactions"],
          titles: {
            investment: t('common.investment'),
            cpt: t('common.cpt'),
            roas_percentage: t('common.roas'),
            cac: t('common.cac'),
            ctr: t('common.ctr'),
            transactions: t('common.transactions')
          },
          types: {
            investment: 'currency',
            cpt: 'currency',
            roas_percentage: 'percent',
            cac: 'currency',
            ctr: 'percent',
            transactions: 'decimal'
          },
          displayFlexSpaceOcupation: {
            investment: 2,
            cpt: 1,
            roas_percentage: 1,
            cac: 1,
            ctr: 1,
            transactions: 1
          },
          data: data
        }
    }

    const onRenderComponent = useCallback(async () => {
        const channels = paidMediaChannelsOptions[0].options.map(channel => channel.value);
        const { initialDateFilter, finalDateFilter } = getSelectedInitialDateRange();
        setDateRange([initialDateFilter, finalDateFilter]);

        await getMediaDataMetrics(
          formatDate(initialDateFilter),
          formatDate(finalDateFilter),
          channels
        );
      }, [getMediaDataMetrics]);

      useEffect(() => {
        onRenderComponent();
      }, [onRenderComponent]);

    return (
        <BackgroundNew
            titlePage={t('menu.paid_media_campaigns')}
            showInformation={true}
            informationTooltipTitle={t('common.click_to_learn_more')}
            informationOnClick={() => setOpenModalInformation(true)}
        >
            <FilterContainer
                showChannelFilter={true}
                showMultiSelectFilter={true}
                multiSelectOptionsTitle={t('common.campaign')}
                selectedDateRange={dateRange}
                setDateRange={setDateRange}
                selectedChannelsOptions={selectedChannelsOptions}
                setSelectedChannelsOptions={setSelectedChannelsOptions}
                multiSelectOptions={campaignOptions}
                setSelectedMultiSelectValueOptions={setSelectedCampaignOptions}
                selectedMultiSelectValueOptions={selectedCampaignOptions}
                onClickFilter={handleApplyFilter}
                channelsOptions={paidMediaChannelsOptions}

            />
            <CardIndicator
                data={getCardIndicatorData(bigNumbersData)}
                currency={currency}
                isLoading={isLoading}
            />
            <CardGroup
                title={t('paidMediaCampaign.conversion_funnel')}
                tagIdentifierColor="#00CCAE"
                isLoading={isLoading}
                ref={conversionFunnelRef}
                orientation={'landscape'}
            >
                <GraphController
                  showExportPDFButton={true}
                  graphComponentRefForPDF={conversionFunnelRef}
                  showDimensionController={false}
                  disabled={bigNumbersData === null}
                  exportPDFAlignContentCenterPage={false}
                />
                <ContainerConversionFunnel>
                    <ConversionFunnelComplement>
                        <div className='containerIndicatorFunnelComplement'>
                            <HiCursorClick size={40} className='iconIndicatorFunnelComplement iconCTR' />
                            <div className='containerIndicatorValueFunnelComplement'>
                                <span className='labelIndicadorFunnelComplement'>CTR</span>
                                <span className='dataIndicadorFunnelComplement'>{bigNumbersData ? bigNumbersData.ctr : 0.0}%</span>
                            </div>
                        </div>
                        <div className='containerIndicatorFunnelComplement'>
                            <AiFillDislike size={40} className='iconIndicatorFunnelComplement iconBounceRate' />
                            <div className='containerIndicatorValueFunnelComplement'>
                                <span className='labelIndicadorFunnelComplement'>{t('paidMediaCampaign.bounce_rate')}</span>
                                <span className='dataIndicadorFunnelComplement'>{bigNumbersData ? bigNumbersData.bounce_rate : 0.0}%</span>
                            </div>
                        </div>
                        <div className='containerIndicatorFunnelComplement'>
                            <RiMoneyDollarCircleFill size={40} className='iconIndicatorFunnelComplement iconConversionRate' />
                            <div className='containerIndicatorValueFunnelComplement'>
                                <span className='labelIndicadorFunnelComplement'>{t('common.conversion_rate')}</span>
                                <span className='dataIndicadorFunnelComplement'>{bigNumbersData ? bigNumbersData.conversion_rate : 0.0}%</span>
                            </div>
                        </div>
                    </ConversionFunnelComplement>
                    <ConversionFunnel>
                        <div className='containerStepFunnel'>
                            <div className='labelStepFunnel'>
                                <span className='labelInnerContentStepFunnel'>{t('common.impressions')}</span>
                            </div>
                            <div className='dataStepFunnel'>
                                <span className='dataInnerContentStepFunnel'>{bigNumbersData ? bigNumbersData.impressions : 0}</span>
                            </div>
                        </div>
                        <div className='containerStepFunnel'>
                            <div className='labelStepFunnel'>
                                <span className='labelInnerContentStepFunnel'>{t('common.clicks')}</span>
                            </div>
                            <div className='dataStepFunnel'>
                                <span className='dataInnerContentStepFunnel clicksStepData'>{bigNumbersData ? bigNumbersData.clicks : 0}</span>
                            </div>
                        </div>
                        <div className='containerStepFunnel'>
                            <div className='labelStepFunnel'>
                                <span className='labelInnerContentStepFunnel'>{t('common.sessions')}</span>
                            </div>
                            <div className='dataStepFunnel'>
                                <span className='dataInnerContentStepFunnel sessionsStepData'>{bigNumbersData ? bigNumbersData.sessions : 0}</span>
                            </div>
                        </div>
                        <div className='containerStepFunnel'>
                            <div className='labelStepFunnel'>
                                <span className='labelInnerContentStepFunnel'>{t('common.transactions')}</span>
                            </div>
                            <div className='dataStepFunnel'>
                                <span className='dataInnerContentStepFunnel transactionsStepData'>{bigNumbersData ? bigNumbersData.transactions : 0}</span>
                            </div>
                        </div>
                    </ConversionFunnel>
                </ContainerConversionFunnel>
            </CardGroup>
            <CardGroup
                title={t('paidMediaCampaign.captured_revenue_vs_investment')}
                tagIdentifierColor="#00CCAE"
                isLoading={isLoading}
                ref={capturedRevenueAndInvestmentRef}
            >
                <GraphBarHorizontal
                    data={campaignsData}
                    YAxisDataKey="campaign"
                    showPrimaryBarXAxis={true}
                    showSecondaryBarXAxis={true}
                    primaryBarXAxisConfig={{
                        name: t('common.captured_revenue'),
                        dataKey: "capturedRevenue"
                    }}
                    secondaryBarXAxisConfig={{
                        name: t('common.investment'),
                        dataKey: "investment"
                    }}
                    formatData={formatCampaignDataForGraph}
                    formaterBarValue={value => formatValueToMoney(value, currency)}
                    graphMarginConfig={{
                        top: 20,
                        right: 20,
                        left:-10,
                        bottom: 20
                    }}
                    showDimensionController={true}
                    disabledDimensionController={campaignsData === null || campaignsData.length === 0}
                    showFullScreenOption={true}
                    fullScreenEnabled={revenueAndInvestmentGraphFullScreen}
                    setFullScreenEnabled={setRevenueAndInvestmentGraphFullScreen}
                    height='50vh'
                    showExportPDFButton={true}
                    graphComponentRefForPDF={capturedRevenueAndInvestmentRef}
                />
            </CardGroup>
            <Container>
                <CardGroup
                    title={t('paidMediaCampaign.captured_revenue_share')}
                    tagIdentifierColor="#00CCAE"
                    isLoading={isLoading}
                    customClassName="cardGroupContainerFirst"
                    ref={cardCapturedRevenueShareRef}
                >
                    <GraphPie
                        data={campaignsData}
                        dataKeyLabel="campaign"
                        dataKeyValue="capturedRevenue"
                        formatData={formatCampaignDataForGraph}
                        formaterValue={value => formatValueToMoney(value, currency)}
                        graphMarginConfig={{
                            top: 20,
                            right: 20,
                            left:-10,
                            bottom: 20
                        }}
                        showDimensionController={true}
                        disabledDimensionController={campaignsData === null || campaignsData.length === 0}
                        showFullScreenOption={true}
                        fullScreenEnabled={revenueShareGraphFullScreen}
                        setFullScreenEnabled={setRevenueShareGraphFullScreen}
                        showExportPDFButton={true}
                        graphComponentRefForPDF={cardCapturedRevenueShareRef}
                    />
                </CardGroup>
                <CardGroup
                    title={t('common.roas')}
                    tagIdentifierColor="#00CCAE"
                    isLoading={isLoading}
                    customClassName="cardGroupContainerSecond"
                    ref={cardROASRef}
                >
                    <GraphBarHorizontal
                        data={campaignsData}
                        YAxisDataKey="campaign"
                        showSecondaryBarXAxis={true}
                        secondaryBarXAxisConfig={{
                            name: t('common.roas'),
                            dataKey: "roas"
                        }}
                        formatData={formatRoasDataForGraph}
                        formaterBarValue={formatValueToPercentage}
                        graphMarginConfig={{
                            top: 20,
                            right: 20,
                            left:-10,
                            bottom: 20
                        }}
                        showDimensionController={true}
                        disabledDimensionController={campaignsData === null || campaignsData.length === 0}
                        showFullScreenOption={true}
                        fullScreenEnabled={roasGraphFullScreen}
                        setFullScreenEnabled={setRoasGraphFullScreen}
                        showExportPDFButton={true}
                        graphComponentRefForPDF={cardROASRef}
                    />
                </CardGroup>
            </Container>
            <CardGroup
                title={t('common.campaigns')}
                tagIdentifierColor="#00CCAE"
                isLoading={isLoading}
            >
                <TableCampaign
                    data={campaignsData}
                    selectedDimension={selectedDimension}
                    setSelectedDimension={setSelectedDimension}
                    showDimensionController={true}
                    disabledDimensionController={campaignsData === null || campaignsData.length === 0}
                    showFullScreenOption={true}
                    fullScreenEnabled={campaignsTableFullScreen}
                    setFullScreenEnabled={setCampaignsTableFullScreen}
                    handleExportCSVReport={handleExportReport}
                    showExportCSVReportButton={true}
                    loadingExportCSVReportButton={exportReportLoading}
                    currency={currency}
                />
            </CardGroup>
            <ModalPageInformation
                title={t('paidMediaCampaign.understand_paid_media_analysis_campaigns')}
                open={openModalInformation}
                handleClose={() => setOpenModalInformation(false)}
                videoUrl={"https://www.youtube.com/embed/mbpLUdq_kIU?si=3wf68FChC9QHcqXX"}
            />
        </BackgroundNew>
    );
}

export default PaidMediaCampaign;
