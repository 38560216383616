import React, { useState } from 'react';

import { useTranslation } from 'react-i18next';
import '../../../i18n';

import { BsThreeDotsVertical } from 'react-icons/bs';
import * as S from './styled';

function UserMenu(props) {
    const { t } = useTranslation();

    const [anchorEl, setAnchorEl] = useState(null);
    const open = Boolean(anchorEl);

    const handleEdit = () => {
        setAnchorEl(null);
        props.show(props.user, 'edit');
    };

    const handleDelete = () => {
        setAnchorEl(null);
        props.show(props.user, 'delete');
    };

    return (
        <>
            <S.UserMenuButton onClick={(ev) => setAnchorEl(ev.currentTarget)}>
                {t('alerts.options')} <BsThreeDotsVertical size={20} color="#2C3149" />
            </S.UserMenuButton>
            <S.UserMenu
                anchorEl={anchorEl}
                open={open}
                onClose={() => setAnchorEl(null)}
            >
                <S.UserMenuItem onClick={handleEdit}>{t('alerts.edit')}</S.UserMenuItem>
                <S.UserMenuItem onClick={handleDelete}>{t('alerts.delete')}</S.UserMenuItem>
            </S.UserMenu>
        </>
    );
}

export default UserMenu;
