import { darken } from 'polished';
import styled from 'styled-components';

export const StyledRangePickerContainer = styled.div`
  @media (max-width: 768px) {
    .ant-picker-panels {
      flex-direction: column !important;
    }
  }
`;

export const ContainerSecondaryFilter = styled.div`
    width: 100%;
    display: flex;
    flex-direction: row;
    border: none;
    border-radius: 10px;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    overflow: auto;
    max-height: 70vh;

    .filterLabel {
      margin-bottom: 5px;
      display: flex;
      justify-content: left;

      .metricInfoIcon {
        margin-left: 4px;
      }
    }

    .multiSelectFilterSecondary, .rangeNumberFilterSecondary {
      color: #1A1D1F;
      width: 40%;
      max-width: 100%;
      margin-right: 10px;
      margin-left: 10px;
      margin-bottom: 20px;
    }
`;

export const Container = styled.div`
    width: 100%;
    display: flex;
    flex-direction: row;
    border: none;
    border-radius: 10px;
    justify-content: right;
    align-items: flex-end;
    flex-wrap: wrap;

    @media (max-width: 829px) {
      justify-content: center;
    }

    .filterLabel {
      margin-bottom: 5px;
    }

    .channelFilter {
      min-width: 180px;
      max-width: 180px;
    }

    .multiSelectFilter {
      min-width: 180px;
      max-width: 180px;
    }

    .timezoneFilter, .channelFilter, .multiSelectFilter {
      margin-right: 10px;
      margin-bottom: 20px;
      color: #1A1D1F;

      @media (max-width: 829px) {
        width: 100%;
        max-width: 100%;
        margin-right: 20%;
        margin-left: 20%;
      }
    }

    .timezoneFilter {
      min-width: 230px;
    }

    .dateRangeFilter {
        color: #1A1D1F;
        margin-bottom: 20px;
    }

    .applyFilterButton, .moreFilterButton, .exportReportButton {
        display: flex;
        align-items: center;
        color: #fff;
        transition: background-color 0.2s;
        border: 0;
        box-shadow: 0 2px 20px rgba(255, 0, 104, 0.3);
        background-color: #ff0068;
        font-size: 13px;
        font-weight: 500;
        letter-spacing: -0.21px;
        border-radius: 20px;
        height: 35px;       
        padding: 0 15px;
        justify-content: center;
        cursor: pointer;
        margin-left: 10px;
        margin-bottom: 3px;
        margin-bottom: 20px;
  
        &:hover {
          background-color: ${darken(0.05, '#ff0068')};
          color: #fff;
        }
    }

    .moreFilterButton {
      background: #00CCAE;
      box-shadow: 0 2px 20px rgba(0, 204, 174, 0.3);

      &:hover {
        background-color: ${darken(0.05, '#00CCAE')};
      }
    }

    .buttonDisabled {
        background-color: #999;
        color: #fff;
        cursor: not-allowed;
        box-shadow: none;

        &:hover {
            background-color: #999;
            color: #fff;
        }
    }
`;