import React, { forwardRef, useImperativeHandle, useRef } from 'react';

import { useTranslation } from 'react-i18next';
import '../../i18n';

import Loader from '../Loader';
import { CardGroupContainer } from './styles';

import { FiArrowLeft } from 'react-icons/fi';
import { FaRegFilePdf } from "react-icons/fa";

const CardGroup = forwardRef( ({
    children, title = "", tagIdentifierColor, isLoading, customClassName,
    showExportPDFButton = false, exportPDFButtonOnClick, exportPDFButtonEnableLoading = false,
    exportPDFButtonDisable, exportPDFButtonOnLoading, showBackButton = false, backButtonOnClick
}, ref) => {
    const { t } = useTranslation();

    const titleRef = useRef();
    const contentRef = useRef();

    useImperativeHandle(ref, () => ({
      getTitle: () => titleRef.current.innerText || title,
      getContentRef: () => contentRef.current
    }));

    return (
      <CardGroupContainer className={customClassName} ref={contentRef}
      >
        <div className='cardGroupHeader' >
          {title &&
            <h2 className='cardGroupTitle' ref={titleRef}>
                    <span
                      className='tagIdentifier'
                      style={{ backgroundColor: tagIdentifierColor ? tagIdentifierColor : "#00CCAE"}}>
                    </span>
              {title}
            </h2>
          }
          {(showExportPDFButton || showBackButton) && (
            <div className='buttonGroup ignorePdf'>
              {showExportPDFButton &&
                <button
                  className='exportPDFButton'
                  onClick={exportPDFButtonOnClick}
                  disabled={exportPDFButtonDisable}
                >
                  <FaRegFilePdf className='exportPDFButtonIcon' size={20}/>
                  {t('bisoInsights.export_pdf')}
                  {exportPDFButtonEnableLoading && exportPDFButtonOnLoading && <Loader
                    size={17}
                    color='#fff'
                    containerStyles={{width: "auto", height: "auto", marginLeft: 5}}
                  />}
                </button>
              }
              {showBackButton &&
                <button
                  className='backButton'
                  onClick={backButtonOnClick}>
                  <FiArrowLeft className='backButtonIcon' size={20}/>
                  {t('goalsManager.back')}
                </button>
              }
            </div>
          )}
        </div>
        <div className='cardGroupContent' >
          {isLoading && <Loader />}
          {!isLoading && children && <div className='cardContent'>{children}</div>}
          {!isLoading && !children && <div className='noData'>{t('common.no_data_to_display')}</div>}
        </div>
      </CardGroupContainer>
    )
});

export default CardGroup;
