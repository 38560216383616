import React, { useState, useEffect } from "react";
import {
    Table, TableContainer, TableHead, TableRow,
    TableCell, TableBody, Paper, TablePagination,
    styled
} from '@mui/material';

import { useTranslation } from 'react-i18next';
import '../../i18n';

import GraphController from '../GraphController';
import { Container } from './styles';

const CustomTableApiPagination = ({
    data,
    disabledDimensionController,
    showFullScreenOption = false,
    fullScreenEnabled = false,
    setFullScreenEnabled,
    currentPage,
    setCurrentPage,
    rowsPerPage,
    setRowsPerPage,
    totalItems,
    rowsPerPageOptions,
    showPagination = true,
    showTableFooter = false,
    tableFooterDescription,
    showExportCSVReportButton,
    handleExportCSVReport,
    loadingExportCSVReportButton
}) => {
    const { t } = useTranslation();

    const [headers, setHeaders] = useState([]);
    const [rows, setRows] = useState([]);

    useEffect(() => {
        setRows(data.rows);
        setHeaders(data.headers);
    }, [data]);

    const StyledTableRow = styled(TableRow)(({ theme }) => ({
        '&:nth-of-type(odd)': {
            backgroundColor: '#F4F4F4',
        },
    }));

    const stylesComponents = {
        mainContainerFullScreen: {
            position: 'fixed',
            top: 0,
            left: 0,
            width: '100%',
            height: '100vh',
            backgroundColor: 'white',
            zIndex: 1200
        },
        tableGroupFullScreen: {
            padding: '20px'
        },
        tableContainerFullScreen: {
            maxHeight: 'calc(100vh - 200px)',
        },
        tableFooter: {
            width: '100%',
            height: '30px',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            marginTop: '20px'
        }
    };

    const defineStickyCellBackgroundColor = (index) => {
        return index % 2 === 0 ? '#F4F4F4' : '#fff';
    };

    return (
        <Container style={fullScreenEnabled ? stylesComponents.mainContainerFullScreen : {}}>
            <div className="tableGroup" style={fullScreenEnabled ? stylesComponents.tableGroupFullScreen : {}}>
                <GraphController
                    disabled={disabledDimensionController}
                    showDimensionController={false}
                    showFullScreenOption={showFullScreenOption}
                    fullScreenEnabled={fullScreenEnabled}
                    setFullScreenEnabled={setFullScreenEnabled}
                    style={{ marginRight: '0px' }}
                    handleExportCSVReport={handleExportCSVReport}
                    showExportCSVReportButton={showExportCSVReportButton}
                    loadingExportCSVReportButton={loadingExportCSVReportButton}
                />
                <TableContainer
                    component={Paper}
                    className="tableContainer"
                    style={fullScreenEnabled ? stylesComponents.tableContainerFullScreen : {}}
                >
                    <Table className="table">
                        <TableHead className="stickyHeader">
                            <TableRow>
                                {headers.length > 0 && headers.map((header, index) => (
                                    <TableCell className={header.stickyCell ? "stickyCell" : ""} style={{ minWidth: header.minWidth, fontWeight: 'bold' }} key={index}>{header.name}</TableCell>
                                ))}
                            </TableRow>
                        </TableHead>
                        <TableBody className="tableBody">
                            {rows.length > 0 && rows.map((row, index) => (
                                <StyledTableRow role="checkbox" tabIndex={-1} key={index}>
                                    {headers.map((header, headerIndex) => (
                                        <TableCell
                                            className={`${headerIndex === 0 ? 'firstTableCell' : ''}${header.stickyCell ? " stickyCell" : ""}`}
                                            key={headerIndex}
                                            style={{
                                                minWidth: header.minWidth,
                                                backgroundColor: header.stickyCell ? defineStickyCellBackgroundColor(index) : 'inherit',
                                                color: header.defineColor ? header.defineColor(row[header.value]) : null,
                                                fontWeight: header.fontWeight
                                            }}
                                        >
                                            {header.format(row[header.value])}
                                        </TableCell>
                                    ))}
                                </StyledTableRow>
                            ))}
                            {rows.length === 0 && (
                                <StyledTableRow role="checkbox" tabIndex={-1} key={0}>
                                    <TableCell colSpan={headers.length} style={{ textAlign: 'center' }}>{t('common.no_data')}</TableCell>
                                </StyledTableRow>
                            )}
                        </TableBody>
                    </Table>
                </TableContainer>
                {showPagination &&
                    <TablePagination
                        labelRowsPerPage={t('common.lines_per_page')}
                        labelDisplayedRows={({ from, to, count }) => `${from}-${to} de ${count}`}
                        rowsPerPageOptions={rowsPerPageOptions}
                        component="div"
                        count={totalItems}
                        rowsPerPage={rowsPerPage}
                        page={currentPage - 1}
                        onPageChange={(event, newPage) => setCurrentPage(newPage + 1)}
                        onRowsPerPageChange={(event) => setRowsPerPage(event.target.value)}
                    />
                }
                {showTableFooter &&
                    <div style={stylesComponents.tableFooter}>
                        <p style={{fontWeight: "bold", color: "#ff0068", textAlign: "center"}}>{tableFooterDescription}</p>
                    </div>
                }
            </div>
        </Container>
    );
};

export default CustomTableApiPagination;
