import api from "./api";

import i18n from "../i18n";

export const getProjectedCustomerGoals = async (years) => {
  const token = localStorage.getItem("token");

  let yearsParam = years.join(",");

  const response = await api.get(`/customer/projected/goal/month?years=${yearsParam}`, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });

  if (response && response.status === 204) {
    return null;
  }

  if (!response || response.status !== 200) {
    throw new Error(i18n.t('error.goals_retrieve_failed'));
  }

  return response.data;
};

export const createProjectedCustomerGoals = async (data) => {
  const token = localStorage.getItem("token");

  const response = await api.post(`/customer/projected/goal`, data, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });

  if (!response || response.status !== 200) {
    throw new Error(i18n.t('error.goals_creation_failed'));
  }

  return response.data;
};

export const deleteProjectedCustomerGoals = async (year) => {
  const token = localStorage.getItem("token");

  const response = await api.delete(`/customer/projected/goal?year=${year}`, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });

  if (!response || response.status !== 200) {
    throw new Error(i18n.t('error.goals_deletion_failed'));
  }

  return;
};

export const getCustomCompanyGoalsGroupByMonth = async (year) => {
  const token = localStorage.getItem("token");

  const response = await api.get(`/customer/goal/custom/month?years=${year}`, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });

  if (response && response?.status === 204) {
    return null;
  }

  if (!response || response?.status !== 200) {
    throw new Error(i18n.t('error.goals_retrieve_failed'));
  }

  return response.data;
};

export const getCustomCompanyGoalsDaily = async (year, month) => {
  const token = localStorage.getItem("token");

  const response = await api.get(`/customer/goal/custom/daily?year=${year}&month=${month}`, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });

  if (response && response?.status === 204) {
    return null;
  }

  if (!response || response?.status !== 200) {
    throw new Error(i18n.t('error.goals_retrieve_failed'));
  }

  return response.data;
};

export const upsertCustomCompanyGoals = async (data) => {
  const token = localStorage.getItem("token");

  const response = await api.put(`/customer/goal/custom`, { goals: data}, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });

  if (!response || response.status !== 201) {
    throw new Error("");
  }

  return response.data;
};

export const deleteCustomCompanyGoals = async (year, month, channel) => {
  const token = localStorage.getItem("token");

  const response = await api.delete(
    `/customer/goal/custom?year=${year}&month=${month}&channels=${channel}`,
    {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }
  );

  if (!response || response.status !== 200) {
    throw new Error(i18n.t('error.goals_deletion_failed'));
  }

  return;
};
