import React, { useState } from 'react';

import {
    Option, CreatableOption
} from './styles';

const SelectOption = ({ options, onChange, value, placeholder, isDisabled, isClearable, isSearchable, isMulti, isCreatable }) => {
    const [inputValue, setInputValue] = useState('');

    const customStyles = {
        valueContainer: (base) => ({
            ...base,
            maxHeight: '35px',
            overflow: 'auto',
            '&::-webkit-scrollbar': {
                width: '0px',
                height: '0px',
            },
            '&::-webkit-scrollbar-track': {
                boxShadow: '#f1f1f1',
                borderRadius: '10px',
                width: '5px',
            },
            '&::-webkit-scrollbar-thumb': {
                background: '#DEDEDE',
                borderRadius: '10px',
            },
            '&::-webkit-scrollbar-thumb:hover': {
                background: '#C8C8C8',
            }
        }),
        menu: (provided) => ({
            ...provided,
            zIndex: 1000,
        })
    };

    const createOption = (label) => ({
        label,
        value: label,
    });

    const handleKeyDown = (event) => {
        if (!inputValue) return;
        if (["Enter", "Tab"].includes(event.key)) {
            const oldValue = value ? value : []
            onChange([...oldValue, createOption(inputValue)]);
            setInputValue('');
            event.preventDefault();
        }
    };
    
    return (
        <>
            {!isCreatable && (
                <Option
                    options={options}
                    onChange={onChange}
                    value={value}
                    placeholder={placeholder}
                    isDisabled={isDisabled}
                    isClearable={isClearable}
                    isSearchable={isSearchable}
                    isMulti={isMulti}
                    theme={(theme) => ({
                        ...theme,
                        colors: {
                        ...theme.colors,
                        primary25: '#F4F4F4',
                        primary: '#ff0068'
                        },
                    })}
                    styles={customStyles}
                />
            )}
            {isCreatable && (
                <CreatableOption
                    components={{
                        DropdownIndicator: null,
                    }}
                    inputValue={inputValue}
                    onChange={onChange}
                    onInputChange={(newValue) => setInputValue(newValue)}
                    onKeyDown={handleKeyDown}
                    value={value}
                    placeholder={placeholder}
                    isDisabled={isDisabled}
                    isClearable
                    isMulti
                    theme={(theme) => ({
                        ...theme,
                        colors: {
                        ...theme.colors,
                        primary25: '#F4F4F4',
                        primary: '#ff0068'
                        },
                    })}
                    styles={customStyles}
                    menuIsOpen={false}
                />
            )}
        </>
    );
}

export default SelectOption;