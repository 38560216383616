import React from "react";

import { ButtonCustomized } from "./styles";

import Loader from '../Loader';

const Button = ({ 
    text, enableLoading, isLoading, disabled, customClass, onClick
}) => {
    return (
        <ButtonCustomized
            className={customClass}
            onClick={onClick}
            disabled={disabled}
        >
            {text}
            {enableLoading && isLoading && <Loader 
                size={17}
                color='#fff'
                containerStyles={{width: "auto", height: "auto", marginLeft: 5}}
            />}
        </ButtonCustomized>
    )
}

export default Button;