import styled from 'styled-components';

export const Container = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;

    .tableGroup {
        display: flex;
        flex-direction: column;
        align-items: flex-end;
        justify-content: center;
        width: 100%;
    }

    .tableContainer {
        margin-top: 20px;
        border-radius: 8px;
        max-height: 500px;
        width: 100%;

        /* width */
        ::-webkit-scrollbar {
        width: 5px;
        height: 4px;
        }

        /* Track */
        ::-webkit-scrollbar-track {
        box-shadow: #f1f1f1; 
        border-radius: 10px;
        width: 2px;
        }
        
        /* Handle */
        ::-webkit-scrollbar-thumb {
        background: #DEDEDE; 
        border-radius: 10px;
        }

        /* Handle on hover */
        ::-webkit-scrollbar-thumb:hover {
        background: #C8C8C8; 
        }

        .table {
            border-collapse: initial;
    
            .stickyHeader {
                position: sticky;
                top: 0;
                background-color: #fff;
                z-index: 2;
            }

            .stickyCell {
                position: sticky;
                left: 0;
                z-index: 1;
                background-color: #fff;
            }

            .stickyTotalRow {
                position: sticky;
                bottom: 0;
                left: 0;
                z-index: 2;
                width: 100%;
                background-color: #fff;
                color: #ff0068;
                font-weight: bold;
            }
    
            .stickyCellTotalRow, .stickyCellTotalRowTitle {
                position: sticky;
                left: 0;
                background-color: #fff;
                z-index: 2;
            }

            .stickyCellTotalRowTitle {
                z-index: 3;
            }

            @media (max-width: 668px) {
                .stickyCell {
                    position: initial;
                    left: initial;
                    z-index: initial;
                    background-color: initial;
                }

                .stickyCellTotalRow, .stickyCellTotalRowTitle {
                    position: initial;
                    left: initial;
                    background-color: initial;
                    z-index: initial;
                }
            }

            .firstTableCellHeader, .firstTableCell {
                min-width: 120px;
                font-weight: bold;
                border-left: 4px #fff solid;
                border-radius: 5px 5px 5px 5px;
            }

            .firstTableCell {
                font-weight: normal;
                border-left: 4px #ff0068 solid;
            }
    
            .tableCell {
                line-height: 1.0;
            }
        }
    }
`;