/* eslint-disable camelcase */
import React, { useState, useEffect, useCallback } from 'react';
import { toast } from 'react-toastify';

import { useTranslation } from 'react-i18next';
import '../../i18n';

import Slide from '@mui/material/Slide';
import * as S from './styles';
import BackgroundNew from '../../components/BackgroundNew';
import Loader from '../../components/Loader';

import { getNotifications } from '../../services/notificationService';

function Notifications() {
  const { t } = useTranslation();

  const [notifications, setNotifications] = useState([]);
  const [page, setPage] = useState(1);
  const [totalPages, setTotalPages] = useState(null);
  const [loading, setLoading] = useState(true);

  const loadNotifications = useCallback(async (page = 1, actualNotificationsList = []) => {
    try {
      const response = await getNotifications(page);

      if (!response) {
        return;
      }

      const result = response["results"];

      const totalPages = response["total_pages"];

      const notificationsList = [...actualNotificationsList, ...result]

      setNotifications(notificationsList);
      setTotalPages(totalPages);
      setPage(page);
    } catch (error) {
      toast.error(error.message);
    } finally {
      setLoading(false);
    }
  }, []);

  useEffect(() => {
    loadNotifications();
  }, [loadNotifications]);

  return (
    <BackgroundNew titlePage={t('menu.notifications')}>
      <S.Container>
        <div className="header">
          <div className="group">
            <div className="description">
              <p className="title">{t('notifications.track_your_notifications')}</p>
            </div>
          </div>
        </div>

        <div className="workSpace">
          {(!loading && notifications.length > 0) && notifications.map((notification) => (
            <Slide direction="up" in mountOnEnter unmountOnExit>
              <div className="option" key={notification.id}>
                <div className="notificationIcon">
                  <img className="word" src={`${process.env.PUBLIC_URL}/icon_notification.png`} alt="Biso"/>
                  <div className="descriptionOption">
                    <p className="titleOption">
                      {notification.heading.substring(notification.heading.indexOf("|") + 1)}
                    </p>
                    <p>{notification.content}</p>
                  </div>
                </div>
                <span className="notificationDate">{notification.created_at}</span>
              </div>
            </Slide>
          ))}
          {!loading && notifications.length === 0 && (
            <div className="noNotifications">
              <p>{t('notifications.no_notifications_found')}</p>
            </div>
          )}
          {loading && (
            <div className="loading">
              <Loader/>
            </div>
          )}
        </div>
        
        {totalPages && page < totalPages && 
          <div className="pagination">
              <button className="loadMoreNotification" onClick={() => loadNotifications(page + 1, notifications)}>{t('common.load_more')}</button>
          </div>
        }
      </S.Container>
    </BackgroundNew>
  );
}

export default Notifications;
