import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { useTranslation } from 'react-i18next';
import '../../../i18n';

import MobileStepper from '@mui/material/MobileStepper';
import * as S from './styled';

import { routes } from '../../../routes';

function CustomModalCreateAlert(props) {
  const { t } = useTranslation();

  const windowWidth = window.innerWidth;
  const navigate = useNavigate();

  const [activeStep, setActiveStep] = useState(0);
  const maxSteps = windowWidth < 1201 ? 14 : 12;

  const handleNextStep = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBackStep = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleRedirect = async () => {
    navigate(routes.alerts);
    props.handleClose();
  };

  return (
    <S.CustomModalCreateAlert {...props}>
      <div className="modal">
        <div className="modal__header">
          <p className="modal__title">Criando alertas</p>
          <div className="line" />
        </div>

        <div className="modal__body">
          <div className="modal__slider">
            <div className="modal__slider-progress">
              <MobileStepper
                steps={maxSteps}
                position="static"
                variant="progress"
                activeStep={activeStep}
                nextButton={
                  <button
                    className="modal__slider-btn is--next"
                    type="button"
                    onClick={handleNextStep}
                    disabled={activeStep === maxSteps - 1}
                  >
                    {t('common.next')}
                  </button>
                }
                backButton={
                  <button
                    className="modal__slider-btn is--back"
                    type="button"
                    onClick={handleBackStep}
                    disabled={activeStep === 0}
                  >
                    {t('common.previous')}
                  </button>
                }
              />
            </div>

            {activeStep === 0 && (
              <div className="modal__slider-item">
                {windowWidth < 1201 ? (
                  <img
                    src={`${process.env.PUBLIC_URL}/assets/imgs/mobile_settings-first-alert-01.png`}
                    alt="Como criar alerta"
                  />
                ) : (
                  <img
                    src={`${process.env.PUBLIC_URL}/assets/imgs/desktop_settings-first-alert-01.png`}
                    alt="Como criar alerta"
                  />
                )}
              </div>
            )}

            {activeStep === 1 && (
              <div className="modal__slider-item">
                {windowWidth < 1201 ? (
                  <img
                    src={`${process.env.PUBLIC_URL}/assets/imgs/mobile_settings-first-alert-02.png`}
                    alt="Como criar alerta"
                  />
                ) : (
                  <img
                    src={`${process.env.PUBLIC_URL}/assets/imgs/desktop_settings-first-alert-02.png`}
                    alt="Como criar alerta"
                  />
                )}
              </div>
            )}

            {activeStep === 2 && (
              <div className="modal__slider-item">
                {windowWidth < 1201 ? (
                  <img
                    src={`${process.env.PUBLIC_URL}/assets/imgs/mobile_settings-first-alert-03.png`}
                    alt="Como criar alerta"
                  />
                ) : (
                  <img
                    src={`${process.env.PUBLIC_URL}/assets/imgs/desktop_settings-first-alert-03.png`}
                    alt="Como criar alerta"
                  />
                )}
              </div>
            )}

            {activeStep === 3 && (
              <div className="modal__slider-item">
                {windowWidth < 1201 ? (
                  <img
                    src={`${process.env.PUBLIC_URL}/assets/imgs/mobile_settings-first-alert-04.png`}
                    alt="Como criar alerta"
                  />
                ) : (
                  <img
                    src={`${process.env.PUBLIC_URL}/assets/imgs/desktop_settings-first-alert-04.png`}
                    alt="Como criar alerta"
                  />
                )}
              </div>
            )}

            {activeStep === 4 && (
              <div className="modal__slider-item">
                {windowWidth < 1201 ? (
                  <img
                    src={`${process.env.PUBLIC_URL}/assets/imgs/mobile_settings-first-alert-05.png`}
                    alt="Como criar alerta"
                  />
                ) : (
                  <img
                    src={`${process.env.PUBLIC_URL}/assets/imgs/desktop_settings-first-alert-05.png`}
                    alt="Como criar alerta"
                  />
                )}
              </div>
            )}

            {activeStep === 5 && (
              <div className="modal__slider-item">
                {windowWidth < 1201 ? (
                  <img
                    src={`${process.env.PUBLIC_URL}/assets/imgs/mobile_settings-first-alert-06.png`}
                    alt="Como criar alerta"
                  />
                ) : (
                  <img
                    src={`${process.env.PUBLIC_URL}/assets/imgs/desktop_settings-first-alert-06.png`}
                    alt="Como criar alerta"
                  />
                )}
              </div>
            )}

            {activeStep === 6 && (
              <div className="modal__slider-item">
                {windowWidth < 1201 ? (
                  <img
                    src={`${process.env.PUBLIC_URL}/assets/imgs/mobile_settings-first-alert-07.png`}
                    alt="Como criar alerta"
                  />
                ) : (
                  <img
                    src={`${process.env.PUBLIC_URL}/assets/imgs/desktop_settings-first-alert-07.png`}
                    alt="Como criar alerta"
                  />
                )}
              </div>
            )}

            {activeStep === 7 && (
              <div className="modal__slider-item">
                {windowWidth < 1201 ? (
                  <img
                    src={`${process.env.PUBLIC_URL}/assets/imgs/mobile_settings-first-alert-08.png`}
                    alt="Como criar alerta"
                  />
                ) : (
                  <img
                    src={`${process.env.PUBLIC_URL}/assets/imgs/desktop_settings-first-alert-08.png`}
                    alt="Como criar alerta"
                  />
                )}
              </div>
            )}

            {activeStep === 8 && (
              <div className="modal__slider-item">
                {windowWidth < 1201 ? (
                  <img
                    src={`${process.env.PUBLIC_URL}/assets/imgs/mobile_settings-first-alert-09.png`}
                    alt="Como criar alerta"
                  />
                ) : (
                  <img
                    src={`${process.env.PUBLIC_URL}/assets/imgs/desktop_settings-first-alert-09.png`}
                    alt="Como criar alerta"
                  />
                )}
              </div>
            )}

            {activeStep === 9 && (
              <div className="modal__slider-item">
                {windowWidth < 1201 ? (
                  <img
                    src={`${process.env.PUBLIC_URL}/assets/imgs/mobile_settings-first-alert-10.png`}
                    alt="Como criar alerta"
                  />
                ) : (
                  <img
                    src={`${process.env.PUBLIC_URL}/assets/imgs/desktop_settings-first-alert-10.png`}
                    alt="Como criar alerta"
                  />
                )}
              </div>
            )}

            {activeStep === 10 && (
              <div className="modal__slider-item">
                {windowWidth < 1201 ? (
                  <img
                    src={`${process.env.PUBLIC_URL}/assets/imgs/mobile_settings-first-alert-11.png`}
                    alt="Como criar alerta"
                  />
                ) : (
                  <img
                    src={`${process.env.PUBLIC_URL}/assets/imgs/desktop_settings-first-alert-11.png`}
                    alt="Como criar alerta"
                  />
                )}
              </div>
            )}

            {activeStep === 11 && (
              <div className="modal__slider-item">
                {windowWidth < 1201 ? (
                  <img
                    src={`${process.env.PUBLIC_URL}/assets/imgs/mobile_settings-first-alert-12.png`}
                    alt="Como criar alerta"
                  />
                ) : (
                  <img
                    src={`${process.env.PUBLIC_URL}/assets/imgs/desktop_settings-first-alert-12.png`}
                    alt="Como criar alerta"
                  />
                )}
              </div>
            )}

            {activeStep === 12 && (
              <div className="modal__slider-item">
                <img
                  src={`${process.env.PUBLIC_URL}/assets/imgs/mobile_settings-first-alert-13.png`}
                  alt="Como criar alerta"
                />
              </div>
            )}

            {activeStep === 13 && (
              <div className="modal__slider-item">
                <img
                  src={`${process.env.PUBLIC_URL}/assets/imgs/mobile_settings-first-alert-14.png`}
                  alt="Como criar alerta"
                />
              </div>
            )}
          </div>
        </div>

        <div className="modal__footer">
          <button
            className="modal__form-btn is--cancel"
            type="button"
            onClick={props.handleClose}
          >
            {t('common.cancel')}
          </button>
          <button
            className="modal__form-btn is--save"
            type="button"
            disabled={activeStep !== maxSteps - 1}
            onClick={handleRedirect}
          >
            Criar alerta
          </button>
        </div>
      </div>
    </S.CustomModalCreateAlert>
  );
}

export default CustomModalCreateAlert;
