import styled from 'styled-components';
import { darken } from 'polished';
import backgroundImageSignin from '../../assets/backgroud_image_signin.png';
import backgroundImageSignin800 from '../../assets/backgroud_image_signin_800px.png';
import backgroundImageSignin375 from '../../assets/backgroud_image_signin_375px.png';
import backgroundImageSignin1640 from '../../assets/backgroud_image_signin_1640.png';

export const Container = styled.div`
  width: 100%;
  height: 100vh;
  background-image: url(${backgroundImageSignin});
  background-size: cover;
  background-position: center;
  background-attachment: fixed;
  display: flex;

  @media screen and (max-width: 800px) {
    justify-content: center;
    background-image: url(${backgroundImageSignin800});
  }

  @media screen and (max-width: 375px) {
    justify-content: center;
    background-image: url(${backgroundImageSignin375});
  }

  @media screen and (min-width: 1640px) {
    background-image: url(${backgroundImageSignin1640});
  }
`;

export const Login = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  width: 100%;
  max-width: 480px;
  color: #54617a;
  background: transparent;
  margin-left: 160px;

  @media screen and (max-width: 800px) {
    margin-left: 0;
  }

  img {
    width: 181px;
    margin-bottom: 48px;
  }

  form {
    width: 90%;
    text-align: center;
    background: #FFFF;
    padding: 32px;
    border-radius: 40px;

    .containerTitle {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      margin-bottom: 20px;

      .welcomeTitle {
        font-size: 16px;
        font-weight: bold;
        color: #00CCAE;
        margin-bottom: 10px;
      }

      .title {
        font-family: 'Montserrat', 'Roboto', sans-serif;
        font-size: 34px;
        text-align: left;
        color: #222222;
      }

      .descriptionTitle {
        font-size: 16px;
        color: #222222;
        font-weight: 300;
        margin-top: 10px;
        text-align: left;
      }
    }

    .input {
      display: flex;
      align-items: flex-start;
      flex-direction: column;
      border-bottom: 1px solid #FF0068;
      padding: 8px 7px;
      margin-bottom: 15px;
      width: 60%;

      p {
        color: #A8A8A8;
        font-size: 12px;
        margin-bottom: 2px;
      }

      input {
        width: 100%;
        color: #222222;
        font-size: 15px;
        background: transparent;
        border: 0;

        &::placeholder {
          color: #A8A8A8;
        }

        @media screen and (max-width: 1200px) {
          font-size: 16px;
        }
      }
    }

    .descriptionForgetPassword {
      font-size: 16px;
      color: #222222;
      font-weight: 300;
      margin-bottom: 20px;
      margin-top: 0px;
      text-align: left;
    }

    .forgotPassword, .signin {
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      margin-top: 20px;
      span {
        color: #A8A8A8;
        font-weight: 400;
        font-size: 14px;
        text-decoration: none;
        transition: color 0.2s;
        cursor: pointer;

        &:hover {
          color: ${darken(0.08, '#A8A8A8')};
        }
      }
    }

    .check {
      display: flex;
      flex-direction: row;
      align-items: center;

      p {
        color: #54617a;
      }
    }

    button {
      display: flex;
      align-items: center;
      justify-content: center;
      background: transparent;
      border: 2px solid #f90c68;
      color: #222222;
      border-radius: 50px;
      margin-top: 25px;
      padding: 3px;
      width: 60%;
      height: 52px;
      font-size: 16px;
      transition: background-color 0.2s;

      svg {
        margin-left: 10px;
      }

      &:hover {
        background-color: #f90c68;
        color: #fff;
      }

      &:disabled {
        background-color: #ccc;
        cursor: not-allowed;
        border-color: #ccc;
      }
    }
  }
`;
