import React from 'react';

import { useTranslation } from 'react-i18next';
import '../../../i18n';

import CustomModal from '../CustomModal';

const ModalPageInformation = ({ open, handleClose, videoUrl, title }) => {
    const { t } = useTranslation();

    return (
        <CustomModal
            title={title}
            open={open} 
            handleClose={handleClose}
            hiddenConfirmButton={true}
            cancelButtonLabel={t('common.close')}
        >
            <div className="video">
                <iframe width="100%" height="315" src={videoUrl} title="YouTube video player" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture;" allowFullScreen></iframe>
            </div>
        </CustomModal>
    );
}

export default ModalPageInformation;