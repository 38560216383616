import styled from 'styled-components';

export const ContainerGraph = styled.div`
    margin-left: 20px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    .graph {
        padding: 10px;
        border-radius: 10px;
        width: 200px;
        height: 90px;
        cursor: pointer;
    }

    .graphTitle {
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 13px;
        text-align: left;
        margin-bottom: 10px;
        color: #6F767E;
        font-weight: bold;

        .graphInfoIcon {
            display: flex;
            justify-content: center;
            align-items: center;
            margin-left: 5px;
            cursor: pointer;
        }
    }

    .graphLegend {
        display: flex;
        justify-content: center;
        align-items: center;
        margin-top: 10px;

        .legendItem {
            display: flex;
            align-items: center;
            margin-right: 10px;
            font-size: 13px;
            color: #6F767E;
            font-weight: bold;

            .legendColor {
            width: 5px;
            height: 5px;
            border-radius: 50%;
            margin-right: 5px;
            }
        }
    }
}
`;

export const CustomTooltipContainer = styled.div`
    background: white;
    padding: 10px;
    border-radius: 10px;
    width: 100%;
    border: 0.5px solid #e5e5e5;

    .label {
        font-size: 13px;
        font-weight: bold;
        margin-bottom: 5px;
    }

    .item {
        font-size: 13px;
        white-space: nowrap;
    }
`;