import axios from 'axios';

import i18n from '../i18n';

import { handleSignOut } from '../utils/loginUtils';

const UNAUTHORIZED = 401;
const OK = 200;

const ERROR_MESSAGE = i18n.t('error.authentication_failed');

const api = axios.create({
  baseURL: process.env.REACT_APP_OAUTH_API_URL,
});

api.interceptors.response.use(
  (response) => response,
  (error) => {
    if (error.response.status === UNAUTHORIZED) {
      handleSignOut()
    }
    return error
  }
);

const _makeRequest = async (url, method, data = null) => {
  const token = localStorage.getItem('token');

  try {
    const response = await api({
      url,
      method,
      data,
      headers: {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json'
      }
    });
  
    if (!response || response.status !== OK) {
      throw new Error();
    }

    return response.data;
  } catch (error) {
    throw new Error(ERROR_MESSAGE);
  }
}

const authorizationEndpointByProvider = {
  googleAds: '/google/ads/authorization_url',
  facebook: '/facebook/authorization_url',
  googleAnalytics: '/google/analytics/authorization_url'
}

const exchangeCodeEndpointByProvider = {
  googleAds: '/google/ads/exchange_code_for_token',
  facebook: '/facebook/exchange_code_for_token',
  googleAnalytics: '/google/analytics/exchange_code_for_token'
}

export const getAuthorizationUrl = async (oauth_provider, state) => {
  const endpoint = authorizationEndpointByProvider[oauth_provider];

  return await _makeRequest(`${endpoint}?state=${state}`, 'GET');
}

export const exchangeCodeForToken = async (oauth_provider, code) => {
    const endpoint = exchangeCodeEndpointByProvider[oauth_provider];

    return await _makeRequest(`${endpoint}?code=${code}`, 'GET');
};

export const generateStateIdentifier = () => {
  return Math.random().toString(36).substring(2, 15) + Math.random().toString(36).substring(2, 15);
};
  