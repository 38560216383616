import styled from 'styled-components';

export const CardGroupContainer = styled.div`  
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: left;
  border: none;
  border-radius: 10px;
  color: #1A1D1F;
  margin-bottom: 20px;
  background: #fff;
  padding: 20px;

  .cardGroupTitle {
    font-size: 1.5rem;
    font-weight: 200;
    margin-bottom: 1rem;
    display: flex;
    align-items: center;

    .tagIdentifier {
      border-radius: 4px;
      padding: 15px 6px;
      margin-right: 10px;
    }

    .containerActionButtonButton {
      flex: 2;
      display: flex;
      justify-content: flex-end;

      .actionButton {
        background: #fff;
        border: 2px solid #F4F4F4;
        // border-radius: 12px;
        border-radius: 50px;
        padding: 8px 16px 8px 16px;
        color: #6F767E;
        font-weight: 600;
        font-size: 14px;
      }

      .actionButton:hover {
        background: #F4F4F4;
      }

      .disableActionButton {
        color: #EFEFEF;
        cursor: not-allowed;
      }

      .disableActionButton:hover {
        background: #fff;
      }
    }
  }

  .cardGroupContent {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    overflow-x: auto;
    scroll-behavior: smooth;

    .noData {
      font-size: 15px;
      font-weight: 200;
    }

    /* width */
      ::-webkit-scrollbar {
        width: 0px;
        height: 0px;
      }

      /* Track */
      ::-webkit-scrollbar-track {
        box-shadow: #f1f1f1; 
        border-radius: 10px;
        width: 5px;
      }
      
      /* Handle */
      ::-webkit-scrollbar-thumb {
        background: #DEDEDE; 
        border-radius: 10px;
      }

      /* Handle on hover */
      ::-webkit-scrollbar-thumb:hover {
        background: #C8C8C8; 
      }
  }
`;

export const Card = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: left;
    color: #1A1D1F;
    padding: 15px 25px;
    border-radius: 10px;
    flex: 1;
    margin: 0 10px;

    .cardTitle {
      font-size: 14px;
      font-weight: normal;
      text-align: left;
      margin-bottom: 10px;
    }

    .cardInfo {
      display: flex;

      .cardInfoItem {
        .totalValue {
          font-size: 24px;
          font-weight: bold;
          text-align: left;
          margin-bottom: 10px;
          white-space: nowrap;
        }
  
        .comparativeGroup {
          display: flex;
          flex-direction: column;
          justify-content: left;
          width: 100%;
    
          .comparativeValue {
            padding: 5px 10px;
            background: #F4F4F4;
            font-size: 12px;
            font-weight: bold;
            border-radius: 5px;
            margin-bottom: 5px;
            white-space: nowrap;
    
            .arrowCardValue {
              margin-right: 5px;
            }
    
            .comparativeText {
              color: #6F767E;
              font-weight: bold;
              font-size: 12px;
              margin-left: 5px;
            }
          }
        }
      }
    }
`;

export const SecondaryCard = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: left;
    color: #1A1D1F;
    padding: 15px 25px;
    border-radius: 10px;
    flex: 1;
    margin: 0 10px;
    background: #F4F4F4;
    margin-top: 20px;
    min-width: 150px;

    .cardTitle {
      font-size: 14px;
      font-weight: normal;
      text-align: left;
      margin-bottom: 10px;
      display: flex;
      align-items: center;
      justify-content: left;

      .metricInfoIcon {
        margin-left: 5px;
        cursor: pointer;
      }
    }

    .totalValue {
      font-size: 18px;
      font-weight: bold;
      text-align: left;
      margin-bottom: 10px;
    }
`;