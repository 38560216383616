import api from './api';

import i18n from '../i18n';

export const getCompanyPreferences = async () => {
  const response = await api.get(`/preferences/company`);

  if (!response || response.status !== 200) {
    throw new Error(i18n.t('error.data_retrieve_failed'));
  }

  return {
    ...response.data,
    lang: response.data.locale
  };
};

export const updateOrCreateCompanyPreferences = async (timezone, currency, lang) => {
  const response = await api.put(`/preferences/company`, {
    timezone,
    currency,
    locale: lang
  });

  console.log(response);

  if (!response || response.status !== 204) {
    throw new Error(i18n.t(i18n.t("error.update_preferences_error")));
  }

  return
};
