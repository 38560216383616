import React from "react";

import { useTranslation } from 'react-i18next';
import "../../../i18n";

import CustomModal from "../CustomModal";
import { Container } from "./styles";
import IntelipostBiso from "../../../assets/banner_intelipost.png";

const CustomModalLogisticCallToAction = ({open, handleClose}) => {
    const { t } = useTranslation();

    return (
        <CustomModal
            title={t('intelipostCallToAction.intelipost_integration')}
            open={open}
            handleClose={handleClose}
            hiddenConfirmButton={true}
            cancelButtonLabel={t('common.close')}
        >
            <Container>
                <p>{t('intelipostCallToAction.thank_you_for_interest')}</p>
                <p>{t('intelipostCallToAction.more_info_contact_sales')}:</p>
                <p>victor.braga@biso.digital / +55 11 97700-5490 {t('intelipostCallToAction.or_request_via')} <a href="https://forms.monday.com/forms/ea0d51bd0ef7a45914beb82827a700ea?r=use1" target="_blank" rel="noopener noreferrer">Help</a></p>
                <img style={{height: "auto", width: "100%", marginTop: '10px'}} src={IntelipostBiso} alt="Intelipost + Biso" />
            </Container>
        </CustomModal>
    );
}

export default CustomModalLogisticCallToAction;