import styledComponents from 'styled-components';
import { lighten, darken } from 'polished';
import { 
  Switch, 
  Modal, 
  Menu,
  MenuItem,
  IconButton,
  styled
} from '@mui/material';

import { BsThreeDotsVertical } from 'react-icons/bs';


export const UsersMore = styledComponents(IconButton)``;

export const UsersMoreIcon = styledComponents(BsThreeDotsVertical)``;

export const UsersMenu = styledComponents(Menu)``;

export const UsersMenuItem = styledComponents(MenuItem)``;

export const Container = styledComponents.div`
  .header {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 10px;

    .group {
      display: flex;
      flex-direction: row;
      justify-content: right;
      align-items: center;
      width: 100%;
      margin-bottom: 16px;

      @media screen and (max-width: 784px) {        
        width: 100%;
        justify-content: center;
      }

      .add_users_list {
        margin-right: 15px;
      }
    }

    button {
      display: flex;
      align-items: center;
      color: #fff;
      transition: background-color 0.2s;
      border: 0;
      box-shadow: 0 2px 20px rgba(255, 0, 104, 0.3);
      background-color: #ff0068;
      font-size: 13px;
      font-weight: 500;
      letter-spacing: -0.21px;
      border-radius: 25px;  
      width: 158px;
      height: 35px;       
      padding: 0 15px;
      justify-content: space-between;

      &:hover {
        background-color: ${darken(0.05, '#ff0068')};
      }
    }
  }

  .workSpace {
    .noUsers {
      display: flex;
      align-items: center;
      justify-content: center;

      p {
        color: #90a4ae;
        font-size: 15px;
      }
    }
    .option {
      display: flex;
      flex-direction: row;
      align-items: center;
      padding: 18px 15px 18px 15px;
      justify-content: space-between;
      min-height: 76px;

      box-shadow: 0 0 20px rgba(215, 222, 227, 0.39);
      border-radius: 8px;
      background-color: #ffffff;

      &:not(:last-child) {
        margin-bottom: 20px;
      }

      .user {
        display: flex;
        flex-direction: row;
        align-items: center;
        width: calc(100% - 103px);
        overflow: hidden;

        .word {
          width: 33px;
          height: 33px;
          border-radius: 50%;
          background-color: #ff0068;
          box-shadow: 0 0 10px rgba(207, 217, 223, 0.5);
          color: #ffffff;
          font-size: 16px;
          font-weight: 700;
          text-transform: uppercase;
          display: flex;
          align-items: center;
          justify-content: center;
          line-height: 25px;
        }

        .descriptionOption {
          margin-left: 27px;
          width: calc(100% - 60px);

          .titleOption {            
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;            
            color: #242e42;            
            font-size: 16px;
            font-weight: 700;  
          }

          p {
            color: #90a4ae;
            font-size: 12px;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
          }
        }
      }

      .functions {
        display: flex;
        align-items: center;

        button {
          margin-left: 25px;
          border: 0;

          svg {
            color: #90a4ae;
            transition: color 0.2s;

            &:hover {
              color: ${lighten(0.5, '#90a4ae')};
            }
          }
        }
      }
    }
  }
`;

export const OnOff = styled(Switch)(({ theme }) => ({
  width: 36,
  height: 18,
  padding: 0,
  display: 'flex',
  overflow: 'initial',

  '& .MuiSwitch-switchBase': {
    padding: 0,
    color: '#D3D3D3',
    '&.Mui-checked': {
      transform: 'translateX(18px)',
      color: '#ff0068',
      boxShadow: '0 0 10px #ff0068',
      '& + .MuiSwitch-track': {
        opacity: 1,
        backgroundColor: '#fff',
        borderColor: '#D3D3D3',
      },
    },
  },

  '& .MuiSwitch-thumb': {
    width: 18,
    height: 18,
    boxShadow: 'none',
  },

  '& .MuiSwitch-track': {
    border: `1px solid #D3D3D3`,
    borderRadius: 25,
    opacity: 1,
    backgroundColor: '#fff',
  },
}));

export const NewRegister = styledComponents(Modal)`
  display: flex;
  justify-content: center;

  .modal {
    position: fixed;
    top: 10%;
    display: flex;
    flex-direction: column;
    width: 60vw;
    max-width: 750px;
    padding: 16px 24px;
    background-color: #f9f9f9;
    border-radius: 8px;

    @media screen and (max-width: 1200px) {
      top: 15px;
      left: 15px;
      width: calc(100vw - 30px);
      padding: 16px;
    }

    &__header {
      margin-bottom: 32px;
    }

    &__body {
      padding: 24px 16px;
      background: #fff;
      border-radius: 8px;
      box-shadow: 0px 1px 3px 0px rgba(142, 142, 142, 0.2),
        0px 1px 1px 0px rgba(243, 243, 243, 0.14),
        0px 2px 1px -1px rgba(204, 204, 204, 0.12);

      &__download__group {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        width: 50%;

        @media screen and (max-width: 1200px) {
          width: 100%;
          margin-top: 10px;
        }

        p {
          font-size: 13px;
          color: #90a4ae;
        }

        &__button_download {
          color: #fff;
          border: 0;
          box-shadow: 0 2px 20px rgba(255, 0, 104, 0.3);
          background-color: #ff0068;
          font-size: 13px;
          font-weight: 500;
          padding: 5px 10px;
          border-radius: 20px;
          margin-top: 5px;
          text-decoration: none;
          display: flex;

          svg {
            margin-left: 5px;
          }

          &:hover {
            background: ${darken(0.08, '#f90c68')};
          }
        }
      }
    }

    &__header-inner-wrapper {
      display: flex;
      justify-content: space-between;
    }

    &__footer {
      display: flex;
      justify-content: center;
      margin-top: 16px;
    }

    .titleModal {
      font-size: 20px;
      font-weight: 600;
      color: #455a64;
      margin-bottom: 16px;
    }

    .line {
      border-bottom: 4px solid #f90c68;
      width: 60px;
    }

    .group {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      margin-bottom: 8px;

      @media screen and (max-width: 1200px) {
        flex-direction: column;
        align-items: center;
      }

      .input {
        display: flex;
        align-items: flex-start;
        flex-direction: column;
        border: 1px solid #ccc;
        border-radius: 4px;
        padding: 5px 7px;
        width: 49%;

        @media screen and (max-width: 1200px) {
          width: 100%;
          margin-bottom: 8px;
        }

        p {
          color: #aaa;
          font-size: 12px;
          margin-bottom: 2px;
        }

        input {
          width: 100%;
          border: 0;
          color: #000;
          font-size: 15px;
          @media screen and (max-width: 1200px) {
            font-size: 16px;
          }
        }
      }
    }

    .inputNormal {
      display: flex;
      align-items: flex-start;
      flex-direction: column;
      border: 1px solid #ccc;
      border-radius: 4px;
      padding: 5px 7px;
      width: 100%;
      margin-bottom: 10px;

      &:last-child {
        margin-bottom: 0;
      }

      p {
        color: #aaa;
        font-size: 12px;
        margin-bottom: 2px;
      }

      input {
        width: 100%;
        border: 0;
        color: #000;
        font-size: 15px;
        @media screen and (max-width: 1200px) {
          font-size: 16px;
        }
      }
    }

    .btn {
      margin: 0 8px;
      font-size: 13px;
      font-weight: 500;
      border: 0;

      &--save {
        height: 35px;
        padding: 0 16px;
        color: #fff;
        line-height: 35px;
        background: #f90c68;
        border-radius: 20px;
        &:hover {
          background: ${darken(0.08, '#f90c68')};
        }
      }

      &--cancel {
        height: 35px;
        color: #455a64;
        line-height: 35px;
        text-decoration: underline;
        background: transparent;
      }
    }
  }
`;

export const CloseButton = styledComponents.button`
  appearance: none;
  border: none;
  background-color: transparent;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 30px;
  height: 30px;

  svg {
    pointer-events: none;
  }
`;
