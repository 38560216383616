import styled from 'styled-components';
import { darken } from 'polished';

export const Container = styled.div`
  .noInsights {
    display: flex;
    align-items: center;
    justify-content: center;

    p {
      color: #90a4ae;
      font-size: 15px;
    }
  }
  
  .pagination {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 20px;

    .loadMoreInsights {
      height: 40px;
      min-width: 158px;
      border: 0;
      -webkit-transition: all 0.2s;
      transition: all 0.2s;
      border-radius: 20px;
      background-color: #ff0068;
      font-size: 12px;
      font-weight: 700;
      border: 2px solid #ff0068;
      color: #ffffff;
      margin-left: 22px;
    }

    .loadMoreInsights:hover {
      background-color: ${darken(0.05, '#ff0068')};
      border-color: ${darken(0.05, '#ff0068')};
    }
  }
`;
