import { createGlobalStyle } from 'styled-components';
import 'react-toastify/dist/ReactToastify.css';

export default createGlobalStyle`
  * {
    margin: 0;
    padding: 0;
    outline: 0;
    box-sizing: border-box;
  }

  body {
    font-family: 'Roboto', sans-serif;
    font-size: 14px;
    font-weight: 400;
    background: #f0f0f5;
    -webkit-font-smoothing: antialiased;

    &.has--no-scroll {
      height: 100%;
      overflow: hidden;
    }
  }

  input, button, textarea {
    font-family: 'Roboto', sans-serif;
    font-size: 18px;
  }

  button {
    cursor: pointer;
  }
`;
