import React, { useState, useEffect } from 'react';

import { useTranslation } from 'react-i18next';
import '../../i18n';

import BackgroundDashboard from '../../components/BackgroundDashboard';
import * as S from './styles';
import { PowerBIEmbed } from 'powerbi-client-react'
import { models } from 'powerbi-client';
import './styles.css';
import { getMicrosoftToken } from '../../services/authService'
import Loader from '../../components/Loader';

const FIFTY_MINUTES_MILLISECONDS = 10800000;

function Dashboard() {
    const { t } = useTranslation();

    const [dashboardUrls, setDashboardUrls] = useState([]);
    const [dashboardsConfig, setDashboardsConfig] = useState([]);
    const [powerBiTokens, setPowerBiTokens] = useState([]);
    const [availablePagesReport, setAvailablePagesReport] = useState([]);
    const [isLoading, setIsLoading] = useState(true);

    window.addEventListener('resize', async ()=>{
        if(window.report) {
            updateSettingsPowerBi()
        }
    });

    const getUserDashboards = () => {
        const { dashboards } = JSON.parse(localStorage.getItem('user'));

        return dashboards;
    }

    const getDashboardUrls = () => {
        const { dashboard } = JSON.parse(localStorage.getItem('user'));

        if(dashboard) {
            const dashboardUrls = dashboard.split(';');
            return dashboardUrls;
        }

        return null
    };

    const getDashboardWorkspaceId = () => {
        const { dashboard_group } = JSON.parse(localStorage.getItem('user'));

        return dashboard_group;
    }

    const updateSettingsPowerBi = _ => {
        let isMobile = activeMobileMode()
            
        if(isMobile){
            let newSettings = {
                layoutType: models.LayoutType.MobilePortrait
            };
            window.report.updateSettings(newSettings);
        }else{
            let newSettings = {
                layoutType: models.LayoutType.MobileLandscape
            }; 
            window.report.updateSettings(newSettings);
        }
    }

    useEffect(() => {
        const handleSignOut = () => {
            localStorage.removeItem('token');
            localStorage.removeItem('user');
            window.location.href = "/";
        };

        const userLocalStorage = localStorage.getItem('user')

        if (!userLocalStorage) {
            handleSignOut()
            return null;
        }
    })

    useEffect(() => {
        const getDashbordsConfig = async () => {
            const dashboards = getUserDashboards();

            if (dashboards) {
                const tokens = await getMicrosoftTokensV2(dashboards);
                setPowerBiTokens(tokens);

                setDashboardsConfig(dashboards);
            } else {
                const dashboardUrls = getDashboardUrls()
        
                const workspaceId = getDashboardWorkspaceId();
                
                if(dashboardUrls && workspaceId) {
                    const tokens = await getMicrosoftTokens(dashboardUrls, workspaceId);
                    
                    setPowerBiTokens(tokens);
        
                    setDashboardUrls(dashboardUrls);
                }
            }
    
            setIsLoading(false)
        }

        const getMicrosoftTokensV2 = async (dashboards) => {
            const tokens = [];
            
            for(let dashboard of dashboards) {
                if(!dashboard.is_biso_account) {
                    tokens.push({token: "", reportId: ""})
                    continue
                }
                
                const reportId = getReportIdFromUrl(dashboard.url);

                const token = await getMicrosoftToken(reportId, dashboard.workspace_id);
                
                tokens.push({token: token, reportId: reportId});
            }

            return tokens
        }

        const getMicrosoftTokens = async (dashboardsUrls, workspaceId) => {
            const tokens = [];
            
            for(let dashboardUrl of dashboardsUrls) {
                if(dashboardUrl.includes("autoAuth")) {
                    tokens.push({token: "", reportId: ""})
                    continue
                }
                
                const reportId = getReportIdFromUrl(dashboardUrl);
                const token = await getMicrosoftToken(reportId, workspaceId);
                
                tokens.push({token: token, reportId: reportId});
            }

            return tokens
        }

        const start = async () => {
            await getDashbordsConfig();

            setInterval(async () => {
                await getDashbordsConfig()
            }, FIFTY_MINUTES_MILLISECONDS)
        }

        start()
    }, [])

    const getPowerBIEmbedV2 = (dashboard, powerBiTokens, layoutType) => {
        const reportId = getReportIdFromUrl(dashboard.url)
        
        if (!dashboard.is_biso_account)
            return <><iframe data-hj-allow-iframe src={dashboard.url} title={dashboard.title} className="report-style-class"></iframe></>
        
        const token = powerBiTokens.find(token => token.reportId === reportId).token;

        return <>
            <PowerBIEmbed
                embedConfig = {{
                    type: 'report',   // Supported types: report, dashboard, tile, visual and qna
                    id: reportId != null ? reportId : 'dashboard',
                    accessToken: token != null ? token : '',
                    tokenType: models.TokenType.Embed,
                    embedUrl: dashboard.url,
                    settings: {
                        layoutType: layoutType,
                        navContentPaneEnabled: true,
                        panes: {
                            filters: {
                                visible: false
                            },
                            pageNavigation: {
                                visible: true
                            }
                        }
                    }
                }}
                cssClassName = {
                    "report-style-class"
                }
                getEmbeddedComponent = {
                    (embeddedReport) => {
                        embeddedReport.iframe.setAttribute('data-hj-allow-iframe', true);

                        window.report = embeddedReport
                    }
                }
            />
        </>
    }

    const getPowerBIEmbed = (reportUrl, powerBiTokens, layoutType, availablePagesReport) => {
        const reportId = getReportIdFromUrl(reportUrl)
        
        if (reportUrl.includes("autoAuth"))
            return <><iframe src={reportUrl} title="Dashboard" className="report-style-class"></iframe></>
        
        const token = powerBiTokens.find(token => token.reportId === reportId).token;

        return <>
            <PowerBIEmbed
                embedConfig = {{
                    type: 'report',   // Supported types: report, dashboard, tile, visual and qna
                    id: reportId != null ? reportId : 'dashboard',
                    accessToken: token != null ? token : '',
                    tokenType: models.TokenType.Embed,
                    embedUrl: reportUrl,
                    settings: {
                        layoutType: layoutType,
                        navContentPaneEnabled: true,
                        panes: {
                            filters: {
                                visible: false
                            },
                            pageNavigation: {
                                visible: true
                            }
                        }
                    }
                }}
                cssClassName = {
                    "report-style-class"
                }
                getEmbeddedComponent = {
                    (embeddedReport) => {
                        window.report = embeddedReport;
                    }
                }
                eventHandlers = { 
                    new Map([
                        ['loaded', async function () { 
                            await updateReportPages()
                            updateSettingsPowerBi()
                        }],
                    ])
                }
            />
        {createReportMenuPagination(availablePagesReport)}
        </>
    }

    const updateReportPages = async () => {
        if(window.report) {
            let pages = await window.report.getPages();

            let availablePages = pages.filter(page => page.visibility === 0)
            
            setAvailablePagesReport(availablePages);

            console.log(availablePagesReport)
        }
    }

    const createReportMenuPagination = (pages) => {
        if(!pages || pages.length === 0) return null;

        return <>
            <br></br>
            <label for="dashboard-pages" className='dashboard-pages-title'>Páginas:</label>
            <select name="dashboard-pages" id="dashboard-pages" onChange={event => handleChangePage(event.target.value)}>
                {pages.map(page => <option value={page.name}>{page.displayName}</option>)}
            </select>
        </>
    }

    const getReportIdFromUrl = (url) => {
        if(url.includes('reportId=')) {
            const urlParts = url.split('reportId=');
            return urlParts[urlParts.length - 1];
        }

        return null 
    }

    const activeMobileMode = () => window.matchMedia("only screen and (max-width: 760px)").matches;

    const handleChangePage = async (pageName) => {
        if(window.report) {
            await window.report.setPage(pageName);
            await updateReportPages();
        }
    }

    const getDashboards = (dashboards, dashboardUrls, powerBiTokens) => {
        if(dashboards.length !== 0){
            let response = dashboards.map(dashboard => {
                let content = getPowerBIEmbedV2(dashboard, powerBiTokens, models.LayoutType.Master)
                return {
                    title: dashboard.title,
                    content: content
                }
            })
    
            return response
        } else {
            if (dashboardUrls.length === 0 || powerBiTokens.length !== dashboardUrls.length) {
                let response = [{ 
                    title: 'Dashboard',
                    content: <S.Text>Você não possui o dashboard instalado na sua conta, <S.Link href="mailto:contato@biso.digital" target="_blank" rel="noopener noreferrer">{t('common.click_here')}</S.Link> para solicitar.</S.Text>
                }]

                return response
            }

            let response = dashboardUrls.map((url, index) => {
                let title = index > 0 ? `Dashboard Personalizado ${index}` : 'Dashboard'
                let content = getPowerBIEmbed(url, powerBiTokens, models.LayoutType.Master)
                return {
                    title: title,
                    content: content
                }
            })

            return response
        }
    }

    return (
        <BackgroundDashboard
            loader={Loader}
            isLoading={isLoading}
            dashboards={getDashboards(dashboardsConfig, dashboardUrls, powerBiTokens)}
        />
    )
}

export default Dashboard;