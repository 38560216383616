import React from 'react';

import { useTranslation } from 'react-i18next';
import '../../../i18n';

import * as S from './styled';

function CustomerGoalsModalInformation(props) {
  const { t } = useTranslation();

  return (
    <S.CustomerGoalsModalInformation open={props.open}>
      <div className="modal">
        <div className="modal__header">
          <p className="modal__title">{t('mediaPlanner.understand_media_planner')}</p>
          <div className="line" />
        </div>

        <div className="modal__body">
          <div className="modal__body__text">
            <p>
              {t('mediaPlanner.media_plan_click_away')}
            </p>
            <p>
              {t('mediaPlanner.annual_planning_tool')}
            </p>
            <p>
              {t('mediaPlanner.tool_projects_metrics')}
            </p>
            <p>
              {t('mediaPlanner.first_step_add_revenue')}
            </p>
            <p>
              {t('mediaPlanner.shall_we')}
            </p>
            <ul>
              <li>
                1. {t('mediaPlanner.select_period')}
              </li>
              <li>
                2. {t('mediaPlanner.create_planning_button')}
              </li>
            </ul>
            <p>
              {t('mediaPlanner.manual_goal_entry')}
            </p>
          </div>
          <div className="video" style={{marginBottom: "15px"}}>
            <iframe width="100%" height="315" src="https://www.youtube.com/embed/4Gd41YPObGg?si=bcfZibYIHhnews3f" title="YouTube video player" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture;" allowFullScreen></iframe>
          </div>
        </div>

        <div className="modal__footer">
          <button
            className="modal__form-btn is--cancel"
            type="button"
            onClick={props.handleClose}
          >
            {t('common.close')}
          </button>
        </div>
      </div>
    </S.CustomerGoalsModalInformation>
  );
}

export default CustomerGoalsModalInformation;
