import { darken } from 'polished';
import styled from 'styled-components';

export const ButtonCustomized = styled.button`
    display: flex;
    align-items: center;
    color: #fff;
    transition: background-color 0.2s;
    border: 0;
    box-shadow: 0 2px 20px rgba(255, 0, 104, 0.3);
    background-color: #ff0068;
    font-size: 13px;
    font-weight: 500;
    letter-spacing: -0.21px;
    border-radius: 20px;
    height: 35px;
    line-height: 35px;       
    padding: 0 15px;
    justify-content: center;
    cursor: pointer;
    margin-left: 10px;
    margin-bottom: 20px;

    &:hover {
      background-color: ${darken(0.05, '#ff0068')};
      border-color: ${darken(0.05, '#ff0068')};
      color: #fff;
    }

    &:disabled {
      background-color: #999;
      border-color: #999;
      color: #fff;
      cursor: not-allowed;
      box-shadow: none;

      &:hover {
          background-color: #999;
          border-color: #999;
          color: #fff;
      }
    }
`;