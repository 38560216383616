import { toast } from 'react-toastify';
import html2pdf from 'html2pdf.js';

import i18n from '../i18n';

import pdfCover from '../assets/biso_cover.jpg';
import pdfOutcome from '../assets/biso_insights_outcome.jpg';

export const exportToPdf = (componentRef, alignContentCenterPage = true) => {
  try {
    const element = componentRef.current.getContentRef();
    const pageTitle = componentRef.current.getTitle();

    const elementClone = element.cloneNode(true);

    const elementsToHide = elementClone.querySelectorAll('.ignorePdf');
    elementsToHide.forEach(element => {
      element.style.visibility = 'hidden';
    });
    const MIN_PAGE_WIDTH = 903;
    const pageWidth = element.offsetWidth > MIN_PAGE_WIDTH ? element.offsetWidth : MIN_PAGE_WIDTH;

    elementClone.style.width = `${pageWidth}px`;
    
    if (alignContentCenterPage) {
      elementClone.style.alignItems = 'center';
    }

    const options = {
      filename: `${pageTitle}.pdf`,
      image: { type: 'jpeg' },
      html2canvas: { scale: 2, width: pageWidth},
      jsPDF: { unit: 'in', format: 'letter', orientation: 'landscape' }
    };

    html2pdf()
      .set(options)
      .from(elementClone)
      .toPdf()
      .get('pdf')
      .then(pdf => {
        pdf.insertPage(1);
        pdf.setPage(1);
        pdf.addImage(pdfCover, 'JPEG', 0, 0, 11, 8.5);

        pdf.addPage();
        pdf.addImage(pdfOutcome, 'JPEG', 0, 0, 11, 8.5);

        pdf.save(options.filename);

        elementClone.remove();

        toast.success(i18n.t('toast.export_pdf_success'));
      });
  } catch (error) {
    toast.error(i18n.t('toast.export_pdf_error'));
  }
};

export default exportToPdf;