import React, { useEffect, useCallback, useState, useContext } from "react";
import { useNavigate } from "react-router-dom";

import { toast } from "react-toastify";

import { useTranslation } from 'react-i18next';
import "../../i18n";

import CardGroup from "./CardGroup";
import BackgroundNew from "../../components/BackgroundNew";
import FilterContainer from "../../components/FilterContainer";
import LTVModalExplanation from "../../components/CustomModal/ModalLTVExplanation";

import { GOOGLE_ANALYTICS_PREDICTION_CHANNELS_OPTIONS } from "../../options/filterOptions";

import {
  getSelectedGoalsOverviewAndEvolutionInitialDateRange,
  setCurrentGoalsOverviewAndEvolutionDateRange,
  formatDate
} from "../../utils/dateUtils";

import {
  getControlPainelMetrics,
  getEcommerceControlPainelMetrics,
  getGoogleAnalyticsDailyMetricsWithPredictions,
  getEcommerceDailyMetricsWithPredictions,
} from "../../services/dashboardApiService";

import { AuthContext } from '../../contexts';

import { routes } from "../../routes";

const GoalsOverview = () => {
  const { t } = useTranslation();
  const { timezone, currency } = useContext(AuthContext);

  const navigate = useNavigate();

  const [data, setData] = useState(null);
  const [dateRange, setDateRange] = useState();
  const [isLoading, setIsLoading] = useState(true);
  const [showLTVModalExplanation, setShowLTVModalExplanation] = useState(false);

  const [allChannelsPredictions, setAllChannelsPredictions] = useState(null);
  const [mediaChannelPredictions, setMediaChannelPredictions] = useState(null);
  const [ecommercePredictions, setEcommercePredictions] = useState(null);
  const [crmChannelPredictions, setCrmChannelPredictions] = useState(null);
  const [seoChannelPredictions, setSeoChannelPredictions] = useState(null);
  const [directChannelPredictions, setDirectChannelPredictions] = useState(null);

  const handleApplyFilter = async () => {
    const [startDate, endDate] = dateRange;
    await getControlPainelData(formatDate(startDate), formatDate(endDate));
  };

  const getControlPainelData = useCallback(async (initialDate, finalDate) => {
    setIsLoading(true);

    try {
      const responses = await Promise.all([
        getControlPainelMetrics(initialDate, finalDate, timezone),
        getEcommerceControlPainelMetrics(initialDate, finalDate, timezone),
        getGoogleAnalyticsDailyMetricsWithPredictions({ initialDate, finalDate }),
        getGoogleAnalyticsDailyMetricsWithPredictions({
          initialDate,
          finalDate,
          channel: GOOGLE_ANALYTICS_PREDICTION_CHANNELS_OPTIONS.MEDIA,
        }),
        getGoogleAnalyticsDailyMetricsWithPredictions({
          initialDate,
          finalDate,
          channel: GOOGLE_ANALYTICS_PREDICTION_CHANNELS_OPTIONS.CRM,
        }),
        getGoogleAnalyticsDailyMetricsWithPredictions({
          initialDate,
          finalDate,
          channel: GOOGLE_ANALYTICS_PREDICTION_CHANNELS_OPTIONS.SEO,
        }),
        getGoogleAnalyticsDailyMetricsWithPredictions({
          initialDate,
          finalDate,
          channel: GOOGLE_ANALYTICS_PREDICTION_CHANNELS_OPTIONS.DIRECT,
        }),
        getEcommerceDailyMetricsWithPredictions({ initialDate, finalDate, timezone }),
      ]);

      const responseControlPainelMetrics = responses[0];
      const responseEcommerceControlPainelMetrics = responses[1];

      if (responseControlPainelMetrics) {
        if (responseEcommerceControlPainelMetrics) {
          responseControlPainelMetrics.ecommerce_earning = responseEcommerceControlPainelMetrics;
        }
      }

      setData(responseControlPainelMetrics);

      const responseGoogleAnalyticsDailyMetrics = responses[2];
      const responseMediaChannelPredictions = responses[3];
      const responseCrmChannelPredictions = responses[4];
      const responseSeoChannelPredictions = responses[5];
      const responseDirectChannelPredictions = responses[6];
      const responseEcommercePredictions = responses[7];

      setAllChannelsPredictions(responseGoogleAnalyticsDailyMetrics || []);
      setMediaChannelPredictions(responseMediaChannelPredictions || []);
      setCrmChannelPredictions(responseCrmChannelPredictions || []);
      setSeoChannelPredictions(responseSeoChannelPredictions || []);
      setDirectChannelPredictions(responseDirectChannelPredictions || []);
      setEcommercePredictions(responseEcommercePredictions || []);
    } catch (error) {
      toast.error(error.message);
      setData(null);
    } finally {
      setIsLoading(false);
    }
  }, [timezone]);

  const getEcommerceSecondaryCardsData = (data) => {
    return {
      keys: ["investment", "cac", "ltv"],
      titles: {
        investment: t('common.investment'),
        cac: t('common.cac'),
        ltv: t('common.ltv'),
      },
      types: {
        investment: "currency",
        cac: "currency",
        ltv: "currency",
      },
      metricInfoConfig: {
        investment: {
          visible: false,
          onClick: null,
        },
        cac: {
          visible: false,
          onClick: null,
        },
        ltv: {
          visible: true,
          onClick: () => setShowLTVModalExplanation(true),
        },
      },
      data: data,
    };
  };

  const onRenderComponent = useCallback(async () => {
    const { initialDateFilter, finalDateFilter } = getSelectedGoalsOverviewAndEvolutionInitialDateRange();
    setDateRange([initialDateFilter, finalDateFilter]);

    await getControlPainelData(
      formatDate(initialDateFilter),
      formatDate(finalDateFilter)
    );
  }, [getControlPainelData]);

  useEffect(() => {
    onRenderComponent();
  }, [onRenderComponent]);

  return (
    <BackgroundNew
      titlePage={t('menu.goals_overview')}
      showButtonAction={true}
      buttonActionOnClick={(_) => navigate(routes.mediaPlanner)}
      buttonActionTitle={t('goalsOverview.register_goal')}
    >
      <FilterContainer
        selectedDateRange={dateRange}
        setDateRange={setDateRange}
        onClickFilter={handleApplyFilter}
        limitDateRangeUntilToday={false}
        setCurrentDateRangeCustomized={setCurrentGoalsOverviewAndEvolutionDateRange}
      />
      <CardGroup
        principalCardsData={data ? data.ecommerce_earning : null}
        title={t('goalsOverview.ecommerce_platform')}
        isLoading={isLoading}
        tagIdentifierColor="#f90c68"
        graphData={ecommercePredictions}
        currency={currency}
      />
      <CardGroup
        principalCardsData={data ? data.ecommerce : null}
        secondaryCardsData={data ? getEcommerceSecondaryCardsData(data.ecommerce) : null}
        title={t('goalsOverview.ecommerce_google_analytics')}
        tagIdentifierColor="#f90c68"
        isLoading={isLoading}
        graphData={allChannelsPredictions}
        currency={currency}
      />
      <CardGroup
        principalCardsData={data ? data.media : null}
        title={t('goalsOverview.media_google_analytics')}
        isLoading={isLoading}
        showActionButton={true}
        handleClickActionButton={(_) => navigate(routes.paidMediaOverview)}
        actionButtonTitle={t('goalsOverview.see_more')}
        graphData={mediaChannelPredictions}
        currency={currency}
      />
      <CardGroup
        principalCardsData={data ? data.crm : null}
        title={t('goalsOverview.crm_google_analytics')}
        isLoading={isLoading}
        showActionButton={true}
        handleClickActionButton={(_) => navigate(routes.audienceOverview)}
        actionButtonTitle={t('goalsOverview.see_more')}
        graphData={crmChannelPredictions}
        currency={currency}
      />
      <CardGroup
        principalCardsData={data ? data.seo : null}
        title={t('goalsOverview.seo_google_analytics')}
        isLoading={isLoading}
        showActionButton={true}
        disableActionButton={true}
        actionButtonTitle={t('goalsOverview.coming_soon')}
        graphData={seoChannelPredictions}
        currency={currency}
      />
      <CardGroup
        principalCardsData={data ? data.direct : null}
        title={t('goalsOverview.direct_google_analytics')}
        isLoading={isLoading}
        showActionButton={true}
        disableActionButton={true}
        actionButtonTitle={t('goalsOverview.coming_soon')}
        graphData={directChannelPredictions}
        currency={currency}
      />
      <LTVModalExplanation
        open={showLTVModalExplanation}
        handleClose={() => setShowLTVModalExplanation(false)}
        ltvDetailCalculation={data ? data.ecommerce.detail_calculation_indicators.ltv : null}
      />
    </BackgroundNew>
  );
};

export default GoalsOverview;
