import styled from 'styled-components';

export const CustomTooltipContainer = styled.div`
  background: white;
  padding: 10px;
  border-radius: 10px;
  width: 100%;
  max-width: 250px;
  text-align: justify;
  border: 0.5px solid #e5e5e5;

  .label {
    font-size: 13px;
    font-weight: bold;
    margin-bottom: 5px;
  }

  .item {
    word-wrap: break-word;
    font-size: 13px;
  }
`;
