import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  border-radius: 16px;
  background-color: #ffffff;
  padding: 11px 20px 20px 20px;
  margin-bottom: 20px;
`;

export const IndicatorLabel = styled.label`
  font-size: 10px;
  font-weight: 300;
  line-height: 24px;
  letter-spacing: -1%;
  color: #000000;
`;

export const IndicatorValue = styled.p`
  text-align: left;
  display: flex;
  justify-content: flex-start;
  flex-direction: column;
  font-size: 14px;
  font-weight: 700;
  line-height: 24px;
  letter-spacing: -1%;
  color: #000000;
  word-wrap: break-word;
`;

export const BigNumbersContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-bottom: 20px;
  margin-top: 10px;
  width: 100%;
  background-color: #FAFAFA;
  border-radius: 8px;
  padding: 12px 16px 12px 16px;

  @media screen and (max-width: 550px) {
    flex-direction: column;
  }
`;

export const BigNumbersIndicatorsContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  overflow: auto;
  width: 100%;
  margin-left: 20px;
  margin-right: 20px;

  /* width */
  ::-webkit-scrollbar {
    width: 20px;
    height: 6px;
  }

  /* Track */
  ::-webkit-scrollbar-track {
    box-shadow: #f1f1f1; 
    border-radius: 10px;
    width: 5px;
  }

  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: #DEDEDE; 
    border-radius: 10px;
  }

  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
    background: #C8C8C8; 
  }

  @media screen and (max-width: 550px) {
    margin-left: 0px;
    margin-right: 0px;
    margin-bottom: 20px;
  }
`;

export const BigNumbersIndicator = styled.div`
  padding: 10px;
  min-width: 120px;
`;

export const BigNumbersIndicatorLabel = styled(IndicatorLabel)`
  font-size: 12px;
`;

export const BigNumbersIndicatorValue = styled(IndicatorValue)`
  font-size: 16px;
`;

export const BigNumbersTitleContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 10px;
`;

export const BigNumbersTitleMonth = styled.p`
  font-size: 20px;
  font-weight: 700;
  line-height: 24px;
  letter-spacing: -1%;
  color: #FF0068;
`;

export const BigNumbersTitleYear = styled.p`
  font-size: 16px;
  font-weight: 300;
  line-height: 24px;
  letter-spacing: -1%;
  color: #1A1D1F;
`;

export const BigNumbersActionsContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  min-width: 105px;

  @media screen and (max-width: 550px) {
    justify-content: center;
    width: 100%;
  }
`;

export const GoalsChannelsListContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  width: 100%;
  padding: 10px;
  padding-bottom: 0px;
  overflow: auto;

  /* width */
  ::-webkit-scrollbar {
    width: 20px;
    height: 6px;
  }

  /* Track */
  ::-webkit-scrollbar-track {
    box-shadow: #f1f1f1; 
    border-radius: 10px;
    width: 5px;
  }

  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: #DEDEDE; 
    border-radius: 10px;
  }

  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
    background: #C8C8C8; 
  }
`;

export const GoalsChannelContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  min-width: 130px;
`;

export const GoalsChannelTitle = styled.p`
  font-size: 14px;
  font-weight: 700;
  line-height: 24px;
  letter-spacing: -1%;
  color: #1A1D1F;
  margin-bottom: 10px;
  padding-bottom: 10px;
  border-bottom: 1px solid #FF0068;
`;

export const GoalsChannelContent = styled.div`
  display: flex;
  flex-direction: column;
  padding: 10px;
`;