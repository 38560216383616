import styled from 'styled-components';

export const LaoderContainer = styled.div`
  @keyframes rotating {
    from {    
      transform: rotate(0deg);
    }
    to {    
      transform: rotate(360deg);
    }
  }
  
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 170px;

  svg {
    animation: rotating .5s linear infinite;
  }
`;