import React, { useState } from 'react';

import { useTranslation } from 'react-i18next';
import '../../../i18n';

import { Table, TableContainer, TableHead, TableRow, TableCell, TableBody, Paper } from '@mui/material';
import { Container } from './styles';
import GraphController from '../../../components/GraphController';

import { IoIosArrowDown } from "react-icons/io";
import { MdSubdirectoryArrowRight } from "react-icons/md";

const MostCommunDeliveryTimeTable = ({ 
  data,
  fullScreenEnabled = false,
  setFullScreenEnabled,
  onClickShowQuarters
}) => {
  const { t } = useTranslation();

  const [selectedRow, setSelectedRow] = useState(null);
  const [expandedRows, setExpandedRows] = useState([]);

  const stylesComponents = {
    mainContainerFullScreen: {
        position: 'fixed',
        top: 0,
        left: 0,
        width: '100%',
        height: '100vh',
        backgroundColor: 'white',
        zIndex: 1200
    },
    tableGroupFullScreen: {
        padding: '20px'
    },
    tableContainerFullScreen: {
        maxHeight: 'calc(100vh - 200px)',
    },
    tableFooter: {
        width: '100%',
        height: '30px',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        marginTop: '20px'
    }
  }

  const handleRowClick = (row) => {
    if (expandedRows.includes(row)) {
      let tempCopyExpandedRows = [...expandedRows]
      tempCopyExpandedRows.splice(tempCopyExpandedRows.indexOf(row), 1)

      setExpandedRows(tempCopyExpandedRows);
    } else {
      setExpandedRows([...expandedRows, row]);
    }

    setSelectedRow(row);
  };

  const renderStates = (shippingCompany, states) => {
    if (!states || states.length === 0) {
      return null;
    }

    return (
      <>
        {states.map((row, index) => (
          <React.Fragment key={index}>
            <TableRow onClick={() => handleRowClick(row)} style={{ cursor: 'pointer', backgroundColor: selectedRow === row ? '#F4F4F4' : '' }}>
              <TableCell style={{ paddingLeft: `${1 * 20}px`, display: "flex", alignItems: "center" }}>
                <MdSubdirectoryArrowRight size={15}/> {row.state} {row.delivery_time_by_cities.length > 0 ? <IoIosArrowDown style={{marginLeft: "5px"}} size={15}/> : ""}
              </TableCell>
              <TableCell>{row.delivery_time}</TableCell>
              <TableCell></TableCell>
            </TableRow>
            {expandedRows.includes(row) && renderCities(shippingCompany, row.state, row.delivery_time_by_cities)}
          </React.Fragment>
        ))}
      </>
    );
  };

  const renderCities = (shippingCompany, state, cities) => {
    if (!cities || cities.length === 0) {
      return null;
    }

    return cities.map((row, index) => (
      <TableRow key={index}>
        <TableCell style={{ paddingLeft: `${2 * 20}px`, display: "flex", alignItems: "center" }}>
          <MdSubdirectoryArrowRight size={15}/> {row.city}
        </TableCell>
        <TableCell>{row.delivery_time}</TableCell>
        <TableCell>
          <span 
            className='buttonShowQuarters'
            onClick={() => onClickShowQuarters(shippingCompany, state, row.city)}
          >
            {t('logisticIntelipostMostCommunDeliveryTime.see_neighborhoods')}
          </span>
        </TableCell>
      </TableRow>
    ));
  };

  return (
    <Container style={fullScreenEnabled ? { ...stylesComponents.mainContainerFullScreen } : {}}>
      <div className="tableGroup" style={fullScreenEnabled ? { ...stylesComponents.tableGroupFullScreen } : {}}>
        <GraphController
          showDimensionController={false}
          showFullScreenOption={true}
          fullScreenEnabled={fullScreenEnabled}
          setFullScreenEnabled={setFullScreenEnabled}
          style={{ marginRight: '0px' }}
        />
        <TableContainer component={Paper} className="tableContainer" style={fullScreenEnabled ? { ...stylesComponents.tableContainerFullScreen } : {}}>
          <Table className="table">
            <TableHead className="stickyHeader">
              <TableRow>
                <TableCell style={{ minWidth: 300, fontWeight: 'bold' }}>{t('logisticIntelipostMostCommunDeliveryTime.shipping_company')}</TableCell>
                <TableCell style={{ minWidth: 200, fontWeight: 'bold' }}>{t('logisticIntelipostMostCommunDeliveryTime.delivery_time')}</TableCell>
                <TableCell style={{ minWidth: 100, fontWeight: 'bold' }}>{t('logisticIntelipostMostCommunDeliveryTime.actions')}</TableCell>
              </TableRow>
            </TableHead>
            <TableBody className="tableBody">
              {data.map((row, index) => (
                <React.Fragment key={index}>
                  <TableRow onClick={() => handleRowClick(row)} style={{ cursor: 'pointer', backgroundColor: selectedRow === row ? '#F4F4F4' : '' }}>
                    <TableCell className='firstTableCell'>{row.shipping_company} {row.delivery_time_by_states.length > 0 ? <IoIosArrowDown style={{marginLeft: "5px"}} size={15}/> : ""}</TableCell>
                    <TableCell>{row.delivery_time}</TableCell>
                    <TableCell></TableCell>
                  </TableRow>
                  {expandedRows.includes(row) && renderStates(row.shipping_company, row.delivery_time_by_states)}
                </React.Fragment>
              ))}
              {(!data || data.length === 0) && (
                  <TableRow key={0}>
                      <TableCell colSpan={10} style={{ textAlign: 'center' }}>{t('common.no_data')}</TableCell>
                  </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
      </div>
    </Container>
  );
};

export default MostCommunDeliveryTimeTable;
