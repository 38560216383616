import styledComponents from 'styled-components';
import { darken } from 'polished';
import { Modal, Switch, Backdrop, styled } from '@mui/material';
import Select from 'react-select';

export const Container = styledComponents.div`
  .header {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: right;
    margin-bottom: 16px;

    @media screen and (max-width: 784px) {        
      width: 100%;
      justify-content: center;
    }

    button {
      display: flex;
      align-items: center;
      color: #fff;
      transition: background-color 0.2s;
      border: none;
      box-shadow: 0 2px 20px rgba(255, 0, 104, 0.3);
      background-color: #ff0068;
      font-size: 13px;
      font-weight: 500;
      letter-spacing: -0.21px;
      border-radius: 25px;
      width: 158px;
      height: 35px; 
      padding: 0 15px;
      justify-content: space-between;   

      &:hover {
        background-color: ${darken(0.05, '#ff0068')};
      }
    }
  }

  .workspace {
    .noAlertsFound {
      display: flex;
      align-items: center;
      justify-content: center;

      p {
        color: #90a4ae;
        font-size: 15px;
      }
    }

    ul {
      display: grid;
      grid-template-columns: repeat(3, 1fr);
      grid-gap: 32px;
      list-style: none;
      grid-auto-rows: 1fr;

      @media screen and (min-width: 1366px) {
        grid-template-columns: repeat(4, 1fr);
      }

      @media screen and (max-width: 1200px) {
        grid-gap: 12px;
      }

      @media screen and (max-width: 768px) {
        display: block;
      }

      li {
        .card {
          width: 100%;
          padding: 16px;
          box-shadow: 0 0 20px rgba(215, 222, 227, .4);
          border-radius: 8px;
          background-color: #ffffff;

          &.is--highlight {
            box-shadow: 0 0 10px rgba(255, 0, 104, .4);
          }
          
          @media screen and (max-width: 768px) {
            margin-bottom: 24px;
          }

          &__header {
            display: flex;
            align-items: center;
            justify-content: space-between;
            margin-bottom: 16px;
          }

          &__footer {
            display: flex;
            align-items: flex-end;
            justify-content: space-between;
            color: #000;
          }

          &__title {
            margin-bottom: 16px;
            font-size: 22px;
            font-weight: 700;
          }

          &__description {
            font-size: 14px;
            font-weight: 300;
          }

          &__result {
            &-value {
              display: block;
              font-size: 18px;
              font-weight: 700;
            }
            &-text {
              color: #818181;
            }
            &.is--current {
              display: flex;
              align-items: center;
              font-weight: 700;
              opacity: 0.35;

              svg {
                margin-left: 5px;
              }
            }
          }

          .alerts {
            width: 100%;

            > button {
              width: 100%;
              margin-bottom: 16px;
              color: #242E42;
              text-align: left;
              background: transparent;
              border: 0;

              /* & + .high {
                margin: 15px 0;
              } */
            }
          }
        }
      }
    }
  }
`;

export const OnOff = styled(Switch)(({ theme }) => ({
  width: 36,
  height: 18,
  padding: 0,
  display: 'flex',
  overflow: 'initial',

  '& .MuiSwitch-switchBase': {
    padding: 0,
    color: '#D3D3D3',
    '&.Mui-checked': {
      transform: 'translateX(18px)',
      color: '#ff0068',
      boxShadow: '0 0 10px #ff0068',
      '& + .MuiSwitch-track': {
        opacity: 1,
        backgroundColor: '#fff',
        borderColor: '#D3D3D3',
      },
    },
  },

  '& .MuiSwitch-thumb': {
    width: 18,
    height: 18,
    boxShadow: 'none',
  },

  '& .MuiSwitch-track': {
    border: `1px solid #D3D3D3`,
    borderRadius: 25,
    opacity: 1,
    backgroundColor: '#fff',
  },
}));

export const AlertModal = styledComponents(Modal)`
  display: flex;
  justify-content: center;

  .modal {
    position: fixed;
    top: 10%;
    bottom: 10%;
    display: flex;
    flex-direction: column;
    width: 60vw;
    max-width: 750px;
    padding: 16px 24px;
    background-color: #f9f9f9;
    border-radius: 8px;

    @media screen and (max-width: 1200px) {
      top: 15px;
      bottom: 15px;
      width: calc(100vw - 30px);
      max-width: calc(100vw - 30px);
      overflow: auto;
      margin-top: 15px;
      padding: 16px;
    }

    &.is--delete {
      bottom: initial;
      width: 450px;
      text-align: center;
      
      @media screen and (max-width: 1200px) {
        bottom: initial;
      }
      
      .modal {
        &__body {
          height: auto;
        }
      }
    }

    &__header {
      margin-bottom: 32px;
    }

    &__header-inner-wrapper {
      display: flex;
      justify-content: space-between;
    }

    &__body {
      height: 80vh;
      overflow: auto;
      padding-right: 8px;
      padding-bottom: 8px;

      @media screen and (max-width: 1200px) {
        height: auto;
        overflow: initial;
        padding: 0;
      }

      &::-webkit-scrollbar {
        width: 8px;
      }

      &::-webkit-scrollbar-thumb {
        border: 1px solid rgba(255, 255, 255, 0.4);
        background-color: rgba(0, 0, 0, 0.3);
        border-radius: 5px;
      }
    }

    &__footer {
      display: flex;
      justify-content: center;
      margin-top: 16px;
    }

    .titleModal {
      font-size: 20px;
      font-weight: 600;
      color: #455a64;
      margin-bottom: 16px;
    }

    .line {
      border-bottom: 4px solid #f90c68;
      width: 60px;
    }

    .form {
      .select {
        padding: 24px 16px;
        background: #fff;
        border-radius: 8px;
        box-shadow: 0px 1px 3px 0px rgba(142, 142, 142, 0.2),
          0px 1px 1px 0px rgba(243, 243, 243, 0.14),
          0px 2px 1px -1px rgba(204, 204, 204, 0.12);

        & + .select {
          margin-top: 24px;
        }

        &.is--disabled {
          opacity: 0.6;
          pointer-events: none;
          box-shadow: none;
          user-select: none;
        }

        .form-group {
          display: flex;
          margin: 0 -4px;
          
          @media screen and (max-width: 1200px) {
            display: block;
          }

          &--no-label {
            margin-top: 8px;
            @media screen and (max-width: 768px) {
              margin: 8px 0 0 0;
            }            
          }

          &--match {
            position: relative;

            &:before {
              content: '';
              position: absolute;
              top: 50%;
              width: 100%;
              border-top: 1px solid #ccc;
            }
          }

          &.is--custom-period {
            display: block;
            margin: 0;

            .percent { 
              width: 100%;
              margin: 8px 0;
            }

            .MuiSwitch-root {
              display: inline-flex;
              margin-right: 8px;
            }
          }
          
          .filter {
            &-select {
              width: 33.333%;
              margin: 0 4px;

              @media screen and (max-width: 1200px) {
                width: 100%;
                margin-bottom: 8px;                
              }

              @media screen and (max-width: 768px) {
                margin-left: 0px;    
              }

              &--match {
                width: 45%;
                max-width: 100px;
                margin: 16px auto;
              }
            }
          }

          .percent {
            width: 33.333%;
            margin: 0 4px;

            @media screen and (max-width: 1200px) {
              width: 100%;
              margin-bottom: 8px;
            }

            @media screen and (max-width: 768px) {
              margin: 0 0 8px 0;              
            }
          }
        }

        p {
          margin-bottom: 8px;
          color: rgba(0, 0, 0, 0.75);
          font-size: 14px;
          font-weight: 700;
          text-transform: uppercase;
        }

        .yesterday {
          margin-top: 16px;
        }

        .percent {
          margin-top: 8px;
          width: 100%;
          height: 38px;
          font-size: 14px;
          font-weight: 400;
          padding: 10px;
          background-color: #fff;
          border: 1px solid #ccc;
          border-radius: 4px;
          @media screen and (max-width: 1200px) {
            font-size: 16px;
          }
        }
      }

      .channels {
        display: flex;
        margin-top: 15px;

        button {
          display: flex;
          align-items: center;
          flex-direction: column;
          width: 70px;
          height: 50px;
          padding: 5px;
          margin-right: 8px;
          color: #fff;
          font-size: 10px;
          border: 0;
          border-radius: 5px;
          background-color: #90a4ae;
          box-shadow: 0 2px 15px -5px #90a4ae;

          &:hover {
            background-color: ${darken(0.1, '#90a4ae')};
          }

          &.is--disabled {
            opacity: .3;
            box-shadow: none;
          }

          svg {
            color: #fff;
          }
        }
      }
    }

    .contacts {
      margin-top: 20px;

      .titleContact {
        margin-bottom: 10px;
        font-size: 15px;
        font-weight: 700;
      }
    }

    .btn {
      margin: 0 8px;
      font-size: 14px;
      font-weight: 600;
      text-transform: uppercase;
      border: 0;

      &--add-filter {
        margin-top: 8px;
        color: #455a64;
        font-size: 14px;
        font-weight: 400;
        text-decoration: underline;
        text-transform: lowercase;
        background: transparent;
      }

      &--remove-filter {
        display: flex;
        align-items: center;
        margin: 0;
        color: #ff3f44;
        font-size: 0;
        font-weight: 400;
        text-transform: lowercase;
        background: transparent;
        @media screen and (max-width: 1200px) {
          font-size: 12px;
          svg {
            width: 20px;
            margin-right: 8px;
          }
        }
      }

      &--save {
        height: 35px;
        padding: 0 16px;
        color: #fff;
        line-height: 35px;
        background: #f90c68;
        border-radius: 20px;
        border: none;
        font-size: 13px;
        font-weight: 500;
        text-transform: none;

        &:hover {
          background: ${darken(0.08, '#f90c68')};
        }
      }

      &--cancel {
        height: 35px;
        color: #455a64;
        line-height: 35px;
        text-decoration: underline;
        background: transparent;
        font-size: 13px;
        font-weight: 500;
        border: none;
        text-transform: none;
      }
    }
  }
`;

export const Option = styledComponents(Select)`
  width: 1005;
  @media screen and (max-width: 1200px) {
    font-size: 16px;
  }
`;

export const Option1 = styledComponents(Select)`
  width: 100%;
  margin-top: 10px;

  @media screen and (max-width: 1200px) {
    font-size: 16px;
  }
`;

export const CloseButton = styledComponents.button`
  appearance: none;
  border: none;
  background-color: transparent;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 30px;
  height: 30px;

  svg {
    pointer-events: none;
  }
`;

export const Overlay = styledComponents(Backdrop)`
  &.MuiBackdrop-root {
    &.is--active {
      z-index: 10;
    }
  }
`;