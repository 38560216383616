import api from './api';

import i18n from '../i18n';

export const getNotifications = async (page = 1) => {
  const token = localStorage.getItem('token');

  const response = await api.get(`/notification/?page=${page}`, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });

  if (response && response.status === 204) {
    return null;
  }

  if (!response || response.status !== 200) {
    throw new Error(i18n.t('error.notifications_retrieve_failed'));
  }

  return response.data;
};
