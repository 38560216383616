import React, { useCallback, useEffect, useRef, useState, useContext } from 'react';

import { toast } from "react-toastify";

import { useTranslation } from 'react-i18next';
import '../../i18n';

import CardGroup from '../../components/CardGroup';
import BackgroundNew from '../../components/BackgroundNew';
import FilterContainer from '../../components/FilterContainer';
import ComposedGraphBarLine from '../../components/ComposedGraphBarLine';
import { DIMENSIONS_OPTONS} from '../../options/filterOptions';
import IntelipostIcon from '../../assets/intelipost_icon.webp';
import CustomModalLogisticCallToAction from '../../components/CustomModal/CustomModalLogisticCallToAction';

import { Container } from './styles';

import {getLogisticEvolutionAverageShippingCostRepresentationPerOrder, getLogisticEvolutionAverageShippingCostProductRepresentation} from '../../services/dashboardApiService';
import { formatValueToPercentage } from "../../utils/metricsUtils";
import { getSelectedInitialDateRange, formatDate, getAgregatedKeyByDimension } from "../../utils/dateUtils";
import { getTranslatedShippingTypeOptionsList, reverseTranslatedShippingTypeOptionsList } from '../../utils/logisticPagesUtils';

import { AuthContext } from '../../contexts';

const LogisticAverageShippingCostEvolution = () => {
    const { t } = useTranslation();
    const { timezone } = useContext(AuthContext);

    const [dateRange, setDateRange] = useState();
    const [isLoading, setIsLoading] = useState(true);
    const [selectedDimension, setSelectedDimension] = useState(DIMENSIONS_OPTONS.day);

    const [averageShippingCostPerOrderData, setAverageShippingCostPerOrderData] = useState(null)
    const [citiesOptions, setCitiesOptions] = useState ([]);
    const [statesOptions, setStatesOptions] = useState ([])
    const [selectedStatesOptions, setSelectedStatesOptions] = useState([]);
    const [selectedCitiesOptions, setSelectedCitiesOptions] = useState([]);
    const [selectedShippingMethodsOptions, setSelectedShippingMethodsOptions] = useState([]);
    const [shippingMethodsOptions, setShippingMethodsOptions] = useState([]);
    const [selectedShippingTypesOptions, setSelectedShippingTypesOptions] = useState([]);
    const [shippingTypesOptions, setShippingTypesOptions] = useState([]);
    const [originsOptions, setOriginsOptions] = useState([]);
    const [salesChannelsOptions, setSalesChannelsOptions] = useState([]);
    const [selectedOriginsOptions, setSelectedOriginsOptions] = useState([]);
    const [selectedSalesChannelsOptions, setSelectedSalesChannelsOptions] = useState([]);

    const [averageShippingCostPerProductData, setAverageShippingCostPerProductData] = useState(null)
    const [selectedProductSkuIdsOptions, setSelectedProductSkuIdsOptions] = useState([]);
    const [selectedProductIdsOptions, setSelectedProductIdsOptions] = useState([]);
    const [averageShippingCostPerProductGraphFullScreen, setAverageShippingCostPerProductGraphFullScreen] = useState(false)

    const [averageShippingCostPerOrderGraphFullScreen, setAverageShippingCostPerOrderGraphFullScreen] = useState(false)
    const [openCustomModalLogisticCallToAction, setOpenCustomModalLogisticCallToAction] = useState(false);

    const cardAverageShippingCostPerOrderRef = useRef(null);
    const cardAverageShippingCostPerProductRef = useRef(null);

    const handleApplyFilter = async () => {
        const [startDate, endDate] = dateRange;
        const shippingMethods = selectedShippingMethodsOptions.length > 0 ? [...selectedShippingMethodsOptions] : null;
        const shippingTypes = selectedShippingTypesOptions.length > 0 ? reverseTranslatedShippingTypeOptionsList([...selectedShippingTypesOptions]) : null;
        const states = selectedStatesOptions.length > 0 ? [...selectedStatesOptions] : null;
        const cities = selectedCitiesOptions.length > 0 ? [...selectedCitiesOptions] : null;
        const origins = selectedOriginsOptions.length > 0 ? [...selectedOriginsOptions] : null;
        const salesChannels = selectedSalesChannelsOptions.length > 0 ? [...selectedSalesChannelsOptions] : null;
        const productIds = selectedProductIdsOptions ? selectedProductIdsOptions.map(productId => productId.value) : null;
        const productSkuIds = selectedProductSkuIdsOptions ? selectedProductSkuIdsOptions.map(sku => sku.value) : null;

        await getLogisticEvolutionAverageShippingCostData(
            formatDate(startDate),
            formatDate(endDate),
            shippingMethods,
            states,
            cities,
            origins,
            salesChannels,
            productIds,
            productSkuIds,
            shippingTypes
        );
    };

    const getLogisticEvolutionAverageShippingCostData = useCallback(async (
        initialDate,
        finalDate,
        shippingMethods,
        states,
        cities,
        origins,
        salesChannels,
        productIds,
        productSkuIds,
        shippingTypes
    ) => {
        setIsLoading(true);

        try{
            const responses = await Promise.all([
                getLogisticEvolutionAverageShippingCostRepresentationPerOrder({
                    initialDate,
                    finalDate,
                    timezone,
                    shippingMethods,
                    states,
                    cities,
                    origins,
                    salesChannels,
                    shippingTypes
                }),
                getLogisticEvolutionAverageShippingCostProductRepresentation({
                    initialDate,
                    finalDate,
                    timezone,
                    shippingMethods,
                    states,
                    cities,
                    origins,
                    salesChannels,
                    productIds,
                    productSkuIds,
                    shippingTypes
                })

            ]);

            const averageShippingCostPerOrderResponse = responses[0]
            const averageShippingCostProductResponse = responses[1]
            if (!averageShippingCostPerOrderResponse || !averageShippingCostProductResponse) {
                setAverageShippingCostPerOrderData(null);
                setAverageShippingCostPerProductData(null);
                setShippingMethodsOptions([]);
                setShippingTypesOptions([]);
                setStatesOptions([]);
                setCitiesOptions([]);
                setOriginsOptions([]);
                setSalesChannelsOptions([]);

                return;
            }

            setAverageShippingCostPerOrderData(averageShippingCostPerOrderResponse.results)
            setAverageShippingCostPerProductData(averageShippingCostProductResponse.results);
            setShippingMethodsOptions(averageShippingCostPerOrderResponse.available_shipping_methods_to_filter)
            setShippingTypesOptions(getTranslatedShippingTypeOptionsList(averageShippingCostPerOrderResponse.available_shipping_types_to_filter))
            setStatesOptions(averageShippingCostPerOrderResponse.available_states_to_filter)
            setCitiesOptions(averageShippingCostPerOrderResponse.available_cities_to_filter)
            setOriginsOptions(averageShippingCostPerOrderResponse.available_origins_to_filter);
            setSalesChannelsOptions(averageShippingCostPerOrderResponse.available_marketplace_channels_to_filter);

        } catch (error){
            toast.error(error.message);
            setAverageShippingCostPerOrderData(null);
            setAverageShippingCostPerProductData(null);
            setShippingMethodsOptions([]);
            setShippingTypesOptions([]);
            setStatesOptions([]);
            setCitiesOptions([]);
            setOriginsOptions([]);
            setSalesChannelsOptions([]);
        } finally {
            setIsLoading(false);
        }

    }, [timezone]);

    const formatDataByDateAndshippingPriceRepresentation = (data, dimension) => {
        if(!data) { return null}

        const aggregatedData = {};

        data.forEach(entry => {
            const key = getAgregatedKeyByDimension(entry.date, dimension);

            if (!aggregatedData[key]) {
                aggregatedData[key] = {
                    date: key,
                    averageShippingCostRepresentation: [],
                };
            }

            aggregatedData[key].averageShippingCostRepresentation.push(entry.shipping_price_representation_percentage)
        });

        Object.keys(aggregatedData).forEach(key => {
            if (aggregatedData[key].averageShippingCostRepresentation.length === 0) {
                aggregatedData[key].averageShippingCostRepresentation = 0.0
            } else {
                const averageShippingCostRepresentationTotal = aggregatedData[key].averageShippingCostRepresentation.reduce((total,currentValue) => total + currentValue, 0.0)
                aggregatedData[key].averageShippingCostRepresentation = Number((averageShippingCostRepresentationTotal / aggregatedData[key].averageShippingCostRepresentation.length).toFixed(2))
            }

        })

        return Object.values(aggregatedData);
    };

    const getSecondaryFilters = () => {
        return [
            {
                title: t('productPerformance.product_id'),
                value: selectedProductIdsOptions,
                setValue: setSelectedProductIdsOptions,
                type: "createValue",
                placeholder: t('productPerformance.type_and_press_enter_example_1123'),
                tooltipInformationValue: t('productPerformance.search_by_product_id')
            },
            {
                title: t('productPerformance.product_sku_id'),
                value: selectedProductSkuIdsOptions,
                setValue:  setSelectedProductSkuIdsOptions,
                type: "createValue",
                placeholder: t('productPerformance.type_and_press_enter_example_1123'),
                tooltipInformationValue: t('productPerformance.search_by_product_sku_id')
            },
            {
                title: t('logisticShippingMethodEvolution.shipping_method'),
                options: shippingMethodsOptions,
                value: selectedShippingMethodsOptions,
                setValue: setSelectedShippingMethodsOptions
            },
            {
                title: t('logisticShippingMethodEvolution.shipping_type'),
                options: shippingTypesOptions,
                value: selectedShippingTypesOptions,
                setValue: setSelectedShippingTypesOptions
            },
            {
                title: t('logisticShippingMethodEvolution.cities'),
                options: citiesOptions,
                value: selectedCitiesOptions,
                setValue: setSelectedCitiesOptions
            },
            {
                title: t('common.states'),
                options: statesOptions,
                value: selectedStatesOptions,
                setValue: setSelectedStatesOptions
            },
            {
                title: `${t('common.source')}`,
                options: originsOptions,
                value: selectedOriginsOptions,
                setValue: setSelectedOriginsOptions
            },
            {
                title: t('common.sales_channel'),
                options: salesChannelsOptions,
                value: selectedSalesChannelsOptions,
                setValue: setSelectedSalesChannelsOptions
            }
        ]
      }

      const onRenderComponent = useCallback(async () => {
        const { initialDateFilter, finalDateFilter } = getSelectedInitialDateRange();
        setDateRange([initialDateFilter, finalDateFilter]);

        await getLogisticEvolutionAverageShippingCostData(
          formatDate(initialDateFilter),
          formatDate(finalDateFilter)
        );
      }, [getLogisticEvolutionAverageShippingCostData]);

      useEffect(() => {
        onRenderComponent();
      }, [onRenderComponent]);

      return (
        <BackgroundNew
          titlePage={t('menu.freight_progress')}
          showButtonAction={true}
          buttonActionOnClick={_ => setOpenCustomModalLogisticCallToAction(true)}
          buttonActionTitle={t('logisticShippingMethodEvolution.integrate')}
          showButtonActionComplement={true}
          buttonActionComplement={
            <img
                alt='Intelipost'
                src={IntelipostIcon}
                style={{ width: 'auto', height: '35px', borderRadius: '50%'}}
            />
          }
        >
            <FilterContainer
                selectedDateRange={dateRange}
                setDateRange={setDateRange}
                onClickFilter={handleApplyFilter}
                showSecondaryFilters={true}
                secondaryFilters={getSecondaryFilters()}
            />
           <Container>
           <CardGroup
                title={t('logisticAverageShippingCostEvolution.average_shipping_cost_representation_per_order')}
                tagIdentifierColor="#00CCAE"
                isLoading={isLoading}
                ref={cardAverageShippingCostPerOrderRef}
            >
                <ComposedGraphBarLine
                    data={averageShippingCostPerOrderData}
                    dimension={selectedDimension}
                    showPrimaryLineYAxis={true}
                    primaryLineYAxisConfig={{
                        name: t('productPerformance.representativeness'),
                        dataKey: "averageShippingCostRepresentation"
                    }}
                    formatData={formatDataByDateAndshippingPriceRepresentation}
                    formaterLineValue={formatValueToPercentage}
                    graphMarginConfig={{
                        top: 20,
                        right: 60,
                        left:0,
                        bottom: 20
                    }}
                    showDimensionController={true}
                    setSelectedDimension={setSelectedDimension}
                    disabledDimensionController={averageShippingCostPerOrderData === null || averageShippingCostPerOrderData.length === 0}
                    showFullScreenOption={true}
                    fullScreenEnabled={averageShippingCostPerOrderGraphFullScreen}
                    setFullScreenEnabled={setAverageShippingCostPerOrderGraphFullScreen}
                    showExportPDFButton={true}
                    graphComponentRefForPDF={cardAverageShippingCostPerOrderRef}
                />
            </CardGroup>
           </Container>
           <Container>
           <CardGroup
                title={t('logisticAverageShippingCostEvolution.average_shipping_cost_representation_per_product')}
                tagIdentifierColor="#00CCAE"
                isLoading={isLoading}
                ref={cardAverageShippingCostPerProductRef}
            >
                <ComposedGraphBarLine
                    data={averageShippingCostPerProductData}
                    dimension={selectedDimension}
                    showPrimaryLineYAxis={true}
                    primaryLineYAxisConfig={{
                        name: t('productPerformance.representativeness'),
                        dataKey: "averageShippingCostRepresentation"
                    }}
                    formatData={formatDataByDateAndshippingPriceRepresentation}
                    formaterLineValue={formatValueToPercentage}
                    graphMarginConfig={{
                        top: 20,
                        right: 60,
                        left:0,
                        bottom: 20
                    }}
                    showDimensionController={true}
                    setSelectedDimension={setSelectedDimension}
                    disabledDimensionController={averageShippingCostPerProductData === null || averageShippingCostPerProductData.length === 0}
                    showFullScreenOption={true}
                    fullScreenEnabled={averageShippingCostPerProductGraphFullScreen}
                    setFullScreenEnabled={setAverageShippingCostPerProductGraphFullScreen}
                    showExportPDFButton={true}
                    graphComponentRefForPDF={cardAverageShippingCostPerProductRef}
                />
            </CardGroup>
           </Container>
           <CustomModalLogisticCallToAction
                open={openCustomModalLogisticCallToAction}
                handleClose={_ => setOpenCustomModalLogisticCallToAction(false)}
            />
        </BackgroundNew>
    );
}

export default LogisticAverageShippingCostEvolution;
