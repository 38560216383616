import React from 'react';
import { Container } from './styles';

import { formatValueToPercentage } from '../../utils/metricsUtils';

const GraphCustomTooltip = ({ active, payload, label, showPercentage }) => {
  if (active && payload && payload.length) {
    const maxItemsPerColumn = 10;
    const totalItems = payload.length;
    const totalValue = payload.reduce((acc, item) => acc + item.value, 0);

    const columns = [];
    for (let i = 0; i < totalItems; i += maxItemsPerColumn) {
      columns.push(payload.slice(i, i + maxItemsPerColumn));
    }

    return (
      <Container>
        <h3>{`${label}`}</h3>
        <div className='groupOfTooltipColumns'>
          {columns.map((column, colIndex) => (
            <div key={colIndex} className="tooltipColumn">
              {column.map((item, index) => {
                const representation =
                  item.value > 0
                    ? formatValueToPercentage(Number((item.value / totalValue) * 100).toFixed(2))
                    : 0;

                return (
                  <p key={index}>
                    {`${item.name}: `}
                    <span className="tooltipValue" style={{ color: item.color }}>
                      {item.formatter(item.value)}
                    </span>
                    {showPercentage ? ` (${representation})` : ''}
                  </p>
                );
              })}
            </div>
          ))}
        </div>
      </Container>
    );
  }

  return null;
};

export default GraphCustomTooltip;
