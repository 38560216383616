import React, {useState, useEffect} from "react";
import { useLocation } from 'react-router-dom';

import { OAuthAuthenticateMessage } from '../../components/OAuthAuthenticateMessage';

const FacebookOAuthAds = () => {
    const location = useLocation()
    const [authSuccess, setOauthSuccess] = useState(false);

    useEffect(() => {
      const params = new URLSearchParams(location.search);
      const code = params.get("code");
      const state = params.get("state");
    
      if (code && state) {
        setOauthSuccess(true);
    
        window.opener.postMessage(
          {
            source: "oAuthFacebookAds",
            completed: true,
            success: true,
            code: code,
            state: state,
          },
          "*"
        );
      } else {
        setOauthSuccess(false);
    
        window.opener.postMessage(
          {
            source: "oAuthFacebookAds",
            completed: true,
            success: false
          },
          "*"
        );
      }
    }, [location.search]);

      return (
        <OAuthAuthenticateMessage isSuccess={authSuccess} />
      );
}

export default FacebookOAuthAds