/* eslint-disable camelcase */
import React, { useState, useEffect, useCallback } from 'react';

import { useTranslation } from 'react-i18next';
import '../../i18n';

import Loader from './Loader';

import { toast } from 'react-toastify';
import * as S from './styles';
import BackgroundNew from '../../components/BackgroundNew';
import CustomModal from '../../components/CustomModal/CustomModal';

import { mediaPlatformType } from '../../options/adTextCreator';

import { featureType } from '../../options/feature';
import { getCompanyFeatureUsageByFeatureId } from '../../services/featureService';
import { generateAdText } from '../../services/adTextCreatorService';

function AdTextCreator() {
  const { t } = useTranslation();

  const [usage, setUsage] = useState(null);
  const [quota, setQuota] = useState(null);
  const [quotaUsageLimitReached, setQuotaUsageLimitReached] = useState(false);
  const [adTextGenerated, setAdTextGenerated] = useState(null);
  const [mediaPlatform, setMediaPlatform] = useState(null);
  const [productDetails, setProductDetails] = useState('');
  const [targetAudience, setTargetAudience] = useState('');
  const [disableButton, setDisableButton] = useState(false);
  const [showLoading, setShowLoading] = useState(false);
  const [showDescriptionLoading, setShowDescriptionLoading] = useState(false);
  const [openModalInformation, setOpenModalInformation] = useState(false);

  const handleChangeMediaPlatform = (select) => {
    setMediaPlatform(select);
  };

  const getAdTextCompanyUsage = useCallback(async () => {
    try {
      const response = await getCompanyFeatureUsageByFeatureId(featureType.AD_TEXT_CREATOR);

      if (!response) {
        return;
      }

      setUsage(response["usage"]);
      setQuota(response["quota"]);

      if (response["quota"] === 0) {
        setQuotaUsageLimitReached(true);
      } else {
        setQuotaUsageLimitReached(false);
      }
    } catch (error) {
      toast.error(error.message);
    }
  }, []);

  const delay = ms => new Promise(res => setTimeout(res, ms));

  const handleGenerateAdText = async () => {
    if (!mediaPlatform) {
      toast.error(t('toast.select_media_platform'));
      return;
    }

    if (!productDetails) {
      toast.error(t('toast.provide_product_details'));
      return;
    }

    setAdTextGenerated(null);
    setDisableButton(true);
    setShowLoading(true);
    setShowDescriptionLoading(true);

    try {
      const data = {
        platform: mediaPlatform.value,
        product_detail: productDetails || '',
        target_audience: targetAudience
      }

      const response = await generateAdText(data);

      setAdTextGenerated(response["ad_text_generated"]);
      setQuota(response["usage"]["quota"]);
      setUsage(response["usage"]["usage"]);

      if (response["usage"]["quota"] === 0) {
        setQuotaUsageLimitReached(true);
      } else {
        setQuotaUsageLimitReached(false);
      }

      await delay(8000);
    } catch (error) {
      toast.error(error.message);
    }

    setDisableButton(false);
    setShowLoading(false);
    setShowDescriptionLoading(false);
  }

  useEffect(() => {
    getAdTextCompanyUsage();
  }, [getAdTextCompanyUsage]);

  return (
    <BackgroundNew 
      titlePage={t('menu.ad_generator')}
      subTitlePage={t('adTextCreator.get_creative_texts')}
      showInformation={true}
      informationTooltipTitle={t('common.click_to_learn_more')}
      informationOnClick={() => setOpenModalInformation(true)}
      >
      <S.Container>
        <div className="workSpace">
          {quotaUsageLimitReached && (<S.MessageQuotaUsageLimitReached>Seu limite de uso mensal foi atingido. Caso queira continuar usando, entre em contato com a nossa equipe e solicite o aumento de sua cota de uso.</S.MessageQuotaUsageLimitReached>)}
          <div className='usageDetails'>
            <p className='titleUsageDetails'>{t('adTextCreator.usage_details_current_month')}</p>
            <div className='usageDetailsContent'>
              <p>{t('adTextCreator.number_of_texts_generated')} <span className='usageValue'>{usage}</span></p>
              <p>{t('adTextCreator.quantity_available_for_use')} <span className='usageValue'>{quota}</span></p>
            </div>
          </div>
          <div className='adTextCreator'>
            <p className='titleAdTextCreator'>{t('adTextCreator.ad_text_generator')}</p>
            <div className='group'>
              <div className='fieldGroup'>
                <p className='title'>{t('adTextCreator.media_platform')}</p>
                <S.Option
                  placeholder={t('common.select')}
                  options={mediaPlatformType}
                  value={mediaPlatform}
                  onChange={handleChangeMediaPlatform}
                  isSearchable={false}
                  isDisabled={quotaUsageLimitReached}
                />
              </div>
              <div className='fieldGroup'>
                <p className='title'>{t('adTextCreator.target_audience')}</p>
                <div className={`input ${quotaUsageLimitReached ? 'disabled' : ''}`}>
                  <input
                    placeholder={`${t('adTextCreator.target_audience')}...`}
                    value={targetAudience}
                    onChange={(value) => setTargetAudience(value.target.value)}
                    maxLength={100}
                    disabled={quotaUsageLimitReached}
                  />
                </div>
              </div>
            </div>
            <div className='group'>
                <p className='title'>{t('adTextCreator.product_detail')}</p>
                <div className={`input ${quotaUsageLimitReached ? 'disabled' : ''}`}>
                  <textarea
                    placeholder={t('adTextCreator.details_of_the_product')}
                    value={productDetails}
                    onChange={(value) => setProductDetails(value.target.value)}
                    maxLength={300}
                    disabled={quotaUsageLimitReached}
                  />
              </div>
            </div>

            <div className="buttonGroup">
              <button 
                disabled={quotaUsageLimitReached} 
                className={`generateAdTextCreatorButton ${quotaUsageLimitReached || disableButton ? 'disabled' : ''}`}
                onClick={handleGenerateAdText}>
                  {t('adTextCreator.generate')}
              </button>
            </div>

            {showLoading && 
              <div className="contentLoading"><Loader showDescription={showDescriptionLoading}/></div>
            }

            {!showLoading && adTextGenerated && (
              <div className='group'>
                  <p className='title'>{t('adTextCreator.recommended_text_for_ad')}</p>
                  <div className="input">
                    <textarea
                      value={adTextGenerated}
                      disabled={true}
                    />
                </div>
              </div>
            )}
          </div>
        </div>
      </S.Container>
      <CustomModal
        title={t('adTextCreator.understand_ad_generator')}
        open={openModalInformation} 
        handleClose={() => setOpenModalInformation(false)}
        hiddenConfirmButton={true}
        cancelButtonLabel={t('common.close')}
      >
        <div className="video">
          <iframe width="100%" height="315" src="https://www.youtube.com/embed/sH6009hUKUc?si=oCfuF6EsvGBQ9xT5" title="YouTube video player" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture;" allowFullScreen></iframe>
        </div>
      </CustomModal>
    </BackgroundNew>
  );
}

export default AdTextCreator;
