import styled from 'styled-components';
import { darken } from 'polished';

export const Container = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
    margin-bottom: 20px;

    .partner {
        display: flex;
        flex-direction: column;
        max-width: 340px;
        min-height: 500px;
        align-items: center;
        justify-content: center;
        background-color: #ffffff;
        border-radius: 8px;
        box-shadow: 0 0 20px rgba(215, 222, 227, 0.39);
        margin: 10px;

        .image {
            img {
                width: 327px;
                height: 193px;
                border-radius: 8px;
            }
        }

        .description {
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            justify-content: center;
            padding: 10px 20px;

            p {
                margin-bottom: 20px;
                color: #263238;
            }

            .key {
                font-weight: bold;
                color: #ff0068;
            }

            .name {
                font-size: 20px;
                font-weight: bold;
            }

            .small_description, .expertise, .commercial_benefit {
                font-size: 15px;
                color: #6F767E;
            }
        }

        .callToAction {
            display: flex;
            align-items: center;
            justify-content: center;

            .buttonCallToAction {
                height: 40px;
                min-width: 130px;
                border: 0;
                -webkit-transition: all 0.2s;
                transition: all 0.2s;
                border-radius: 20px;
                background-color: #ff0068;
                font-size: 13px;
                font-weight: 500;
                border: 0;
                color: #ffffff;
                margin-bottom: 20px;
            }
        
            .buttonCallToAction:hover {
                background-color: ${darken(0.05, '#ff0068')};
            }
        }
    }

    .noPartners {
        display: flex;
        align-items: center;
        justify-content: center;

        p {
            color: #90a4ae;
            font-size: 15px;
        }
    }
`;