import React from 'react';

import { useTranslation } from 'react-i18next';
import '../../../../i18n';

import NumberFormat from 'react-number-format';

export const InputGoalMonth = ({ month, value, onChange, disabled }) => {
  const { t } = useTranslation();

  return (
    <div className="modal__form-group margin-bottom-input-month">
        <span className="modal__form-label">{t('mediaPlanner.enter_revenue_goal_for')} <span className='modal__form-label-month'>{month}</span>:</span>
        <NumberFormat
            allowNegative={false}
            allowLeadingZeros={true}
            thousandSeparator="."
            decimalSeparator=","
            decimalScale={2}
            className={`modal__form-input is--text ${ disabled ? 'is--disabled' : ''}`}
            placeholder="0,00"
            value={value}
            onChange={(v) => onChange(v.target.value)}
            disabled={disabled}
        />
    </div>
  );
}