import i18n from '../i18n'

export const getTranslatedShippingTypeOptionsList = (options) => {
    const translatedShippingTypeOptionsDictionary = {
        "Frete pago": i18n.t('logisticShippingTypeEvolution.paid_shipping'),
        "Frete grátis": i18n.t('logisticShippingTypeEvolution.free_shipping')
    }

    return options.map(option => translatedShippingTypeOptionsDictionary[option])
}

export const reverseTranslatedShippingTypeOptionsList = (options) => {
    const reverseTranslatedShippingTypeOptionsDictionary = {
        [i18n.t('logisticShippingTypeEvolution.paid_shipping')]: "Frete pago",
        [i18n.t('logisticShippingTypeEvolution.free_shipping')]: "Frete grátis"
    }
    
    return options.map(option => reverseTranslatedShippingTypeOptionsDictionary[option])
}