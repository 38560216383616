import styled from 'styled-components';

export const MinAndMaxValueFilterContainer = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    max-height: 38px;
    overflow: hidden;

    input {
        font-size: 14px;
        color: #2e3039;
        width: 50%;
        height: 38px;
        padding: 18.5px 14px;
        border: 1px solid #C8C8C8;
        border-radius: 5px;

        @media screen and (max-width: 1200px) {
            font-size: 14px;
        }
    }

    .min {
        margin-right: 5px;
    }

    .max {
        margin-left: 5px;
    }
`;