import React, { useState, useEffect } from 'react';
import { styled } from '@mui/material';
import MenuItem from '@mui/material/MenuItem';
import ListItemText from '@mui/material/ListItemText';
import Select from '@mui/material/Select';
import Checkbox from '@mui/material/Checkbox';
import Input from '@mui/material/Input';

import { useTranslation } from 'react-i18next';
import '../../i18n';

const SelectEmpty = styled(Select)(({ theme }) => ({
    height: 38,
    width: '100%',
    overflow: 'hidden',
    fontSize: 14,
    backgroundColor: '#fff',
    '& .MuiOutlinedInput-notchedOutline': {
        borderColor: "rgba(0, 0, 0, 0.20)"
    },
    '&:hover .MuiOutlinedInput-notchedOutline': {
        borderColor: "rgba(0, 0, 0, 0.30)"
    },
    '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
        borderColor: '#ff0068'
    }
}));

const MenuItemText = styled(ListItemText)(({ theme }) => ({
    '& .MuiListItemText-primary': {
        fontSize: '14px',
    },
    '& .MuiListItemText-secondary': {
        fontSize: '14px',
    },
    maxWidth: 250,
    overflow: 'auto',
    '&::-webkit-scrollbar': {
        width: '0px',
        height: '0px',
    },
    '&::-webkit-scrollbar-track': {
        boxShadow: '#f1f1f1',
        borderRadius: '10px',
        width: '5px',
    },
    '&::-webkit-scrollbar-thumb': {
        background: '#DEDEDE',
        borderRadius: '10px',
    },
    '&::-webkit-scrollbar-thumb:hover': {
        background: '#C8C8C8',
    }
}));

const SelectEmptyFieldValue = styled('span')(({ theme }) => ({
    color: 'rgba(0, 0, 0, 0.50)',
    fontSize: 14,
}));

const InputSearch = styled(Input)(({ theme }) => ({
    fontSize: 14,
    margin: '0px 20px',
    width: "calc(100% - 40px)",
    '&::before': {
        borderColor: 'rgba(0, 0, 0, 0.20)'
    },
    '&::after': {
        borderColor: '#ff0068'
    },
}));

const DisabledSelectEmpty = styled(Select)(({ theme }) => ({
    height: 38,
    width: '100%',
    overflow: 'hidden',
    fontSize: 14,
    backgroundColor: '#DEDEDE',
    '& .MuiOutlinedInput-notchedOutline': {
        borderColor: "rgba(0, 0, 0, 0.20)"
    },
    '&:hover .MuiOutlinedInput-notchedOutline': {
        borderColor: "rgba(0, 0, 0, 0.30)"
    }
}));

const SelectOptionTag = ({ options, onChange, value, placeholder }) => {
    const { t } = useTranslation();

    const [searchText, setSearchText] = useState('');
    const [filteredOptions, setFilteredOptions] = useState([]);

    const handleSearchTextChange = (event) => {
        setSearchText(event.target.value);

        if (event.target.value === '') {
            setFilteredOptions(options);
            return;
        }

        setFilteredOptions(options.filter(option => option.toLowerCase().includes(event.target.value.toLowerCase())));
    };

    const handleChangeValue = (event) => {
        let resultValues = [];
        if (event.target.value && event.target.value.includes('search')) {
            resultValues = event.target.value.filter(value => value !== 'search');
        } else {
            resultValues =[...event.target.value];
        }

        onChange(resultValues);
    }

    useEffect(() => {
        setSearchText('');
        setFilteredOptions(options);
    }, [options]);

    if (filteredOptions.length === 0 && value.length === 0){
        return (
            <DisabledSelectEmpty
                variant='outlined'
                multiple
                displayEmpty
                renderValue={_ => <SelectEmptyFieldValue>{t('common.all')}</SelectEmptyFieldValue>}
                disabled
            />
        )
    }

    return (
        <SelectEmpty
            variant='outlined'
            multiple
            displayEmpty
            value={value}
            onChange={handleChangeValue}
            renderValue={(selected) => {
                if (selected.length === 0) {
                    return <SelectEmptyFieldValue>{t('common.all')}</SelectEmptyFieldValue>;
                }

                return selected.join(', ')
            }}
        >
            <MenuItem value="" disabled>{placeholder}</MenuItem>
            <MenuItem value="search" name="search" onKeyDown={(e) => e.stopPropagation()}>
                <InputSearch
                    placeholder={t('common.search')}
                    value={searchText}
                    onChange={handleSearchTextChange}
                />
            </MenuItem>
            {(filteredOptions.length > 0 ? filteredOptions : value).map((option) => (
                <MenuItem key={option} value={option} sx={{
                    '&.Mui-selected': {
                        backgroundColor: 'rgba(0, 0, 0, 0.08)'
                    },
                    '&.Mui-selected:hover': {
                        backgroundColor: 'rgba(0, 0, 0, 0.08)'
                    }
                }}>
                    <Checkbox checked={value.indexOf(option) > -1} sx={{
                        '&.Mui-checked': {
                            color: "#ff0068"
                        }
                    }}/>
                    <MenuItemText primary={option}/>
                </MenuItem>
            ))}
        </SelectEmpty>
    );
}

export default SelectOptionTag;
