import api from './api';

import i18n from '../i18n';

const OK = 200;

export const getMicrosoftToken = async (report_id, workspace_id) => {
  const token = localStorage.getItem('token');

  const response = await api.get(`/microsoft/token/workspace/${workspace_id}/report/${report_id}`, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });

  const { access_token } = response.data;

  return access_token;
};

export const updateFacebookAdsToken = async (oauthToken, expirationDate, notificationDate) => {
  try {
    const token = localStorage.getItem('token');

    const response = await api.put(`/oauth/facebook/ads/token?oauth_token=${oauthToken}&expiration_date=${expirationDate}&notification_date=${notificationDate}`, null, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });

    if (!response || response.status !== OK) {
      throw new Error();
    }
  } catch (error) {
    throw new Error(i18n.t('error.facebook_token_update_failed'));
  }
}

export const resetPassword = async (username) => {
  const response = await api.post(`/reset_password/${username}`);

  if (!response || response.status !== OK) {
    throw new Error();
  }
}