import axios from 'axios';

const TrelloApi = axios.create({
  baseURL: 'https://api.trello.com/1',
});

const TrelloService = {
  key: '737c66eaca8af5d829a9858072255997',
  token: '',
  getLoginUrl: (returnUrl) => {
    return `https://trello.com/1/authorize?key=${TrelloService.key}&scope=read,write&expiration=never&name=Biso%20Digital&callback_method=fragment&return_url=${returnUrl}`;
  },
  getUserId: () => {
    if (TrelloService.userId) {
      return TrelloService.userId;
    }
    return TrelloApi.get('/members/me', {
      params: {
        key: TrelloService.key,
        token: TrelloService.token,
        fields: 'id',
      },
    });
  },
  getUserBoards: (userId) => {
    return TrelloApi.get(`/members/${userId}/boards`, {
      params: {
        key: TrelloService.key,
        token: TrelloService.token,
        fields: 'name,id',
      },
    });
  },
  setToken: (token) => {
    TrelloService.token = token;
  },
};

export default TrelloService;
