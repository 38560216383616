import React, { useEffect, useCallback, useState, useRef, useContext } from 'react';

import { toast } from 'react-toastify';

import { useTranslation } from 'react-i18next';
import '../../i18n';

import CardGroup from '../../components/CardGroup';
import CardIndicator from '../../components/CardIndicator';
import BackgroundNew from '../../components/BackgroundNew';
import FilterContainer from '../../components/FilterContainer';
import ComposedGraphBarLine from '../../components/ComposedGraphBarLine';
import LTVModalExplanation from '../../components/CustomModal/ModalLTVExplanation';
import ModalPageInformation from '../../components/CustomModal/ModalPageInformation';

import { Container } from './styles';

import { isValidAvailableFilter } from '../../utils/validationUtils';
import { DIMENSIONS_OPTONS} from '../../options/filterOptions';
import { getSelectedInitialDateRange, formatDate, getAgregatedKeyByDimension } from '../../utils/dateUtils';
import { formatValueToMoney, formatValueToNumber, formatValueToPercentage } from '../../utils/metricsUtils';

import {
    getCRMDailyMetrics,
    getCRMBigNumbersMetrics,
    getCRMAverageTimeBetweenPurchasesMetrics
} from '../../services/dashboardApiService';

import { AuthContext } from '../../contexts';

const AudienceOverview = () => {
    const { t } = useTranslation();
    const { timezone, currency } = useContext(AuthContext);

    const [dateRange, setDateRange] = useState();
    const [isLoading, setIsLoading] = useState(true);
    const [selectedDimension, setSelectedDimension] = useState(DIMENSIONS_OPTONS.day);
    const [crmData, setCRMData] = useState(null);
    const [bigNumbersData, setBigNumbersData] = useState(null);
    const [originsOptions, setOriginsOptions] = useState([]);
    const [salesChannelsOptions, setSalesChannelsOptions] = useState([]);
    const [vtexAffiliatesOptions, setVtexAffiliatesOptions] = useState([]);
    const [selectedOriginsOptions, setSelectedOriginsOptions] = useState([]);
    const [selectedSalesChannelsOptions, setSelectedSalesChannelsOptions] = useState([]);
    const [selectedVtexAffiliatesOptions, setSelectedVtexAffiliatesOptions] = useState([]);
    const [showLTVModalExplanation, setShowLTVModalExplanation] = useState(false);
    const [openModalInformation, setOpenModalInformation] = useState(false);

    const [newCustomerAndRebuyersRevenueShareGraphFullScreen, setNewCustomerAndRebuyersRevenueShareGraphFullScreen] = useState(false);
    const [newCustomersAndRebuyersRevenueGraphFullScreen, setNewCustomersAndRebuyersRevenueGraphFullScreen] = useState(false);
    const [newCustomersAndRebuyersQuantityGraphFullScreen, setNewCustomersAndRebuyersQuantityGraphFullScreen] = useState(false);
    const [ltvAndCACGraphFullScreen, setLtvAndCACGraphFullScreen] = useState(false);
    const [platformAverageTicketAndTimeBetweenPurchasesGraphFullScreen, setPlatformAverageTicketAndTimeBetweenPurchasesGraphFullScreen] = useState(false);

    const cardNewCustomersAndRebuyersRevenueRef = useRef(null);
    const cardNewCustomerAndRebuyersRevenueShareRef = useRef(null);
    const cardNewCustomersAndRebuyersQuantityRef = useRef(null);
    const cardLtvAndCACRef = useRef(null);
    const cardPlatformAverageTicketAndTimeBetweenPurchasesRef = useRef(null);


    const handleApplyFilter = async () => {
        const [startDate, endDate] = dateRange;
        const origins = selectedOriginsOptions.length > 0 ? [...selectedOriginsOptions] : null;
        const salesChannels = selectedSalesChannelsOptions.length > 0 ? [...selectedSalesChannelsOptions] : null;
        const vtexAffiliates = selectedVtexAffiliatesOptions.length > 0 ? [...selectedVtexAffiliatesOptions] : null;

        await getCRMDataMetrics(
            formatDate(startDate),
            formatDate(endDate),
            origins,
            salesChannels,
            vtexAffiliates
        );
    };

    const getUnifiedData = (crmDataResults, averageTimeBetweenPurchasesResults) => {
        const unifiedData = [];

        crmDataResults.forEach(crmData => {
            const averageTimeBetweenPurchasesData = averageTimeBetweenPurchasesResults.find(averageTimeBetweenPurchasesData => averageTimeBetweenPurchasesData.date === crmData.date);
            unifiedData.push({...crmData, ...averageTimeBetweenPurchasesData});
        })

        return unifiedData;
    }

    const getCRMDataMetrics = useCallback(async (
        initialDate,
        finalDate,
        origins = null,
        salesChannels = null,
        vtexAffiliates = null
    ) => {
        setIsLoading(true);

        try {
            const responses = await Promise.all([
              getCRMAverageTimeBetweenPurchasesMetrics(
                initialDate,
                finalDate,
                timezone,
                origins,
                salesChannels,
                vtexAffiliates
              ),
              getCRMDailyMetrics(
                initialDate,
                finalDate,
                timezone,
                origins,
                salesChannels,
                vtexAffiliates
              ),
              getCRMBigNumbersMetrics(
                initialDate,
                finalDate,
                timezone,
                origins,
                salesChannels,
                vtexAffiliates
              )
            ]);

            const responseAverageTimeBetweenPurchases = responses[0];
            const responseDailyMetrics = responses[1];
            const responseBigNumbers = responses[2];

            if (!responseAverageTimeBetweenPurchases || !responseDailyMetrics || !responseBigNumbers) {
                setCRMData(null);
                setBigNumbersData(null);
                setOriginsOptions([]);
                setSalesChannelsOptions([]);
                setVtexAffiliatesOptions([]);

                return;
            }

            const allBigNumbers = {...responseBigNumbers, ...responseAverageTimeBetweenPurchases.big_numbers}
            setBigNumbersData(allBigNumbers)

            setOriginsOptions(responseDailyMetrics.available_origins_to_filter);
            setSalesChannelsOptions(responseDailyMetrics.available_marketplace_channels_to_filter);

            if (isValidAvailableFilter(responseDailyMetrics.available_affiliates_to_filter)) {
                setVtexAffiliatesOptions(responseDailyMetrics.available_affiliates_to_filter);
            } else {
                setVtexAffiliatesOptions([])
            }

            const unifiedData = getUnifiedData(responseDailyMetrics.results, responseAverageTimeBetweenPurchases.results);
            setCRMData(unifiedData)
        } catch (error) {
            toast.error(error.message);
            setCRMData(null);
            setBigNumbersData(null);
            setOriginsOptions([]);
            setSalesChannelsOptions([]);
            setVtexAffiliatesOptions([]);
        } finally {
          setIsLoading(false);
        }
    }, [timezone]);

    const calculateAggregatedLTV = (
        platformAverageTicket,
        ordersQuantityPerCustomer,
        averageCustomerRelationship
    ) => {
        return averageCustomerRelationship >= 1
            ? Number((platformAverageTicket * ordersQuantityPerCustomer * averageCustomerRelationship)).toFixed(2)
            : Number((platformAverageTicket * ordersQuantityPerCustomer)).toFixed(2)
    }

    const formatCRMDataForGraph = (data, dimension) => {
        if(!data) { return null}

        const aggregatedData = {};

        data.forEach(entry => {
            const key = getAgregatedKeyByDimension(entry.date, dimension);
            if (!aggregatedData[key]) {
                aggregatedData[key] = {
                    date: key,
                    newCustomersRevenue: 0,
                    rebuyersRevenue: 0,
                    newCustomersAmountOrders: 0,
                    rebuyersAmountOrders: 0,
                    newCustomersQuantity: 0,
                    rebuyersQuantity: 0,
                    investment: 0.0,
                    ltv: 0.0,
                    cac: 0.0,
                    platformAverageTicket: 0.0,
                    averageTimeBetweenPurchases: [],
                    ordersQuantityPerCustomer: 0.0,
                    averageCustomerRelationship: 0.0,
                    rebuyersRevenuePercentage: 0.0,
                    newCustomersRevenuePercentage: 0.0
                };
            }

            aggregatedData[key].newCustomersRevenue += entry.new_customers_revenue;
            aggregatedData[key].rebuyersRevenue += entry.rebuying_revenue;
            aggregatedData[key].newCustomersAmountOrders += entry.new_customers_amount_orders;
            aggregatedData[key].rebuyersAmountOrders += entry.rebuying_amount_orders;
            aggregatedData[key].newCustomersQuantity += entry.new_customers_count;
            aggregatedData[key].rebuyersQuantity += entry.rebuying_count;
            aggregatedData[key].investment += entry.investment;
            aggregatedData[key].ltv = entry.ltv;
            aggregatedData[key].cac = entry.cac;
            aggregatedData[key].platformAverageTicket = entry.platform_average_ticket;
            aggregatedData[key].averageTimeBetweenPurchases.push(entry.average_time_between_purchases);

            if (entry.orders_quantity_per_customer)
                aggregatedData[key].ordersQuantityPerCustomer = entry.orders_quantity_per_customer;

            aggregatedData[key].averageCustomerRelationship = entry.average_customer_relationship;
        });

        if (dimension !== DIMENSIONS_OPTONS.day) {
            Object.keys(aggregatedData).forEach(key => {
                aggregatedData[key].newCustomersRevenue = Number((aggregatedData[key].newCustomersRevenue).toFixed(2));
                aggregatedData[key].rebuyersRevenue = Number((aggregatedData[key].rebuyersRevenue).toFixed(2));
                const totalRevenue = Number(aggregatedData[key].newCustomersRevenue + aggregatedData[key].rebuyersRevenue).toFixed(2);
                const totalOrders = aggregatedData[key].newCustomersAmountOrders + aggregatedData[key].rebuyersAmountOrders;
                const platformAverageTicket = totalOrders > 0 ? Number((totalRevenue / totalOrders).toFixed(2)) : 0.0;
                aggregatedData[key].ltv = calculateAggregatedLTV(
                    platformAverageTicket,
                    aggregatedData[key].ordersQuantityPerCustomer,
                    aggregatedData[key].averageCustomerRelationship
                )
                aggregatedData[key].platformAverageTicket = platformAverageTicket;

                const cac = aggregatedData[key].newCustomersQuantity > 0 ? aggregatedData[key].investment / aggregatedData[key].newCustomersQuantity : 0.0;
                aggregatedData[key].cac = Number((cac).toFixed(2));

                aggregatedData[key].averageTimeBetweenPurchases = Number((aggregatedData[key].averageTimeBetweenPurchases.reduce((a, b) => a + b, 0) / aggregatedData[key].averageTimeBetweenPurchases.length).toFixed(0));
            })
        }

        Object.keys(aggregatedData).forEach(key => {
            const totalRevenue = aggregatedData[key].newCustomersRevenue + aggregatedData[key].rebuyersRevenue;
            aggregatedData[key].rebuyersRevenuePercentage = totalRevenue > 0 ? Number((aggregatedData[key].rebuyersRevenue / totalRevenue * 100).toFixed(2)) : 0;
            aggregatedData[key].newCustomersRevenuePercentage = totalRevenue > 0 ? Number((aggregatedData[key].newCustomersRevenue / totalRevenue * 100).toFixed(2)) : 0;
        })

        return Object.values(aggregatedData);
    };

    const getCardIndicatorData = (data) => {
        return {
          keys: ["average_time_between_purchases", "total_new_customers_count", "total_rebuying_customers_count", "total_ltv"],
          titles: {
            average_time_between_purchases: t('common.average_time_between_purchases'),
            total_new_customers_count: t('common.new_customers'),
            total_rebuying_customers_count: t('common.returning_customers'),
            total_ltv: t('common.ltv')
          },
          types: {
            average_time_between_purchases: 'decimal',
            total_new_customers_count: 'decimal',
            total_rebuying_customers_count: 'decimal',
            total_ltv: 'currency'
          },
          displayFlexSpaceOcupation: {
            average_time_between_purchases: 1,
            total_new_customers_count: 1,
            total_rebuying_customers_count: 1,
            total_ltv: 1
          },
          metricInfoConfig: {
            average_time_between_purchases: {
              visible: false,
              onClick: null
            },
            total_new_customers_count: {
              visible: false,
              onClick: null
            },
            total_rebuying_customers_count: {
                visible: false,
                onClick: null
              },
            total_ltv: {
              visible: true,
              onClick: () => setShowLTVModalExplanation(true)
            }
          },
          data: data
        }
    }

    const getSecondaryFilters = () => {
        return [
            {
                title: `${t('common.source')}`,
                options: originsOptions,
                value: selectedOriginsOptions,
                setValue: setSelectedOriginsOptions
            },
            {
                title: t('common.sales_channel'),
                options: salesChannelsOptions,
                value: selectedSalesChannelsOptions,
                setValue: setSelectedSalesChannelsOptions
            },
            {
                title: `${t('common.affiliates_vtex')}`,
                options: vtexAffiliatesOptions,
                value: selectedVtexAffiliatesOptions,
                setValue: setSelectedVtexAffiliatesOptions
            }
        ]
      }

      const onRenderComponent = useCallback(async () => {
        const { initialDateFilter, finalDateFilter } = getSelectedInitialDateRange();
        setDateRange([initialDateFilter, finalDateFilter]);

        await getCRMDataMetrics(
          formatDate(initialDateFilter),
          formatDate(finalDateFilter)
        );
      }, [getCRMDataMetrics]);

      useEffect(() => {
        onRenderComponent();
      }, [onRenderComponent]);

    return (
        <BackgroundNew
          titlePage={t('menu.audience_overview')}
          showInformation={true}
          informationTooltipTitle={t('common.click_to_learn_more')}
          informationOnClick={() => setOpenModalInformation(true)}
        >
            <FilterContainer
                selectedDateRange={dateRange}
                setDateRange={setDateRange}
                onClickFilter={handleApplyFilter}
                showSecondaryFilters={true}
                secondaryFilters={getSecondaryFilters()}
            />
            <CardIndicator
                data={getCardIndicatorData(bigNumbersData)}
                currency={currency}
                isLoading={isLoading}
            />
            <CardGroup
                title={t('audienceOverview.new_vs_returning_customers_revenue_platform')}
                tagIdentifierColor="#00CCAE"
                isLoading={isLoading}
                ref={cardNewCustomersAndRebuyersRevenueRef}
            >
                <ComposedGraphBarLine
                    data={crmData}
                    dimension={selectedDimension}
                    showPrimaryLineYAxis={true}
                    showSecondaryLineYAxis={true}
                    primaryLineYAxisConfig={{
                        name: t('common.new_customers'),
                        dataKey: "newCustomersRevenue"
                    }}
                    secondaryLineYAxisConfig={{
                        name: t('common.returning_customers'),
                        dataKey: "rebuyersRevenue"
                    }}
                    formatData={formatCRMDataForGraph}
                    formaterLineValue={value => formatValueToMoney(value, currency)}
                    graphMarginConfig={{
                        top: 20,
                        right: 60,
                        left:0,
                        bottom: 20
                    }}
                    showDimensionController={true}
                    setSelectedDimension={setSelectedDimension}
                    disabledDimensionController={crmData === null || crmData.length === 0}
                    showFullScreenOption={true}
                    fullScreenEnabled={newCustomersAndRebuyersRevenueGraphFullScreen}
                    setFullScreenEnabled={setNewCustomersAndRebuyersRevenueGraphFullScreen}
                    showExportPDFButton={true}
                    graphComponentRefForPDF={cardNewCustomersAndRebuyersRevenueRef}
                />
            </CardGroup>
            <Container>
                <CardGroup
                    title={t('audienceOverview.share_new_vs_returning_customers_revenue_platform')}
                    tagIdentifierColor="#00CCAE"
                    isLoading={isLoading}
                    customClassName="cardGroupContainerFirst"
                    ref={cardNewCustomerAndRebuyersRevenueShareRef}
                >
                    <ComposedGraphBarLine
                        data={crmData}
                        dimension={selectedDimension}
                        showPrimaryBarYAxis={true}
                        showSecondaryBarYAxis={true}
                        secondaryBarIsStacked={true}
                        primaryBarYAxisConfig={{
                            name: t('common.new_customers'),
                            dataKey: "newCustomersRevenuePercentage"
                        }}
                        secondaryBarYAxisConfig={{
                            name: t('common.returning_customers'),
                            dataKey: "rebuyersRevenuePercentage"
                        }}
                        formatData={formatCRMDataForGraph}
                        formaterBarValue={formatValueToPercentage}
                        graphMarginConfig={{
                            top: 20,
                            right: -30,
                            left: 5,
                            bottom: 20
                        }}
                        showDimensionController={true}
                        setSelectedDimension={setSelectedDimension}
                        disabledDimensionController={crmData === null || crmData.length === 0}
                        showFullScreenOption={true}
                        fullScreenEnabled={newCustomerAndRebuyersRevenueShareGraphFullScreen}
                        setFullScreenEnabled={setNewCustomerAndRebuyersRevenueShareGraphFullScreen}
                        showExportPDFButton={true}
                        graphComponentRefForPDF={cardNewCustomerAndRebuyersRevenueShareRef}
                    />
                </CardGroup>
                <CardGroup
                    title={t('audienceOverview.new_vs_returning_customers_quantity')}
                    tagIdentifierColor="#00CCAE"
                    isLoading={isLoading}
                    customClassName="cardGroupContainerSecond"
                    ref={cardNewCustomersAndRebuyersQuantityRef}
                >
                    <ComposedGraphBarLine
                        data={crmData}
                        dimension={selectedDimension}
                        showPrimaryBarYAxis={true}
                        showSecondaryBarYAxis={true}
                        secondaryBarIsStacked={true}
                        primaryBarYAxisConfig={{
                            name: t('common.new_customers'),
                            dataKey: "newCustomersQuantity"
                        }}
                        secondaryBarYAxisConfig={{
                            name: t('common.returning_customers'),
                            dataKey: "rebuyersQuantity"
                        }}
                        formatData={formatCRMDataForGraph}
                        formaterBarValue={formatValueToNumber}
                        graphMarginConfig={{
                            top: 20,
                            right: -30,
                            left:0,
                            bottom: 20
                        }}
                        showDimensionController={true}
                        setSelectedDimension={setSelectedDimension}
                        disabledDimensionController={crmData === null || crmData.length === 0}
                        showFullScreenOption={true}
                        fullScreenEnabled={newCustomersAndRebuyersQuantityGraphFullScreen}
                        setFullScreenEnabled={setNewCustomersAndRebuyersQuantityGraphFullScreen}
                        height="44.0vh"
                        showExportPDFButton={true}
                        graphComponentRefForPDF={cardNewCustomersAndRebuyersQuantityRef}
                    />
                </CardGroup>
            </Container>
            <Container>
                <CardGroup
                    title={t('audienceOverview.ltv_vs_cac')}
                    tagIdentifierColor="#00CCAE"
                    isLoading={isLoading}
                    customClassName="cardGroupContainerFirst"
                    ref={cardLtvAndCACRef}
                >
                    <ComposedGraphBarLine
                        data={crmData}
                        dimension={selectedDimension}
                        showPrimaryBarYAxis={true}
                        showSecondaryLineYAxis={true}
                        primaryBarYAxisConfig={{
                            name: t('common.cac'),
                            dataKey: "cac"
                        }}
                        secondaryLineYAxisConfig={{
                            name: t('common.ltv'),
                            dataKey: "ltv"
                        }}
                        formatData={formatCRMDataForGraph}
                        formaterBarValue={value => formatValueToMoney(value, currency)}
                        formaterLineValue={value => formatValueToMoney(value, currency)}
                        graphMarginConfig={{
                            top: 20,
                            right: 50,
                            left:30,
                            bottom: 20
                        }}
                        showDimensionController={true}
                        setSelectedDimension={setSelectedDimension}
                        disabledDimensionController={crmData === null || crmData.length === 0}
                        showFullScreenOption={true}
                        fullScreenEnabled={ltvAndCACGraphFullScreen}
                        setFullScreenEnabled={setLtvAndCACGraphFullScreen}
                        showExportPDFButton={true}
                        graphComponentRefForPDF={cardLtvAndCACRef}
                    />
                </CardGroup>
                <CardGroup
                    title={t('audienceOverview.average_ticket_vs_avg_time_between_purchases')}
                    tagIdentifierColor="#00CCAE"
                    isLoading={isLoading}
                    customClassName="cardGroupContainerSecond"
                    ref={cardPlatformAverageTicketAndTimeBetweenPurchasesRef}
                >
                    <ComposedGraphBarLine
                        data={crmData}
                        dimension={selectedDimension}
                        showPrimaryBarYAxis={true}
                        showSecondaryLineYAxis={true}
                        secondaryLineYAxisConfig={{
                            name: t('audienceOverview.average_ticket_approved_platform'),
                            dataKey: "platformAverageTicket"
                        }}
                        primaryBarYAxisConfig={{
                            name: t('common.average_time_between_purchases'),
                            dataKey: "averageTimeBetweenPurchases"
                        }}
                        formatData={formatCRMDataForGraph}
                        formaterBarValue={formatValueToNumber}
                        formaterLineValue={value => formatValueToMoney(value, currency)}
                        graphMarginConfig={{
                            top: 20,
                            right: 40,
                            left:0,
                            bottom: 20
                        }}
                        showDimensionController={true}
                        setSelectedDimension={setSelectedDimension}
                        disabledDimensionController={crmData === null || crmData.length === 0}
                        showFullScreenOption={true}
                        fullScreenEnabled={platformAverageTicketAndTimeBetweenPurchasesGraphFullScreen}
                        setFullScreenEnabled={setPlatformAverageTicketAndTimeBetweenPurchasesGraphFullScreen}
                        showExportPDFButton={true}
                        graphComponentRefForPDF={cardPlatformAverageTicketAndTimeBetweenPurchasesRef}
                    />
                </CardGroup>
            </Container>
            <LTVModalExplanation
              open={showLTVModalExplanation}
              handleClose={() => setShowLTVModalExplanation(false)}
              ltvDetailCalculation={bigNumbersData ? bigNumbersData.detail_calculation_indicators.ltv : null}
            />
            <ModalPageInformation
                title={t('audienceOverview.understand_audience_overview_analysis')}
                open={openModalInformation}
                handleClose={() => setOpenModalInformation(false)}
                videoUrl={"https://www.youtube.com/embed/b7zFJsMZ6yM?si=tRBZ3IdaO0VfQZsR"}
            />
        </BackgroundNew>
    );
}

export default AudienceOverview;
