import React from 'react';

import { useTranslation } from 'react-i18next';
import '../../../i18n';

import * as S from './styled';

function CustomModalAppToken(props) {
  const { t } = useTranslation();

  return (
    <S.CustomModalAppToken {...props}>
      <div className="modal">
        <div className="modal__header">
          <p className="modal__title">{t('firstStep.setting_up_device')}</p>
          <div className="line" />
        </div>

        <div className="modal__body">
          <ul>
            <li>1. {t('firstStep.download_app_from')} <a href="https://play.google.com/store/apps/details?id=com.appbiso" target="_blank" rel="noopener noreferrer">Play Store (Android)</a> {t('firstStep.or')} <a href="https://apps.apple.com/br/app/bi-solutions/id1531965629" target="_blank" rel="noopener noreferrer">App Store (iOS)</a>;</li>
            <li>2. {t('firstStep.log_in')};</li>
            <li>3. {t('firstStep.authorize_notifications')};</li>
            <li>4. {t('firstStep.setup_complete')}</li>
          </ul>
        </div>

        <div className="modal__footer">
          <button
            className="modal__form-btn is--cancel"
            type="button"
            onClick={props.handleClose}
          >
            {t('common.cancel')}
          </button>
          <button
            className="modal__form-btn is--save"
            type="button"
            onClick={props.handleClose}
          >
            OK
          </button>
        </div>
      </div>
    </S.CustomModalAppToken>
  );
}

export default CustomModalAppToken;
