import React, { useEffect, useState } from 'react';

import { useTranslation } from 'react-i18next';
import '../../../i18n';

import BisoAdvertiseImg from '../../../assets/advertise.png';
import CustomModal from '../CustomModal';

import { ModalPageBisoAdvertiseContainer } from './styles';

const ModalPageBisoAdvertise = () => {
    const { t } = useTranslation();

    const LAST_UPDATE = '2024_08_21';
    const KEY = `bisoAdvertiseRead_${LAST_UPDATE}`;
    const [open, setOpen] = useState(false);

    const handleClose = () => {
        setOpen(false);

        localStorage.setItem(KEY, true);
    }

    useEffect(() => {
        const read = localStorage.getItem(KEY);
        if (!read) {
            setOpen(true);
        } else {
            setOpen(false);
        }
    }, [KEY]);

    return (
        <CustomModal
            title={'Webinar - Biso Features 🚀'}
            open={open}
            handleClose={handleClose}
            hiddenConfirmButton={true}
            cancelButtonLabel={t('common.close')}
        >
            <ModalPageBisoAdvertiseContainer>
                <div className="banner">
                    <img src={BisoAdvertiseImg} alt="Ad Biso" width="100%" />
                </div>
                <p>{t('common.ad_biso_description')}</p>
                <button className="button" onClick={_ => window.open("https://www.biso.digital/webinar-biso-features")}>{t('common.ad_biso_button_sign_up')}</button>
            </ModalPageBisoAdvertiseContainer>
        </CustomModal>
    );
}

export default ModalPageBisoAdvertise;