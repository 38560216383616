import React, { useEffect, useCallback, useState, useRef, useContext } from 'react';

import { toast } from 'react-toastify';

import { useTranslation } from 'react-i18next';
import '../../i18n';

import CardGroup from '../../components/CardGroup';
import CardIndicator from '../../components/CardIndicator';
import BackgroundNew from '../../components/BackgroundNew';
import FilterContainer from '../../components/FilterContainer';
import ComposedGraphBarLine from '../../components/ComposedGraphBarLine';
import LTVModalExplanation from '../../components/CustomModal/ModalLTVExplanation';
import ModalPageInformation from '../../components/CustomModal/ModalPageInformation';

import { getSelectedInitialDateRange, formatDate } from '../../utils/dateUtils';
import { formatValueToMoney, formatValueToNumber } from '../../utils/metricsUtils';
import { isValidAvailableFilter } from '../../utils/validationUtils';

import {
  getCRMBigNumbersMetrics,
  getCRMAverageTimeBetweenPurchasesMetrics,
  getCRMGeolocationMetrics
} from '../../services/dashboardApiService';

import { AuthContext } from '../../contexts';

const Geolocation = () => {
    const { t } = useTranslation();
    const { timezone, currency } = useContext(AuthContext);

    const [dateRange, setDateRange] = useState();
    const [isLoading, setIsLoading] = useState(true);
    const [statesOptions, setStatesOptions] = useState([]);
    const [selectedStatesOptions, setSelectedStatesOptions] = useState([]);
    const [bigNumbersData, setBigNumbersData] = useState(null);
    const [statesMetricsData, setStatesMetricsData] = useState(null);
    const [originsOptions, setOriginsOptions] = useState([]);
    const [salesChannelsOptions, setSalesChannelsOptions] = useState([]);
    const [vtexAffiliatesOptions, setVtexAffiliatesOptions] = useState([]);
    const [selectedOriginsOptions, setSelectedOriginsOptions] = useState([]);
    const [selectedSalesChannelsOptions, setSelectedSalesChannelsOptions] = useState([]);
    const [selectedVtexAffiliatesOptions, setSelectedVtexAffiliatesOptions] = useState([]);
    const [showLTVModalExplanation, setShowLTVModalExplanation] = useState(false);
    const [openModalInformation, setOpenModalInformation] = useState(false);

    const [statesGraphFullScreen, setStatesGraphFullScreen] = useState(false);
    
    const cardStatesRef = useRef(null);

    const handleApplyFilter = async () => {
        const [startDate, endDate] = dateRange;
        const states = selectedStatesOptions.length > 0 ? [...selectedStatesOptions] : null;
        const origins = selectedOriginsOptions.length > 0 ? [...selectedOriginsOptions] : null;
        const salesChannels = selectedSalesChannelsOptions.length > 0 ? [...selectedSalesChannelsOptions] : null;
        const vtexAffiliates = selectedVtexAffiliatesOptions.length > 0 ? [...selectedVtexAffiliatesOptions] : null;

        await getCRMDataMetrics(
            formatDate(startDate),
            formatDate(endDate),
            states,
            origins,
            salesChannels,
            vtexAffiliates
        );
    };

    const getCRMDataMetrics = useCallback(async (
      initialDate,
      finalDate,
      states,
      origins = null,
      salesChannels = null,
      vtexAffiliates = null
    ) => {
        setIsLoading(true);

        try {
            const responses = await Promise.all([
              getCRMAverageTimeBetweenPurchasesMetrics(
                initialDate,
                finalDate,
                timezone,
                origins,
                salesChannels,
                vtexAffiliates
              ),
              getCRMBigNumbersMetrics(
                initialDate,
                finalDate,
                timezone,
                origins,
                salesChannels,
                vtexAffiliates
              ),
              getCRMGeolocationMetrics(
                initialDate,
                finalDate,
                timezone,
                states,
                origins,
                salesChannels,
                vtexAffiliates
              )
            ]);

            const responseAverageTimeBetweenPurchases = responses[0];
            const responseBigNumbers = responses[1];
            const responseStatesMetrics = responses[2];

            if (!responseAverageTimeBetweenPurchases || !responseBigNumbers || !responseStatesMetrics) {
              setBigNumbersData(null);
              setStatesOptions([]);
              setStatesMetricsData(null);

              return;
            }

            const allBigNumbers = {...responseBigNumbers, ...responseAverageTimeBetweenPurchases.big_numbers}
            setBigNumbersData(allBigNumbers)

            setStatesMetricsData(responseStatesMetrics.results)
            setStatesOptions(responseStatesMetrics.states_to_filter)
            setOriginsOptions(responseStatesMetrics.available_origins_to_filter);
            setSalesChannelsOptions(responseStatesMetrics.available_marketplace_channels_to_filter);

            if (isValidAvailableFilter(responseStatesMetrics.available_affiliates_to_filter)) {
              setVtexAffiliatesOptions(responseStatesMetrics.available_affiliates_to_filter);
            } else {
                setVtexAffiliatesOptions([])
            }
        } catch (error) {
            toast.error(error.message);
            setBigNumbersData(null);
            setStatesOptions([])
            setStatesMetricsData(null)
        } finally {
          setIsLoading(false);
        }
    }, [timezone]);

    const formatStatesMetricsData = (data) => {
      if (!data) return null;

      return data.sort((a, b) => a.platform_approved_revenue - b.platform_approved_revenue);
    }

    const getCardIndicatorData = (data) => {
        return {
          keys: ["average_time_between_purchases", "total_new_customers_count", "total_rebuying_customers_count", "total_ltv"],
          titles: {
            average_time_between_purchases: t('common.average_time_between_purchases'),
            total_new_customers_count: t('common.new_customers'),
            total_rebuying_customers_count: t('common.returning_customers'),
            total_ltv: t('common.ltv')
          },
          types: {
            average_time_between_purchases: 'decimal',
            total_new_customers_count: 'decimal',
            total_rebuying_customers_count: 'decimal',
            total_ltv: 'currency'
          },
          displayFlexSpaceOcupation: {
            average_time_between_purchases: 1,
            total_new_customers_count: 1,
            total_rebuying_customers_count: 1,
            total_ltv: 1
          },
          metricInfoConfig: {
            average_time_between_purchases: {
              visible: false,
              onClick: null
            },
            total_new_customers_count: {
              visible: false,
              onClick: null
            },
            total_rebuying_customers_count: {
                visible: false,
                onClick: null
              },
            total_ltv: {
              visible: true,
              onClick: () => setShowLTVModalExplanation(true)
            }
          },
          data: data
        }
    }

    const getSecondaryFilters = () => {
      return [
          {
              title: `${t('common.states')}`,
              options: statesOptions,
              value: selectedStatesOptions,
              setValue: setSelectedStatesOptions
          },
          {
              title: `${t('common.source')}`,
              options: originsOptions,
              value: selectedOriginsOptions,
              setValue: setSelectedOriginsOptions
          },
          {
              title: t('common.sales_channel'),
              options: salesChannelsOptions,
              value: selectedSalesChannelsOptions,
              setValue: setSelectedSalesChannelsOptions
          },
          {
              title: `${t('common.affiliates_vtex')}`,
              options: vtexAffiliatesOptions,
              value: selectedVtexAffiliatesOptions,
              setValue: setSelectedVtexAffiliatesOptions
          }
      ]
    }

    const onRenderComponent = useCallback(async () => {
      const { initialDateFilter, finalDateFilter } = getSelectedInitialDateRange();
      setDateRange([initialDateFilter, finalDateFilter]);

      await getCRMDataMetrics(
        formatDate(initialDateFilter),
        formatDate(finalDateFilter)
      );
    }, [getCRMDataMetrics]);

    useEffect(() => {
      onRenderComponent();
    }, [onRenderComponent]);

    return (
        <BackgroundNew
          titlePage={t('menu.geolocation')}
          showInformation={true}
          informationTooltipTitle={t('common.click_to_learn_more')}
          informationOnClick={() => setOpenModalInformation(true)}
        >
            <FilterContainer
                selectedDateRange={dateRange}
                setDateRange={setDateRange}
                onClickFilter={handleApplyFilter}
                showSecondaryFilters={true}
                secondaryFilters={getSecondaryFilters()}
            />
            <CardIndicator
                data={getCardIndicatorData(bigNumbersData)}
                currency={currency}
                isLoading={isLoading}
            />
            <CardGroup
                title={t('geolocation.approved_revenue_vs_orders_by_state_platform')}
                tagIdentifierColor="#00CCAE"
                isLoading={isLoading}
                ref={cardStatesRef}
            >
                <ComposedGraphBarLine
                    xDataKey="state"
                    data={statesMetricsData}
                    showPrimaryBarYAxis={true}
                    showSecondaryLineYAxis={true}
                    primaryBarYAxisConfig={{
                        name: t('paidMediaCampaign.approved_revenue_platform'),
                        dataKey: "platform_approved_revenue"
                    }}
                    secondaryLineYAxisConfig={{
                        name: t('geolocation.approved_orders_platform'),
                        dataKey: "amount_orders"
                    }}
                    formatData={formatStatesMetricsData}
                    formaterBarValue={value => formatValueToMoney(value, currency)}
                    formaterLineValue={formatValueToNumber}
                    graphMarginConfig={{
                        top: 20,
                        right: -15,
                        left:55,
                        bottom: 20
                    }}
                    showDimensionController={false}
                    disabledDimensionController={statesMetricsData == null || statesMetricsData.length === 0}
                    showFullScreenOption={true}
                    fullScreenEnabled={statesGraphFullScreen}
                    setFullScreenEnabled={setStatesGraphFullScreen}
                    showExportPDFButton={true}
                    graphComponentRefForPDF={cardStatesRef}
                />
            </CardGroup>
            <LTVModalExplanation
              open={showLTVModalExplanation}
              handleClose={() => setShowLTVModalExplanation(false)}
              ltvDetailCalculation={bigNumbersData ? bigNumbersData.detail_calculation_indicators.ltv : null}
            />
            <ModalPageInformation
                title={t('geolocation.understand_geolocation_analysis')}
                open={openModalInformation}
                handleClose={() => setOpenModalInformation(false)}
                videoUrl={"https://www.youtube.com/embed/-7bUFBya7KI?si=SAf8dejFix11tYSH"}
            />
        </BackgroundNew>
    );
}

export default Geolocation;
