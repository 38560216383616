import styled from 'styled-components';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';

export const UserMenuButton = styled.button`
    display: flex;
    align-items: center;
    color: rgba(129, 129, 129, 0.5);
    font-size: 12px;
    background: transparent;
    border: none;
`;

export const UserMenu = styled(Menu)``;

export const UserMenuItem = styled(MenuItem)``;
