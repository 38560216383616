import React, { useEffect, useState, useCallback } from 'react';

import { useTranslation } from 'react-i18next';
import '../../../i18n';

import { MatrixContainer } from './styles';
import { formatValueToPercentage } from '../../../utils/metricsUtils';
import { generateGradientColor } from '../../../utils/colorUtils';

const CustomerRetentionMatrix = ({ data }) => {
  const { t } = useTranslation();

  const [retentionData, setRetentionData] = useState(null);
  const [headers, setHeaders] = useState(null);
  const [minValue, setMinValue] = useState(0);
  const [maxValue, setMaxValue] = useState(100);

  const convertToMonthName = useCallback((month) => {
    const months = [
      `${t('common.january')}`, `${t('common.february')}`, `${t('common.march')}`, `${t('common.april')}`,
      `${t('common.may')}`, `${t('common.june')}`, `${t('common.july')}`, `${t('common.august')}`,
      `${t('common.september')}`, `${t('common.october')}`, `${t('common.november')}`, `${t('common.december')}`
    ];
    return months[month];
  }, [t]);

  const getRetentionData = useCallback((data) => {
    if (!data || data.length === 0) return null;

    const retentionData = {};
    let newMinValue = 100;
    let newMaxValue = 0;
    
    data.forEach((entry) => {
      const year = entry.year;
      const month = convertToMonthName(entry.month - 1);
      retentionData[`${month} ${year}`] = entry.customer_retention.map(item => item.retention_percentage);

      const retentionPercentages = entry.customer_retention.map(item => item.retention_percentage);

      if (retentionPercentages.length > 1) {
        const maxPercentage = Math.max(...retentionPercentages.slice(1));
        const minPercentage = Math.min(...retentionPercentages.slice(1));
        newMinValue = Math.min(newMinValue, minPercentage);
        newMaxValue = Math.max(newMaxValue, maxPercentage);
      }
    });

    const headers = [t('cohort.first_purchase'), ...Array(data[0].customer_retention.length).fill(0).map((_, i) => i)];
    setHeaders(headers);
    
    setMinValue(newMinValue);
    setMaxValue(newMaxValue);
    return retentionData;
  }, [convertToMonthName, t]);

  useEffect(() => {
    setRetentionData(getRetentionData(data));
  }, [data, getRetentionData]);

  return (
    <>
      {retentionData && (
        <MatrixContainer>
          <table>
            <thead className='stickyHeader'>
              <tr>
                {headers.map((month, index) => (
                  <th key={index} className={index === 0 ? "stickyCell" : ""}>{month}</th>
                ))}
              </tr>
            </thead>
            <tbody>
              {Object.keys(retentionData).map((month, index) => (
                <tr key={index}>
                  <td className='stickyCell' style={{ backgroundColor: "#fff" }}>{month}</td>
                  {retentionData[month].map((percentage, index) => {
                    return (
                      <td 
                        key={index} 
                        style={{ background: index !== 0 ? generateGradientColor(percentage, minValue, maxValue) : "#fff" }}
                      >
                        {formatValueToPercentage(percentage)}
                      </td>
                    );
                  })}
                </tr>
              ))}
            </tbody>
          </table>
        </MatrixContainer>
      )}
      {!retentionData && (
        <div 
            style={{
              textAlign: 'center',
              marginTop: 20,
              fontSize: 15,
              fontWeight: 200
            }}
            >
              {t('common.no_data')}
          </div>
      )}
    </>
  );
};

export default CustomerRetentionMatrix;
