import React from 'react';

import { useTranslation } from 'react-i18next';
import '../../../i18n';

import * as S from './styled';

function LTVModalExplanation({
  open,
  handleClose,
  ltvDetailCalculation,
}) {
  const { t } = useTranslation();

  return (
    <S.LTVModalExplanation open={open}>
      <div className="modal">
        <div className="modal__header">
          <p className="modal__title">{t('ltv.understand_ltv_calculation')}</p>
          <div className="line" />
        </div>

        <div className="modal__body">
          <div className="modal__body__text">
            <p>
            {t('ltv.ltv_calculation_description')}
            </p>
            <p>
            {t('ltv.ltv_basic_formula_part1')} <strong>{t('ltv.ltv_basic_formula_part2')}</strong>{t('ltv.ltv_basic_formula_part3')} <strong>{t('ltv.ltv_basic_formula_part4')}</strong> {t('ltv.ltv_basic_formula_part5')}
            </p>
            <ul>
              <li>
                <strong>{t('ltv.approved_average_ticket_part1')}</strong>{t('ltv.approved_average_ticket_part2')}
              </li>
              <li>
                <strong>{t('ltv.average_purchase_frequency_part1')}</strong>{t('ltv.average_purchase_frequency_part2')}
              </li>
              <li>
                <strong>{t('ltv.average_relationship_duration_part1')}</strong>{t('ltv.average_relationship_duration_part2')}
              </li>
            </ul>
            <p>
            {t('ltv.ltv_formula_intro')}
            </p>
            <p>
            {t('ltv.ltv_formula_1_year_or_more')} 
            </p>
            <ul>
              <li>
              <strong>{t('ltv.ltv_formula')}</strong>
              </li>
            </ul>
            <p>
            {t('ltv.ltv_formula_less_than_1_year')}
            </p>
            <ul>
              <li>
                <strong>{t('ltv.ltv_formula_short_relationship')}</strong>
              </li>
            </ul>
            <p>
              {t('ltv.ltv_values_meaning')}
            </p>
            {ltvDetailCalculation && (
              <>
                <p>
                  {t('ltv.see_your_ltv_calculation')}
                </p>
                <ul>
                  <li className='ltvDetailCalculation'>
                    {ltvDetailCalculation}
                  </li>
                </ul>
              </>
            )}
          </div>
        </div>

        <div className="modal__footer">
          <button
            className="modal__form-btn is--cancel"
            type="button"
            onClick={handleClose}
          >
            {t('common.close')}
          </button>
        </div>
      </div>
    </S.LTVModalExplanation>
  );
}

export default LTVModalExplanation;
