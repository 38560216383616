import styled from 'styled-components';
import { darken } from 'polished';
import Select from 'react-select';

export const Container = styled.div`
  .header {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 16px;

    @media screen and (max-width: 784px) {        
      flex-direction: column;
    }

    .modal {
      &__form-group {
        width: 100%;
        display: flex;
        justify-content: right;
        align-items: center;

        @media screen and (max-width: 784px) {        
          width: 100%;
          justify-content: center;
          margin-top: 15px;
        }
      }
    }

    .css-2b097c-container {
      width: 40%;

      @media screen and (max-width: 980px) {        
        width: 50%;
      }

      @media screen and (max-width: 667px) {        
        width: 60%;
      }
    }

    .disable-button {
      background: #ccc;
      cursor: not-allowed;
    }

    .group {
      display: flex;
      flex-direction: row;
      align-items: center;

      .description {
        .title {
          color: #2e3039;          
          font-size: 20px;
          font-weight: 300
        }
      }
    }

    button {
      display: flex;
      align-items: center;
      color: #fff;
      transition: background-color 0.2s;
      border: none;
      box-shadow: 0 2px 20px rgba(255, 0, 104, 0.3);
      background-color: #ff0068;
      font-size: 13px;
      font-weight: 500;
      letter-spacing: -0.21px;
      border-radius: 50%;  
      width: 33px;
      height: 33px;       
      padding: 0;
      justify-content: center;
      margin-left: 16px;

      @media screen and (min-width: 1201px) {        
        justify-content: space-between;   
        padding: 0 15px;  
        width: 158px;
        border-radius: 25px;  
        right: 30px;
        height: 35px;       
      }


      &:hover {
        background-color: ${darken(0.05, '#ff0068')};
      }
    }
  }

  .workspace {
    .contentEmpty {
      display: flex;
      align-items: center;
      justify-content: center;
      height: 45vh;
      width: 100%;
      font-size: 16px;
      font-weight: 300;
      color: #2e3039;
    }
    .contentLoading {
      width: 100%;
      height: 45vh;
      display: flex;
      align-items: center;
    }
  }
`;

export const Option = styled(Select)`
  @media screen and (max-width: 1200px) {
    font-size: 16px;
  }

  color: #2e3039;
`;