import i18n from '../i18n';

import { LANGUAGE, CURRENCY_CODE } from './preference';

export const DIMENSIONS_OPTONS = {
  year: 'year',
  month: 'month',
  day: 'day'
}

export const GOOGLE_ANALYTICS_PREDICTION_CHANNELS_OPTIONS = {
  MEDIA: 'media',
  CRM: 'crm',
  GOOGLE_ADS: 'google_ads',
  FACEBOOK_ADS: 'facebook_ads',
  DIRECT: 'direct',
  SEO: 'seo',
  REFERRAL: 'referral'
}

export const GOOGLE_ANALYTICS_CAMPAIGN_REPORT_TYPE = {
  PAID_MEDIA: 'paid_media',
  ALL_CHANNELS: 'all_channels',
  EMAIL_MARKETING: 'email_marketing'
}

let languageOptions = [];
let currencyOptions = [];
let timezonesOptions = [];
let defaultChannelsOptions = [];
let paidMediaChannelsOptions = [];

const updateOptions = _ => {
  languageOptions = [
    {
      label: `${i18n.t('common.language')}`,
        options: [
          {
            value: LANGUAGE.pt_BR,
            label: i18n.t('preferences.portuguese_brazil')
          },
          {
            value: LANGUAGE.en_US,
            label: i18n.t('preferences.english')
          },
          {
            value: LANGUAGE.es,
            label: i18n.t('preferences.spanish')
          }
        ]
    }
  ];

  currencyOptions = [
    {
      label: `${i18n.t('common.currency')}`,
        options: CURRENCY_CODE.map(currency => {
          return {
            value: currency,
            label: currency
          }
        })
    }
  ];

  timezonesOptions = [
    {
      label: `${i18n.t('common.timezone')}`,
      options: [
        {
          value: 'Atlantic/Azores',
          label: 'GMT-1 (Atlantic/Azores)'
        },
        {
          value: 'Atlantic/South_Georgia',
          label: 'GMT-2 (Atlantic/South_Georgia)'
        },
        {
          value: 'America/Sao_Paulo',
          label: 'GMT-3 (America/Sao_Paulo)'
        },
        {
          value: 'America/La_Paz',
          label: 'GMT-4 (America/La_Paz)'
        },
        {
          value: 'America/New_York',
          label: 'GMT-5 (America/New_York)'
        },
        {
          value: 'America/Mexico_City',
          label: 'GMT-6 (America/Mexico_City)'
        },
        {
          value: 'America/Phoenix',
          label: 'GMT-7 (America/Phoenix)'
        },
        {
          value: 'America/Anchorage',
          label: 'GMT-8 (America/Anchorage)'
        },
        {
          value: 'Pacific/Gambier',
          label: 'GMT-9 (Pacific/Gambier)'
        },
        {
          value: 'Pacific/Tahiti',
          label: 'GMT-10 (Pacific/Tahiti)'
        },
        {
          value: 'Pacific/Midway',
          label: 'GMT-11 (Pacific/Midway)'
        },
        {
          value: 'Pacific/Wallis',
          label: 'GMT-12 (Pacific/Wallis)'
        },
        {
          value: 'Europe/London',
          label: 'GMT+0 (Europe/London)'
        },
        {
          value: 'Europe/Paris',
          label: 'GMT+1 (Europe/Paris)'
        },
        {
          value: 'Africa/Cairo',
          label: 'GMT+2 (Africa/Cairo)'
        },
        {
          value: 'Asia/Istanbul',
          label: 'GMT+3 (Asia/Istanbul)'
        },
        {
          value: 'Asia/Dubai',
          label: 'GMT+4 (Asia/Dubai)'
        },
        {
          value: 'Asia/Kolkata',
          label: 'GMT+5 (Asia/Kolkata)'
        },
        {
          value: 'Asia/Almaty',
          label: 'GMT+6 (Asia/Almaty)'
        },
        {
          value: 'Asia/Bangkok',
          label: 'GMT+7 (Asia/Bangkok)'
        },
        {
          value: 'Asia/Hong_Kong',
          label: 'GMT+8 (Asia/Hong_Kong)'
        },
        {
          value: 'Asia/Tokyo',
          label: 'GMT+9 (Asia/Tokyo)'
        },
        {
          value: 'Pacific/Honolulu',
          label: 'GMT+10 (Pacific/Honolulu)'
        },
        {
          value: 'Pacific/Apia',
          label: 'GMT+11 (Pacific/Apia)'
        },
        {
          value: 'Pacific/Fiji',
          label: 'GMT+12 (Pacific/Fiji)'
        }
      ]
    }
  ];

  defaultChannelsOptions = [
    {
      label: i18n.t('common.channel'),
      options: [
        {
          value: 'google_ads',
          label: 'Google Ads'
        },
        {
          value: 'facebook_ads',
          label: 'Facebook Ads'
        },
        {
          value: 'direct',
          label: i18n.t("common.direct")
        },
        {
          value: 'mail',
          label: i18n.t("common.email")
        },
        {
          value: 'seo',
          label: i18n.t("common.seo")
        },
        {
          value: 'referral',
          label: i18n.t("common.referral")
        },
        {
          value: 'other',
          label: i18n.t("common.other")
        }
      ]
    }
  ];

  paidMediaChannelsOptions = [
    {
      label: i18n.t('common.channel'),
      options: [
        {
          value: 'google_ads',
          label: 'Google Ads'
        },
        {
          value: 'facebook_ads',
          label: 'Facebook Ads'
        }
      ]
    }
  ];
}

i18n.on('languageChanged', (lng) => {
  updateOptions();
});

updateOptions();

export { languageOptions, currencyOptions, timezonesOptions, defaultChannelsOptions, paidMediaChannelsOptions };