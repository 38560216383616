import api from './api';

import i18n from '../i18n';

export const getInsights = async (page = 1) => {
  const token = localStorage.getItem('token');

  const response = await api.get(`/insight/?page=${page}`, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });

  if (!response || response.status !== 200) {
    throw new Error(i18n.t('error.insights_retrieve_failed'));
  }

  return response.data;
};

export const registerUserFeedback = async (insightId, like) => {
  const token = localStorage.getItem('token');

  const response = await api.post(
    `/insight/${insightId}/feedback?like=${like}`,
    null,
    {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    },
  );

  if (!response || response.status !== 200) {
    throw new Error(i18n.t('error.feedback_registration_failed'));
  }
}
