import React, { useEffect, useCallback, useState, useRef, useContext } from 'react';

import { toast } from 'react-toastify';

import { useTranslation } from 'react-i18next';
import '../../i18n'; 

import CardGroup from '../../components/CardGroup';
import BackgroundNew from '../../components/BackgroundNew';
import FilterContainer from '../../components/FilterContainer';
import ComposedGraphBarLine from '../../components/ComposedGraphBarLine';

import { DIMENSIONS_OPTONS} from '../../options/filterOptions';
import { getSelectedInitialDateRange, formatDate, getAgregatedKeyByDimension } from '../../utils/dateUtils';
import { formatValueToPercentage, formatValueToMoney, customRound } from '../../utils/metricsUtils';

import { 
    getPaymentDetailsMetrics, 
    getPaymentInstallmentMetrics, 
    sendPaymentDetailsReportByEmail
} from '../../services/dashboardApiService';

import GraphPie from '../../components/GraphPie';
import CustomTableApiPagination from '../../components/CustomTableApiPagination';
import { Container } from './styles';
import { AuthContext } from '../../contexts';


const PaymentOverview = () => {
    const { t } = useTranslation();
    const { timezone, currency } = useContext(AuthContext);

    const [dateRange, setDateRange] = useState();
    const [isLoading, setIsLoading] = useState(true);
    const [selectedDimension, setSelectedDimension] = useState(DIMENSIONS_OPTONS.day);

    const [marketplaceChannelsOptions, setMarketplaceChannelsOptions] = useState([]);
    const [selectedMarketplaceChannelsOptions, setSelectedMarketplaceChannelsOptions] = useState([]);

    const [affiliatesOptions, setAffiliatesOptions] = useState([]);
    const [selectedAffiliatesOptions, setSelectedAffiliatesOptions] = useState([]);

    const [paymentMethodsOptions, setPaymentMethodsOptions] = useState([]);
    const [formsOfPaymentOptions, setFormsOfPaymentOptions] = useState([]);
    const [originsOptions, setOriginsOptions] = useState([]);

    const [selectedFormsOfPaymentOptions, setSelectedFormsOfPaymentOptions] = useState([]);
    const [selectedPaymentMethodsOptions, setSelectedPaymentMethodsOptions] = useState([]);
    const [selectedOriginsOptions, setSelectedOriginsOptions] = useState([]);

    const [paymentDetailData, setPaymentDetailData] = useState(null);
    const [paymentInstallmentData, setPaymentInstallmentData] = useState ([]);

    const [evolutionDayPerFormsOfPaymentGraphFullScreen, setEvolutionDayPerFormsOfPaymentGraphFullScreen] = useState(false);
    const [evolutionOfApprovedRevenueShareByPaymentMethodGraphFullScreen, setEvolutionOfApprovedRevenueShareByPaymentMethodGraphFullScreen] = useState(false);
    const [percentageApprovedOdersPerPaymentMethodGraphFullScreen, setPercentageApprovedOdersPerPaymentMethodGraphFullScreen] = useState(false);
    const [percentageApprovedOrdersPerInstallmentGraphFullScreen, setPercentageApprovedOrdersPerinstallmentGraphFullScreen] = useState(false);
    const [paymentDetailTableFullScreen, setPaymentDetailTableFullScreen] = useState(false);

    const cardEvolutionOfRateByFormsOfPaymentRef = useRef(null);
    const cardEvolutionOfApprovedRevenueShareByPaymentMethodRef = useRef(null);
    const cardPercentageApprovedOrdersPerInstallmentRef = useRef(null);
    const cardPercentageApprovedOdersPerPaymentMethodRef = useRef(null);

    const [exportReportLoading, setExportReportLoading] = useState(false);

    const getDataByAppliedFilter = async () => {
        const filter = getActiveFilters()

        await getPaymentData(filter);
    };

    const getActiveFilters = () => {
        const [startDate, endDate] = dateRange;
        const paymentMethod = selectedPaymentMethodsOptions.length > 0 ? selectedPaymentMethodsOptions : null;
        const formsOfPayment = selectedFormsOfPaymentOptions.length > 0 ?selectedFormsOfPaymentOptions : null;
        const origins = selectedOriginsOptions.length > 0 ? selectedOriginsOptions : null;
        const vtexAffiliates = selectedAffiliatesOptions.length ? selectedAffiliatesOptions : null;
        const salesChannels = selectedMarketplaceChannelsOptions.length ? selectedMarketplaceChannelsOptions : null;

        const filter = {
            initialDate: formatDate(startDate),
            finalDate: formatDate(endDate),
            timezone,
            paymentMethod,
            origins,
            formsOfPayment,
            vtexAffiliates,
            salesChannels,
        }

        return filter;
    }

    const handleApplyFilter = async () => {
        getDataByAppliedFilter();
    }

    const getPaymentData = useCallback(async (filter) => {
        setIsLoading(true);

        try {
            const response = await Promise.all([

                getPaymentDetailsMetrics(filter),
                getPaymentInstallmentMetrics(filter)
            ])

            const paymentDetailsMetricsResponse =response[0];
            const paymentInstallmentMetricsResponse = response[1];

            if (!paymentDetailsMetricsResponse || !paymentInstallmentMetricsResponse) {
                setPaymentDetailData(null);
                setPaymentInstallmentData(null)
                setMarketplaceChannelsOptions([]);
                setAffiliatesOptions([]);
                setPaymentMethodsOptions([]);
                setFormsOfPaymentOptions([]);
                setOriginsOptions([]);

                return;
            }

            setPaymentInstallmentData(paymentInstallmentMetricsResponse.results)
            setPaymentDetailData(paymentDetailsMetricsResponse.results)

            setAffiliatesOptions(paymentDetailsMetricsResponse.available_vtex_affiliates_to_filter)
            setMarketplaceChannelsOptions(paymentDetailsMetricsResponse.available_marketplace_channels_to_filter)
            setPaymentMethodsOptions(paymentDetailsMetricsResponse.available_payment_methods_to_filter)
            setFormsOfPaymentOptions(paymentDetailsMetricsResponse.available_forms_of_payments_to_filter)
            setOriginsOptions(paymentDetailsMetricsResponse.available_origins_to_filter)

        } catch (error) {
            toast.error(error.message);
            setPaymentDetailData([])
            setPaymentInstallmentData([]);
            setAffiliatesOptions([]);
            setMarketplaceChannelsOptions([]);
            setPaymentMethodsOptions([]);
            setFormsOfPaymentOptions([]);
            setOriginsOptions([]);
        } finally {
          setIsLoading(false);
        }

    }, []);

    const generateFieldKey = (field, complementKey) => {
        return `${field}_${complementKey}`;
    }

    const getGraphConfigs = (fields, complementKey) => {
        if (!fields) { return []; }

        const configs = [];

        fields.forEach(field => {
            const fieldDataKey = generateFieldKey(field, complementKey);

            configs.push({
            name: field || " ",
            dataKey: fieldDataKey
            });
        })

        return configs;
    }
    
    const formatShareOfApprovedOrdersByPaymentMethodData = (data) => {
        if (!data)  { return null };

        const aggregatedData = {};

        data.forEach(entry => {
            const key = entry.payment_method; 
            if (!aggregatedData[key]) {
                aggregatedData[key] = {
                    paymentMethod: key,
                    totalApprovedOrders: 0,
                };
                aggregatedData[key].paymentMethod = key;
            }

            aggregatedData[key].totalApprovedOrders += entry.approved_orders;
        });

        const totalApprovedOrdersAllMethods = Object.values(aggregatedData)
            .reduce((sum, entry) => sum + entry.totalApprovedOrders, 0);

        Object.values(aggregatedData).forEach(entry => {
            entry.approvedRatePercentage = Number(((entry.totalApprovedOrders / totalApprovedOrdersAllMethods) * 100).toFixed(2));
        });

        return Object.values(aggregatedData).sort((a, b) => b.approvedRatePercentage - a.approvedRatePercentage);
    };
    const formatEvolutionOfRateByFormsOfPaymentData = (data, dimension) => {
        if (!data) { 
            return null; 
        }
    
        const aggregatedData = {};
        const fieldValues = new Set();
    
        data.forEach(entry => {
            const key = getAgregatedKeyByDimension(entry.date, dimension);
            const approvedRateKey = generateFieldKey(entry.payment_method, "approvalRate");
    
            fieldValues.add(entry.payment_method);
    
            if (!aggregatedData[key]) {
                aggregatedData[key] = { 
                    date: key,
                    totalApprovedOrders: 0,
                    totalCapturedOrders: 0,
                };
            }
    
            aggregatedData[key].totalApprovedOrders += entry.approved_orders;
            aggregatedData[key].totalCapturedOrders += entry.total_orders;
    
            if (aggregatedData[key].totalCapturedOrders > 0) {
                aggregatedData[key][approvedRateKey] = Number(
                    ((aggregatedData[key].totalApprovedOrders / aggregatedData[key].totalCapturedOrders) * 100).toFixed(2)
                );
            } else {
                aggregatedData[key][approvedRateKey] = 0.0;
            }

        });
            
        return Object.values(aggregatedData);
    };

    const formatEvolutionOfApprovedRevenueShareByPaymentMethodData = (data, dimension) => {
        if (!data) { return null };
    
        const aggregatedData = {};
        const formsOfPayments = new Set();
        let totalApprovedRevenueByDate = {};
    
        data.forEach(entry => {
            const key = getAgregatedKeyByDimension(entry.date, dimension);
    
            const revenueKey = generateFieldKey(entry.forms_of_payment, "approvedRevenue");
    
            formsOfPayments.add(entry.forms_of_payment);
    
            if (!aggregatedData[key]) {
                aggregatedData[key] = { 
                    date: key 
                };
            }
    
            if (!aggregatedData[key][revenueKey]) {
                aggregatedData[key][revenueKey] = 0.0;
            }
    
            aggregatedData[key][revenueKey] += entry.approved_revenue;
    
            if (!totalApprovedRevenueByDate[key]) {
                totalApprovedRevenueByDate[key] = 0.0;
            }
    
            totalApprovedRevenueByDate[key] += entry.approved_revenue;
        });
    
        Object.keys(aggregatedData).forEach(key => {
            let totalPercentage = 0;
            let lastFieldKey = null;
        
            formsOfPayments.forEach(formsOfPayment => {
                const revenueKey = generateFieldKey(formsOfPayment, "approvedRevenue");
                const revenuePercentageKey = generateFieldKey(formsOfPayment, "approvedRevenuePercentage");
        
                if (!aggregatedData[key][revenueKey]) {return};
        
                aggregatedData[key][revenuePercentageKey] = totalApprovedRevenueByDate[key] > 0 ? Number(((aggregatedData[key][revenueKey] / totalApprovedRevenueByDate[key]) * 100).toFixed(2)) : 0.0;
                aggregatedData[key][revenueKey] = Number((aggregatedData[key][revenueKey]).toFixed(2));

        
                totalPercentage += aggregatedData[key][revenuePercentageKey];
                lastFieldKey = revenuePercentageKey;
            });
        
            // Adjusting the last marketplace approved revenue percentage to be 100%
            if (totalPercentage !== 100) {
                const diff = 100 - totalPercentage;
                aggregatedData[key][lastFieldKey] += diff;
                aggregatedData[key][lastFieldKey] =  Number(aggregatedData[key][lastFieldKey]).toFixed(2);
            }
        });
    
        return Object.values(aggregatedData);
    };
    
    const formatShareOfApprovedOrdersByInstallmentData = (data = []) => {
        if (!data) { return null };
        
        const aggregatedData = [];

        data.forEach(entry => {
            aggregatedData.push({
                installment: entry.payment_installment + " X",
                share: entry.installment_percentage
            });
        });

        return aggregatedData.sort((a, b) => b.share - a.share);
    };

    const paymentDetailTableData = (data) => {
        if (!data)  { return null };

        const aggregatedData = {};
    
        data.forEach(item => {
            const paymentMethod = item.payment_method;
            const formsOfPayment = item.forms_of_payment;
    
            const key = `${paymentMethod}_${formsOfPayment}`;
    
            if (!aggregatedData[key]) {
                aggregatedData[key] = {
                    payment_method: paymentMethod,
                    forms_of_payment: formsOfPayment,
                    captured_revenue: 0,
                    approved_revenue: 0,
                    approved_orders: 0,
                    approved_avg_ticket: 0,
                    approval_rate: 0,
                    total_orders: 0
                };
            }
    
            aggregatedData[key].captured_revenue += item.captured_revenue;
            aggregatedData[key].approved_revenue += item.approved_revenue;
            aggregatedData[key].approved_orders += item.approved_orders;
            aggregatedData[key].total_orders += item.total_orders;
        });
    
        Object.values(aggregatedData).forEach(item => {
            item.approved_avg_ticket = item.approved_orders > 0 
                ? item.approved_revenue / item.approved_orders 
                : 0;
    
            item.approval_rate = item.total_orders > 0 
                ? customRound((item.approved_orders / item.total_orders) * 100 * 100) / 100
                : 0;
        });
    
        const aggregatedRows = Object.values(aggregatedData);
    
        return {
            headers: [
                { name: t('paymentOverview.payment_method'), value: "payment_method", minWidth: 100, format: (value) => value },
                { name: t('paymentOverview.forms_of_payment'), value: "forms_of_payment", minWidth: 100, format: (value) => value },
                { name: t('common.captured_revenue'), value: "captured_revenue", minWidth: 100, format: value => formatValueToMoney(value, currency)  },
                { name: t('common.approved_revenue'), value: "approved_revenue", minWidth: 100, format: value => formatValueToMoney(value, currency) },
                { name: t('common.approved_orders'), value: "approved_orders", minWidth: 100, format: (value) => value },
                { name: t('common.approved_average_ticket'), value: "approved_avg_ticket", minWidth: 100, format: value => formatValueToMoney(value, currency)  },
                { name: t('common.approval_rate'), value: "approval_rate", minWidth: 100, format: formatValueToPercentage},
            ],
            rows: aggregatedRows
        };
    };
    
    const handleExportReport = async () => {
        setExportReportLoading(true);

        try {
            toast.info(t('toast.wait_for_report'));

            const filter = getActiveFilters()

            await sendPaymentDetailsReportByEmail(filter);

            toast.success(t('toast.report_preparation'));
        } catch (error) {
            toast.error(t('toast.report_generation_failed'));
        } finally {
            setExportReportLoading(false);
        }
    };

    const getSecondaryFilters = () => {
        return [
            {
                title: `${t('common.source')}`,
                options: originsOptions,
                value: selectedOriginsOptions,
                setValue: setSelectedOriginsOptions
            },
            {
                title: `${t('common.sales_channel')}`,
                options: marketplaceChannelsOptions,
                value: selectedMarketplaceChannelsOptions,
                setValue: setSelectedMarketplaceChannelsOptions
            },
            {
                title: `${t('common.affiliates_vtex')}`,
                options: affiliatesOptions,
                value: selectedAffiliatesOptions,
                setValue: setSelectedAffiliatesOptions
            },
            {
                title:  `${t('paymentOverview.payment_method')}`,
                options: paymentMethodsOptions,
                value: selectedPaymentMethodsOptions,
                setValue: setSelectedPaymentMethodsOptions
            },
            {
                title: `${t('paymentOverview.forms_of_payment')}`,
                options: formsOfPaymentOptions,
                value: selectedFormsOfPaymentOptions,
                setValue: setSelectedFormsOfPaymentOptions
            },
        ]
      }

      const onRenderComponent = useCallback(async () => {
        const { initialDateFilter, finalDateFilter } = getSelectedInitialDateRange();
        setDateRange([initialDateFilter, finalDateFilter]);

        await getPaymentData({
            initialDate: formatDate(initialDateFilter),
            finalDate: formatDate(finalDateFilter),
            
        });
      }, [getPaymentData]);

      useEffect(() => {
        onRenderComponent();
      }, [onRenderComponent]);


    return (
        <BackgroundNew
          titlePage={t('paymentOverview.payment_overview')}
        >
            <FilterContainer
                selectedDateRange={dateRange}
                setDateRange={setDateRange}
                onClickFilter={handleApplyFilter}
                showSecondaryFilters={true}
                secondaryFilters={getSecondaryFilters()}
            />
            <CardGroup
                title={t('paymentOverview.evolution_of_approval_rate_by_payment_method')}
                tagIdentifierColor="#00CCAE"
                isLoading={isLoading}
                customClassName="cardEvolutionDayPerFormsOfPaymentRef"
                ref={cardEvolutionOfRateByFormsOfPaymentRef}
            >
                <ComposedGraphBarLine
                    data={paymentDetailData}
                    dimension={selectedDimension}
                    showMultiBar={true}
                    multiBarConfigs={getGraphConfigs(paymentMethodsOptions, "approvalRate")}
                    formatData={(data, dimension) => formatEvolutionOfRateByFormsOfPaymentData(data, dimension, 'payment_method')}
                    formaterBarValue={value =>formatValueToPercentage(value, currency)}
                    graphMarginConfig={{
                        top: 20,
                        right: -30,
                        left: 5,
                        bottom: 20
                    }}
                    showDimensionController={true}
                    setSelectedDimension={setSelectedDimension}
                    disabledDimensionController={!paymentDetailData?.length}
                    showFullScreenOption={true}
                    fullScreenEnabled={evolutionDayPerFormsOfPaymentGraphFullScreen}
                    setFullScreenEnabled={setEvolutionDayPerFormsOfPaymentGraphFullScreen}
                    domainBarYAxis={[0, 100]}
                    showExportPDFButton={true}
                    graphComponentRefForPDF={cardEvolutionOfRateByFormsOfPaymentRef}
                />
            </CardGroup>
            <CardGroup
                title={t('paymentOverview.evolution_of_approved_revenue_share_by_forms_of_payment')}
                tagIdentifierColor="#00CCAE"
                isLoading={isLoading}
                customClassName="cardEvolutionDayPerPaymentMethodRef"
                ref={cardEvolutionOfApprovedRevenueShareByPaymentMethodRef}
            >
                <ComposedGraphBarLine
                    data={paymentDetailData}
                    dimension={selectedDimension}
                    showMultiBarStacked={true}
                    multiBarStackedConfigs={getGraphConfigs(formsOfPaymentOptions, "approvedRevenuePercentage")}
                    formatData={(data, dimension) => formatEvolutionOfApprovedRevenueShareByPaymentMethodData(data, dimension, 'forms_of_payment')}
                    formaterBarValue={value =>formatValueToPercentage(value, currency)}
                    graphMarginConfig={{
                        top: 20,
                        right: -30,
                        left:0,
                        bottom: 20
                    }}
                    showDimensionController={true}
                    setSelectedDimension={setSelectedDimension}
                    disabledDimensionController={!paymentDetailData?.length}
                    showFullScreenOption={true}
                    domainBarYAxis={[0, 100]}
                    fullScreenEnabled={evolutionOfApprovedRevenueShareByPaymentMethodGraphFullScreen}
                    setFullScreenEnabled={setEvolutionOfApprovedRevenueShareByPaymentMethodGraphFullScreen}
                    showExportPDFButton={true}
                    graphComponentRefForPDF={cardEvolutionOfApprovedRevenueShareByPaymentMethodRef}
                />
            </CardGroup>
            <Container>
                <CardGroup
                    title={t('paymentOverview.share_of_approved_orders_by_payment_method')}
                    tagIdentifierColor="#00CCAE"
                    isLoading={isLoading}
                    customClassName="cardPercentageApprovedOdersPerPaymentMethodRef"
                    ref={cardPercentageApprovedOdersPerPaymentMethodRef}
                >
                    <GraphPie
                        data={paymentDetailData}
                        dimension={selectedDimension}
                        dataKeyLabel="paymentMethod"
                        dataKeyValue="approvedRatePercentage"
                        formatData={data => formatShareOfApprovedOrdersByPaymentMethodData(data, 'payment_method')}
                        formaterValue={value =>formatValueToPercentage(value, currency)}
                        graphMarginConfig={{
                        top: 20,
                        right: -30,
                        left: 10,
                        bottom: 20
                        }}
                        showDimensionController={true}
                        setSelectedDimension={setSelectedDimension}
                        disabledDimensionController={!paymentDetailData?.length}
                        showFullScreenOption={true}
                        fullScreenEnabled={percentageApprovedOdersPerPaymentMethodGraphFullScreen}
                        setFullScreenEnabled={setPercentageApprovedOdersPerPaymentMethodGraphFullScreen}
                        domainBarYAxis={[0, 100]}
                        showExportPDFButton={true}
                        graphComponentRefForPDF={cardPercentageApprovedOdersPerPaymentMethodRef}
                    />
                </CardGroup>
                <CardGroup
                    title={t('paymentOverview.share_of_approved_orders_by_installment')}
                    tagIdentifierColor="#00CCAE"
                    isLoading={isLoading}
                    customClassName="cardPercentageApprovedOrdersPerInstallmentRef"
                    ref={cardPercentageApprovedOrdersPerInstallmentRef}
                >
                    <GraphPie
                        data={paymentInstallmentData}
                        dataKeyLabel="installment"
                        dataKeyValue="share"
                        formatData={data => formatShareOfApprovedOrdersByInstallmentData(data)}
                        formaterValue={value =>formatValueToPercentage(value, currency)}
                        graphMarginConfig={{
                        top: 20,
                        right: -30,
                        left: 10,
                        bottom: 20
                        }}
                        showDimensionController={true}
                        showFullScreenOption={true}
                        fullScreenEnabled={percentageApprovedOrdersPerInstallmentGraphFullScreen}
                        setFullScreenEnabled={setPercentageApprovedOrdersPerinstallmentGraphFullScreen}
                        multiColor={true}
                        showExportPDFButton={true}
                        graphComponentRefForPDF={cardPercentageApprovedOrdersPerInstallmentRef}
                        disabledDimensionController={!paymentInstallmentData?.length}
                        forceMultiColor={true}
                    />
                </CardGroup>
            </Container>
            <Container>
                <CardGroup
                    title={t('paymentOverview.payment')}
                    tagIdentifierColor="#00CCAE"
                    isLoading={isLoading}
                >
                    <CustomTableApiPagination
                        data={paymentDetailTableData(paymentDetailData)}
                        disabledDimensionController={!paymentDetailData?.length}
                        showFullScreenOption
                        fullScreenEnabled={paymentDetailTableFullScreen}
                        setFullScreenEnabled={setPaymentDetailTableFullScreen}
                        handleExportCSVReport={handleExportReport}
                        showExportCSVReportButton
                        loadingExportCSVReportButton={exportReportLoading}
                        showPagination={false}
                    />
                </CardGroup>
            </Container>
        </BackgroundNew>
    );

}

export default PaymentOverview
