import React from 'react';

import { useTranslation } from 'react-i18next';
import './../../../i18n';

import {
    Option
} from '../styles';

import {
    MdRemoveCircleOutline
} from 'react-icons/md';

import { v4 as uuidv4 } from 'uuid';

const AlertFilter = ({
    filters,
    setFilters,
    filterCombinerOperator,
    setFilterCombinerOperator,
    modalType,
    filterOptions,
    findLabelObj,
    filterTitle
}) => {
    const { t } = useTranslation();

    const FIRST_MATCH_OPERATOR_INDEX = 1;

    const handleFilterAdd = (combinerOperator) => {
        const filtersToUse = filters;
        
        const newFilters = filtersToUse.concat({
          id: uuidv4(),
          option: '',
          condition: '',
          value: '',
          ...(filtersToUse.length > 0 && { match: combinerOperator }),
        });

        if (newFilters.length === 2) {
          setFilterCombinerOperator(filterOptions.defaultMatch);
        }
    
        setFilters(newFilters);
      };
    
      const handleFilterRemove = (filterId) => {
        const newFilters = filters.filter((item) => item.id !== filterId);
  
        if (newFilters.length) {
          delete newFilters[0].match;
        } else {
          setFilterCombinerOperator(null);
        }
  
        setFilters(newFilters);
      };
    
      const handleFilterChange = (position, prop, value) => {
        const newFilters = filters;
        newFilters[position][prop] = value;
        
        setFilters(newFilters);
      };
    
      const handleFilterCombinerOperatorChange = (position, prop, value) => {
        const newFilters = filters;
        newFilters[position][prop] = value;
        
        setFilters(newFilters);

        if (position === FIRST_MATCH_OPERATOR_INDEX) {
          setFilterCombinerOperator(value);
        }
      };

    return (
        <div
            className={`select ${
            modalType === 'edit' ? 'is--disabled' : ''
            }`}
        >
            <p>{filterTitle}</p>

            {filters.map((item, index) => (
            <div key={item.id}>
                {'match' in item && (
                <div className="form-group form-group--match">
                    <Option
                    className="filter-select filter-select--match"
                    placeholder={t('common.select')}
                    options={filterOptions.match}
                    defaultValue={findLabelObj(filterOptions.match, item.match)}
                    onChange={(ev) =>
                        handleFilterCombinerOperatorChange(
                        index,
                        'match',
                        ev ? ev.value : ''
                        )
                    }
                    isSearchable={false}
                    isDisabled={filters && filters[FIRST_MATCH_OPERATOR_INDEX].match && filters.length > 2}
                    />
                </div>
                )}

                <div className="form-group">
                <Option
                    className="filter-select"
                    placeholder={t('common.select')}
                    isClearable
                    options={filterOptions.options}
                    defaultValue={findLabelObj(filterOptions.options, item.option)}
                    onChange={(ev) =>
                    handleFilterChange(
                        index,
                        'option',
                        ev ? ev.value : ''
                    )
                    }
                />
                <Option
                    className="filter-select"
                    placeholder={t('common.select')}
                    isClearable
                    options={filterOptions.condition}
                    defaultValue={findLabelObj(
                    filterOptions.condition,
                    item.condition
                    )}
                    onChange={(ev) =>
                    handleFilterChange(
                        index,
                        'condition',
                        ev ? ev.value : ''
                    )
                    }
                />
                <input
                    type="text"
                    className="percent"
                    placeholder={t('goalsManager.value')}
                    defaultValue={item.value}
                    onChange={(ev) =>
                    handleFilterChange(index, 'value', ev.target.value)
                    }
                />
                <button
                    className="btn btn--remove-filter"
                    type="button"
                    onClick={() => handleFilterRemove(item.id)}
                >
                    <MdRemoveCircleOutline size={25} color="#ff3f44" />
                    Remover
                </button>
                </div>
            </div>
            ))}

            <button
            className="btn btn--add-filter"
            type="button"
            onClick={() => handleFilterAdd(filterCombinerOperator || filterOptions.defaultMatch)}
            >
            + {t('alerts.add_filter')}
            </button>
        </div>
    )
}


export default AlertFilter;