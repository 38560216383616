const currencyFormatter = require('currency-formatter');

export const getSymbolFromCurrency = require('currency-symbol-map');

export const formatValueToMoney = (value, currency) => {
  return currencyFormatter.format(value, { code: currency });
}

export const formatValueToNumber = (value) => {
  const formatter = new Intl.NumberFormat();

  return formatter.format(value);
}

export const formatValueToPercentage = (value) => {
  const formatter = new Intl.NumberFormat();

  return `${formatter.format(value)}%`;
}


export const getVariationValue = (value, goal) => {
  if (value === 0 && goal !== 0) {
    return -100;
  }

  if (value !== 0 && goal === 0) {
    return 100;
  }

  const variation = ((value / goal) - 1) * 100;

  return Number(variation.toFixed(2));
}

export const extractNumbersFromString = (input) => {
  const cleanedInput = input.replace(/[^0-9,]/g, '').replace(',', '.');

  return parseFloat(cleanedInput) || 0;
}

export const customRound = (value) => {
  return (value % 1 >= 0.5) ? Math.ceil(value) : Math.floor(value);
}
