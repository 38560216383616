import styled from 'styled-components';
import { Container } from '../GraphContainer/styles';

export const CustomGraphContainer = styled(Container)`
  .recharts-legend-wrapper {
    left: 0 !important;
    display: flex !important;
    align-items: center !important;
    justify-content: center !important;
    width: 100% !important;
  }
`;
