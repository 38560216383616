import styled from 'styled-components';
import { darken } from 'polished';
import { Container } from '../GraphContainer/styles';

export const TooltipContainer = styled.div`
  background-color: #ffffff;
  border: 1px solid #e5e5e5;
  border-radius: 5px;
  padding: 10px;
  font-size: 15px;
  color: #2e3039;
  font-weight: 400;

  h3 {
      font-size: 15px;
  }

  p {
      margin-top: 8px;
      .tooltipValue {
        font-weight: bold;
      }
  }
`;


export const CustomGraphContainer = styled(Container)`
    overflow: hidden;
`;

export const TagWrapperControllerPage = styled.button`
  padding: 6px 8px;
  margin-left: 10px;
  color: #fff;
  font-size: 10px;
  text-transform: uppercase;
  background: #ff0068;
  border-radius: 50px;
  border: none;

  &:hover {
    background-color: ${darken(0.05, '#ff0068')};
    border-color: ${darken(0.05, '#ff0068')};
    color: #fff;
  }
`;

export const TagContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin-right: 30px;
  margin-top: 20px;

  .disabled {
    background-color: #999;
    border-color: #999;
    color: #fff;
    cursor: not-allowed;
    box-shadow: none;

    &:hover {
        background-color: #999;
        border-color: #999;
        color: #fff;
    }
  }
`;

export const LegendContainer = styled.div`
    display: flex;
    flex-direction: column;
    position: relative;
    align-items: self-start;
    justify-content: flex-start;
    overflow-y: scroll;
    overflow-x: hidden;
    height: 35vh;

    /* width */
    ::-webkit-scrollbar {
      width: 4px;
      height: 0px;
    }

    /* Track */
    ::-webkit-scrollbar-track {
      box-shadow: #f1f1f1; 
      border-radius: 10px;
      width: 5px;
    }
    
    /* Handle */
    ::-webkit-scrollbar-thumb {
      background: #DEDEDE; 
      border-radius: 10px;
    }

    /* Handle on hover */
    ::-webkit-scrollbar-thumb:hover {
      background: #C8C8C8; 
    }

    .legendItem {
      display: flex;
      align-items: center;
      margin: 5px 0;

      .legendItemNameAndPercentContainer {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: center;

        .legendItemName {
          font-size: 12px;
          font-weight: 500;
          color: #6F767E;
          margin: 0;
          overflow: hidden;
        }

        .legendItemPercent {
          font-size: 20px;
          font-weight: 300;
          color: #272B30;
          line-height: 32px;
        }
      }
    }
`;