import React from 'react'
import * as S from './styles';
import { AiOutlineLoading3Quarters } from 'react-icons/ai'

const Loader = ({size = 60, color = "#ff0467", containerStyles = null }) => {
  return (
    <S.LaoderContainer style={containerStyles}>
      <AiOutlineLoading3Quarters size={size} color={color} />
    </S.LaoderContainer>
  )
};

export default Loader;
