import React, { Fragment, useState, useEffect, useCallback, useContext } from 'react';

import { useTranslation } from 'react-i18next';
import '../../i18n';

import { v4 as uuidv4 } from 'uuid';
import NumberFormat from 'react-number-format';
import AlertMenu from '../../components/Alert/AlertMenu';
import CircularProgress from '@mui/material/CircularProgress';
import {
  MdAdd,
  MdNotificationsActive,
  MdClose,
} from 'react-icons/md';
import { FaTrello, FaDesktop } from 'react-icons/fa';
import { ImArrowUp, ImArrowDown, ImCheckmark, ImCross, ImWarning } from 'react-icons/im';
import makeAnimated from 'react-select/animated';

import { toast } from 'react-toastify';
import {
  Container,
  OnOff,
  AlertModal,
  Option,
  CloseButton,
  Overlay,
} from './styles';
import BackgroundNew from '../../components/BackgroundNew';
import CustomModal from '../../components/CustomModal/CustomModal';
import {
  status,
  type,
  calculationMethods,
  getDataSource,
  getUsersActive,
  vtex_dimension,
  alertsPercentageMetrics,
  newDateRange
} from './options';
import { getList, createAlerts } from './services/getList';
import { updateAlert, deleteAlert } from './services/alert-data';

import * as FBAdsOptions from '../../options/FacebookAdsV2';
import * as GoogleAdsOptions from '../../options/GoogleAdsV2';
import * as VTEXOptions from '../../options/Vtex';
import * as GoogleAnalytics4Options from '../../options/GoogleAnalytics4';
import * as alertModuleSetting from '../../options/alertModuleSetting';

import api from '../../services/api';

import AlertFilter from './AlertFilter'
import Loader from '../../components/Loader';
import Grow from '@mui/material/Grow';

import { getSymbolFromCurrency } from '../../utils/metricsUtils';

import { AuthContext } from '../../contexts';

function Alerts() {
  const { t } = useTranslation();
  const { currency } = useContext(AuthContext);

  const [alerts, setAlerts] = useState([]);
  const [modalType, setModalType] = useState('');
  const [showOverlay, setShowOverlay] = useState(false);

  const [id, setId] = useState('');
  const [dataSource, setDataSource] = useState([]);
  const [dataSourceDefault, setDataSourceDefault] = useState();
  const [users, setUsers] = useState([]);
  const [usersDefault, setUsersDefault] = useState();
  const [name, setName] = useState('');
  const [description, setDescription] = useState('');

  const [variationType, setVariationType] = useState('');
  const [variationTypeDefault, setVariationTypeDefault] = useState();
  const [variationValue, setVariationValue] = useState('');
  const [variationValueType, setVariationValueType] = useState('');
  const [variationValueTypeDefault, setVariationValueTypeDefault] = useState();

  const [metric, setMetric] = useState('');
  const [metricDefault, setMetricDefault] = useState();
  const [metricLabel, setMetricLabel] = useState('');

  const [period, setPeriod] = useState('');
  const [periodDefault, setPeriodDefault] = useState('');
  const [showPeriod, setShowPeriod] = useState(false);
  const [customPeriodValue, setCustomPeriodValue] = useState('');
  const [showCustomPeriod, setShowCustomPeriod] = useState(false);

  const [filters, setFilters] = useState([]);
  const [metricsFilter, setMetricsFilter] = useState([]);
  const [metricsFilterCombinerOperator, setMetricsFilterCombinerOperator] = useState(null);
  const [dimensionsFilter, setDimensionsFilter] = useState([]);
  const [dimensionsFilterCombinerOperator, setDimensionsFilterCombinerOperator] = useState(null);

  const [module, setModule] = useState(null);
  const [calculationMethod, setCalculationMethod] = useState('');
  const [calculationMethodDefault, setCalculationMethodDefault] = useState('');

  const [isTrelloActive, setIsTrelloActive] = useState(true);
  const [isWebPushActive, setIsWebPushActive] = useState(true);
  const [userList, setUserList] = useState([]);

  const [alertTypes, setAlertTypes] = useState([]);
  const [alertType, setAlertType] = useState('');
  const [alertTypeDefault, setAlertTypeDefault] = useState('');

  const [vtexStockSkuValue, setVtexStockSkuValue] = useState('');
  const [vtexStockIdValue, setVtexStockIdValue] = useState('');

  const [openModalInformation, setOpenModalInformation] = useState(false);

  const [loading, setLoading] = useState(true);

  const alertModuleFilterAddtionalConfigDictonary = alertModuleSetting.alertModuleFilterAddtionalConfigDictonary

  const alertModuleOptionsDictonary = alertModuleSetting.alertModuleOptionsDictonary

  const defaultCalculationMethod = "total"

  const defaultVariationValueType = "absolute"

  const getData = useCallback(async () => {
    setLoading(true);

    const availableDataSource = await getDataSource();
    const availableUsers = await getUsersActive();
    const listAlerts = await getList();

    setDataSource(availableDataSource);
    setUsers(availableUsers);
    setAlertTypes([
      {"label": t('alerts.every_three_hours'), "value": "same_day"},
      {"label": t('alerts.daily'), "value": "daily"},
    ]);
    setAlerts(listAlerts);
    setLoading(false);
  }, [t]);

  useEffect(() => {
    getData();
  }, [getData]);

  const alertModalIsOpen = (modalType) => modalType && modalType !== 'delete'

  const findLabelObj = (optionsArray, optionId) => {
    if (Array.isArray(optionId)) {
      return optionsArray.filter((item) => optionId.indexOf(item.value) !== -1);
    }

    if ('options' in optionsArray[0]) {
      let newLabelObj = [];
      for (let i = 0, arrLength = optionsArray.length; i < arrLength; i++) {
        const labelObj = optionsArray[i].options.filter(
          (item) => item.value === optionId
        );
        if (labelObj.length) {
          newLabelObj = labelObj;
          break;
        }
      }
      return newLabelObj;
    }

    return optionsArray.filter((item) => item.value === optionId);
  };

  const transformStringToObj = (str, strType) => {
    const obj = {};

    if (str && strType) {
      if (strType === 'period') {
        const value = str.match(/(\d+)/g);
        obj.id = value ? str.replace(value, 'x') : str;
        obj.value = value ? value[0] : null;
        return obj;
      }
    }

    return false;
  };

  const getAlertFiltersToShow = (alertDataInputFilters) => {
    const regex_to_extract_operator = /([=!<>@]+@?)/gm;

    const alertDataOutputFilters = alertDataInputFilters.filters.map((item, index) => {
      const filter_values =  item.split(regex_to_extract_operator)

      const option = filter_values[0]
      const condition = filter_values[1]
      const value = filter_values[2]

      return {
        id: uuidv4(),
        option: option,
        condition: condition,
        value: value,
        ...(index !== 0 && { match: alertDataInputFilters.filter_combiner_operator }),
      };
    })

    return alertDataOutputFilters;
  }

  const getAlertModule = (alertInfo) => {
    let alertModule = '';

    Object.keys(alertInfo.alert_data).forEach((item) => {
      if (alertInfo.alert_data[item]) {
        alertModule = item;
      }
    });

    return alertModule;
  };

  const getAlertIcon = (alert) => {
    const { variation, last_result, variation_value } = alert;
    const notRunned = last_result === null;

    if (notRunned) {
      return (<span className="dash">-</span>)
    }

    if (alert.alert_data.VTEX) {
      if (alert.alert_data.VTEX.dimension === 'positive') {
        return (last_result >= variation_value ? <ImCheckmark size={16} /> : <ImCross size={12} />)
      } else {
        return (last_result <= variation_value ? <ImWarning size={16} /> : <ImCheckmark size={12} />)
      }
    }

    if (variation !== 'reach' && variation !== 'dont_reach') {
      return (last_result > 0 ? <ImArrowUp size={16} /> : <ImArrowDown size={16} />)
    }

    return (last_result > variation_value ? <ImCheckmark size={16} /> : <ImCross size={12} />)
  };

  const generateDescriptionText = (alertInfo) => {
    const alertModule = getAlertModule(alertInfo);
    const calculationMethodText = findLabelObj(calculationMethods, alertInfo.calculation_method)[0].label.toLowerCase();
    const alertPeriod = transformStringToObj(alertInfo.alert_data[alertModule].compared_to, 'period');
    const dateRangeToUse = newDateRange
    const alertPeriodLabelObj = findLabelObj(dateRangeToUse, alertPeriod.id)[0] ? findLabelObj(dateRangeToUse, alertPeriod.id)[0] : '';
    const alertPeriodText = alertPeriodLabelObj.label ? `${alertPeriodLabelObj.prefix} ${alertPeriodLabelObj.label.replace('X', alertPeriod.value)}`.toLowerCase() : '';

    if (alertInfo.variation === 'reach' || alertInfo.variation === 'dont_reach') {
      return (
        <Fragment>
          {findLabelObj(status, alertInfo.variation)[0].label} <strong>
            <NumberFormat
              displayType="text"
              thousandSeparator="."
              decimalSeparator=","
              prefix={getValuePrefix(alertInfo)}
              suffix={alertInfo.variation_value_type === 'percent' ? '%' : ''}
              value={alertInfo.variation_value}
            />
          </strong>
        </Fragment>
      )
    }

    return (
      <Fragment>
        {findLabelObj(status, alertInfo.variation)[0].label} <strong>
          <NumberFormat
            displayType="text"
            thousandSeparator="."
            decimalSeparator=","
            prefix={getValuePrefix(alertInfo)}
            suffix={alertInfo.variation_value_type === 'percent' ? '%' : ''}
            value={alertInfo.variation_value}
          />
        </strong> {t('alerts.in_relation_to')} {calculationMethodText} {alertPeriodText}
      </Fragment>
    )
  };

  const forceDecimalScale = (alertInfo, type) => {
    const alertModule = getAlertModule(alertInfo);
    const alertMetric = alertInfo.alert_data[alertModule].metric;

    if (alertMetric.toLowerCase().indexOf('revenue') !== -1 || alertMetric.toLowerCase().indexOf('price') !== -1) {
      if (type === 'result') {
        return true;
      }
    }

    return false;
  };

  const getValuePrefix = (alertInfo, type) => {
    const alertModule = getAlertModule(alertInfo);
    const alertMetric = alertInfo.alert_data[alertModule].metric;

    if (alertMetric.toLowerCase().indexOf('revenue') !== -1 || alertMetric.toLowerCase().indexOf('price') !== -1
    || alertMetric.toLowerCase().indexOf('sale') !== -1 || alertMetric.toLowerCase().indexOf('discount') !== -1
    || alertMetric.toLowerCase().indexOf('ship') !== -1 || alertMetric.toLowerCase().indexOf('return') !== -1
    || alertMetric.toLowerCase().indexOf('tax') !== -1) {
      if (type === 'result') {
        return getSymbolFromCurrency(currency);
      } else {
        if (alertInfo.variation === 'reach' || alertInfo.variation === 'dont_reach') {
          return getSymbolFromCurrency(currency);
        }
      }
    }
    return '';
  };

  const getValueSuffix = (alertInfo, type) => {
    const alertModule = getAlertModule(alertInfo);
    const alertMetric = alertInfo.alert_data[alertModule].metric;

    if (type === 'result') {
      if (alertMetric.toLowerCase().indexOf('rate') !== -1 || alertMetric.toLowerCase().indexOf('perc') !== -1) {
        return '%';
      }
    } else {
      if (alertInfo.variation_value_type === 'percent') {
        return '%';
      }
    }

    return '';
  };

  const getResultValue = (alertInfo, value) => {
    const alertModule = getAlertModule(alertInfo);
    const alertMetric = alertInfo.alert_data[alertModule].metric;

    if (alertsPercentageMetrics.includes(alertMetric)) {
      return value * 100;
    }

    return value;
  };

  const validateAlertTrigger = ({ last_shot }) => {
    if (!last_shot) {
      return false;
    }

    const today = new Date();
    const lastShotDate = new Date(last_shot);

    const todayString = today.toISOString().slice(0, 10);
    const lastShotDateString = lastShotDate.toISOString().slice(0, 10);

    if (todayString === lastShotDateString) {
      return true;
    }

    return false;
  };

  const validateFilter = (filterObj) => {
    const filterValues = Object.values(filterObj);
    let isValid = true;

    filterValues.forEach((item) => {
      if (!item.length) {
        isValid = false;
      }
    });

    return isValid;
  };

  const handleClose = () => setModalType('');

  const handleResetAlertMetricAndFilters = () => {
    setModule(null);
    setDataSourceDefault('');
    setFilters([]);
    setVariationType('');
    setShowPeriod(false);
    setVariationValue('');
    setVtexStockSkuValue('');
    setVtexStockIdValue('');
    setMetric('');
    setMetricLabel('');
    setShowCustomPeriod(false);
    setCustomPeriodValue('');
    setVariationValueTypeDefault('');
    setVariationTypeDefault('');
    setVariationValueType('');
    setDataSourceDefault(null);
    setMetricsFilter([]);
    setDimensionsFilter([]);
    setMetricsFilterCombinerOperator(null);
    setDimensionsFilterCombinerOperator(null);
    setPeriod('')
    setPeriodDefault('')
    setCustomPeriodValue('')
    setCalculationMethod('')
    setCalculationMethodDefault('')
  }

  const animatedComponents = makeAnimated();

  const handleDataSource = (select, action) => {
    handleResetAlertMetricAndFilters();
    setModule(select.value);

    if (action === 'edit') {
      setDataSourceDefault(select);
    }
  };

  const handleMetric = (select, action) => {
    setMetric(select.value);
    setMetricLabel(select.label);

    if (action === 'edit') {
      setMetricDefault(select);
    }
  };

  const handleVariationType = (select, action) => {
    const { value } = select;
    setVariationType(value);

    if (action === 'edit') {
      setVariationTypeDefault(select);
    }

    if (value !== 'reach' && value !== 'dont_reach') {
      setShowPeriod(true);
      setVariationValueType('');
    } else {
      setShowPeriod(false);
      setVariationValueType('absolute');
    }
  };

  const handleVariationValueType = (select, action) => {
    if (action === 'edit' || action === 'duplicate') {
      setVariationValueTypeDefault(select);
    }

    setVariationValueType(select.value);

    if (action === 'duplicate' && select.length > 0) {
      setVariationValueType(select[0].value);
    }
  };

  const handleCalculationMethod = (select, action) => {
    if (action === 'edit') {
      setCalculationMethodDefault(select);
    }

    setCalculationMethod(select.value);

    if (action === 'duplicate' && select.length > 0) {
      setCalculationMethodDefault(select);
      setCalculationMethod(select[0].value);
    }
  };

  const handlePeriod = (select, action) => {
    if (action === 'edit') {
      setPeriodDefault(select);
    }

    const newCustomPeriodOptions = [
      'last_x_days_until_yesterday',
      'last_x_days_until_today',
      'last_x_weeks',
      'last_x_months',
      'last_x_months_until_today',
      'last_x_years',
      'last_x_years_until_today'
    ]
    
    if (newCustomPeriodOptions.indexOf(select.value) !== -1) {
      setShowCustomPeriod(true);
    } else {
      setShowCustomPeriod(false);
    }

    setPeriod(select.value);
  };

  const handleToggleTrello = () => {
    setIsTrelloActive(!isTrelloActive);
  };

  const handleToggleWebPush = () => {
    setIsWebPushActive(!isWebPushActive);
  };

  const handleAlertType = (select, action) => {
    setAlertType(select.value);
    if (action === 'edit' || action === 'duplicate') {
      setAlertTypeDefault(select);
    }

    if (action === 'duplicate' && select.length > 0) {
      setAlertType(select[0].value);
    }
  };

  async function handleUsers(value, { action, removedValue }) {
    // eslint-disable-next-line default-case
    switch (action) {
      case 'remove-value':
      case 'pop-value':
        if (removedValue.isFixed) {
          return;
        }
        break;
      case 'clear':
        value = userList.filter((v) => v.isFixed);
        break;
    }

    const data = value || [];

    const response = data.map((dt) => {
      return dt.value;
    });

    setUserList(response);
  }

  const handleStatus = async (idAlert, statusAlert) => {
    const token = await localStorage.getItem('token');

    const data = {
      status: statusAlert,
    };

    await api.put(`/alerts/${idAlert}`, data, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });

    getData();
  };

  const resetAlertData = () => {
    setName('');
    setDescription('');
    setDataSourceDefault(false);
    setVariationTypeDefault(false);
    setVariationValue('');
    setVariationValueType('');
    setVariationValueTypeDefault(false);
    setShowPeriod(false);
    handleMetric('', 'edit');
    setFilters([]);
    setUsersDefault(false);
    setIsTrelloActive(true);
    setAlertTypeDefault(false);
    setVtexStockSkuValue('');
    setVtexStockIdValue('');
    setModule(null);
    setUserList([]);

    handleResetAlertMetricAndFilters();
  };

  const handleShow = (alertInfo, alertAction) => {
    setModalType(alertAction);

    if (alertInfo) {
      let alertModule = '';

      Object.keys(alertInfo.alert_data).forEach((item) => {
        if (alertInfo.alert_data[item]) {
          alertModule = item;
        }
      });

      if (alertModule) {
        let lookupMetric = [];

        switch (alertModule) {
          case alertModuleSetting.types.FA:
            lookupMetric = FBAdsOptions.metrics;
            break;
          case alertModuleSetting.types.AW:
            lookupMetric = GoogleAdsOptions.metrics;
            break;
          case alertModuleSetting.types.VTEX:
            lookupMetric = VTEXOptions.metrics;
            break;
          case alertModuleSetting.types.GAWA:
              lookupMetric = GoogleAnalytics4Options.metrics;
              break;
          default:
            return false;
        }

        const alertDataSource = findLabelObj(
          dataSource,
          alertModule
        )[0];

        const alertType = findLabelObj(
          alertTypes,
          alertInfo.alert_type ? alertInfo.alert_type : 'daily'
        );

        const alertMetric = findLabelObj(
          lookupMetric,
          alertInfo.alert_data[alertDataSource.value].metric
        )[0];

        const alertVariationType = findLabelObj(status, alertInfo.variation)[0];
        const alertVariationValueType = findLabelObj(
          type,
          alertInfo.variation_value_type
        );
        const alertCalculationMethod = findLabelObj(
          calculationMethods,
          alertInfo.calculation_method
        );

        const alertPeriodObj = transformStringToObj(
          alertInfo.alert_data[alertDataSource.value].compared_to,
          'period'
        );

        handleDataSource(alertDataSource, 'edit');

        if (alertPeriodObj) {
          const dateRangeToUse = newDateRange;
          const alertPeriod = findLabelObj(dateRangeToUse, alertPeriodObj.id)[0];
          handlePeriod(alertPeriod, 'edit');
          setCustomPeriodValue(alertPeriodObj.value);
        }

        const alertUsers = findLabelObj(users, alertInfo.channels[0].user_list);
        const trello = alertInfo.channels.filter((item) => item.media === 'settings_trello');
        const webPush = alertInfo.channels.filter((item) => item.media === 'web_token');

        if (alertModule === alertModuleSetting.types.VTEX) {
          let filterValue = alertInfo.alert_data[alertDataSource.value].filters
            ? JSON.parse(alertInfo.alert_data[alertDataSource.value].filters[0])
            : '';
          
          setVtexStockSkuValue(filterValue.sku);
          setVtexStockIdValue(filterValue.stockId);
          setFilters([]);
        } else {
          const alertDataMetricsFilter = alertInfo.alert_data[alertDataSource.value].metrics_filters;
          const alertDataDimensionsFilter = alertInfo.alert_data[alertDataSource.value].dimensions_filters;

          if (alertDataMetricsFilter) {
            const alertDataMetricsFilterObj = getAlertFiltersToShow(alertDataMetricsFilter);
            setMetricsFilter(alertDataMetricsFilterObj || []);
            setMetricsFilterCombinerOperator(alertDataMetricsFilter.filter_combiner_operator);
          }

          if (alertDataDimensionsFilter) {
            const alertDataDimensionsFilterObj = getAlertFiltersToShow(alertDataDimensionsFilter);
            setDimensionsFilter(alertDataDimensionsFilterObj || []);
            setDimensionsFilterCombinerOperator(alertDataDimensionsFilter.filter_combiner_operator);
          }

        }

        setIsTrelloActive(trello.length > 0);
        setIsWebPushActive(webPush.length > 0);
        setUserList(alertInfo.channels[0].user_list || []);
        setUsersDefault(alertUsers);
        setId(alertInfo.id);
        setName(alertAction === 'duplicate' ? `${alertInfo.name} - Cópia` : alertInfo.name);
        setDescription(alertInfo.description);
        handleMetric(alertMetric, 'edit');
        handleAlertType(alertType, alertAction);
        handleVariationType(alertVariationType, 'edit');
        setVariationValue(alertInfo.variation_value);
        handleVariationValueType(alertVariationValueType, alertAction);
        handleCalculationMethod(alertCalculationMethod, alertAction);
      }
    } else {
      resetAlertData();
    }
  };

  const handleEdit = async () => {
    let hasError = false;
    const data = {
      name,
      description,
      channels: [
        {
          media: 'app_token',
          users: userList,
        },
      ],
    };

    if(!name) {
      hasError = true;
      toast.error(t('toast.alert_name_required'));
    }

    if (!userList.length) {
      hasError = true;
      toast.error(t('toast.select_user'));
    }

    if (isTrelloActive) {
      data.channels.push({
        media: 'settings_trello',
      });
    }

    if (isWebPushActive) {
      data.channels.push({
        media: 'web_token',
        users: userList,
      });
    }

    if (!hasError) {
      await updateAlert(id, data);
      toast.success(t('toast.alert_updated_success'));
      handleClose();
      getData();
    }
  };

  const handleDelete = async () => {
    setShowOverlay(true);
    handleClose();
    await deleteAlert(id);
    await getData();
    toast.success(t('toast.alert_deleted_success'));
    setShowOverlay(false);
  };

  const getFilterAdditionalConfigForAlertModule = (alertModule) => {
    if (alertModule === alertModuleSetting.types.AW) {
      if (dimensionsFilter.length) {
        const AWAdditionalConfig = alertModuleFilterAddtionalConfigDictonary[alertModule];

        const first_option_filter = dimensionsFilter[0].option;
        return AWAdditionalConfig.get(first_option_filter);
      } else {
        const AWAdditionalConfig = alertModuleFilterAddtionalConfigDictonary[alertModule];
        return AWAdditionalConfig.default;
      }
    } else {
      return alertModuleFilterAddtionalConfigDictonary[alertModule];
    }
  }

  const getFilters = (inputFilters, filterCombinerOperator) => {
    const filtersCombinated = inputFilters.map(item => {
      return `${item.option}${item.condition}${item.value}`
    })
     return {
      filters: filtersCombinated,
      filter_combiner_operator: filterCombinerOperator
     }
  }

  const isValidFilters = (inputfilters, filterCombinerOperator) => {
    let isValid = true;
    inputfilters.forEach(filter => {
      if (!filter.option || !filter.condition || !filter.value) {
        isValid = false;
      }
    })

    if (inputfilters.length > 1 && !filterCombinerOperator) {
      isValid = false;
    }

    return isValid;
  }

  const handleCreate = async () => {
    let customPeriod = "";
    let queryFilter;
    let hasError = false;
    let version = 2;

    if(!name) {
      hasError = true;
      toast.error(t('toast.alert_name_required'));
    }

    if(!module) {
      hasError = true;
      toast.error(t('toast.select_data_source'));
    }

    if(!metric) {
      hasError = true;
      toast.error(t('toast.select_metric'));
    }

    if (showPeriod) {
      if (!calculationMethod) {
        toast.error(t('toast.select_calculation_method'));
        hasError = true;
      }

      if (!period) {
        toast.error(t('toast.select_comparison_period'));
        hasError = true;
      }

      if (showCustomPeriod) {
        if (customPeriodValue) {
          customPeriod = period.replace('x', customPeriodValue);
        } else {
          toast.error(t('toast.fill_custom_comparison_value'));
          hasError = true;
        }
      }
    }

    if (filters.length) {
      queryFilter = filters
        .map((item) => {
          if (validateFilter(item)) {
            return `${item.match || ''}${item.option}${item.condition}${
              item.value
            }`;
          }
          if (!hasError) {
            hasError = true;
            toast.error(
              t('toast.check_filter_values')
            );
          }
          return '';
        })
        .join('');
    }

    if (metricsFilter.length && !isValidFilters(metricsFilter, metricsFilterCombinerOperator)) {
      hasError = true;
      toast.error(t('toast.check_metric_filter_values'))
    }

    if (dimensionsFilter.length && !isValidFilters(dimensionsFilter, dimensionsFilterCombinerOperator)) {
      hasError = true;
      toast.error(t('toast.check_dimension_filter_values'))
    }

    if (!userList.length) {
      hasError = true;
      toast.error(t('toast.select_user'));
    }

    if (variationValue === '') {
      hasError = true;
      toast.error(t('toast.provide_variation_value'));
    }

    if (!variationValueType) {
      hasError = true;
      toast.error(t('toast.select_variation_type'));
    }

    if(!alertType) {
      hasError = true;
      toast.error(t('toast.select_alert_type'));
    }

    if (module === alertModuleSetting.types.VTEX) {
      if (vtexStockSkuValue !== '') {
        queryFilter = JSON.stringify({sku: vtexStockSkuValue, stockId: vtexStockIdValue});
      }
      else {
        hasError = true;
        toast.error(t('toast.provide_product_sku'));
      }
    } 

    const dimension = module !== alertModuleSetting.types.VTEX ? alertModuleOptionsDictonary[module].dimension : vtex_dimension[metric];
    const compared_from = module !== alertModuleSetting.types.VTEX ? alertModuleOptionsDictonary[module].defaultComparedFrom : "";
    const calculation_method = module !== alertModuleSetting.types.VTEX ? calculationMethod : alertModuleOptionsDictonary[module].calculationMethod;

    const metricsFilterToRequest = metricsFilter.length ? getFilters(metricsFilter, metricsFilterCombinerOperator) : null;
    const dimensionsFilterToRequest = dimensionsFilter.length ? getFilters(dimensionsFilter, dimensionsFilterCombinerOperator) : null;
    const filterAdditionalConfig = getFilterAdditionalConfigForAlertModule(module);

    const data = {
      name,
      description,
      alert_data: {
        [module]: {
          metric,
          metric_label: metricLabel,
          filters: [queryFilter || ''],
          metrics_filters: metricsFilterToRequest,
          dimensions_filters: dimensionsFilterToRequest,
          additional_config: filterAdditionalConfig,
          dimension: dimension,
          compared_from: compared_from,
          compared_to: customPeriod || period,
          version: version
        },
      },
      channels: [
        {
          media: 'app_token',
          users: userList,
        },
      ],
      variation: variationType,
      variation_value: variationValue,
      calculation_method: calculation_method || defaultCalculationMethod,
      status: true,
      alert_type: alertType,
      variation_value_type: defaultVariationValueType
    };

    if (!hasError && module !== alertModuleSetting.types.VTEX) {
      if (variationValueType) {
        data.variation_value_type = variationValueType;
      } else {
        data.variation_value_type = variationValueTypeDefault[0].value;
      }
    }

    if (isTrelloActive) {
      data.channels.push({
        media: "settings_trello",
      });
    }

    if (isWebPushActive) {
      data.channels.push({
        media: 'web_token',
        users: userList,
      });
    }

    if (!hasError) {
      await createAlerts(data);
      toast.success(t('toast.alert_created_success'));
      handleClose();
      getData();
    }
  };

  return (
    <BackgroundNew 
      titlePage={t('menu.alerts')}
      subTitlePage={t('alerts.control_your_alerts')}
      showInformation={true}
      informationTooltipTitle={t('common.click_to_learn_more')}
      informationOnClick={() => setOpenModalInformation(true)}
    >
      <Container>
        <div className="header">
          <button type="button" onClick={() => handleShow(null, 'new')}>
            {t('alerts.create_alert')}
            <MdAdd size={22} />
          </button>
        </div>
        <div className="workspace">
          {!loading && alerts && alerts.length > 0 && (
            <ul>
              {alerts.map((alert) => {
                const alertModule = getAlertModule(alert);

                if (!alertModuleSetting.types[alertModule]) {
                  return null;
                }

                return (
                  <Grow
                    in
                    style={{ transformOrigin: '0 0 0' }}
                    timeout={1000}
                  >
                    <li key={alert.id}>
                      <div className={`card ${validateAlertTrigger(alert) ? 'is--highlight' : ''}`}>
                        <div className="alerts">
                          <div className="card__header">
                            <OnOff
                              checked={alert.status === true}
                              onChange={() => handleStatus(alert.id, !alert.status)}
                            />
                            <AlertMenu alert={alert} show={handleShow} />
                          </div>
                          <button type="button" onClick={() => handleShow(alert, 'edit')}>
                            <p className="card__title">{alert.name}</p>
                            <p className="card__description">
                              {generateDescriptionText(alert)}
                            </p>
                          </button>
                          <div className="card__footer">
                            <div className="card__result is--previous">
                              <span className="card__result-value">
                                <NumberFormat
                                  displayType="text"
                                  thousandSeparator="."
                                  decimalSeparator=","
                                  decimalScale="2"
                                  fixedDecimalScale={forceDecimalScale(alert, 'result')}
                                  prefix={getValuePrefix(alert, 'result')}
                                  suffix={getValueSuffix(alert, 'result')}
                                  value={alert.variation === 'reach' || alert.variation === 'dont_reach' ? "" : getResultValue(alert, alert.last_value)}
                                />
                              </span>
                              <span className="card__result-text">{t('alerts.result')}</span>
                            </div>
                            <div className="card__result is--current">
                              <NumberFormat
                                displayType="text"
                                thousandSeparator="."
                                decimalSeparator=","
                                prefix={getValuePrefix(alert, 'variation')}
                                suffix={getValueSuffix(alert, 'variation')}
                                value={alert.last_result}
                              />
                              {getAlertIcon(alert)}
                            </div>
                          </div>
                        </div>
                      </div>
                    </li>
                  </Grow>
                )
              })}
            </ul>
          )}
          {loading && (
            <div className="loading">
              <Loader/>
            </div>
          )}
          {!loading && alerts.length === 0 && (
            <div className="noAlertsFound">
              <p>Você ainda não possui alertas cadastrados.</p>
            </div>
          )}
        </div>
      </Container>

      <AlertModal open={alertModalIsOpen(modalType)} onClose={handleClose}>
        <div className="modal">
          <div className="modal__header">
            <div className="modal__header-inner-wrapper">
              <p className="titleModal">
                {modalType === 'edit' ? t('alerts.edit_alert') : t('alerts.create_alert')}
              </p>
              <CloseButton
                type="button"
                title={t('common.close')}
                onClick={handleClose}
              >
                <MdClose size={25} color="#000" />
              </CloseButton>
            </div>
            <div className="line" />
          </div>

          <div className="modal__body">
            <div className="form">
              <div className="select">
                <p>{t('alerts.new_alert_information')}</p>
                <input
                  type="text"
                  className="percent"
                  placeholder={t('alerts.alert_name')}
                  value={name}
                  onChange={(v) => setName(v.target.value)}
                />
                <input
                  type="text"
                  className="percent"
                  placeholder={t('alerts.alert_description')}
                  value={description}
                  onChange={(v) => setDescription(v.target.value)}
                />
              </div>
              <div
                className={`select ${
                  modalType === 'edit' ? 'is--disabled' : ''
                }`}
              >
                <p>{t('alerts.data_source')}</p>
                <Option
                  placeholder={t('common.select')}
                  options={dataSource}
                  defaultValue={dataSourceDefault}
                  onChange={handleDataSource}
                />
              </div>

              <div
                className={`select ${
                  modalType === 'edit' ? 'is--disabled' : ''
                }`}
              >
                <p>{t('alerts.alert_metric')}</p>
                <Option
                  placeholder={t('common.select')}
                  options={module ? alertModuleOptionsDictonary[module].metrics : []}
                  defaultValue={metricDefault}
                  onChange={handleMetric}
                />

                <div className="form-group form-group--no-label">
                  {module !== alertModuleSetting.types.VTEX && (
                    <Option
                      className="filter-select"
                      placeholder={t('common.select')}
                      options={status}
                      defaultValue={variationTypeDefault}
                      onChange={handleVariationType}
                    />
                  )}

                  {module === alertModuleSetting.types.VTEX && metric !== '' && (
                    <NumberFormat
                      allowNegative={false}
                      className="percent"
                      placeholder={t('alerts.product_sku')}
                      value={vtexStockSkuValue}
                      onChange={(v) => setVtexStockSkuValue(v.target.value)}
                    />
                  )}

                  {module === alertModuleSetting.types.VTEX && metric !== '' && (
                    <input
                      type="text"
                      className="percent"
                      placeholder={t('alerts.stock_id')}
                      value={vtexStockIdValue}
                      onChange={(v) => setVtexStockIdValue(v.target.value)}
                    />
                  )}

                  {module === alertModuleSetting.types.VTEX && (
                    <Option
                      className="filter-select"
                      placeholder={t('common.select')}
                      options={[status[3]]}
                      defaultValue={variationTypeDefault}
                      onChange={handleVariationType}
                    />
                  )}

                  <NumberFormat
                    allowNegative={false}
                    allowLeadingZeros={true}
                    decimalScale={2}
                    className="percent"
                    placeholder={t('alerts.enter_quantity')}
                    value={variationValue}
                    onChange={(v) => setVariationValue(v.target.value)}
                  />

                  {showPeriod && (
                    <Option
                      className="filter-select"
                      placeholder={t('common.select')}
                      options={type}
                      defaultValue={variationValueTypeDefault}
                      onChange={handleVariationValueType}
                    />
                  )}
                </div>
              </div>

              {showPeriod && (
                <div
                  className={`select ${
                    modalType === 'edit' ? 'is--disabled' : ''
                  }`}
                >
                  <p>{t('alerts.in_relation_to')}</p>
                  <Option
                    placeholder={t('common.select')}
                    options={calculationMethods}
                    defaultValue={calculationMethodDefault}
                    onChange={handleCalculationMethod}
                  />

                  <p className="yesterday">De</p>
                  <Option
                    placeholder={t('common.select')}
                    options={newDateRange}
                    defaultValue={periodDefault}
                    onChange={handlePeriod}
                  />
                  {showCustomPeriod && (
                    <div class="form-group is--custom-period">
                      <NumberFormat
                        className="percent"
                        placeholder={t('goalsManager.value')}
                        allowNegative={false}
                        value={customPeriodValue}
                        onChange={(ev) => setCustomPeriodValue(ev.target.value)}
                      />
                    </div>
                  )}
                </div>
              )}

              {module && module !== alertModuleSetting.types.VTEX && (
                <AlertFilter
                  filters={dimensionsFilter}
                  setFilters={setDimensionsFilter}
                  filterCombinerOperator={dimensionsFilterCombinerOperator}
                  setFilterCombinerOperator={setDimensionsFilterCombinerOperator}
                  modalType={modalType}
                  filterOptions={alertModuleOptionsDictonary[module].dimensionsFilter}
                  findLabelObj={findLabelObj}
                  filterTitle={t('alerts.dimension_filters')}
                />
              )}

              {module && module !== alertModuleSetting.types.VTEX && (
                <AlertFilter
                  filters={metricsFilter}
                  setFilters={setMetricsFilter}
                  filterCombinerOperator={metricsFilterCombinerOperator}
                  setFilterCombinerOperator={setMetricsFilterCombinerOperator}
                  modalType={modalType}
                  filterOptions={alertModuleOptionsDictonary[module].metricsFilter}
                  findLabelObj={findLabelObj}
                  filterTitle={t('alerts.metrics_filters')}
                />
              )}

              <div className={`select ${
                  modalType === 'edit' ? 'is--disabled' : ''
                }`}

              >
                <p>{t('alerts.periodicity')}</p>

                <div className="form-group">
                  <Option
                    className="filter-select"
                    placeholder={t('common.select')}
                    components={animatedComponents}
                    options={alertTypes}
                    defaultValue={alertTypeDefault}
                    onChange={handleAlertType}
                  />
                </div>
              </div>
              <div className="select">
                <p>{t('alerts.channels')}</p>

                <div className="channels">
                  <button type="button">
                    <MdNotificationsActive size={30} />
                    Push
                  </button>
                  <button
                    className={isTrelloActive ? '' : 'is--disabled'}
                    type="button"
                    onClick={handleToggleTrello}
                  >
                    <FaTrello size={30} />
                    Trello
                  </button>

                  <button
                    className={isWebPushActive ? '' : 'is--disabled'}
                    type="button"
                    onClick={handleToggleWebPush}
                  >
                    <FaDesktop size={30} />
                    Web
                  </button>
                </div>

                <p className="yesterday">{t('alerts.contacts')}</p>
                <Option
                  placeholder={t('common.select')}
                  closeMenuOnSelect={false}
                  components={animatedComponents}
                  isMulti
                  options={users}
                  defaultValue={usersDefault}
                  onChange={handleUsers}
                />
              </div>

            </div>
          </div>

          <div className="modal__footer">
            <button
              className="btn btn--cancel"
              type="button"
              onClick={handleClose}
            >
              {t('common.cancel')}
            </button>
            <button
              className="btn btn--save"
              type="button"
              onClick={modalType === 'edit' ? handleEdit : handleCreate}
            >
              {t('common.save')}
            </button>
          </div>
        </div>
      </AlertModal>

      <AlertModal open={modalType === 'delete'} onClose={handleClose}>
        <div className={`modal is--${modalType}`}>
          <div className="modal__header">
            <div className="modal__header-inner-wrapper">
              <p className="titleModal">
                {t('alerts.delete_alert')}
              </p>
              <CloseButton
                type="button"
                title={t('common.close')}
                onClick={handleClose}
              >
                <MdClose size={25} color="#000" />
              </CloseButton>
            </div>
            <div className="line" />
          </div>
          <div className="modal__body">
            <p>
              {t('alerts.are_you_sure_delete_alert')} <br />
              <strong>{t('common.this_action_cannot_be_undone')}</strong>
            </p>
          </div>
          <div className="modal__footer">
            <button
              className="btn btn--cancel"
              type="button"
              onClick={handleClose}
            >
              {t('common.cancel')}
            </button>
            <button
              className="btn btn--save"
              type="button"
              onClick={handleDelete}
            >
              {t('common.confirm')}
            </button>
          </div>
        </div>
      </AlertModal>

      <Overlay className={showOverlay ? 'is--active' : ''} open={showOverlay}>
        <CircularProgress color={'inherit'} />
      </Overlay>
      <CustomModal
        title={t('alerts.understand_alerts')}
        open={openModalInformation} 
        handleClose={() => setOpenModalInformation(false)}
        hiddenConfirmButton={true}
        cancelButtonLabel={t('common.close')}
      >
        <div className="video">
          <iframe width="100%" height="315" src="https://www.youtube.com/embed/tOCWKm1i9AM?si=fF2Jg-EgKLQiuLUd" title="YouTube video player" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture;" allowFullScreen></iframe>
        </div>
      </CustomModal>
    </BackgroundNew>
  );
}

export default Alerts;
