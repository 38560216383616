import React from 'react';

import {
    Bar, Line, XAxis, YAxis, CartesianGrid, Tooltip,
    Legend, ResponsiveContainer, ComposedChart
} from 'recharts';

import GraphCustomTooltip from '../GraphCustomTooltip';
import GraphController from '../GraphController';

import { abbreviateText } from '../../utils/textUtils';
import { generateDynamicColors } from '../../utils/colorUtils';

import { CustomGraphContainer } from './styles';

const ComposedGraphBarLine = ({
    data,
    formatData,
    dimension,
    formaterBarValue,
    formaterLineValue,
    showPrimaryBarYAxis,
    showPrimaryLineYAxis = false,
    showSecondaryBarYAxis = false,
    showSecondaryLineYAxis = false,
    showMultiBarStacked = false,
    showMultiLine = false,
    showDimensionController = false,
    setSelectedDimension,
    disabledDimensionController,
    primaryBarYAxisConfig,
    primaryLineYAxisConfig,
    secondaryBarYAxisConfig,
    secondaryLineYAxisConfig,
    multiBarStackedConfigs,
    multiLineConfigs,
    secondaryBarIsStacked = false,
    graphMarginConfig,
    domainLineYAxis,
    domainBarYAxis,
    height = '40vh',
    showFullScreenOption = false,
    fullScreenEnabled = false,
    setFullScreenEnabled,
    showValuePercentage = false,
    xDataKey = "date",
    hiddenLegend = false,
    graphComponentRefForPDF,
    showExportPDFButton,
    hideDateOnXAxis = false,
    disable,
    showMultiBar=false,
    multiBarConfigs
}) => {
    const MAX_LEGEND_NAME_LENGTH = 25;

    const colors = generateDynamicColors(
        multiBarStackedConfigs
            ? multiBarStackedConfigs.length
            : multiLineConfigs
                ? multiLineConfigs.length
                : multiBarConfigs
                    ? multiBarConfigs.length
                    : 0
    );

    const renderGraph = (data, dimension) => {
        const formattedData = formatData(data, dimension);

        return (
            <ResponsiveContainer height="100%">
                <ComposedChart
                    data={formattedData}
                    margin={{
                        top: graphMarginConfig.top,
                        right: graphMarginConfig.right,
                        left: graphMarginConfig.left,
                        bottom: graphMarginConfig.bottom
                    }}
                    >
                    <CartesianGrid strokeDasharray="3 3"/>
                    <XAxis dataKey={xDataKey} hide={hideDateOnXAxis}/>
                    <YAxis yAxisId="bar" orientation="left" tickFormatter={formaterBarValue} domain={domainBarYAxis}/>
                    <YAxis yAxisId="line" orientation="right" domain={domainLineYAxis} tickFormatter={formaterLineValue} />
                    <Tooltip content={GraphCustomTooltip} showPercentage={showValuePercentage}/>
                    {showMultiBarStacked && multiBarStackedConfigs.map((config, index) => (
                        <Bar
                            key={`${index}-bar`}
                            name={config.name}
                            dataKey={config.dataKey}
                            stackId="a"
                            fill={colors[index]}
                            formatter={formaterBarValue}
                            yAxisId="bar"
                            radius={[3, 3, 3, 3]}
                        />
                    ))}
                    {showMultiBar && multiBarConfigs.map((config, index) => (
                        <Bar
                            key={`${index}-bar`}
                            name={config.name}
                            dataKey={config.dataKey}
                            fill={colors[index]}
                            formatter={formaterBarValue}
                            yAxisId="bar"
                            radius={[3, 3, 3, 3]}
                            minPointSize={1}
                            barSize={30}
                        />
                    ))}
                    {showMultiLine && multiLineConfigs.map((config, index) => (
                        <Line
                            key={`${index}-line`}
                            name={config.name}
                            dataKey={config.dataKey}
                            type="monotone"
                            stroke={colors[index]}
                            yAxisId="line"
                            strokeWidth={3}
                            formatter={formaterLineValue}
                        />
                    ))}
                    {showPrimaryBarYAxis && (
                        <Bar
                            name={primaryBarYAxisConfig.name}
                            dataKey={primaryBarYAxisConfig.dataKey}
                            stackId="a"
                            fill="rgba(0, 155, 208, 0.4)"
                            formatter={formaterBarValue}
                            yAxisId="bar"
                            radius={[4, 4, 0, 0]}
                        />
                    )}
                    {showSecondaryBarYAxis && (
                        <Bar
                            name={secondaryBarYAxisConfig.name}
                            dataKey={secondaryBarYAxisConfig.dataKey}
                            stackId={secondaryBarIsStacked ? "a" : "b"}
                            fill="rgba(255, 0, 104, 0.5)"
                            formatter={formaterBarValue}
                            yAxisId="bar"
                            radius={[4, 4, 0, 0]}
                        />
                    )}
                    {showPrimaryLineYAxis && (
                        <Line
                            name={primaryLineYAxisConfig.name}
                            dataKey={primaryLineYAxisConfig.dataKey}
                            type="monotone"
                            stroke="#00CCAE"
                            yAxisId="line"
                            strokeWidth={3}
                            stackId="c"
                            formatter={formaterLineValue}
                        />
                    )}
                    {showSecondaryLineYAxis && (
                        <Line
                            name={secondaryLineYAxisConfig.name}
                            dataKey={secondaryLineYAxisConfig.dataKey}
                            type="monotone"
                            stroke="#FF0068"
                            yAxisId="line"
                            strokeWidth={3}
                            stackId="d"
                            formatter={formaterLineValue}
                        />
                    )}
                    {!hiddenLegend && <Legend verticalAlign="bottom" formatter={value => abbreviateText(value, MAX_LEGEND_NAME_LENGTH)}/>}
                </ComposedChart>
            </ResponsiveContainer>
        );
    };

    const stylesComponents = {
        mainContainerFullScreen: {
            position: 'fixed',
            top: 0,
            left: 0,
            width: '100%',
            height: '100vh',
            backgroundColor: 'white',
            zIndex: 9999,
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center'
        },
        graphContainerFullScreen: {
            height: '80vh'
        }
    }

    return (
        <div style={fullScreenEnabled ? stylesComponents.mainContainerFullScreen : {}}>
            <GraphController
                showDimensionController={showDimensionController}
                selectedDimension={dimension}
                setSelectedDimension={setSelectedDimension}
                disabled={disabledDimensionController}
                showFullScreenOption={showFullScreenOption}
                fullScreenEnabled={fullScreenEnabled}
                setFullScreenEnabled={setFullScreenEnabled}
                graphComponentRefForPDF={graphComponentRefForPDF}
                showExportPDFButton={showExportPDFButton}
                disable={disable}
                hideDateOnXAxis={hideDateOnXAxis}
            />
            {fullScreenEnabled && (
                <CustomGraphContainer
                    style={stylesComponents.graphContainerFullScreen}
                >
                    {renderGraph(data, dimension)}
                </CustomGraphContainer>
            )}
            {!fullScreenEnabled && (
                <CustomGraphContainer
                    style={{height: height}}
                >
                    {renderGraph(data, dimension)}
                </CustomGraphContainer>
            )}
        </div>
    )

}

export default ComposedGraphBarLine;
