import React, { useCallback, useEffect, useRef, useState, useContext } from 'react';

import { toast } from 'react-toastify';

import { useTranslation } from 'react-i18next';
import '../../i18n';

import BackgroundNew from '../../components/BackgroundNew';
import FilterContainer from '../../components/FilterContainer';
import CardGroup from '../../components/CardGroup';
import ComposedGraphBarLine from '../../components/ComposedGraphBarLine';
import CardIndicator from '../../components/CardIndicator';
import GraphPie from '../../components/GraphPie';

import { Container } from './styles';

import { DIMENSIONS_OPTONS } from '../../options/filterOptions';
import {
  formatDate,
  getAgregatedKeyByDimension,
  getSelectedInitialDateRange
} from '../../utils/dateUtils';
import { formatValueToMoney, formatValueToNumber, formatValueToPercentage } from '../../utils/metricsUtils';
import { isValidAvailableFilter } from '../../utils/validationUtils';

import { getPlatformOverviewGroupByOriginAndChannel } from '../../services/dashboardApiService';

import { AuthContext } from '../../contexts';

const OperationOverview = () => {
  const { t } = useTranslation();
  const { timezone, currency } = useContext(AuthContext);

  const [dateRange, setDateRange] = useState();
  const [isLoading, setIsLoading] = useState(true);
  const [marketplaceChannelsOptions, setMarketplaceChannelsOptions] = useState([]);
  const [selectedMarketplaceChannelsOptions, setSelectedMarketplaceChannelsOptions] = useState([]);
  const [selectedOriginsOptions, setSelectedOriginsOptions] = useState([]);
  const [affiliatesOptions, setAffiliatesOptions] = useState([]);
  const [selectedAffiliatesOptions, setSelectedAffiliatesOptions] = useState([]);
  const [statesOptions, setStatesOptions] = useState([]);
  const [originsOptions, setOriginsOptions] = useState([]);
  const [selectedStatesOptions, setSelectedStatesOptions] = useState([]);
  const [selectedDimension, setSelectedDimension] = useState(DIMENSIONS_OPTONS.day);
  const [operationData, setOperationData] = useState(null);
  const [bigNumbersData, setBigNumbersData] = useState(null);

  const [evolutionApprovedRevenueSharePerChannelGraphFullScreen, setEvolutionApprovedRevenueSharePerChannelGraphFullScreen] = useState(false);
  const [approvedRevenueByApprovedOrdersPerChannelGraphFullScreen, setApprovedRevenueByApprovedOrdersPerChannelGraphFullScreen] = useState(false);
  const [approvedRevenueSharePerChannelGraphFullScreen, setApprovedRevenueSharePerChannelGraphFullScreen] = useState(false);
  const [evolutionApprovedRevenueSharePerOriginGraphFullScreen, setEvolutionApprovedRevenueSharePerOriginGraphFullScreen] = useState(false);
  const [approvedRevenueByApprovedOrdersPerOriginGraphFullScreen, setApprovedRevenueByApprovedOrdersPerOriginGraphFullScreen] = useState(false);
  const [approvedRevenueSharePerOriginGraphFullScreen, setApprovedRevenueSharePerOriginGraphFullScreen] = useState(false);

  const [visibleChannels, setVisibleMarketplaceChannels] = useState([]);

  const cardApprovedRevenueAndApprovedOrdersPerOriginRef = useRef()
  const cardEvolutionApprovedRevenueSharePerOriginRef = useRef()
  const cardApprovedRevenueSharePerOriginRef = useRef()

  const cardApprovedRevenueByApprovedOrdersPerChannelRef = useRef()
  const cardEvolutionApprovedRevenueSharePerChannelRef = useRef()
  const cardApprovedRevenueSharePerChannelRef = useRef()

  const handleApplyFilter = async () => {
    const [startDate, endDate] = dateRange;
    const states = selectedStatesOptions.length ? selectedStatesOptions : null;
    const marketplaceChannels = selectedMarketplaceChannelsOptions.length ? selectedMarketplaceChannelsOptions : null;
    const origins = selectedOriginsOptions.length ? selectedOriginsOptions : null;
    const vtexAffiliates = selectedAffiliatesOptions.length ? selectedAffiliatesOptions : null;

    await getPlatformOverviewOriginAndChannelDataMetrics(
      formatDate(startDate),
      formatDate(endDate),
      states,
      marketplaceChannels,
      origins,
      vtexAffiliates
    );
  };

  const getPlatformOverviewOriginAndChannelDataMetrics = useCallback(async (
    initialDate,
    finalDate,
    states,
    marketplaceChannels,
    origins,
    vtexAffiliates
  ) => {
    setIsLoading(true);

    try {
      const response = await getPlatformOverviewGroupByOriginAndChannel({
        initialDate,
        finalDate,
        timezone,
        states,
        marketplaceChannels,
        origins,
        vtexAffiliates
      });

      if (!response) {
        setOperationData(null);
        setBigNumbersData(null);
        setStatesOptions([]);
        setMarketplaceChannelsOptions([]);
        setOriginsOptions([]);
        setAffiliatesOptions([]);
        setVisibleMarketplaceChannels([]);

        return;
      }

      setOperationData(response.results)
      setBigNumbersData(response.big_numbers)
      setStatesOptions(response.available_states_to_filter)
      setMarketplaceChannelsOptions(response.available_marketplace_channel_to_filter)
      setOriginsOptions(response.available_origins_to_filter)
      setVisibleMarketplaceChannels(getVisibleMarketplaceChannels(response.results))

      if (isValidAvailableFilter(response.available_affiliates_to_filter)) {
        setAffiliatesOptions(response.available_affiliates_to_filter)
      } else {
        setAffiliatesOptions([])
      }
    } catch (error) {
      toast.error(error.message);
      setOperationData(null);
      setBigNumbersData(null);
      setStatesOptions([])
      setMarketplaceChannelsOptions([])
      setAffiliatesOptions([])
      setVisibleMarketplaceChannels([])
    } finally {
      setIsLoading(false);
    }
  }, [timezone]);

  const getVisibleMarketplaceChannels = (data) => {
    if (!data) { return []; }

    const visibleMarketplaceChannels = [];

    data.forEach(entry => {
      if (!visibleMarketplaceChannels.includes(entry.marketplace_channel)) {
        visibleMarketplaceChannels.push(entry.marketplace_channel);
      }
    });

    return visibleMarketplaceChannels;
  }

  const generateFieldKey = (field, complementKey) => {
    return `${field}_${complementKey}`;
  }

  const getGraphConfigs = (fields, complementKey) => {
    if (!fields) { return []; }

    const configs = [];

    fields.forEach(field => {
      const fieldDataKey = generateFieldKey(field, complementKey);

      configs.push({
        name: field || " ",
        dataKey: fieldDataKey
      });
    })

    return configs;
  }

  const formatDataByField = (data, field) => {
    if (!data) { return null }

    const aggregatedData = {};

    data.forEach(entry => {
      const key = entry[field];
      if (!aggregatedData[key]) {
        aggregatedData[key] = {
          approvedRevenue: 0.0,
        };
        aggregatedData[key][field] = key;
      }

      aggregatedData[key].approvedRevenue += entry.approved_revenue;
    });

    Object.values(aggregatedData).forEach(entry => {
      entry.approvedRevenue = Number((entry.approvedRevenue).toFixed(2));
    });

    return Object.values(aggregatedData).sort((a, b) => b.approvedRevenue - a.approvedRevenue);
  };

  const formatDataByDateAndField = (data, dimension, field) => {
    if(!data) { return null}

    const aggregatedData = {};

    const fieldValues = new Set();

    let totalRevenueByKey = {};

    data.forEach(entry => {
      const key = getAgregatedKeyByDimension(entry.date, dimension);

      const fieldApprovedRevenueKey = generateFieldKey(entry[field], "approvedRevenue");
      const fieldApprovedOrdersKey = generateFieldKey(entry[field], "approvedOrders");
      const fieldApprovedRevenuePercentageKey = generateFieldKey(entry[field], "approvedRevenuePercentage");

      fieldValues.add(entry[field]);

      if (!aggregatedData[key]) {
        aggregatedData[key] = {
          date: key
        };
      }

      if (!aggregatedData[key][fieldApprovedRevenueKey]) {
        aggregatedData[key][fieldApprovedRevenueKey] = 0.0;
        aggregatedData[key][fieldApprovedOrdersKey] = 0;
        aggregatedData[key][fieldApprovedRevenuePercentageKey] = 0.0;
      }

      aggregatedData[key][fieldApprovedRevenueKey] += entry.approved_revenue;
      aggregatedData[key][fieldApprovedOrdersKey] += entry.approved_orders;

      if (!totalRevenueByKey[key]) {
        totalRevenueByKey[key] = 0.0;
      }

      totalRevenueByKey[key] += entry.approved_revenue;
    });

    Object.keys(aggregatedData).forEach(key => {
      let fieldApprovedRevenuePercentagePreviousTotal = 0;
      let fieldApprovedRevenuePercentageLastKey = null;

      fieldValues.forEach(fieldValue => {
        const fieldApprovedRevenueKey = generateFieldKey(fieldValue, "approvedRevenue");
        const fieldApprovedRevenuePercentageKey = generateFieldKey(fieldValue, "approvedRevenuePercentage");

        if (!aggregatedData[key][fieldApprovedRevenueKey]) { return }


        aggregatedData[key][fieldApprovedRevenuePercentageKey] = totalRevenueByKey[key] > 0 ? Number(((aggregatedData[key][fieldApprovedRevenueKey] / totalRevenueByKey[key]) * 100).toFixed(2)) : 0.0;
        aggregatedData[key][fieldApprovedRevenueKey] = Number((aggregatedData[key][fieldApprovedRevenueKey]).toFixed(2));

        fieldApprovedRevenuePercentagePreviousTotal += aggregatedData[key][fieldApprovedRevenuePercentageKey];
        fieldApprovedRevenuePercentageLastKey = fieldApprovedRevenuePercentageKey;
      });

      // Adjusting the last marketplace approved revenue percentage to be 100%
      if (fieldApprovedRevenuePercentagePreviousTotal !== 100) {
        const diff = 100 - (fieldApprovedRevenuePercentagePreviousTotal);
        aggregatedData[key][fieldApprovedRevenuePercentageLastKey] += diff;
        aggregatedData[key][fieldApprovedRevenuePercentageLastKey] = Number((aggregatedData[key][fieldApprovedRevenuePercentageLastKey]).toFixed(2));
      }
    })

    return Object.values(aggregatedData);
  };

  const getCardIndicatorData = (data) => {
    return {
      keys: ["approved_revenue", "approved_orders", "approved_avg_ticket","approval_rate" ,"canceled_orders"],
      titles: {
        approved_revenue: t('common.approved_revenue'),
        approved_orders: t('common.approved_orders'),
        approved_avg_ticket: t('common.approved_average_ticket'),
        approval_rate: t('common.approval_rate'),
        canceled_orders: t('common.canceled_orders')
      },
      types: {
        approved_revenue: 'currency',
        approved_orders: 'decimal',
        approved_avg_ticket: 'currency',
        approval_rate: 'percent',
        canceled_orders: 'decimal'
      },
      displayFlexSpaceOcupation: {
        approved_revenue: 1,
        approved_orders: 1,
        approved_avg_ticket: 1,
        approval_rate: 1,
        canceled_orders: 1
      },
      maxHeights: {
        approved_revenue: 200,
        approved_orders: 200,
        approved_avg_ticket: 200,
        approval_rate: 200,
        canceled_orders: 200
      },
      minWidths: {
        approved_revenue: 225,
        approved_orders: 225,
        approved_avg_ticket: 225,
        approval_rate: 255,
        canceled_orders: 225
      },
      comparativeValues: {
        approved_revenue: {
          previous_year: data ? data.approved_revenue.comparative_previous_year_in_percentage : 0,
          previous_period: data ? data.approved_revenue.comparative_previous_period_in_percentage : 0
        },
        approved_orders: {
          previous_year: data ? data.approved_orders.comparative_previous_year_in_percentage : 0,
          previous_period: data ? data.approved_orders.comparative_previous_period_in_percentage : 0
        },
        approved_avg_ticket: {
          previous_year: data ? data.approved_avg_ticket.comparative_previous_year_in_percentage : 0,
          previous_period: data ? data.approved_avg_ticket.comparative_previous_period_in_percentage : 0
        },
        approval_rate: {
          previous_year: data ? data.approval_rate.comparative_previous_year_in_percentage : 0,
          previous_period: data ? data.approval_rate.comparative_previous_period_in_percentage : 0
        },
        canceled_orders: {
          previous_year: data ? data.canceled_orders.comparative_previous_year_in_percentage : 0,
          previous_period: data ? data.canceled_orders.comparative_previous_period_in_percentage : 0
        }
      },
      data: {
        approved_revenue: data ? data.approved_revenue.total_value : 0,
        approved_orders: data ? data.approved_orders.total_value : 0,
        approved_avg_ticket: data ? data.approved_avg_ticket.total_value : 0,
        approval_rate: data ? data.approval_rate.total_value: 0,
        canceled_orders: data ? data.canceled_orders.total_value : 0
      }
    }
  }

  const getSecondaryFilters = () => {
    return [
      {
        title: `${t('common.source')}`,
        options: originsOptions,
        value: selectedOriginsOptions,
        setValue: setSelectedOriginsOptions
      },
      {
        title: `${t('common.sales_channel')}`,
        options: marketplaceChannelsOptions,
        value: selectedMarketplaceChannelsOptions,
        setValue: setSelectedMarketplaceChannelsOptions
      },
      {
        title: `${t('common.affiliates_vtex')}`,
        options: affiliatesOptions,
        value: selectedAffiliatesOptions,
        setValue: setSelectedAffiliatesOptions
      },
      {
        title: `${t('common.states')}`,
        options: statesOptions,
        value: selectedStatesOptions,
        setValue: setSelectedStatesOptions
      }
    ]
  }

  const onRenderComponent = useCallback(async () => {
    const { initialDateFilter, finalDateFilter } = getSelectedInitialDateRange();
    setDateRange([initialDateFilter, finalDateFilter]);

    await getPlatformOverviewOriginAndChannelDataMetrics(
      formatDate(initialDateFilter),
      formatDate(finalDateFilter)
    );
  }, [getPlatformOverviewOriginAndChannelDataMetrics]);

  useEffect(() => {
    onRenderComponent();
  }, [onRenderComponent]);

  return (
    <BackgroundNew
      titlePage={t('menu.operations_overview')}
    >
      <FilterContainer
        selectedDateRange={dateRange}
        setDateRange={setDateRange}
        showSecondaryFilters={true}
        secondaryFilters={getSecondaryFilters()}
        onClickFilter={handleApplyFilter}
      />
      <CardIndicator
        data={getCardIndicatorData(bigNumbersData)}
        currency={currency}
        isLoading={isLoading}
      />
      <CardGroup
        title={t('operationOverview.approved_revenue_vs_approved_orders_by_source')}
        tagIdentifierColor="#00CCAE"
        isLoading={isLoading}
        ref={cardApprovedRevenueAndApprovedOrdersPerOriginRef}
      >
        <ComposedGraphBarLine
          data={operationData}
          dimension={selectedDimension}
          showPrimaryBarYAxis={true}
          showSecondaryBarYAxis={true}
          secondaryBarIsStacked={true}
          primaryBarYAxisConfig={{
            name: "Ecommerce",
            dataKey: "Ecommerce_approvedRevenue"
          }}
          secondaryBarYAxisConfig={{
            name: "Marketplace",
            dataKey: "Marketplace_approvedRevenue"
          }}
          showPrimaryLineYAxis={true}
          primaryLineYAxisConfig={{
            name: "Ecommerce",
            dataKey: "Ecommerce_approvedOrders"
          }}
          showSecondaryLineYAxis={true}
          secondaryLineYAxisConfig={{
            name: "Marketplace",
            dataKey: "Marketplace_approvedOrders"
          }}
          formatData={(data, dimension) => formatDataByDateAndField(data, dimension, 'origin')}
          formaterBarValue={value => formatValueToMoney(value, currency)}
          formaterLineValue={formatValueToNumber}
          graphMarginConfig={{
            top: 20,
            right: -15,
            left: 40,
            bottom: 20
          }}
          showDimensionController={true}
          setSelectedDimension={setSelectedDimension}
          disabledDimensionController={operationData === null || operationData.length === 0}
          showFullScreenOption={true}
          fullScreenEnabled={approvedRevenueByApprovedOrdersPerOriginGraphFullScreen}
          setFullScreenEnabled={setApprovedRevenueByApprovedOrdersPerOriginGraphFullScreen}
          showExportPDFButton={true}
          graphComponentRefForPDF={cardApprovedRevenueAndApprovedOrdersPerOriginRef}
        />
      </CardGroup>
      <Container>
        <CardGroup
          title={t('operationOverview.approved_revenue_share_by_source_progress')}
          tagIdentifierColor="#00CCAE"
          isLoading={isLoading}
          ref={cardEvolutionApprovedRevenueSharePerOriginRef}
        >
          <ComposedGraphBarLine
            data={operationData}
            dimension={selectedDimension}
            showPrimaryBarYAxis={true}
            showSecondaryBarYAxis={true}
            secondaryBarIsStacked={true}
            primaryBarYAxisConfig={{
              name: "Ecommerce",
              dataKey: "Ecommerce_approvedRevenuePercentage"
            }}
            secondaryBarYAxisConfig={{
              name: "Marketplace",
              dataKey: "Marketplace_approvedRevenuePercentage"
            }}
            formatData={(data, dimension) => formatDataByDateAndField(data, dimension, 'origin')}
            formaterBarValue={formatValueToPercentage}
            graphMarginConfig={{
              top: 20,
              right: -35,
              left: -15,
              bottom: 20
            }}
            showDimensionController={true}
            setSelectedDimension={setSelectedDimension}
            disabledDimensionController={operationData === null || operationData.length === 0}
            showFullScreenOption={true}
            fullScreenEnabled={evolutionApprovedRevenueSharePerOriginGraphFullScreen}
            setFullScreenEnabled={setEvolutionApprovedRevenueSharePerOriginGraphFullScreen}
            showExportPDFButton={true}
            graphComponentRefForPDF={cardEvolutionApprovedRevenueSharePerOriginRef}
          />
        </CardGroup>
        <CardGroup
          title={t('operationOverview.approved_revenue_share_by_source')}
          tagIdentifierColor="#00CCAE"
          isLoading={isLoading}
          customClassName="cardGroupContainerSecond"
          ref={cardApprovedRevenueSharePerOriginRef}
        >
          <GraphPie
            data={operationData}
            dataKeyLabel="origin"
            dataKeyValue="approvedRevenue"
            formatData={data => formatDataByField(data, 'origin')}
            formaterValue={value => formatValueToMoney(value, currency)}
            graphMarginConfig={{
              top: 20,
              right: -30,
              left: 10,
              bottom: 20
            }}
            showDimensionController={true}
            showFullScreenOption={true}
            fullScreenEnabled={approvedRevenueSharePerOriginGraphFullScreen}
            setFullScreenEnabled={setApprovedRevenueSharePerOriginGraphFullScreen}
            showExportPDFButton={true}
            graphComponentRefForPDF={cardApprovedRevenueSharePerOriginRef}
            disabledDimensionController={operationData === null || operationData.length === 0}
          />
        </CardGroup>
      </Container>
      <CardGroup
        title={t('operationOverview.approved_revenue_vs_approved_orders_by_sales_channel')}
        tagIdentifierColor="#00CCAE"
        isLoading={isLoading}
        ref={cardApprovedRevenueByApprovedOrdersPerChannelRef}
      >
        <ComposedGraphBarLine
          data={operationData}
          dimension={selectedDimension}
          showMultiBarStacked={true}
          showMultiLine={true}
          multiBarStackedConfigs={getGraphConfigs(visibleChannels, "approvedRevenue")}
          multiLineConfigs={getGraphConfigs(visibleChannels, "approvedOrders")}
          formatData={(data, dimension) => formatDataByDateAndField(data, dimension,'marketplace_channel')}
          formaterBarValue={value => formatValueToMoney(value, currency)}
          formaterLineValue={formatValueToNumber}
          graphMarginConfig={{
            top: 20,
            right: -15,
            left: 40,
            bottom: 20
          }}
          showDimensionController={true}
          setSelectedDimension={setSelectedDimension}
          disabledDimensionController={operationData === null || operationData.length === 0}
          showFullScreenOption={true}
          fullScreenEnabled={approvedRevenueByApprovedOrdersPerChannelGraphFullScreen}
          setFullScreenEnabled={setApprovedRevenueByApprovedOrdersPerChannelGraphFullScreen}
          showExportPDFButton={true}
          graphComponentRefForPDF={cardApprovedRevenueByApprovedOrdersPerChannelRef}
        />
      </CardGroup>
      <Container>
        <CardGroup
          title={t('operationOverview.approved_revenue_share_by_sales_channel_progress')}
          tagIdentifierColor="#00CCAE"
          isLoading={isLoading}
          customClassName="cardGroupContainerFirst"
          ref={cardEvolutionApprovedRevenueSharePerChannelRef}
        >
          <ComposedGraphBarLine
            data={operationData}
            dimension={selectedDimension}
            showMultiBarStacked={true}
            multiBarStackedConfigs={getGraphConfigs(visibleChannels, "approvedRevenuePercentage")}
            formatData={(data, dimension) => formatDataByDateAndField(data, dimension,'marketplace_channel')}
            formaterBarValue={formatValueToPercentage}
            graphMarginConfig={{
              top: 20,
              right: -35,
              left: -15,
              bottom: 20
            }}
            showDimensionController={true}
            setSelectedDimension={setSelectedDimension}
            disabledDimensionController={operationData === null || operationData.length === 0}
            showFullScreenOption={true}
            fullScreenEnabled={evolutionApprovedRevenueSharePerChannelGraphFullScreen}
            setFullScreenEnabled={setEvolutionApprovedRevenueSharePerChannelGraphFullScreen}
            domainBarYAxis={[0, 100]}
            showExportPDFButton={true}
            graphComponentRefForPDF={cardEvolutionApprovedRevenueSharePerChannelRef}
          />
        </CardGroup>
        <CardGroup
          title={t('operationOverview.approved_revenue_share_by_sales_channel')}
          tagIdentifierColor="#00CCAE"
          isLoading={isLoading}
          customClassName="cardGroupContainerSecond"
          ref={cardApprovedRevenueSharePerChannelRef}
        >
          <GraphPie
            data={operationData}
            dataKeyLabel="marketplace_channel"
            dataKeyValue="approvedRevenue"
            formatData={data => formatDataByField(data, 'marketplace_channel')}
            formaterValue={value => formatValueToMoney(value, currency)}
            graphMarginConfig={{
              top: 20,
              right: -30,
              left: 10,
              bottom: 20
            }}
            showDimensionController={true}
            showFullScreenOption={true}
            fullScreenEnabled={approvedRevenueSharePerChannelGraphFullScreen}
            setFullScreenEnabled={setApprovedRevenueSharePerChannelGraphFullScreen}
            multiColor={true}
            showExportPDFButton={true}
            graphComponentRefForPDF={cardApprovedRevenueSharePerChannelRef}
            disabledDimensionController={operationData === null || operationData.length === 0}
            forceMultiColor={true}
          />
        </CardGroup>
      </Container>
    </BackgroundNew>
  );
}

export default OperationOverview;
