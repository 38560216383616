import React, { createContext, useEffect, useState, useCallback } from "react";

import { HttpStatusCode } from "axios";

import { toast } from "react-toastify";

import { useTranslation } from 'react-i18next';
import "../../i18n";

import { handleSignOut, getUserInfo, getUserToken } from "../../utils/loginUtils";
import { 
  setCompanyPreferencesToLocalStorage, 
  getCompanyPreferencesFromLocalStorage,
  setUserLanguagePreferenceToLocalStorage,
  getUserLanguagePreferenceFromLocalStorage 
} from '../../utils/preferencesUtils';

import api from "../../services/api";
import { validateToken } from "../../services/validateToken";
import { getCompanyPreferences } from "../../services/preferencesService";

import { LANGUAGE } from '../../options/preference';

export const AuthContext = createContext({});

export const AuthProvider = ({ children }) => {
  const { t, i18n } = useTranslation();

  const [user, setUser] = useState(null);
  const [lang, setLang] = useState(LANGUAGE.pt_BR);
  const [timezone, setTimezone] = useState('America/Sao_Paulo');
  const [currency, setCurrency] = useState('BRL');

  const configureCompanyPreferences = useCallback(async (isAutenticated, isCompanyUser) => {
    const companyPreferences = !isAutenticated 
      ? await getCompanyPreferencesFromLocalStorage() 
      : await getCompanyPreferences();

    const userLanguagePreference = !isCompanyUser 
      ? getUserLanguagePreferenceFromLocalStorage()
      : null;

    if (companyPreferences) {
      setLang(userLanguagePreference || companyPreferences.lang);
      setTimezone(companyPreferences.timezone);
      setCurrency(companyPreferences.currency);

      setCompanyPreferencesToLocalStorage(
        companyPreferences.lang, 
        companyPreferences.timezone, 
        companyPreferences.currency
      );
      i18n.changeLanguage(userLanguagePreference || companyPreferences.lang)
    } else if (userLanguagePreference) {
      setLang(userLanguagePreference);
      i18n.changeLanguage(userLanguagePreference);
    }
  }, [i18n]);

  const setUserLanguagePreference = useCallback((lang) => {
    setLang(lang);

    setUserLanguagePreferenceToLocalStorage(lang);

    i18n.changeLanguage(lang);
  }, [i18n]);

  const validateUserToken = useCallback(async () => {
    try {
      await validateToken();
    } catch (error) {
      toast.error(t('toast.session_expired'));
      handleSignOut();
    }
  }, [t]);

  const setApiAuthConfig = (userToken) => {
    if (userToken) {
      api.defaults.headers.Authorization = `Bearer ${userToken}`;
    }

    api.interceptors.response.use(
      (response) => response,
      (error) => {
        const unauthenticatedStatus = [HttpStatusCode.Unauthorized, HttpStatusCode.Forbidden];

        if (unauthenticatedStatus.includes(error?.response?.status)) {
          handleSignOut();
        }

        return error;
      }
    );
  };

  const handleSignInSuccess = async (user, userToken) => {
    setUser(user);
    setApiAuthConfig(userToken);
    await configureCompanyPreferences(true, user.is_company_user);
  };

  useEffect(() => {
    const user = getUserInfo();
    const userToken = getUserToken();

    if (user && userToken) {
      setUser(user);
      setApiAuthConfig(userToken);
      validateUserToken();
    } else {
      setApiAuthConfig();
    }
  }, [validateUserToken]);

  useEffect(() => {
    const user = getUserInfo();
    const userToken = getUserToken();

    configureCompanyPreferences((user && userToken), user?.is_company_user);
  }, [configureCompanyPreferences]);

  return (
    <AuthContext.Provider 
      value={{ 
        authenticated: !!user, user, 
        handleSignOut, 
        handleSignInSuccess, 
        lang, 
        timezone, 
        currency,
        configureCompanyPreferences,
        setUserLanguagePreference 
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};
