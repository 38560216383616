import React from 'react';

import { useTranslation } from 'react-i18next';
import '../../../../i18n';

import { 
    formatValueToMoney, 
    formatValueToPercentage, 
    formatValueToNumber 
  } from '../../../../utils/metricsUtils';

import { routes } from '../../../../routes';

export const ChannelInsight = ({
    insight,
    renderTable,
    formartNumbersToBold,
    currency
}) => {
    const { t } = useTranslation();

    const removePunctuation = (text) => text ? text.replace(/[,;]/g, ''): text;

    const handleExportProductsToCsv = (products, suggestedCampaignNumber) => {
        const headers = `${t('productPerformance.product_id')},${t('productPerformance.product_name')},${t('productPerformance.product_sku_id')},${t('productPerformance.product_sku_name')},${t('productBehaviorCurveAbc.department')},${t('productPerformance.category')},${t('productBehaviorCurveAbc.subcategory')},${t('bisoInsights.product_url')},${t('common.sessions')},${t('productPerformance.stock')},${t('bisoInsights.coverage_days')},${t('productPerformance.margin')},${t('bisoInsights.price')},${t('common.revenue')},${t('bisoInsights.sales')},${t('common.conversion_rate')}\n`;

        const formattedProduct = product => [
            product.product_id,
            removePunctuation(product.product_name),
            product.product_sku_id,
            removePunctuation(product.product_sku_name),
            removePunctuation(product.product_department_name),
            removePunctuation(product.product_category_name),
            removePunctuation(product.product_sub_category_name),
            product.product_page_path,
            product.sessions,
            product.stock,
            product.stock_coverage,
            product.margin,
            product.product_price,
            product.revenue,
            product.sales,
            product.conversion_rate
        ].join(',');
          
        const rows = products.map(formattedProduct).join('\n');
    
        const csv = headers + rows; 
    
        const blob = new Blob([csv], { type: 'text/csv' });
        const url = window.URL.createObjectURL(blob);
    
        const a = document.createElement('a');
        a.href = url;
        a.download = `suggested_campaign_products_${suggestedCampaignNumber}.csv`;
        a.click();
    }

    const handleOpenCustomerBehaviorPageWithSegmentation = (segmentation_rx, segmentation_fx) => {
        const regexSignal = /(R[1-6]|F[1-5])/g;
        segmentation_rx = segmentation_rx.match(regexSignal)[0];
        segmentation_fx = segmentation_fx.match(regexSignal)[0];

        window.open(`${routes.customerBehavior}?segmentation_rx=${segmentation_rx}&segmentation_fx=${segmentation_fx}`, '_blank');
    }

    const getCrmCampaignsFields = _ => [
        { label: `${t('common.campaign')}`, value: 'campaign_name', formatter: null },
        { label: t('common.sessions'), value: 'sessions', formatter: formatValueToNumber },
        { label: t('common.conversion_rate'), value: 'conversion_rate', formatter: formatValueToPercentage },
        { label: t('common.average_ticket'), value: 'average_ticket', formatter: value => formatValueToMoney(value, currency) },
        { label: t('common.revenue'), value: 'revenue', formatter: value => formatValueToMoney(value, currency) }
    ]

    const getPaidMediaCampaignsFields = _ => [
        { label: `${t('common.campaign')}`, value: 'campaign_name', formatter: null },
        { label: `${t('common.channel')}`, value: 'channel', formatter: null },
        { label: t('common.sessions'), value: 'sessions', formatter: formatValueToNumber },
        { label: t('common.conversion_rate'), value: 'conversion_rate', formatter: formatValueToPercentage },
        { label: t('common.average_ticket'), value: 'average_ticket', formatter: value => formatValueToMoney(value, currency) },
        { label: t('common.captured_revenue'), value: 'revenue', formatter: value => formatValueToMoney(value, currency) },
        { label: t('common.approved_revenue'), value: 'ecommerce_approved_revenue', formatter: value => formatValueToMoney(value, currency) },
        { label: t('common.transactions'), value: 'transactions', formatter: formatValueToNumber },
        { label: t('common.impressions'), value: 'impressions', formatter: formatValueToNumber },
        { label: t('common.clicks'), value: 'clicks', formatter: formatValueToNumber },
        { label: t('common.ctr'), value: 'ctr', formatter: formatValueToPercentage },
        { label: 'CPC', value: 'cpc', formatter: value => formatValueToMoney(value, currency) },
        { label: t('common.cpt'), value: 'cpt', formatter: value => formatValueToMoney(value, currency) },
        { label: 'ROAS %', value: 'roas', formatter: formatValueToPercentage },
        { label: t('common.investment'), value: 'investment', formatter: value => formatValueToMoney(value, currency) },
        { label: t('common.cac'), value: 'cac', formatter: value => formatValueToMoney(value, currency) },
        { label: t('common.new_customers'), value: 'total_new_customers', formatter: formatValueToNumber },
        { label: t('common.returning_customers'), value: 'total_rebuyers_customers', formatter: formatValueToNumber }
    ]
    
    const renderProducts = (products) => {
        const fields = [
            { label: t('common.product'), value: 'product_name', formatter: null, maxWidth: '120px' },
            { label: t('bisoInsights.variation'), value: 'product_sku_name', formatter: null, maxWidth: '120px'},
            { label: t('productPerformance.category'), value: 'product_category_name', formatter: (value) => (<span className="tableTdTag">{value}</span>), minWidth: '120px', maxWidth: '200px' },
            { label: t('common.sessions'), value: 'sessions', formatter: formatValueToNumber },
            { label: t('productPerformance.stock'), value: 'stock', formatter: formatValueToNumber },
            { label: t('bisoInsights.coverage_days'), value: 'stock_coverage', formatter: formatValueToNumber },
            { label: t('productPerformance.margin'), value: 'margin', formatter: formatValueToPercentage },
            { label: t('bisoInsights.price'), value: 'product_price', formatter: value => formatValueToMoney(value, currency) },
            { label: t('common.revenue'), value: 'revenue', formatter: value => formatValueToMoney(value, currency) },
            { label: t('bisoInsights.sales'), value: 'sales', formatter: formatValueToNumber},
            { label: t('common.conversion_rate'), value: 'conversion_rate', formatter: formatValueToPercentage }
        ]

        return renderTable(products, fields);
    }

    const renderCampaigns = (insight) => {
        const fieldsByAnalysisType = {
            "CRM": getCrmCampaignsFields,
            "PAID_MEDIA": getPaidMediaCampaignsFields
        }

        return renderTable(
            insight.analysis.top_campaigns, 
            fieldsByAnalysisType[insight.analysis_type]()
        );
    }

    const renderAdditionalCrmInsightContent = (insight) => {
        return (
            <div className="whyCustomers">
                <p dangerouslySetInnerHTML={{ __html: formartNumbersToBold(insight.analysis.why_customers) }}/>
            </div>
        );
    }

    const renderAdditionalPaidMediaInsightContent = (insight) => {
        return (
            <div className="whyChannels">
                <p className="whyChannelsTitle">{t('bisoInsights.channel_performance')}</p>
                <p className="whyChannelsExplanation" dangerouslySetInnerHTML={{ __html: formartNumbersToBold(insight.analysis.why_channels) }}/>
            </div>
        );
    }

    const renderAdditionalInsightContent = (insight) => {
        const additionalInsightContentByAnalysisType = {
            "CRM": renderAdditionalCrmInsightContent,
            "PAID_MEDIA": renderAdditionalPaidMediaInsightContent
        }

        return additionalInsightContentByAnalysisType[insight.analysis_type](insight);
    }
    
    const renderCrmInsightSuggestions = (insight) => {
        return (
            <div className="channelSuggestions">
                {insight.analysis.suggested_campaigns && insight.analysis.suggested_campaigns.map((suggestedCampaign, index) => {
                    return (
                        <div key={index} className="channelSuggestion">
                            <div className="channelSuggestionHeader">
                            <p className="channelSuggestionNumber"><span className='tagIdentifier'/> {t('bisoInsights.suggestion')} {index+1}</p>
                                <div className="channelSuggestionHeaderActions ignorePdf">
                                <button 
                                    onClick={_ => handleOpenCustomerBehaviorPageWithSegmentation(suggestedCampaign.segmentation_RX, suggestedCampaign.segmentation_FX)}
                                >
                                    {t('bisoInsights.export_base')}
                                </button>
                                <button onClick={_ => handleExportProductsToCsv(suggestedCampaign.products, index+1)}>{t('bisoInsights.export_products')}</button>
                                </div>
                            </div>
                            <div className="channelSuggestionContent">
                                <p><b>{t('bisoInsights.type')}:</b> {suggestedCampaign.campaign_type}</p>
                                <p><b>{t('bisoInsights.segmentation')}:</b> {suggestedCampaign.segmentation_RX} - {suggestedCampaign.segmentation_FX}</p>
                                <p><b>{t('bisoInsights.segmentation_clients')}:</b> {formatValueToNumber(suggestedCampaign.segmentation_customers_quantity)}</p>
                                <p><b>{t('common.day')}:</b> {suggestedCampaign.day}</p>
                                <p><b>{t('bisoInsights.week')}:</b> {suggestedCampaign.week}</p>
                                <p><b>{t('common.products')}:</b></p>
                                <ul className="crmSuggestedCampaignContentProducts">
                                    {suggestedCampaign.products.map((product, index) => {
                                    return (
                                        <li key={index}>{index+1}. {product.product_sku_name !== "" ? product.product_sku_name : product.product_name}</li>
                                    );
                                    })}
                                </ul>
                                <p className="channelSuggestionContentExplanation"><b>{t('common.explanation')}: </b>{suggestedCampaign.defense}</p>
                            </div>
                        </div>
                    )
                })}
            </div>
        );
    }

    const renderPaidMediaInsightSuggestions = (insight) => {
        return (
            <div className="channelSuggestions">
                {insight.analysis.suggestions_for_campaigns && insight.analysis.suggestions_for_campaigns.map((suggestionForCampaign, index) => {
                    return (
                        <div key={index} className="channelSuggestion">
                            <div className="channelSuggestionHeader">
                                <p className="channelSuggestionNumber"><span className='tagIdentifier'/> {t('bisoInsights.suggestion')} {index+1}</p>
                            </div>
                            <div className="channelSuggestionContent">
                                <p><b>{t('common.campaign')}:</b> {suggestionForCampaign.campaign_name}</p>
                                <p><b>{t('common.channel')}:</b> {suggestionForCampaign.channel}</p>
                                <p className="channelSuggestionContentExplanation"><b>{t('bisoInsights.suggestion')}: </b>{suggestionForCampaign.suggestion}</p>
                            </div>
                        </div>
                    )
                })}
            </div>
        );
    }

    const renderInsightSuggestions = (insight) => {
        const insightSuggestionsByAnalysisType = {
            "CRM": renderCrmInsightSuggestions,
            "PAID_MEDIA": renderPaidMediaInsightSuggestions
        }

        return insightSuggestionsByAnalysisType[insight.analysis_type](insight);
    }

    const getInsightConclusionDescription = (insight) => {
        const conclusionDescriptionByAnalysisType = {
            "CRM": t('bisoInsights.campaign_suggestions'),
            "PAID_MEDIA": t('bisoInsights.improvement_suggestions')
        }

        return conclusionDescriptionByAnalysisType[insight.analysis_type];
    }

    return (
        <>
            <div className="whatAnalysis">
                <p>{t('bisoInsights.stay_informed_about_your_business')}</p>
                <p dangerouslySetInnerHTML={{ __html: formartNumbersToBold(insight.analysis.what_analysis) }}/>
            </div>
            <div className="whyCampaignsAnalysis">
                <p className="whyCampaignsAnalysisTitle">{t('bisoInsights.highlighted_campaigns')}</p>
                {renderCampaigns(insight)}
                <p className="whyCampaignsAnalysisExplanation" dangerouslySetInnerHTML={{ __html: formartNumbersToBold(insight.analysis.why_campaigns_analysis) }}/>
            </div>
            <div className="whyCategoriesAndProductsAnalysis">
                <p className="whyCategoriesAndProductsAnalysisTitle">{t('bisoInsights.highlighted_products')}</p>
                {renderProducts(insight.analysis.top_products)}
                <p className="whyCategoriesAndProductsAnalysisExplanation" dangerouslySetInnerHTML={{ __html: formartNumbersToBold(insight.analysis.why_categories_and_products) }}/>
            </div>
            {renderAdditionalInsightContent(insight)}
            <div className="conclusion">
                <p><b>{t('bisoInsights.conclusion')}</b></p>
                <p>{getInsightConclusionDescription(insight)}</p>
            </div>
            {renderInsightSuggestions(insight)}
        </>
    )
}