import React from 'react';

import { MinAndMaxValueFilterContainer } from './styles';

const MinAndMaxValueFilter = ({ min, max, onChangeMin, onChangeMax }) => {
    return (
        <MinAndMaxValueFilterContainer>
            <input
                type="number"
                className='min'
                value={min}
                onEmptied={e => onChangeMin(1)}
                onChange={e => onChangeMin(e.target.value)}
            />
            <input
                type="number"
                className='max'
                value={max}
                onChange={e => onChangeMax(e.target.value )}
            />
        </MinAndMaxValueFilterContainer>
    );
}

export default MinAndMaxValueFilter;