import i18n from '../i18n';

const addtionalConfigDictonary = {
    campaign: {
        additional_config_google_ads_table: 'campaign'
    },
    ad_group: {
        additional_config_google_ads_table: 'ad_group'
    }
}

export const additionalConfig = {
    get: (field_filter) => {
        const key = field_filter.splii18n.t('.')[0]

        if (addtionalConfigDictonary.hasOwnProperty(key)) {
            return addtionalConfigDictonary[key]
        } else {
            return null
        }
    },
    default: addtionalConfigDictonary.campaign
}

export const dimension = "date"

export const defaultComparedFrom = "today"

let metrics = []
let metricsFilter = {}
let dimensionsFilter = {}

const updateOptions = _ => { 
    metrics = [
        {
            label: i18n.t('alerts.metrics'),
            options: [
                { value: "metrics.absolute_top_impression_percentage", label: "Absolute Top Impression Percentage" },
                { value: "metrics.active_view_cpm", label: "Active View CPM" },
                { value: "metrics.active_view_ctr", label: "Active View CTR" },
                { value: "metrics.active_view_impressions", label: "Active View Impressions" },
                { value: "metrics.active_view_measurability", label: "Active View Measurability" },
                { value: "metrics.active_view_measurable_cost_micros", label: "Active View Measurable Cost (micros)" },
                { value: "metrics.active_view_measurable_impressions", label: "Active View Measurable Impressions" },
                { value: "metrics.active_view_viewability", label: "Active View Viewability" },
                { value: "metrics.all_conversions", label: "All Conversions" },
                { value: "metrics.all_conversions_by_conversion_date", label: "All conversions By Conversion Date" },
                { value: "metrics.all_conversions_from_click_to_call", label: "All Conversions From Click to Call" },
                { value: "metrics.all_conversions_from_directions", label: "All Conversions From Directions" },
                { value: "metrics.all_conversions_from_interactions_rate", label: "All Conversions From Interactions Rate" },
                { value: "metrics.all_conversions_from_location_asset_click_to_call", label: "All Conversions From Location Asset Click to Call" },
                { value: "metrics.all_conversions_from_location_asset_directions", label: "All Conversions From Location Asset Directions" },
                { value: "metrics.all_conversions_from_location_asset_menu", label: "All Conversions From Location Asset Store Menu" },
                { value: "metrics.all_conversions_from_location_asset_order", label: "All Conversions From Location Asset Order" },
                { value: "metrics.all_conversions_from_location_asset_other_engagement", label: "All Conversions From Location Asset Other Engagement" },
                { value: "metrics.all_conversions_from_location_asset_store_visits", label: "All Conversions From Location Asset Store Visits" },
                { value: "metrics.all_conversions_from_location_asset_website", label: "All Conversions From Location Asset Website" },
                { value: "metrics.all_conversions_from_menu", label: "All Conversions From Store Menu" },
                { value: "metrics.all_conversions_from_order", label: "All Conversions From Order" },
                { value: "metrics.all_conversions_from_other_engagement", label: "All Conversions From Other Engagement" },
                { value: "metrics.all_conversions_from_store_visit", label: "All Conversions From Store Visit" },
                { value: "metrics.all_conversions_from_store_website", label: "All Conversions From Store Website" },
                { value: "metrics.all_conversions_value", label: "All Conversions Value" },
                { value: "metrics.all_conversions_value_by_conversion_date", label: "All Conversions Value By Conversion Date" },
                { value: "metrics.auction_insight_search_absolute_top_impression_percentage", label: "Auction Insight Search Absolute Top Impression Percentage" },
                { value: "metrics.auction_insight_search_impression_share", label: "Auction Insight Search Impression Share" },
                { value: "metrics.auction_insight_search_outranking_share", label: "Auction Insight Search Outranking Share" },
                { value: "metrics.auction_insight_search_overlap_rate", label: "Auction Insight Search Overlap Rate" },
                { value: "metrics.auction_insight_search_position_above_rate", label: "Auction Insight Search Position Above Rate" },
                { value: "metrics.auction_insight_search_top_impression_percentage", label: "Auction Insight Search Top Impression Percentage" },
                { value: "metrics.average_cost", label: "Average Cost" },
                { value: "metrics.average_cpc", label: "Average CPC" },
                { value: "metrics.average_cpe", label: "Average CPE" },
                { value: "metrics.average_cpm", label: "Average CPM" },
                { value: "metrics.average_cpv", label: "Average CPV" },
                { value: "metrics.average_page_views", label: "Average Page Views" },
                { value: "metrics.average_time_on_site", label: "Average Time On Site" },
                { value: "metrics.bounce_rate", label: "Bounce Rate" },
                { value: "metrics.clicks", label: "Clicks" },
                { value: "metrics.content_budget_lost_impression_share", label: "Content Budget Lost Impression Share" },
                { value: "metrics.content_impression_share", label: "Content Impression Share" },
                { value: "metrics.content_rank_lost_impression_share", label: "Content Rank Lost Impression Share" },
                { value: "metrics.conversions", label: "Conversions" },
                { value: "metrics.conversions_by_conversion_date", label: "Conversions By Conversion Date" },
                { value: "metrics.conversions_from_interactions_rate", label: "Conversions From Interactions Rate" },
                { value: "metrics.conversions_value", label: "Conversions Value" },
                { value: "metrics.conversions_value_by_conversion_date", label: "Conversions Value By Conversion Date" },
                { value: "metrics.cost_micros", label: "Cost (micros)" },
                { value: "metrics.cost_per_all_conversion", label: "Cost Per All Conversion" },
                { value: "metrics.cost_per_conversion", label: "Cost Per Conversion" },
                { value: "metrics.cost_per_current_model_attributed_conversion", label: "Cost Per Current Model Attributed Conversion" },
                { value: "metrics.cross_device_conversions", label: "Cross Device Conversions" },
                { value: "metrics.ctr", label: "CTR" },
                { value: "metrics.engagement_rate", label: "Engagement Rate" },
                { value: "metrics.engagements", label: "Engagements" },
                { value: "metrics.gmail_forwards", label: "Gmail Forwards" },
                { value: "metrics.gmail_saves", label: "Gmail Saves" },
                { value: "metrics.gmail_secondary_clicks", label: "Gmail Secondary Clicks" },
                { value: "metrics.impressions", label: "Impressions" },
                { value: "metrics.interaction_rate", label: "Interaction Rate" },
                { value: "metrics.interactions", label: "Interactions" },
                { value: "metrics.invalid_click_rate", label: "Invalid Click Rate" },
                { value: "metrics.invalid_clicks", label: "Invalid Clicks" },
                { value: "metrics.percent_new_visitors", label: "Percent New Visitors" },
                { value: "metrics.phone_calls", label: "Phone Calls" },
                { value: "metrics.phone_impressions", label: "Phone Impressions" },
                { value: "metrics.phone_through_rate", label: "Phone Through Rate" },
                { value: "metrics.publisher_organic_clicks", label: "Publisher Organic Clicks" },
                { value: "metrics.publisher_purchased_clicks", label: "Publisher Purchased Clicks" },
                { value: "metrics.publisher_unknown_clicks", label: "Publisher Unknown Clicks" },
                { value: "metrics.relative_ctr", label: "Relative CTR" },
                { value: "metrics.search_click_share", label: "Search Click Share" },
                { value: "metrics.search_exact_match_impression_share", label: "Search Exact Match Impression Share" },
                { value: "metrics.search_impression_share", label: "Search Impression Share" },
                { value: "metrics.value_per_all_conversions", label: "Value Per All Conversions" },
                { value: "metrics.value_per_all_conversions_by_conversion_date", label: "Value Per All Conversions By Conversion Date" },
                { value: "metrics.value_per_conversion", label: "Value Per Conversion" },
                { value: "metrics.value_per_conversions_by_conversion_date", label: "Value Per Conversions By Conversion Date" },
                { value: "metrics.video_quartile_p100_rate", label: "Video Quartile P100 Rate" },
                { value: "metrics.video_quartile_p25_rate", label: "Video Quartile P25 Rate" },
                { value: "metrics.video_quartile_p50_rate", label: "Video Quartile P50 Rate" },
                { value: "metrics.video_quartile_p75_rate", label: "Video Quartile P75 Rate" },
                { value: "metrics.video_view_rate", label: "Video View Rate" },
                { value: "metrics.video_views", label: "Video Views" },
                { value: "metrics.view_through_conversions", label: "View Through Conversions" },
                { value: "metrics.view_through_conversions_from_location_asset_click_to_call", label: "View Through Conversions From Location Asset Click To Call" },
                { value: "metrics.view_through_conversions_from_location_asset_directions", label: "View Through Conversions From Location Asset Directions" },
                { value: "metrics.view_through_conversions_from_location_asset_menu", label: "View Through Conversions From Location Asset Menu" },
                { value: "metrics.view_through_conversions_from_location_asset_order", label: "View Through Conversions From Location Asset Order" },
                { value: "metrics.view_through_conversions_from_location_asset_other_engagement", label: "View Through Conversions From Location Asset Other Engagement" },
                { value: "metrics.view_through_conversions_from_location_asset_store_visits", label: "View Through Conversions From Location Asset Store Visits" },
                { value: "metrics.view_through_conversions_from_location_asset_website", label: "View Through Conversions From Location Asset Website" }
            ]
        }
    ]

    metricsFilter = {
        condition: [
            { value: '==', label: i18n.t('alerts.equal') },
            { value: '!=', label: i18n.t('alerts.different') },
            { value: '>', label: i18n.t('alerts.greater_than') },
            { value: '>=', label: i18n.t('alerts.greater_than_or_equal_to') },
            { value: '<', label: i18n.t('alerts.less_than') },
            { value: '<=', label: i18n.t('alerts.less_than_or_equal_to') }
        ],
        match: [
            { value: 'AND', label: 'E' }
        ],
        defaultMatch: 'AND',
        options: [
            {
                label: i18n.t('alerts.metrics'),
                options: [
                    { value: "metrics.absolute_top_impression_percentage", label: "Absolute Top Impression Percentage" },
                    { value: "metrics.active_view_cpm", label: "Active View CPM" },
                    { value: "metrics.active_view_ctr", label: "Active View CTR" },
                    { value: "metrics.active_view_impressions", label: "Active View Impressions" },
                    { value: "metrics.active_view_measurability", label: "Active View Measurability" },
                    { value: "metrics.active_view_measurable_cost_micros", label: "Active View Measurable Cost (micros)" },
                    { value: "metrics.active_view_measurable_impressions", label: "Active View Measurable Impressions" },
                    { value: "metrics.active_view_viewability", label: "Active View Viewability" },
                    { value: "metrics.all_conversions", label: "All Conversions" },
                    { value: "metrics.all_conversions_by_conversion_date", label: "All conversions By Conversion Date" },
                    { value: "metrics.all_conversions_from_click_to_call", label: "All Conversions From Click to Call" },
                    { value: "metrics.all_conversions_from_directions", label: "All Conversions From Directions" },
                    { value: "metrics.all_conversions_from_interactions_rate", label: "All Conversions From Interactions Rate" },
                    { value: "metrics.all_conversions_from_location_asset_click_to_call", label: "All Conversions From Location Asset Click to Call" },
                    { value: "metrics.all_conversions_from_location_asset_directions", label: "All Conversions From Location Asset Directions" },
                    { value: "metrics.all_conversions_from_location_asset_menu", label: "All Conversions From Location Asset Store Menu" },
                    { value: "metrics.all_conversions_from_location_asset_order", label: "All Conversions From Location Asset Order" },
                    { value: "metrics.all_conversions_from_location_asset_other_engagement", label: "All Conversions From Location Asset Other Engagement" },
                    { value: "metrics.all_conversions_from_location_asset_store_visits", label: "All Conversions From Location Asset Store Visits" },
                    { value: "metrics.all_conversions_from_location_asset_website", label: "All Conversions From Location Asset Website" },
                    { value: "metrics.all_conversions_from_menu", label: "All Conversions From Store Menu" },
                    { value: "metrics.all_conversions_from_order", label: "All Conversions From Order" },
                    { value: "metrics.all_conversions_from_other_engagement", label: "All Conversions From Other Engagement" },
                    { value: "metrics.all_conversions_from_store_visit", label: "All Conversions From Store Visit" },
                    { value: "metrics.all_conversions_from_store_website", label: "All Conversions From Store Website" },
                    { value: "metrics.all_conversions_value", label: "All Conversions Value" },
                    { value: "metrics.all_conversions_value_by_conversion_date", label: "All Conversions Value By Conversion Date" },
                    { value: "metrics.auction_insight_search_absolute_top_impression_percentage", label: "Auction Insight Search Absolute Top Impression Percentage" },
                    { value: "metrics.auction_insight_search_impression_share", label: "Auction Insight Search Impression Share" },
                    { value: "metrics.auction_insight_search_outranking_share", label: "Auction Insight Search Outranking Share" },
                    { value: "metrics.auction_insight_search_overlap_rate", label: "Auction Insight Search Overlap Rate" },
                    { value: "metrics.auction_insight_search_position_above_rate", label: "Auction Insight Search Position Above Rate" },
                    { value: "metrics.auction_insight_search_top_impression_percentage", label: "Auction Insight Search Top Impression Percentage" },
                    { value: "metrics.average_cost", label: "Average Cost" },
                    { value: "metrics.average_cpc", label: "Average CPC" },
                    { value: "metrics.average_cpe", label: "Average CPE" },
                    { value: "metrics.average_cpm", label: "Average CPM" },
                    { value: "metrics.average_cpv", label: "Average CPV" },
                    { value: "metrics.average_page_views", label: "Average Page Views" },
                    { value: "metrics.average_time_on_site", label: "Average Time On Site" },
                    { value: "metrics.bounce_rate", label: "Bounce Rate" },
                    { value: "metrics.clicks", label: "Clicks" },
                    { value: "metrics.content_budget_lost_impression_share", label: "Content Budget Lost Impression Share" },
                    { value: "metrics.content_impression_share", label: "Content Impression Share" },
                    { value: "metrics.content_rank_lost_impression_share", label: "Content Rank Lost Impression Share" },
                    { value: "metrics.conversions", label: "Conversions" },
                    { value: "metrics.conversions_by_conversion_date", label: "Conversions By Conversion Date" },
                    { value: "metrics.conversions_from_interactions_rate", label: "Conversions From Interactions Rate" },
                    { value: "metrics.conversions_value", label: "Conversions Value" },
                    { value: "metrics.conversions_value_by_conversion_date", label: "Conversions Value By Conversion Date" },
                    { value: "metrics.cost_micros", label: "Cost (micros)" },
                    { value: "metrics.cost_per_all_conversion", label: "Cost Per All Conversion" },
                    { value: "metrics.cost_per_conversion", label: "Cost Per Conversion" },
                    { value: "metrics.cost_per_current_model_attributed_conversion", label: "Cost Per Current Model Attributed Conversion" },
                    { value: "metrics.cross_device_conversions", label: "Cross Device Conversions" },
                    { value: "metrics.ctr", label: "CTR" },
                    { value: "metrics.engagement_rate", label: "Engagement Rate" },
                    { value: "metrics.engagements", label: "Engagements" },
                    { value: "metrics.gmail_forwards", label: "Gmail Forwards" },
                    { value: "metrics.gmail_saves", label: "Gmail Saves" },
                    { value: "metrics.gmail_secondary_clicks", label: "Gmail Secondary Clicks" },
                    { value: "metrics.impressions", label: "Impressions" },
                    { value: "metrics.interaction_rate", label: "Interaction Rate" },
                    { value: "metrics.interactions", label: "Interactions" },
                    { value: "metrics.invalid_click_rate", label: "Invalid Click Rate" },
                    { value: "metrics.invalid_clicks", label: "Invalid Clicks" },
                    { value: "metrics.percent_new_visitors", label: "Percent New Visitors" },
                    { value: "metrics.phone_calls", label: "Phone Calls" },
                    { value: "metrics.phone_impressions", label: "Phone Impressions" },
                    { value: "metrics.phone_through_rate", label: "Phone Through Rate" },
                    { value: "metrics.publisher_organic_clicks", label: "Publisher Organic Clicks" },
                    { value: "metrics.publisher_purchased_clicks", label: "Publisher Purchased Clicks" },
                    { value: "metrics.publisher_unknown_clicks", label: "Publisher Unknown Clicks" },
                    { value: "metrics.relative_ctr", label: "Relative CTR" },
                    { value: "metrics.search_click_share", label: "Search Click Share" },
                    { value: "metrics.search_exact_match_impression_share", label: "Search Exact Match Impression Share" },
                    { value: "metrics.search_impression_share", label: "Search Impression Share" },
                    { value: "metrics.value_per_all_conversions", label: "Value Per All Conversions" },
                    { value: "metrics.value_per_all_conversions_by_conversion_date", label: "Value Per All Conversions By Conversion Date" },
                    { value: "metrics.value_per_conversion", label: "Value Per Conversion" },
                    { value: "metrics.value_per_conversions_by_conversion_date", label: "Value Per Conversions By Conversion Date" },
                    { value: "metrics.video_quartile_p100_rate", label: "Video Quartile P100 Rate" },
                    { value: "metrics.video_quartile_p25_rate", label: "Video Quartile P25 Rate" },
                    { value: "metrics.video_quartile_p50_rate", label: "Video Quartile P50 Rate" },
                    { value: "metrics.video_quartile_p75_rate", label: "Video Quartile P75 Rate" },
                    { value: "metrics.video_view_rate", label: "Video View Rate" },
                    { value: "metrics.video_views", label: "Video Views" },
                    { value: "metrics.view_through_conversions", label: "View Through Conversions" },
                    { value: "metrics.view_through_conversions_from_location_asset_click_to_call", label: "View Through Conversions From Location Asset Click To Call" },
                    { value: "metrics.view_through_conversions_from_location_asset_directions", label: "View Through Conversions From Location Asset Directions" },
                    { value: "metrics.view_through_conversions_from_location_asset_menu", label: "View Through Conversions From Location Asset Menu" },
                    { value: "metrics.view_through_conversions_from_location_asset_order", label: "View Through Conversions From Location Asset Order" },
                    { value: "metrics.view_through_conversions_from_location_asset_other_engagement", label: "View Through Conversions From Location Asset Other Engagement" },
                    { value: "metrics.view_through_conversions_from_location_asset_store_visits", label: "View Through Conversions From Location Asset Store Visits" },
                    { value: "metrics.view_through_conversions_from_location_asset_website", label: "View Through Conversions From Location Asset Website" }
                ]
            }
        ]
    }

    dimensionsFilter = {
        condition: [
            { value: '==', label: i18n.t('alerts.equal') },
            { value: '!=', label: i18n.t('alerts.different') },
            { value: '=@', label: i18n.t('alerts.contains') },
            { value: '!@', label: i18n.t('alerts.does_not_contain') }
        ],
        match: [
            { value: 'AND', label: 'E' }
        ],
        defaultMatch: 'AND',
        options: [
            {
                label: i18n.t('alerts.campaign_dimensions'),
                options: [
                    { value: "campaign.advertising_channel_type", label: "Campaign Advertising Channel Type" },
                    { value: "campaign.advertising_channel_sub_type", label: "Campaign Advertising Channel Sub Type" },
                    { value: "campaign.base_campaign", label: "Campaign Base Campaign" },
                    { value: "campaign.bidding_strategy", label: "Campaign Bidding Strategy" },
                    { value: "campaign.bidding_strategy_type", label: "Campaign Bidding Strategy Type" },
                    { value: "campaign.campaign_budget", label: "Campaign Campaign Budget" },
                    { value: "campaign.campaign_group", label: "Campaign Campaign Group" },
                    { value: "campaign.end_date", label: "Campaign Campaign End Date" },
                    { value: "campaign.final_url_suffix", label: "Campaign Final Url Suffix" },
                    { value: "campaign.id", label: "Campaign Campaign ID" },
                    { value: "campaign.labels", label: "Campaign Labels" },
                    { value: "campaign.manual_cpa", label: "Campaign Manual CPA" },
                    { value: "campaign.manual_cpc.enhanced_cpc_enabled", label: "Campaign Manual CPC Enhanced CPC Enabled" },
                    { value: "campaign.manual_cpm", label: "Campaign Manual CPM" },
                    { value: "campaign.manual_cpv", label: "Campaign Manual CPV" },
                    { value: "campaign.name", label: "Campaign Campaign Name" },
                    { value: "campaign.network_settings.target_google_search", label: "Campaign Network Settings Target Google Search" },
                    { value: "campaign.network_settings.target_search_network", label: "Campaign Network Settings Target Search Network" },
                    { value: "campaign.optimization_goal_setting.optimization_goal_types", label: "Campaign Optimization Goal Setting Goal Types" },
                    { value: "campaign.optimization_score", label: "Campaign Optimization Score" },
                    { value: "campaign.payment_mode", label: "Campaign Payment Mode" },
                    { value: "campaign.percent_cpc.enhanced_cpc_enabled", label: "Campaign Percent CPC Enhanced CPC Enabled" },
                    { value: "campaign.primary_status", label: "Campaign Primary Status" },
                    { value: "campaign.primary_status_reasons", label: "Campaign Primary Status Reasons" },
                    { value: "campaign.resource_name", label: "Campaign Resource Name" },
                    { value: "campaign.selective_optimization.conversion_actions", label: "Campaign Selective Optimization Conversion Actions" },
                    { value: "campaign.serving_status", label: "Campaign Serving Status" },
                    { value: "campaign.shopping_setting.campaign_priority", label: "Campaign Shopping Setting Campaign Priority" },
                    { value: "campaign.shopping_setting.enable_local", label: "Campaign Shopping Setting Enable Local" },
                    { value: "campaign.shopping_setting.merchant_id", label: "Campaign Shopping Setting Merchant ID" },
                    { value: "campaign.shopping_setting.sales_country", label: "Campaign Shopping Setting Sales Country" },
                    { value: "campaign.shopping_setting.feed_label", label: "Campaign Shopping Setting Feed Label" },
                    { value: "campaign.shopping_setting.use_vehicle_inventory", label: "Campaign Shopping Setting Use Vehicle Inventoty" },
                    { value: "campaign.start_date", label: "Campaign Campaign Start Date" },
                    { value: "campaign.status", label: "Campaign Campaign Status" },
                    { value: "campaign.target_impression_share.location", label: "Campaign Target Impression Share Location" },
                    { value: "campaign.targeting_setting.target_restrictions", label: "Campaign Targeting Setting Target Restrictions" },
                    { value: "campaign.tracking_setting.tracking_url", label: "Campaign Tracking Setting Tracking Url" },
                    { value: "campaign.tracking_url_template", label: "Campaign Tracking Url Template" },
                    { value: "campaign.url_custom_parameters", label: "Campaign Url Custom Parameters" }
                ]
            },
            {
                label: i18n.t('alerts.ad_group_dimensions'),
                options: [
                    { value: "ad_group.base_ad_group", label: "Ad Group Base Ad Group" },
                    { value: "ad_group.campaign", label: "Ad Group Campaign" },
                    { value: "ad_group.final_url_suffix", label: "Ad Group Final Url Suffix" },
                    { value: "ad_group.id", label: "Ad Group ID" },
                    { value: "ad_group.labels", label: "Ad Group Labels" },
                    { value: "ad_group.name", label: "Ad Group Name" },
                    { value: "ad_group.optimized_targeting_enabled", label: "Ad Group Optimized Targeting Enabled" },
                    { value: "ad_group.resource_name", label: "Ad Group Resource Name" },
                    { value: "ad_group.status", label: "Ad Group Status" },
                    { value: "ad_group.targeting_setting.target_restrictions", label: "Ad Group Targeting Setting Restrictions" },
                    { value: "ad_group.tracking_url_template", label: "Ad Group Tracking Url Template" },
                    { value: "ad_group.type", label: "Ad Group Type" },
                    { value: "ad_group.url_custom_parameters", label: "Ad Group Url Custom Parameters" }
                ]
            }
        ]
    }
}

i18n.on('languageChanged', (lng) => {
    updateOptions();
});
  
updateOptions();

export { metrics, metricsFilter, dimensionsFilter } 