import React, { useState, useEffect } from 'react';
import queryString from 'query-string';
import { toast } from 'react-toastify';

import { useTranslation } from 'react-i18next';
import '../../../i18n';

import TrelloService from '../../../services/TrelloService';
import { editCompany } from '../../../services/UserService';


import * as S from './styled';

function CustomModalTrello(props) {
  const { t } = useTranslation();

  const [trelloBoards, setTrelloBoards] = useState([]);
  const [selectedTrelloBoard, setSelectedTrelloBoard] = useState('');
  
  const checkUrlToken = async () => {
    const { token } = queryString.parse(window.location.hash);
    if (token) {
      TrelloService.setToken(token);
      const userData = await TrelloService.getUserId();
      const userId = userData.data.id;
      const boardsData = await TrelloService.getUserBoards(userId);
      setTrelloBoards(
        boardsData.data.map((board) => {
          return {
            value: board.id,
            label: board.name,
          };
        })
      );
    }
  };

  useEffect(() => {
    checkUrlToken();
  }, []);

  const handleTrelloBoardChange = (select) =>
    setSelectedTrelloBoard(select.value);

  const handleTrelloSaveConfig = async () => {
    if (!selectedTrelloBoard) {
      toast.error(t('toast.select_board'));
    } else {
      const { id } = JSON.parse(localStorage.getItem('user'));
      const data = {
        settings_trello: {
          key: TrelloService.key,
          token: TrelloService.token,
          board_id: selectedTrelloBoard,
        },
      };

      const response = await editCompany(id, data);

      if (response) {
        const userInfo = JSON.parse(localStorage.getItem('user'));

        if (userInfo) {
          userInfo.trello = true;
          localStorage.setItem('user', JSON.stringify(userInfo));
        }

        props.handleClose();
        toast.success(t('toast.configuration_saved'));
      }
    }
  };

  return (
    <S.CustomModalTrello {...props}>
      <div className="modal">
        <div className="modal__header">
          <p className="modal__title">{t('firstStep.trello_configuration')}</p>
          <div className="line" />
        </div>

        <div className="modal__body">
          <form className="modal__form">
            <div className="modal__form-group">
              <span className="modal__form-label">{t('firstStep.select_board')}:</span>
              <S.Option
                placeholder={t('common.select')}
                options={trelloBoards}
                onChange={handleTrelloBoardChange}
              />
            </div>
          </form>
        </div>

        <div className="modal__footer">
          <button
            className="modal__form-btn is--cancel"
            type="button"
            onClick={props.handleClose}
          >
            {t('common.cancel')}
          </button>
          <button
            className="modal__form-btn is--save"
            type="button"
            onClick={handleTrelloSaveConfig}
          >
            {t('common.save')}
          </button>
        </div>
      </div>
    </S.CustomModalTrello>
  );
}

export default CustomModalTrello;
