import styled, { css } from 'styled-components';
import { darken } from 'polished';
import { Modal } from '@mui/material';
import Select from 'react-select';

export const Container = styled.div`
  .header {
    display: flex;
    flex-direction: row;
    align-items: center;    
    margin-bottom: 10px;

    .description {      
      .title {
        color: #2e3039;          
        font-size: 20px;
        font-weight: 300
      }
    }
  }

  .settings {
    display: flex;
    flex-wrap: wrap;
    @media(max-width: 1110px) {
      flex-wrap: nowrap;   
      flex-direction: column;   
    }

    .noSettings {
      display: flex;
      align-items: center;
      justify-content: center;

      p {
        color: #90a4ae;
        font-size: 15px;
      }
    }

    .loading {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 100%;
    }

    .setting {
      display: flex;
      flex-direction: row;
      align-items: center;
      padding: 21px 20px 21px 32px;
      justify-content: space-between;
      min-height: 120px;
      width: calc(50% - 16px);
      box-shadow: 0 0 20px rgba(215, 222, 227, 0.39);
      border-radius: 8px;
      background-color: #ffffff;

      &:nth-child(even) {
        margin-left: 32px;
      }

      &:nth-last-child(n + 3) {
        margin-bottom: 32px;
      }

      
      @media(max-width: 1110px) {
        flex-wrap: wrap;
        padding-bottom: 0px;
        width: 100%;
        margin-left: 0 !important;
        padding: 16px;

        &:not(:last-child) {
          margin-bottom: 16px;
        }
      }

      img {
        max-width: 90px;
        width: 100%;
        height: auto;        

        @media(max-width: 1110px) {
          width: 100px;
          height: auto;
          padding: 0;
          margin-right: 10px;
          justify-self: center;
          align-self: center;
        }
      }

      .descriptionSet {
        margin-left: 41px;
        color: #242e42;
        flex-grow: 1;

        @media(max-width: 1110px) {
          margin: 0;
          width: calc(100% - 110px);
        }

        .titleSet {
          font-size: 16px;
          font-weight: 700;
          line-height: 23px;
          margin-bottom: 2px;

          @media(max-width: 1110px) {
            margin-top: 0px;
          }
        }

        .text {
          font-size: 14px;
          font-weight: 300;
          line-height: 18px;
        }        

        .status {
          margin: 7px 0;
          font-size: 15px;
          font-weight: 600;
        }
      }

      button {
        height: 40px;
        min-width: 158px;
        border: 0;        
        transition: all 0.2s;        
        border-radius: 20px;
        background-color: #ff0068;
        font-size: 13px;
        font-weight: 500;
        border: 0;
        color: #ffffff;
        margin-left: 22px;
        display: flex;
        align-items: center;
        justify-content: center;

        @media(max-width: 1110px) {
          width: 100%;
          margin-left: 0;
          margin-top: 8px;
        }

        ${(props) =>
          props.active === true &&
          css`
            background: #007999;
            color: #fff;
          `}

          &:hover {
            background: ${darken(0.08, '#f90c68')};
          }
      }

      button.is--configured {
        background-color: #00CCAE;
        color: #fff;
        pointer-events: none;

        &:hover {
          background-color: #00CCAE
        }
      }

      button.not--configurable {
        background-color: #ccc;
        color: #fff;
        pointer-events: none;

        &:hover {
          background-color: #ccc
        }
      }
    }
  }

  .development {
    margin-top: 50px;

    p {
      font-size: 20px;
      color: #fff;
    }

    .row {
      display: flex;
      margin-top: 20px;
      @media(max-width: 1110px) {
        justify-content: space-between;
      }

      .dev {
        display: flex;
        align-items: center;
        padding-right: 25px;
        @media(max-width: 1110px) {
          padding-right: 0;
        }

        img {
          width: 23px;
          height: 23px;
        }

        .nome {
          font-size: 17px;
          margin-left: 15px;
        }

        & + .dev {
          margin-left: 50px;
          @media(max-width: 1110px) {
            margin-left: 0;
          }
        }
      }
    }
  }
`;

export const SettingsModal = styled(Modal)`
  display: flex;
  justify-content: center;

  .modal {
    position: fixed;
    top: 10%;
    display: flex;
    flex-direction: column;
    width: 60vw;
    max-width: 750px;
    padding: 16px 24px;
    background-color: #f9f9f9;
    border-radius: 8px;

    @media screen and (max-width: 1200px) {
      top: 15px;
      bottom: 15px;
      width: calc(100vw - 30px);
      overflow: auto;
      margin-top: 15px;
      padding: 16px;
    }

    &__header {
      margin-bottom: 32px;
    }

    &__footer {
      display: flex;
      justify-content: center;
      margin-top: 16px;
    }

    &__title {
      font-size: 20px;
      font-weight: 600;
      color: #455a64;

      &:after {
        content: '';
        display: block;
        width: 60px;
        height: 4px;
        margin-top: 16px;
        background: #f90c68;
      }
    }

    &__form {
      &-group {
        padding: 24px 16px;
        background: #fff;
        border-radius: 8px;
        box-shadow: 0px 1px 3px 0px rgba(142, 142, 142, 0.2),
          0px 1px 1px 0px rgba(243, 243, 243, 0.14),
          0px 2px 1px -1px rgba(204, 204, 204, 0.12);
      }

      &-label {
        display: block;
        margin-bottom: 8px;
        color: rgba(0, 0, 0, 0.75);
        font-size: 14px;
        font-weight: 700;
        text-transform: uppercase;
      }

      &-input {
        margin-top: 8px;
        width: 100%;
        height: 38px;
        font-size: 14px;
        font-weight: 400;
        padding: 10px;
        background-color: #fff;
        border: 1px solid #ccc;
        border-radius: 4px;
        @media screen and (max-width: 1200px) {
          font-size: 16px;
        }
      }
      
      &-btn {
        margin: 0 8px;
        font-size: 13px;
        font-weight: 500;
        text-transform: uppercase;
        border: 0;

        &.is--save {
          height: 35px;
          padding: 0 16px;
          color: #fff;
          line-height: 35px;
          background: #f90c68;
          border-radius: 20px;
          &:hover {
            background: ${darken(0.08, '#f90c68')};
          }
          &:disabled {
            opacity: 0.5;
            pointer-events: none;
          }
        }

        &.is--cancel {
          height: 35px;
          color: #455a64;
          line-height: 35px;
          text-decoration: underline;
          background: transparent;
        }
      }
    }

    &__slider {
      &-item {
        min-height: 400px;
      }

      &-progress {
        margin-bottom: 8px;
      }

      &-btn {
        font-size: 13px;
        font-weight: 500;
        background: none;
        border: none;
        user-select: none;
      }
      
      img {
        display: block;
        max-width: 100%;
        max-height: 400px;
        margin: 0 auto;
      }
      
      .MuiMobileStepper-progress {
        margin: 0 8px;
      }
      
      .MuiLinearProgress {
        &-colorPrimary {
          background: #fbc3d8;
        }

        &-barColorPrimary {
          background: #f90b68;
        }
      }


    }
  }
`;

export const Option = styled(Select)``;
