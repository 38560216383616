import api from './api';

import i18n from '../i18n';

export const generateAdText = async (data) => {
  const token = localStorage.getItem('token');

  const response = await api.post(`/ad_text_creator/suggestion`, data, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });

  if (!response || response.status !== 200) {
    throw new Error(i18n.t('error.ad_content_generation_failed'));
  }

  return response.data;
};
