import styled from 'styled-components';

export const Container = styled.div`
    p {
        margin-bottom: 17px;
        font-size: 16px;
    }

    a {
        color: #f90c68;
    }
`;