import { toast } from 'react-toastify';
import React, { useState, useContext } from 'react';

import { useTranslation } from 'react-i18next';
import '../../i18n';

import ptBR from 'antd/lib/locale/pt_BR';
import enUS from 'antd/lib/locale/en_US';
import esES from 'antd/lib/locale/es_ES';

import { DatePicker, ConfigProvider } from 'antd';

import MinAndMaxValueFilter from '../../components/MinAndMaxValueFilter';
import { BisoTooltip } from '../../components/Tooltip';
import { RiInformationLine } from 'react-icons/ri';
import SelectOption from '../../components/SelectOption';
import SelectOptionTag from '../../components/SelectOptionTag';
import { CustomModal } from '../../components/CustomModal';
import Loader from '../../components/Loader';

import { BsFilter } from 'react-icons/bs';
import { HiDocumentReport } from 'react-icons/hi';

import { LANGUAGE } from '../../options/preference';
import { defaultChannelsOptions } from '../../options/filterOptions';
import { isValidDateRange, convertDaysToMonths, setCurrentDateRange, parseDateToDayjs } from '../../utils/dateUtils';

import { Container, StyledRangePickerContainer, ContainerSecondaryFilter } from './styles';

import { AuthContext } from '../../contexts';

const FilterContainer = ({
    showDateRangeFilter = true,
    showChannelFilter = false,
    showMultiSelectFilter = false,
    multiSelectOptionsTitle,
    multiSelectOptions,
    selectedMultiSelectValueOptions,
    setSelectedMultiSelectValueOptions,
    showAdditionalMultiSelectFilter = false,
    additionalMultiSelectOptionsTitle,
    additionalMultiSelectOptions,
    selectedAdditionalMultiSelectValueOptions,
    setSelectedAdditionalMultiSelectValueOptions,
    selectedDateRange,
    setDateRange,
    selectedChannelsOptions,
    setSelectedChannelsOptions,
    onClickFilter,
    channelsOptions = defaultChannelsOptions,
    showSecondaryFilters = false,
    secondaryFilters,
    maxDaysToFilterInDateRange = 155,
    showExportReportButton = false,
    handleExportReport,
    disabledExportReportButton = false,
    loadingExportReportButton = false,
    setCurrentDateRangeCustomized = null,
    limitDateRangeUntilToday = true
}) => {
    const { t } = useTranslation();
    const { lang } = useContext(AuthContext);

    const [enableApplyFilterButton, setEnableApplyFilterButton] = useState(false);
    const [showSecondaryFiltersModal, setShowSecondaryFiltersModal] = useState(false);

    const today = parseDateToDayjs(new Date()).startOf('day');

    const handleMultiSelectChange = (values) => {
        setSelectedMultiSelectValueOptions(values);
        setEnableApplyFilterButton(true);
    }

    const handleAdditionalMultiSelectChange = (values) => {
        setSelectedAdditionalMultiSelectValueOptions(values);
        setEnableApplyFilterButton(true);
    }

    const handleDateRangeChange = (dates) => {
        setDateRange(dates);
        if (setCurrentDateRangeCustomized) {
            setCurrentDateRangeCustomized(dates);
        } else {
            setCurrentDateRange(dates);
        }
        setEnableApplyFilterButton(true);
    }

    const handleChannelChange = (channels) => {
        setSelectedChannelsOptions(channels);
        setEnableApplyFilterButton(true);
    }

    const handleChangeSecondaryFilter = (setValue, value) => {
        setValue(value);
        setEnableApplyFilterButton(true);
    }

    const handleApplyFilter = async () => {
        if(!showDateRangeFilter) {
            setEnableApplyFilterButton(false);
            onClickFilter();

            return;
        }

        if(!selectedDateRange) {
          toast.error(t('toast.invalid_period'));
          return;
        }
  
        const [startDate, endDate] = selectedDateRange;
  
        if (!isValidDateRange(startDate, endDate, maxDaysToFilterInDateRange)) {
          toast.error(`${t('toast.invalid_period_max')} ${convertDaysToMonths(maxDaysToFilterInDateRange)} ${t('toast.months')}.`);
          return;
        }

        setEnableApplyFilterButton(false);
  
        onClickFilter();
    };

    return (
        <>
            {(showSecondaryFilters || showExportReportButton) && (
                <Container>
                    {showExportReportButton &&
                        <button
                            className={`exportReportButton ${disabledExportReportButton ? 'buttonDisabled' : ''}`}
                            onClick={handleExportReport}
                            disabled={disabledExportReportButton}
                        >
                            <HiDocumentReport style={{marginRight: 5}} size={17}/> 
                            {t('customerBehavior.export_report')} 
                            {loadingExportReportButton && <Loader 
                                size={17}
                                color='#fff'
                                containerStyles={{width: "auto", height: "auto", marginLeft: 5}}
                            />}
                        </button>
                    }
                    {secondaryFilters && secondaryFilters.length > 0 && (
                        <>
                            <button
                                className="moreFilterButton"
                                onClick={() => setShowSecondaryFiltersModal(true)}
                            >
                                <BsFilter style={{marginRight: 5}} size={17}/> {t('common.additional_filters')}
                            </button>
                            <CustomModal
                                title={t('common.additional_filters')}
                                open={showSecondaryFiltersModal}
                                handleClose={() => setShowSecondaryFiltersModal(false)}
                                cancelButtonLabel={t('common.close')}
                                hiddenConfirmButton={true}
                            >
                                <ContainerSecondaryFilter>
                                    {secondaryFilters.map((filter, index) => {
                                        if (filter.type === "rangeNumber") {
                                            return (
                                                <div 
                                                    className='rangeNumberFilterSecondary'
                                                    key={index}
                                                >
                                                    <p className='filterLabel'>{filter.title}</p>
                                                    <MinAndMaxValueFilter
                                                        min={filter.min}
                                                        max={filter.max}
                                                        onChangeMin={value => handleChangeSecondaryFilter(filter.setMin, value)}
                                                        onChangeMax={value => handleChangeSecondaryFilter(filter.setMax, value)}
                                                    />
                                                </div>
                                            )
                                        } else if (filter.type === "createValue") {
                                            return (
                                                <div 
                                                    className='multiSelectFilterSecondary'
                                                    key={index}
                                                >
                                                    <p className='filterLabel'>
                                                        {filter.title} 
                                                        {filter.tooltipInformationValue && 
                                                        (
                                                            <BisoTooltip 
                                                                    title={filter.tooltipInformationValue}
                                                                    placement='right-start'
                                                            >
                                                                <span className='metricInfoIcon'><RiInformationLine size={16} color="#f90c68"/></span>
                                                            </BisoTooltip>
                                                        )}
                                                    </p>
                                                    <SelectOption
                                                        options={channelsOptions}
                                                        onChange={value => handleChangeSecondaryFilter(filter.setValue, value)}
                                                        value={filter.value}
                                                        placeholder={filter.placeholder}
                                                        isDisabled={false}
                                                        isCreatable={true}
                                                    />
                                                </div>
                                            )
                                        } else {
                                            return (
                                                <div 
                                                    className='multiSelectFilterSecondary'
                                                    key={index}
                                                >
                                                    <p className='filterLabel'>{filter.title}</p>
                                                    <SelectOptionTag
                                                        options={filter.options}
                                                        onChange={value => handleChangeSecondaryFilter(filter.setValue, value)}
                                                        value={filter.value}
                                                        placeholder={filter.title}
                                                    />
                                                </div>
                                            )
                                        }
                                    })}
                                </ContainerSecondaryFilter>
                            </CustomModal>
                        </>
                    )}
                </Container>
            )}
            <Container>
                {showChannelFilter &&
                    (<div className='channelFilter'>
                        <p className='filterLabel'>{t('common.channel')}</p>
                        <SelectOption
                            options={channelsOptions}
                            onChange={handleChannelChange}
                            value={selectedChannelsOptions}
                            placeholder={t('common.all')}
                            isDisabled={false}
                            isClearable={false}
                            isSearchable={true}
                            isMulti={true}
                        />
                    </div>)
                }
                {showMultiSelectFilter &&
                    (<div className='multiSelectFilter'>
                        <p className='filterLabel'>{multiSelectOptionsTitle}</p>
                        <SelectOptionTag
                            options={multiSelectOptions}
                            onChange={handleMultiSelectChange}
                            value={selectedMultiSelectValueOptions}
                            placeholder={multiSelectOptionsTitle}
                        />
                    </div>)
                }
                {showAdditionalMultiSelectFilter &&
                    (<div className='multiSelectFilter'>
                        <p className='filterLabel'>{additionalMultiSelectOptionsTitle}</p>
                        <SelectOptionTag
                            options={additionalMultiSelectOptions}
                            onChange={handleAdditionalMultiSelectChange}
                            value={selectedAdditionalMultiSelectValueOptions}
                            placeholder={additionalMultiSelectOptionsTitle}
                        />
                    </div>)
                }
                {showDateRangeFilter && (
                    <div className='dateRangeFilter'>
                        <p className='filterLabel'>{t('common.period')}</p>
                        <ConfigProvider 
                            locale={
                                lang === LANGUAGE.es 
                                    ? esES 
                                    : lang === LANGUAGE.en_US 
                                        ? enUS : ptBR
                            }
                            theme={{
                            token: {
                                colorPrimary: '#ff0068',
                                borderRadius: 5,
                                controlHeight: 38,
                                fontFamily: 'Roboto, sans-serif',
                                fontSizeBase: 18
                            }
                            }}
                        >
                            <DatePicker.RangePicker
                                value={selectedDateRange}
                                onChange={handleDateRangeChange}
                                panelRender={(panelNode) => (
                                    <StyledRangePickerContainer>{ panelNode }</StyledRangePickerContainer>
                                )}
                                disabledDate={limitDateRangeUntilToday ? (current) => current.startOf('day').isAfter(today) : null}
                            />
                        </ConfigProvider>
                    </div>
                )}
                <button
                    className={`applyFilterButton ${!enableApplyFilterButton ? 'buttonDisabled' : ''}`}
                    onClick={handleApplyFilter} 
                    disabled={!enableApplyFilterButton}
                >
                    {t('common.apply_filter')}
                </button>
            </Container>
        </>
    )
}

export default FilterContainer;