import React, { useCallback, useEffect, useRef, useState, useContext } from 'react';

import { toast } from 'react-toastify';

import { useTranslation } from 'react-i18next';
import '../../i18n';

import CustomModal from '../../components/CustomModal/CustomModal';
import BackgroundNew from '../../components/BackgroundNew';
import CardGroup from '../../components/CardGroup';
import CustomTableApiPagination from '../../components/CustomTableApiPagination';
import FilterContainer from '../../components/FilterContainer';

import ComposedGraphBarLine from '../../components/ComposedGraphBarLine';

import { formatDate, getSelectedInitialDateRange } from '../../utils/dateUtils';
import { customRound, formatValueToMoney, formatValueToPercentage } from '../../utils/metricsUtils';
import { isValidAvailableFilter } from '../../utils/validationUtils';

import {
    getProductBehaviorAbc,
    sendReportToEmailProductBehaviorAbc,
} from '../../services/dashboardApiService';

import { AuthContext } from '../../contexts';

const ProductBehaviorAbc = () => {
    const { t } = useTranslation();
    const { timezone, currency } = useContext(AuthContext);

    const [dateRange, setDateRange] = useState();
    const [isLoading, setIsLoading] = useState(true);

    const [selectedProductIdsOptions, setSelectedProductIdsOptions] = useState([]);
    const [selectedProductNamesOptions, setSelectedProductNamesOptions] = useState([]);
    const [productDepartmentsOptions, setProductDepartmentsOptions] = useState([]);
    const [selectedProductDepartmentsOptions, setSelectedProductDepartmentsOptions] = useState([]);
    const [productCategoriesOptions, setProductCategoriesOptions] = useState([]);
    const [selectedProductCategoriesOptions, setSelectedProductCategoriesOptions] = useState([]);
    const [productSubCategoriesOptions, setProductSubCategoriesOptions] = useState([]);
    const [selectedProductSubCategoriesOptions, setSelectedProductSubCategoriesOptions] = useState([]);
    const [weekDaysOptions, setWeekDaysOptions] = useState([]);
    const [selectedWeekDaysOptions, setSelectedWeekDaysOptions] = useState([]);
    const [couponsOptions, setCouponsOptions] = useState([]);
    const [selectedCouponsOptions, setSelectedCouponsOptions] = useState([]);
    const [originsOptions, setOriginsOptions] = useState([]);
    const [selectedOriginsOptions, setSelectedOriginsOptions] = useState([]);
    const [salesChannelsOptions, setSalesChannelsOptions] = useState([]);
    const [selectedSalesChannelsOptions, setSelectedSalesChannelsOptions] = useState([]);
    const [vtexAffiliatesOptions, setVtexAffiliatesOptions] = useState([]);
    const [selectedVtexAffiliatesOptions, setSelectedVtexAffiliatesOptions] = useState([]);
    const [vtexWarehousesIdsOptions, setVtexWarehousesIdsOptions] = useState([]);
    const [selectedVtexWarehousesIdsOptions, setSelectedVtexWarehousesIdsOptions] = useState([]);
    const [abcClassificationOptions, setAbcClassificationOptions] = useState([]);
    const [selectedAbcClassificationOptions, setSelectedAbcClassificationOptions] = useState([])

    const [productsData, setProductsData] = useState([]);

    const cardApprovedRevenueAndApprovedRevenueCumulativeRef = useRef();
    const cardApprovedRevenueCumulativeAndProductsQuantityRef = useRef();

    const [exportReportLoading, setExportReportLoading] = useState(false);
    const [totalValuePercentageClassificationGraphFullScreen, setTotalValuePercentageClassificationGraphFullScreen] = useState(false)
    const [totalRevenueAndCumulativePercentageGraphFullScreen, setTotalRevenueAndCumulativePercentageGraphFullScreen] = useState(false)
    const [productBehaviorAbcTableFullScreen, setProductBehaviorAbcTableFullScreen] = useState(false)

    const [openModalInformation, setOpenModalInformation] = useState(false);

    const ProductBehaviorAbcModalInformation = () => (
        <CustomModal
            title={t('productBehaviorCurveAbc.understand_abc_curve')}
            open={openModalInformation} 
            handleClose={() => setOpenModalInformation(false)}
            hiddenConfirmButton={true}
            cancelButtonLabel={t('common.close')}
        >
            <div style={{fontSize: "15px"}}>
                <h2 style={{fontSize: "16px", marginBottom: "10px"}}>{t('productBehaviorCurveAbc.what_is_abc_curve')}</h2>
                <p>{t('productBehaviorCurveAbc.abc_curve_explanation')}</p>
                
                <h2 style={{fontSize: "16px", marginBottom: "10px", marginTop: "10px"}}>{t('productBehaviorCurveAbc.classifications')}:</h2>
                <p style={{marginBottom: "5px"}}><strong>{t('productBehaviorCurveAbc.classification_a')}</strong> {t('productBehaviorCurveAbc.classification_a_explanation')}</p>
                <p style={{marginBottom: "5px"}}><strong>{t('productBehaviorCurveAbc.classification_b')}:</strong> {t('productBehaviorCurveAbc.classification_b_explanation')}</p>
                <p style={{marginBottom: "5px"}}><strong>{t('productBehaviorCurveAbc.classification_c')}:</strong> {t('productBehaviorCurveAbc.classification_c_explanation')}</p> 

                <h2 style={{fontSize: "16px", marginBottom: "10px", marginTop: "10px"}}>{t('productBehaviorCurveAbc.how_to_interpret_data')}:</h2>
                <p style={{marginBottom: "5px"}}><strong>{t('common.approved_revenue')}</strong> {t('productBehaviorCurveAbc.individual_value')}</p>
                <p style={{marginBottom: "5px"}}><strong>{t('productBehaviorCurveAbc.accumulated_approved_revenue')}:</strong> {t('productBehaviorCurveAbc.accumulated_approved_revenue_explanation')}</p>
                <p style={{marginBottom: "5px"}}><strong>{t('productBehaviorCurveAbc.product_quantity_percentage')}:</strong> {t('productBehaviorCurveAbc.product_quantity_percentage_explanation')}</p> 
                
                <h2 style={{fontSize: "16px", marginBottom: "10px", marginTop: "10px"}}>{t('productBehaviorCurveAbc.benefits_of_abc_curve')}</h2>
                <p>{t('productBehaviorCurveAbc.abc_curve_benefits_explanation')}</p>
            </div>
        </CustomModal>
    );

    const getDataByAppliedFilter = async () => {
        const filter = getActiveFilters()

        await getDataMetrics(filter);
    };

    const getActiveFilters = () => {

        const [startDate, endDate] = dateRange;
        const productIds = selectedProductIdsOptions ? selectedProductIdsOptions.map(productId => productId.value) : null;
        const productNames = selectedProductNamesOptions ? selectedProductNamesOptions.map(productName => productName.value) : null;
        const productDepartments = selectedProductDepartmentsOptions.length > 0 ? [...selectedProductDepartmentsOptions] : null;
        const productCategories = selectedProductCategoriesOptions.length > 0 ? [...selectedProductCategoriesOptions] : null;
        const productSubcategories = selectedProductSubCategoriesOptions.length > 0 ? [...selectedProductSubCategoriesOptions] : null;
        const weekDays = selectedWeekDaysOptions.length > 0 ? [...selectedWeekDaysOptions] : null;
        const coupons = selectedCouponsOptions.length > 0 ? [...selectedCouponsOptions] : null;
        const origins = selectedOriginsOptions.length > 0 ? [...selectedOriginsOptions] : null;
        const salesChannels = selectedSalesChannelsOptions.length > 0 ? [...selectedSalesChannelsOptions] : null;
        const vtexAffiliates = selectedVtexAffiliatesOptions.length > 0 ? [...selectedVtexAffiliatesOptions] : null;
        const vtexWarehouseIds = selectedVtexWarehousesIdsOptions.length > 0 ? [...selectedVtexWarehousesIdsOptions] : null;
        const abcClassification = selectedAbcClassificationOptions.length > 0 ? [...selectedAbcClassificationOptions] : null;
        const filter = {
            initialDate: formatDate(startDate),
            finalDate: formatDate(endDate),
            timezone,
            productIds,
            productNames,
            productDepartments,
            productCategories,
            productSubcategories,
            weekDays,
            coupons,
            origins,
            salesChannels,
            vtexAffiliates,
            vtexWarehouseIds,
            abcClassification
        }

        return filter;
    }

    const handleApplyFilter = async () => {
        getDataByAppliedFilter();
    }

    const getWeekDaysFilterCustomLabel = (values) => {
        const labels = {
            1: t('common.sunday'),
            2: t('common.monday'),
            3: t('common.tuesday'),
            4: t('common.wednesday'),
            5: t('common.thursday'),
            6: t('common.friday'),
            7: t('common.saturday')
        };

        return values.map((value) => value in labels ? labels[value] : value);
    };

    const getWeekDaysFilterValueByCustomLabel = (values) => {
        const labels = {
            [t('common.sunday')]: 1,
            [t('common.monday')]: 2,
            [t('common.tuesday')]: 3,
            [t('common.wednesday')]: 4,
            [t('common.thursday')]: 5,
            [t('common.friday')]: 6,
            [t('common.saturday')]: 7
        };

        return values.map((value) => value in labels ? labels[value] : value);
    }

    const sortFilterOptions = (options) => {
        return options.sort((a, b) => a > b ? 1 : -1);
    }

    const getDataMetrics = useCallback(async (filter) => {
        setIsLoading(true);

        try {
            const productBehaviorAbcResponse = await getProductBehaviorAbc(filter);

            if (!productBehaviorAbcResponse) {
                setProductsData([])
                setProductDepartmentsOptions([]);
                setProductCategoriesOptions([]);
                setProductSubCategoriesOptions([]);
                setWeekDaysOptions([]);
                setCouponsOptions([]);
                setOriginsOptions([]);
                setSalesChannelsOptions([]);
                setVtexAffiliatesOptions([]);
                setVtexWarehousesIdsOptions([]);
                setAbcClassificationOptions([]);

                return;
            }

            setProductsData(productBehaviorAbcResponse.results)

            setProductDepartmentsOptions(sortFilterOptions(productBehaviorAbcResponse.available_product_departments_to_filter));
            setProductCategoriesOptions(sortFilterOptions(productBehaviorAbcResponse.available_product_categories_to_filter));
            setProductSubCategoriesOptions(sortFilterOptions(productBehaviorAbcResponse.available_product_sub_categories_to_filter));
            setWeekDaysOptions(sortFilterOptions(productBehaviorAbcResponse.available_week_days_to_filter));
            setCouponsOptions(productBehaviorAbcResponse.available_coupons_to_filter);
            setOriginsOptions(productBehaviorAbcResponse.available_origins_to_filter);
            setSalesChannelsOptions(productBehaviorAbcResponse.available_marketplace_channels_to_filter);

            if (isValidAvailableFilter(productBehaviorAbcResponse.available_warehouse_ids_to_filter)) {
                setVtexWarehousesIdsOptions(productBehaviorAbcResponse.available_warehouse_ids_to_filter);
            } else {
                setVtexWarehousesIdsOptions([]);
            }

            if (isValidAvailableFilter(productBehaviorAbcResponse.available_vtex_affiliates_to_filter)) {
                setVtexAffiliatesOptions(productBehaviorAbcResponse.available_vtex_affiliates_to_filter);
            } else {
                setVtexAffiliatesOptions([])
            }
            setAbcClassificationOptions(sortFilterOptions(productBehaviorAbcResponse.available_abc_classifications_to_filter));

        } catch (error) {
            toast.error(error.message);
            setProductsData([])
            setProductDepartmentsOptions([]);
            setProductCategoriesOptions([]);
            setProductSubCategoriesOptions([]);
            setWeekDaysOptions([]);
            setCouponsOptions([]);
            setOriginsOptions([]);
            setSalesChannelsOptions([]);
            setVtexAffiliatesOptions([]);
            setVtexWarehousesIdsOptions([]);
            setAbcClassificationOptions([]);
        } finally {
            setIsLoading(false);
        }
    }, []);


    const formatTotalRevenueAndCumulativePercentageData = (data = []) => {
        if (!data) {
            return null;
        }
        const aggregatedData = [];

        data.forEach(entry => {
            aggregatedData.push({
                date: entry.product_name,
                totalRevenue: entry.total_revenue,
                cumulativePercentage: customRound(entry.cumulative_percentage)
            });
        });

        return aggregatedData;
    };

    const formatTotalValuePercentageClassificationData = (data = []) => {
        if (!data) { return null }

        const aggregatedData = {};
        let totalRevenue = 0;
        let totalProducts = 0;
        let cumulativeTotalRevenue = 0;

        data.forEach(entry => {
            const key = entry.abc_classification;
            if (!aggregatedData[key]) {
                aggregatedData[key] = {
                    classification: key,
                    totalProducts: 0,
                    totalRevenue: 0

                };
            }

            aggregatedData[key].totalRevenue += entry.total_revenue
            aggregatedData[key].totalProducts += 1
            totalProducts += 1
            totalRevenue += entry.total_revenue
        });

        const aggregatedDataResult = Object.values(aggregatedData)
            .sort((a, b) => a.classification - b.classification)
            .map(item => {
                cumulativeTotalRevenue += item.totalRevenue

                item.totalProductsShare = customRound((item.totalProducts / totalProducts) * 100);
                item.cumulativeTotalRevenuePercentage = customRound((cumulativeTotalRevenue / totalRevenue) * 100)

                return item;
            });

        return aggregatedDataResult;
    };

    const handleExportReport = async () => {
        setExportReportLoading(true);

        try {
            toast.info(t('toast.wait_for_report'));

            const filter = getActiveFilters()
            await sendReportToEmailProductBehaviorAbc(filter);

            toast.success(t('toast.report_preparation'));
        } catch (error) {
            toast.error(t('toast.report_generation_failed'));
        } finally {
            setExportReportLoading(false);
        }
    };

    const getFormattedData = (data) => {
        return {
            headers: [
                { name: t('productBehaviorCurveAbc.position'), value: "product_rank", minWidth: 100, format: (value) => value },
                { name: t('productPerformance.product_id'), value: "product_id", minWidth: 50, format: (value) => value },
                { name: t('productPerformance.product_name'), value: "product_name", minWidth: 200, format: (value) => value },
                { name: t('productBehaviorCurveAbc.department'), value: "product_department_name", minWidth: 90, format: (value) => value },
                { name: t('productPerformance.category'), value: "product_category_name", minWidth: 90, format: (value) => value },
                { name: t('productBehaviorCurveAbc.subcategory'), value: "product_sub_category_name", minWidth: 90, format: (value) => value },
                { name: t('common.approved_revenue'), value: "total_revenue", minWidth: 100, format: value => formatValueToMoney(value, currency) },
                { name: t('productBehaviorCurveAbc.accumulated_approved_revenue'), value: "cumulative_revenue", minWidth: 150, format: value => formatValueToMoney(value, currency) },
                { name: t('productBehaviorCurveAbc.approved_revenue_accumulated_percentage'), value: "cumulative_percentage", minWidth: 150, format: formatValueToPercentage },
                { name: t('productBehaviorCurveAbc.classification'), value: "abc_classification", minWidth: 100, format: (value) => value },
            ],
            rows: data
        }
    }

    const getSecondaryFilters = () => {
        return [
            {
                title: t('productPerformance.product_id'),
                value: selectedProductIdsOptions,
                setValue: setSelectedProductIdsOptions,
                type: "createValue",
                placeholder: t('productPerformance.type_and_press_enter_example_1123'),
                tooltipInformationValue: t('productPerformance.search_by_product_id')
            },
            {
                title: t('productPerformance.product_name'),
                value: selectedProductNamesOptions,
                setValue: setSelectedProductNamesOptions,
                type: "createValue",
                placeholder: t('productPerformance.type_and_press_enter_example_shirt'),
                tooltipInformationValue: t('productPerformance.search_by_keywords')
            },
            {
                title: t('productPerformance.departments'),
                options: productDepartmentsOptions,
                value: selectedProductDepartmentsOptions,
                setValue: setSelectedProductDepartmentsOptions
            },
            {
                title: t('productPerformance.categories'),
                options: productCategoriesOptions,
                value: selectedProductCategoriesOptions,
                setValue: setSelectedProductCategoriesOptions
            },
            {
                title: t('productPerformance.subcategories'),
                options: productSubCategoriesOptions,
                value: selectedProductSubCategoriesOptions,
                setValue: setSelectedProductSubCategoriesOptions
            },
            {
                title: t('productPerformance.day_of_the_week'),
                options: getWeekDaysFilterCustomLabel(weekDaysOptions),
                value: getWeekDaysFilterCustomLabel(selectedWeekDaysOptions),
                setValue: (values) => setSelectedWeekDaysOptions(getWeekDaysFilterValueByCustomLabel(values))
            },
            {
                title: t('productPerformance.coupons'),
                options: couponsOptions,
                value: selectedCouponsOptions,
                setValue: setSelectedCouponsOptions
            },
            {
                title: `${t('common.source')}`,
                options: originsOptions,
                value: selectedOriginsOptions,
                setValue: setSelectedOriginsOptions
            },
            {
                title: t('common.sales_channel'),
                options: salesChannelsOptions,
                value: selectedSalesChannelsOptions,
                setValue: setSelectedSalesChannelsOptions
            },
            {
                title: `${t('common.affiliates_vtex')}`,
                options: vtexAffiliatesOptions,
                value: selectedVtexAffiliatesOptions,
                setValue: setSelectedVtexAffiliatesOptions
            },
            {
                title: t('productPerformance.warehouse_id_vtex'),
                options: vtexWarehousesIdsOptions,
                value: selectedVtexWarehousesIdsOptions,
                setValue: setSelectedVtexWarehousesIdsOptions
            },
            {
                title: t('productBehaviorCurveAbc.abc_classification'),
                options: abcClassificationOptions,
                value: selectedAbcClassificationOptions,
                setValue: setSelectedAbcClassificationOptions
            },
        ]
    }

    const onRenderComponent = useCallback(async () => {
        const { initialDateFilter, finalDateFilter } = getSelectedInitialDateRange();
        setDateRange([initialDateFilter, finalDateFilter]);

        await getDataMetrics({
            initialDate: formatDate(initialDateFilter),
            finalDate: formatDate(finalDateFilter)
        });
    }, [getDataMetrics]);

    useEffect(() => {
        onRenderComponent();
    }, [onRenderComponent]);

    return (
        <BackgroundNew
            titlePage={t('menu.abc_curve_of_products')}
            showInformation={true}
            informationTooltipTitle={t('common.click_to_learn_more')}
            informationOnClick={() => setOpenModalInformation(true)}
        >
            <FilterContainer
                selectedDateRange={dateRange}
                setDateRange={setDateRange}
                onClickFilter={handleApplyFilter}
                showSecondaryFilters
                secondaryFilters={getSecondaryFilters()}
            />
            <CardGroup
                title={t('productBehaviorCurveAbc.approved_revenue_vs_accumulated_approved_revenue_by_product')}
                tagIdentifierColor="#00CCAE"
                isLoading={isLoading}
                customClassName="cardGroupContainerFirst"
                ref={cardApprovedRevenueAndApprovedRevenueCumulativeRef}
            >
                <ComposedGraphBarLine
                    data={productsData}
                    showPrimaryBarYAxis
                    showSecondaryLineYAxis
                    primaryBarYAxisConfig={{
                        name: t('common.approved_revenue'),
                        dataKey: "totalRevenue"
                    }}
                    secondaryLineYAxisConfig={{
                        name: t('productBehaviorCurveAbc.approved_revenue_accumulated_percentage'),
                        dataKey: "cumulativePercentage"
                    }}
                    formatData={formatTotalRevenueAndCumulativePercentageData}
                    formaterBarValue={value => formatValueToMoney(value, currency)}
                    formaterLineValue={formatValueToPercentage}
                    graphMarginConfig={{
                        top: 20,
                        right: -10,
                        left: 55,
                        bottom: 20
                    }}
                    hideDateOnXAxis
                    showDimensionController={false}
                    disabledDimensionController={!productsData?.length}
                    showFullScreenOption
                    fullScreenEnabled={totalRevenueAndCumulativePercentageGraphFullScreen}
                    setFullScreenEnabled={setTotalRevenueAndCumulativePercentageGraphFullScreen}
                    showExportPDFButton
                    graphComponentRefForPDF={cardApprovedRevenueAndApprovedRevenueCumulativeRef}
                />
            </CardGroup>
            <CardGroup
                title={t('productBehaviorCurveAbc.accumulated_approved_revenue_vs_quantity_of_products_by_classification')}
                tagIdentifierColor="#00CCAE"
                isLoading={isLoading}
                customClassName="cardGroupContainerFirst"
                ref={cardApprovedRevenueCumulativeAndProductsQuantityRef}
            >
                <ComposedGraphBarLine
                    data={productsData}
                    showPrimaryBarYAxis
                    showSecondaryLineYAxis
                    primaryBarYAxisConfig={{
                        name: t('productBehaviorCurveAbc.approved_revenue_accumulated_percentage'),
                        dataKey: "cumulativeTotalRevenuePercentage"
                    }}
                    secondaryLineYAxisConfig={{
                        name: t('productBehaviorCurveAbc.quantity_products'),
                        dataKey: "totalProductsShare"
                    }}
                    formatData={formatTotalValuePercentageClassificationData}
                    formaterBarValue={formatValueToPercentage}
                    formaterLineValue={formatValueToPercentage}
                    graphMarginConfig={{
                        top: 20,
                        right: 0,
                        left: 20,
                        bottom: 20
                    }}
                    xDataKey='classification'
                    showDimensionController={false}
                    disabledDimensionController={!productsData?.length}
                    showFullScreenOption
                    fullScreenEnabled={totalValuePercentageClassificationGraphFullScreen}
                    setFullScreenEnabled={setTotalValuePercentageClassificationGraphFullScreen}
                    showExportPDFButton
                    graphComponentRefForPDF={cardApprovedRevenueCumulativeAndProductsQuantityRef}
                />
            </CardGroup>
            <CardGroup
                title={t('productBehaviorCurveAbc.products')}
                tagIdentifierColor="#00CCAE"
                isLoading={isLoading}
            >
                <CustomTableApiPagination
                    data={getFormattedData(productsData)}
                    disabledDimensionController={!productsData?.length}
                    showFullScreenOption
                    fullScreenEnabled={productBehaviorAbcTableFullScreen}
                    setFullScreenEnabled={setProductBehaviorAbcTableFullScreen}
                    handleExportCSVReport={handleExportReport}
                    showExportCSVReportButton
                    loadingExportCSVReportButton={exportReportLoading}
                    showPagination={false}
                />
            </CardGroup>
            <ProductBehaviorAbcModalInformation />
        </BackgroundNew>
    );
}

export default ProductBehaviorAbc;
