import styled from "styled-components";
import { darken } from "polished";

export const Container = styled.div``;

export const LoadingContainer = styled.div`
  width: 100%;
  height: 45vh;
  display: flex;
  align-items: center;
`;

export const EmptyMessage = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 45vh;
  width: 100%;
  font-size: 16px;
  font-weight: 300;
  color: #2e3039;
`;

export const ActionsContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: right;
  margin-bottom: 16px;

  @media screen and (max-width: 784px) {
    justify-content: center;
  }
`;

export const Button = styled.button`
  display: flex;
  align-items: center;
  color: #fff;
  transition: background-color 0.2s;
  border: 0;
  background-color: #ff0068;
  font-size: 13px;
  font-weight: 500;
  letter-spacing: -0.21px;
  border-radius: 20px;
  height: 38px;
  line-height: 35px;
  padding: 10px 15px;
  justify-content: center;

  &:hover {
    background-color: ${darken(0.05, '#ff0068')};
  }

  &:disabled {
    background-color: #999;
    border-color: #999;
    color: #fff;
    cursor: not-allowed;
    box-shadow: none;

    &:hover {
        background-color: #999;
        border-color: #999;
        color: #fff;
    }
  }
`;

export const TransparentButton = styled.button`
  display: flex;
  align-items: center;
  color: #ff0068;
  transition: background-color 0.2s;
  border: 2px solid #ff0068;
  font-size: 13px;
  font-weight: 500;
  letter-spacing: -0.21px;
  border-radius: 20px;
  height: 38px;
  line-height: 35px;
  padding: 10px 15px;
  justify-content: center;
  cursor: pointer;
  background-color: transparent;

  &:hover {
    background-color: ${darken(0.05, '#ff0068')};
    color: #fff;
    border-color: transparent;
  }
`;