import React, { useState, useEffect } from "react";
import {
    Table, TableContainer,
    TableHead, TableRow,
    TableCell, TableBody, Paper, TablePagination,
    styled
} from '@mui/material';

import { useTranslation } from 'react-i18next';
import '../../i18n';

import GraphController from '../GraphController';
import { Container } from './styles';

const CustomTable = ({
    data,
    disabledDimensionController,
    showFullScreenOption = false,
    fullScreenEnabled = false,
    setFullScreenEnabled,
    cardGroupRef
}) => {
    const { t } = useTranslation();

    const ROWS_PER_PAGE_OPTIONS = [10, 25, 50, 100];
    const DEFAULT_ROWS_PER_PAGE = 10;

    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(DEFAULT_ROWS_PER_PAGE);
    const [allData, setAllData] = useState(null);
    const [headers, setHeaders] = useState([]);
    const [rows, setRows] = useState([]);

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, DEFAULT_ROWS_PER_PAGE));
        setPage(0);
    };

    useEffect(() => {
        setPage(0);
        setAllData(data);
    }, [data]);

    useEffect(() => {
        if (allData === null) return;

        const rows = allData.rows.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage);
        setRows(rows);
        setHeaders(allData.headers);
    }, [allData, setRows, page, rowsPerPage]);

    const StyledTableRow = styled(TableRow)(({ theme }) => ({
        '&:nth-of-type(odd)': {
            backgroundColor: '#F4F4F4',
        },
    }));

    const stylesComponents = {
        mainContainerFullScreen: {
            position: 'fixed',
            top: 0,
            left: 0,
            width: '100%',
            height: '100vh',
            backgroundColor: 'white',
            zIndex: 1200
        },
        tableGroupFullScreen: {
            padding: '20px'
        },
        tableContainerFullScreen: {
            maxHeight: 'calc(100vh - 200px)',
        }
    }

    return (
        <Container style={fullScreenEnabled ? stylesComponents.mainContainerFullScreen : {}}>
            <div className="tableGroup" style={fullScreenEnabled ? stylesComponents.tableGroupFullScreen : {}}>
                <GraphController
                    disabled={disabledDimensionController}
                    showDimensionController={false}
                    showFullScreenOption={showFullScreenOption}
                    fullScreenEnabled={fullScreenEnabled}
                    setFullScreenEnabled={setFullScreenEnabled}
                    style={{ marginRight: '0px' }}
                    cardGroupRef={cardGroupRef}
                />
                <TableContainer
                    component={Paper}
                    className="tableContainer"
                    style={fullScreenEnabled ? stylesComponents.tableContainerFullScreen : {}}
                >
                    <Table className="table">
                        <TableHead className="stickyHeader">
                            <TableRow>
                                {headers.length > 0 && headers.map((header, index) => (
                                    <TableCell style={{ minWidth: header.minWidth, fontWeight: 'bold' }} key={index}>{header.name}</TableCell>
                                ))}
                            </TableRow>
                        </TableHead>
                        <TableBody className="tableBody">
                            {rows.length > 0 && rows.map((row, index) => (
                                <StyledTableRow role="checkbox" tabIndex={-1} key={index}>
                                    {headers.map((header, index) => (
                                        <TableCell className={index === 0 ? 'firstTableCell' : ''} key={index} style={{ minWidth: header.minWidth }}>{header.format(row[header.value])}</TableCell>
                                    ))}
                                </StyledTableRow>
                            ))}
                            {rows.length === 0 && (
                                <StyledTableRow role="checkbox" tabIndex={-1} key={0}>
                                    <TableCell colSpan={headers.length} style={{ textAlign: 'center' }}>{t('common.no_data')}</TableCell>
                                </StyledTableRow>
                            )}
                        </TableBody>
                    </Table>
                </TableContainer>
                <TablePagination
                    labelRowsPerPage={t('common.lines_per_page')}
                    labelDisplayedRows={({ from, to, count }) => `${from}-${to} de ${count}`}
                    rowsPerPageOptions={ROWS_PER_PAGE_OPTIONS}
                    component="div"
                    count={allData ? allData.rows.length : 0}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    onPageChange={handleChangePage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                />
            </div>
        </Container>
    );
};

export default CustomTable;
