import React, { useState, useEffect } from 'react';
import { v4 as uuidv4 } from 'uuid';

import { useTranslation } from 'react-i18next';
import './../../i18n';

import { getAlerts, getUserModules } from './../../services/AlertService';
import { getCurrentUser, getUsers } from './../../services/UserService';
import TrelloService from './../../services/TrelloService';

import queryString from 'query-string';
import CircularProgress from '@mui/material/CircularProgress';
import Box from '@mui/material/Box';
import {
  CustomModalTrello,
  CustomModalCreateUser,
  CustomModalCreateAlert,
  CustomModalAppToken,
  CustomModalProfileLogin,
} from '../CustomModal';

import { ImCheckboxChecked, ImCheckboxUnchecked, ImCheckmark } from 'react-icons/im';

import * as S from './styled';

function FirstSteps({ hiddenSetupResumeText = false, styles }) {
  const { t } = useTranslation();

  const { type: userType } = JSON.parse(localStorage.getItem('user') || '{}');

  const [activeModal, setActiveModal] = useState('');
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  
  const [steps, setSteps] = useState([]);
  const [showSteps, setShowSteps] = useState(false);
  const [remainingSteps, setRemainingSteps] = useState(0);
  const [setupProgress, setSetupProgress] = useState(0);

  const openSetupModal = (type) => {
    if (type === 'trello') {
      window.location.href = TrelloService.getLoginUrl(
        window.location.href
      );
      return false;
    };
    setAnchorEl(null);
    setActiveModal(type);
  };

  const closeSetupModal = () => {
    mountSteps();
    setActiveModal('');
  };

  const validateFirstAlert = async () => {
    const { id: user_id } = JSON.parse(localStorage.getItem('user') || '{}');
    const options = {
      params: {
        user_id,
      },
    };
    const alerts = await getAlerts(options);
    return alerts.length > 0;
  };
  
  const validateAppToken = async () => {
    const { app_token } = await getCurrentUser();
    return !!app_token;
  };

  const validateFirstUser = async () => {
    const users = await getUsers();
    return users.length > 0;
  };

  const validateUserModules = async (module) => {
    const userModules = await getUserModules();
    const moduleStatus = userModules.filter(item => item.module === module);
    return moduleStatus.length > 0;
  };
  
  const validateTrello = () => {
    const { trello } = JSON.parse(localStorage.getItem('user') || '{}');
    return trello;
  };
  
  const validateProfileLogin = async () => {
    const { first_access_user } = await getCurrentUser();
    return first_access_user;
  };

  const checkUrlToken = async () => {
    const { token } = queryString.parse(window.location.hash);
    const { trello } = JSON.parse(localStorage.getItem('user') || '{}');
    if (token && !trello) {
      setActiveModal('trello');
    }
  };

  const mountSteps = async (element) => {
    let setupSteps = [];

    if (userType === 'company') {
      const googleAnalytics = {
        name: 'googleAnalytics',
        description: t('firstStep.setup_google_analytics'),
        status: await validateUserModules('GAWA'),
      };

      const firstUser = {
        name: 'firstUser',
        description: t('firstStep.create_first_user'),
        status: await validateFirstUser(),
      };

      const trello = {
        name: 'trello',
        description: t('firstStep.configure_trello'),
        status: validateTrello(),
      };

      const profileLogin = {
        name: 'profileLogin',
        description: t('firstStep.access_user_account'),
        status: await validateProfileLogin(),
      };

      setupSteps = [
        googleAnalytics,
        firstUser,
        trello,
        profileLogin,
      ];
    } else {
      const appToken = {
        name: 'appToken',
        description: t('firstStep.setup_device'),
        status: await validateAppToken(),
      };

      const firstAlert = {
        name: 'firstAlert',
        description: t('firstStep.create_first_alert'),
        status: await validateFirstAlert(),
      };

      setupSteps = [
        appToken,
        firstAlert,
      ];
    }

    setSteps(setupSteps);
    getSetupProgress(setupSteps);
    setShowSteps(true);
    setAnchorEl(element);
  };

  const getSetupProgress = (setupSteps) => {
    const totalSteps = setupSteps.length;
    const stepsDone = setupSteps.filter(step => step.status).length;
    setRemainingSteps(totalSteps - stepsDone);
    setSetupProgress(Math.round(stepsDone * 100 / totalSteps));
  }
  
  const getStepIcon = (setupStep) => {
    return setupStep.status ? <ImCheckboxChecked color="#ff0467" /> : <ImCheckboxUnchecked color="#ff0467" />;
  };

  useEffect(() => {
    mountSteps();
    checkUrlToken();
    // eslint-disable-next-line
  }, []);

  const CircularProgressWithLabel = (props) => {
    return (
      <Box position="relative" display="inline-flex">
        <CircularProgress style={{color:"#00CCAE", width: 40}} variant="determinate" {...props}/>
        <Box
          top={0}
          left={0}
          bottom={0}
          right={0}
          position="absolute"
          display="flex"
          alignItems="center"
          justifyContent="center"
          style={{
            color:"#00CCAE",
            fontSize: '15px',
            fontWeight: '700',
            lineHeight: '24px',
          }}
        >
          {props.value === 100 && (
            <ImCheckmark />
          )}

          {props.value !== 100 && (
            `${props.value}%`
          )}

        </Box>
      </Box>
    );
  };

  return (
    <S.SetupWrapper style={styles}>
      {showSteps && (
        <S.SetupResume onClick={(ev) => {
          mountSteps(ev.currentTarget);
        }}>
          <CircularProgressWithLabel value={setupProgress} />
          {!hiddenSetupResumeText && (
            <S.SetupResumeText>
              <S.SetupResumeTitle>{t('firstStep.initial_setup')}</S.SetupResumeTitle>
              <S.SetupResumeSubtitle>
                {remainingSteps !== 0 && (
                  `${remainingSteps} ${t('firstStep.remaining_step_part1')}${remainingSteps > 1 ? 's' : ''} ${t('firstStep.remaining_step_part2')}${remainingSteps > 1 ? 's' : ''}`
                )}
                
                {remainingSteps === 0 && (
                  t('firstStep.no_further_steps')
                )}
              </S.SetupResumeSubtitle>
            </S.SetupResumeText>
          )}
        </S.SetupResume>
      )}

      <S.SetupMenu
        anchorEl={anchorEl}
        open={open}
        onClose={() => setAnchorEl(null)}
        getcontentanchorel={null}
        anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
      >
        {steps.map((step) => (
          <S.SetupMenuItem
            key={uuidv4()}
            className={step.status ? 'is--disabled' : ''}
            onClick={() => openSetupModal(step.name)}
          >
            {getStepIcon(step)}
            {step.description}
          </S.SetupMenuItem>
        ))}

      </S.SetupMenu>

      {userType === 'company' && (
        <>
          <CustomModalCreateUser open={activeModal === 'firstUser'} handleClose={closeSetupModal} />
          <CustomModalTrello open={activeModal === 'trello'} handleClose={closeSetupModal} />
          <CustomModalProfileLogin open={activeModal === 'profileLogin'} handleClose={closeSetupModal} />
        </>
      )}

      {userType === 'profile' && (
        <>
          <CustomModalCreateAlert open={activeModal === 'firstAlert'} handleClose={closeSetupModal} />
          <CustomModalAppToken open={activeModal === 'appToken'} handleClose={closeSetupModal} />
        </>
      )}


    </S.SetupWrapper>
  );
} 

export default FirstSteps;