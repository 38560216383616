import styled from 'styled-components';

export const Container = styled.div`
    .preferences-config-group {
        .preferences-field {
            margin-bottom: 20px;

            .field-label {
                margin-bottom: 5px;
            }
        }
    }

    .preferences-user-notification {
        display: flex;
        padding: 20px;
        background-color: #ffd119;
        margin-bottom: 20px;
        font-size: 15px;
        color: #2e3039;
        font-weight: 500;
    }

    .preferences-actions {
        display: flex;
        justify-content: flex-end;
        margin-top: 20px;

        .cancel-button {
            color: #455a64;
            line-height: 35px;
            text-decoration: underline;
            background: transparent;
            border:none;
            box-shadow: none;
            &:hover {
                color: #546e7a;
            }
        }
    }
`