import styled from 'styled-components';

export const Iframe = styled.iframe`
    width: 100%;
    height: calc(100vh - 190px);
    border: none;
`;

export const Text = styled.p`
    color: #2e3039;
`;

export const Link = styled.a`
    color: #f90c67;
`;