import React from 'react';

import { useTranslation } from 'react-i18next';
import '../../../i18n';

import * as S from './styled';

import Loader from '../../Loader';

function CustomModal(props) {
  const { t } = useTranslation();

  return (
    <S.CustomModal open={props.open}>
      <div className="modal">
        <div className="modal__header">
          <p className="modal__title">{props.title}</p>
          <div className="line" />
        </div>

        <div className="modal__body">
          {props.children}
        </div>

        <div className="modal__footer">
          <button
            className="modal__btn is--cancel"
            type="button"
            onClick={props.handleClose}
          >
            {props.cancelButtonLabel || t('common.cancel')}
          </button>
          {!props.hiddenConfirmButton &&
            <button
              className="modal__btn is--confirm"
              type="button"
              disabled={props.handleConfirmStatus}
              onClick={props.handleConfirm}
            >
              {props.confirmButtonLabel || t('common.save')}
              {props.confirmButtonLoading && <Loader 
                size={17}
                color='#fff'
                containerStyles={{width: "auto", height: "auto", marginLeft: 5}}
              />}
            </button>
          }
        </div>
      </div>
    </S.CustomModal>
  );
}

export default CustomModal;
