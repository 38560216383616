import i18n from '../i18n'

export const calculationMethod = "total"

let metrics = []

const updateOptions = _ => {
  metrics = [{
    label: i18n.t('alerts.stock'),
    options: [
      {
        value: "availableQuantity",
        label: i18n.t('alerts.available_quantity')
      },
      {
        value: "reservedQuantity",
        label: i18n.t('alerts.reserved_quantity')
      },
      {
        value: "totalQuantity",
        label: i18n.t('alerts.total_quantity')
      }
    ]
  }];
}

i18n.on('languageChanged', (lng) => {
  updateOptions();
});
  
updateOptions();

export { metrics };