import styled from 'styled-components';
import { darken } from 'polished';

export const Container = styled.div`
  .insightItem {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 15px 15px 15px 15px;
    justify-content: space-between;
    min-height: 76px;
    position: relative;

    box-shadow: 0 0 20px rgba(215, 222, 227, 0.39);
    border-radius: 8px;
    background-color: #ffffff;
    margin-bottom: 20px;

    .usersFeedbackInfo {
      position: absolute;
      font-size: 12px;
      right: 10px;
      top: 5px;
    }

    .insightInfo {
      display: flex;
      flex-direction: row;
      align-items: center;
      width: 100%;
      overflow: hidden;
      justify-content: space-between;

      @media (max-width: 520px) {
        flex-direction: column;
        align-items: flex-start;
      }

      .description {
        display: flex;

        .tagIdentifier {
          background-color: #ff0068;
          border-radius: 4px;
          padding: 2px 6px;
          height: 50px;
          margin-right: 15px;
        }

        .descriptionContent {
          .title {            
            white-space: wrap;
            overflow: hidden;
            text-overflow: ellipsis;            
            color: #242e42;            
            font-size: 18px;
            font-weight: 500;  
            display: flex;
            align-items: center;
  
            @media (max-width: 520px) {
              font-size: 16px;
            }
          }

          p {
            color: #90a4ae;
            font-size: 14px;
            white-space: wrap;
            overflow: hidden;
            text-overflow: ellipsis;
  
            @media (max-width: 520px) {
              font-size: 12px;
            }
          }
        }
      }

      .seeMoreGroup {
        @media (max-width: 520px) {
          width: 100%;
          display: flex;
          justify-content: center;
        }

        .seeMore {
          background-color: #ff0068;
          border: none;
          color: #ffffff;
          font-size: 13px;
          font-weight: 500;
          padding: 10px 15px;
          border-radius: 20px;
          cursor: pointer;
          transition: background-color 0.3s;
          display: flex;
          align-items: center;
    
          .seeMoreIcon {
            margin-left: 5px;
          }
    
          &:hover {
            background-color: ${darken(0.1, '#ff0068')};
          }
  
          @media (max-width: 520px) {
            margin-top: 10px;
            font-size: 12px;
          }
        }
      }
    }
  }
`;
