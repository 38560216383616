import React, { useState } from 'react';
import { toast } from 'react-toastify';

import { useTranslation } from 'react-i18next';
import '../../../i18n';

import * as S from './styled';
import { monthDictionary } from '../utils/customerGoals';
import NumberFormat from 'react-number-format';
import { 
  createProjectedCustomerGoals 
} from '../../../services/customerService';

import { InputGoalMonth } from './InputGoalMonth';

export default function CustomerModalRegistrationGoals(props) {
  const { t } = useTranslation();

  const defaultRevenueMode = 'annualRevenueMode'

  const [annualRevenue, setAnnualRevenue] = useState('');
  const [enableSaveButton, setEnableSaveButton] = useState(true);
  const [revenueMode, setRevenueMode] = useState(defaultRevenueMode);

  const [januaryRevenue, setJanuaryRevenue] = useState('');
  const [februaryRevenue, setFebruaryRevenue] = useState('');
  const [marchRevenue, setMarchRevenue] = useState('');
  const [aprilRevenue, setAprilRevenue] = useState('');
  const [mayRevenue, setMayRevenue] = useState('');
  const [juneRevenue, setJuneRevenue] = useState('');
  const [julyRevenue, setJulyRevenue] = useState('');
  const [augustRevenue, setAugustRevenue] = useState('');
  const [septemberRevenue, setSeptemberRevenue] = useState('');
  const [octoberRevenue, setOctoberRevenue] = useState('');
  const [novemberRevenue, setNovemberRevenue] = useState('');
  const [decemberRevenue, setDecemberRevenue] = useState('');

  const handleSave = async () => {
    if (revenueMode === defaultRevenueMode) {
      handleCreateProjectedCustomerGoalsAutomatic();
    } else {
      handleCreateProjectedCustomerGoalsManual();
    }
  }

  const handleCreateProjectedCustomerGoalsAutomatic = async () => {
    setEnableSaveButton(false);

    const annualRevenueWithoutFormat = props.cleanFormatedCurrency(annualRevenue);

    if (isNaN(annualRevenueWithoutFormat)) {
      toast.warning(t('toast.annual_revenue_target'));
      
      setEnableSaveButton(true);
      
      return;
    }

    if (annualRevenueWithoutFormat <= 0) {
      toast.warning(t('toast.invalid_value'));

      setEnableSaveButton(true);
      
      return;
    }

    const data = {
      year: props.activeCustomerGoalYear,
      annual_revenue: annualRevenueWithoutFormat,
      project_automatic_month_revenue: true,
      revenues_by_month: []
    };

    try {
      await createProjectedCustomerGoals(data);
      
      handleClose();

      props.setShowDescriptionLoading();

      await props.loadCustomerGoals(props.activeCustomerGoalYear, true);

      toast.success(t('toast.plan_created_success'));
    } catch (error) {
      toast.error(t('toast.plan_creation_failed'));
    } finally {
      setEnableSaveButton(true);
    }
  };

  const getRevenuesByMonth = () => {
    const januaryRevenueWithoutFormat = props.cleanFormatedCurrency(januaryRevenue);
    const februaryRevenueWithoutFormat = props.cleanFormatedCurrency(februaryRevenue);
    const marchRevenueWithoutFormat = props.cleanFormatedCurrency(marchRevenue);
    const aprilRevenueWithoutFormat = props.cleanFormatedCurrency(aprilRevenue);
    const mayRevenueWithoutFormat = props.cleanFormatedCurrency(mayRevenue);
    const juneRevenueWithoutFormat = props.cleanFormatedCurrency(juneRevenue);
    const julyRevenueWithoutFormat = props.cleanFormatedCurrency(julyRevenue);
    const augustRevenueWithoutFormat = props.cleanFormatedCurrency(augustRevenue);
    const septemberRevenueWithoutFormat = props.cleanFormatedCurrency(septemberRevenue);
    const octoberRevenueWithoutFormat = props.cleanFormatedCurrency(octoberRevenue);
    const novemberRevenueWithoutFormat = props.cleanFormatedCurrency(novemberRevenue);
    const decemberRevenueWithoutFormat = props.cleanFormatedCurrency(decemberRevenue);

    const revenuesByMonth = [
      {
        month: monthDictionary.JANUARY,
        revenue: januaryRevenueWithoutFormat || 0
      },
      {
        month: monthDictionary.FEBRUARY,
        revenue: februaryRevenueWithoutFormat || 0
      },
      {
        month: monthDictionary.MARCH,
        revenue: marchRevenueWithoutFormat || 0
      },
      {
        month: monthDictionary.APRIL,
        revenue: aprilRevenueWithoutFormat || 0
      },
      {
        month: monthDictionary.MAY,
        revenue: mayRevenueWithoutFormat || 0
      },
      {
        month: monthDictionary.JUNE,
        revenue: juneRevenueWithoutFormat || 0
      },
      {
        month: monthDictionary.JULY,
        revenue: julyRevenueWithoutFormat || 0
      },
      {
        month: monthDictionary.AUGUST,
        revenue: augustRevenueWithoutFormat || 0
      },
      {
        month: monthDictionary.SEPTEMBER,
        revenue: septemberRevenueWithoutFormat || 0
      },
      {
        month: monthDictionary.OCTOBER,
        revenue: octoberRevenueWithoutFormat || 0
      },
      {
        month: monthDictionary.NOVEMBER,
        revenue: novemberRevenueWithoutFormat || 0
      },
      {
        month: monthDictionary.DECEMBER,
        revenue: decemberRevenueWithoutFormat || 0
      }
    ];

    return revenuesByMonth;
  }

  const isValidRevenuesByMonth = (revenuesByMonth) => {
    const currentYear = getCurrentYear().toString();

    let initialMonth = 1;

    if (props.activeCustomerGoalYear === currentYear) {
      initialMonth = getCurrentMonth();
    }

    let isValid = true;

    revenuesByMonth.forEach(revenueByMonth => {
      if (revenueByMonth.month >= initialMonth && revenueByMonth.revenue <= 0) {
        isValid = false;
      }
    });

    return isValid;
  }

  const handleCreateProjectedCustomerGoalsManual = async () => {
    setEnableSaveButton(false);

    const revenuesByMonth = getRevenuesByMonth();

    if (!isValidRevenuesByMonth(revenuesByMonth)) {
      toast.warning(t('toast.monthly_revenue_target'));

      setEnableSaveButton(true);
      
      return;
    }

    const data = {
      year: props.activeCustomerGoalYear,
      annual_revenue: 0,
      project_automatic_month_revenue: false,
      revenues_by_month: revenuesByMonth
    };

    try {
      await createProjectedCustomerGoals(data);
      
      toast.success(t('toast.plan_created_success'));
      
      handleClose();

      props.setShowDescriptionLoading();

      await props.loadCustomerGoals(props.activeCustomerGoalYear, true);
    } catch (error) {
      toast.error(t('toast.plan_creation_failed'));
    } finally {
      setEnableSaveButton(true);
    }
  };

  const handleClose = () => {
    setAnnualRevenue('');
    setEnableSaveButton(true);
    setRevenueMode(defaultRevenueMode);
    cleanRevenueMonthState();

    props.handleClose();
  }

  const cleanRevenueMonthState = () => {
    setJanuaryRevenue('');
    setFebruaryRevenue('');
    setMarchRevenue('');
    setAprilRevenue('');
    setMayRevenue('');
    setJuneRevenue('');
    setJulyRevenue('');
    setAugustRevenue('');
    setSeptemberRevenue('');
    setOctoberRevenue('');
    setNovemberRevenue('');
    setDecemberRevenue('');
  }

  const getCurrentYear = () => {
    const date = new Date();
    return date.getFullYear();
  }

  const getCurrentMonth = () => {
    const date = new Date();
    return date.getMonth() + 1;
  }

  const handleChangeRevenueModel = (value) => {
    if (value === 'annualRevenueMode') {
      setAnnualRevenue('');
      setRevenueMode('annualRevenueMode');
    } else {
      cleanRevenueMonthState();
      setRevenueMode('monthlyRevenueMode');
    }
  }

  const isDisableInputMonth = (month) => {
    const currentYear = getCurrentYear().toString();

    let initialMonth = 1;

    if (props.activeCustomerGoalYear === currentYear) {
      initialMonth = getCurrentMonth();
    }

    if (month < initialMonth) {
      return true;
    }

    return false;
  }

  return (
    <S.CustomerModalRegistrationGoals open={props.open}>
      <div className={`modal ${revenueMode === defaultRevenueMode? 'limit__height': ''}`}>
        <div className="modal__header">
          <div className='model__header__group'>
            <p className="modal__title">{t('mediaPlanner.create_planning')}</p>
            <div className="line" />
          </div>
          <button
                className="modal__form-btn is--save"
                type="button"
                onClick={(() => handleChangeRevenueModel(revenueMode === defaultRevenueMode ? 'monthlyRevenueMode' : defaultRevenueMode))}
              >
                {revenueMode === defaultRevenueMode 
                  ? t('mediaPlanner.set_goal_manually')
                  : t('mediaPlanner.set_annual_goal')
                }
          </button>
        </div>

        <p className="modal__sub-header">{t('mediaPlanner.goals_per_channel')}</p>

        <div className="modal__body">
          <div className="modal__slider">
            <form className="modal__form">
              {revenueMode === defaultRevenueMode && (
                <div className="modal__form-group">
                  <span className="modal__form-label">{t('mediaPlanner.enter_annual_revenue_goal')}:</span>
                  <NumberFormat
                    allowNegative={false}
                    allowLeadingZeros={true}
                    thousandSeparator="."
                    decimalSeparator=","
                    decimalScale={2}
                    className="modal__form-input is--text"
                    placeholder="0,00"
                    value={annualRevenue}
                    onChange={(v) => setAnnualRevenue(v.target.value)}
                  />
                </div>)
              }
              {revenueMode !== defaultRevenueMode && 
                <>
                  <InputGoalMonth month={t('common.january')} value={januaryRevenue} onChange={setJanuaryRevenue} disabled={isDisableInputMonth(monthDictionary.JANUARY)}/>
                  <InputGoalMonth month={t('common.february')} value={februaryRevenue} onChange={setFebruaryRevenue} disabled={isDisableInputMonth(monthDictionary.FEBRUARY)}/>
                  <InputGoalMonth month={t('common.march')} value={marchRevenue} onChange={setMarchRevenue} disabled={isDisableInputMonth(monthDictionary.MARCH)}/>
                  <InputGoalMonth month={t('common.april')} value={aprilRevenue} onChange={setAprilRevenue} disabled={isDisableInputMonth(monthDictionary.APRIL)}/>
                  <InputGoalMonth month={t('common.may')} value={mayRevenue} onChange={setMayRevenue} disabled={isDisableInputMonth(monthDictionary.MAY)}/>
                  <InputGoalMonth month={t('common.june')} value={juneRevenue} onChange={setJuneRevenue} disabled={isDisableInputMonth(monthDictionary.JUNE)}/>
                  <InputGoalMonth month={t('common.july')} value={julyRevenue} onChange={setJulyRevenue} disabled={isDisableInputMonth(monthDictionary.JULY)}/>
                  <InputGoalMonth month={t('common.august')} value={augustRevenue} onChange={setAugustRevenue} disabled={isDisableInputMonth(monthDictionary.AUGUST)}/>
                  <InputGoalMonth month={t('common.september')} value={septemberRevenue} onChange={setSeptemberRevenue} disabled={isDisableInputMonth(monthDictionary.SEPTEMBER)}/>
                  <InputGoalMonth month={t('common.october')} value={octoberRevenue} onChange={setOctoberRevenue} disabled={isDisableInputMonth(monthDictionary.OCTOBER)}/>
                  <InputGoalMonth month={t('common.november')} value={novemberRevenue} onChange={setNovemberRevenue} disabled={isDisableInputMonth(monthDictionary.NOVEMBER)}/>
                  <InputGoalMonth month={t('common.december')} value={decemberRevenue} onChange={setDecemberRevenue} disabled={isDisableInputMonth(monthDictionary.DECEMBER)}/>
                </>
              }
            </form>
          </div>
        </div>

        <div className="modal__footer">
          <button
            className="modal__form-btn is--cancel"
            type="button"
            onClick={handleClose}
          >
            {t('common.cancel')}
          </button>
          <button
            className={`modal__form-btn is--save ${enableSaveButton ? '' : 'disable-button'}`}
            type="button"
            onClick={handleSave}
            disabled={!enableSaveButton}
          >
            {t('common.save')}
          </button>
        </div>
      </div>
    </S.CustomerModalRegistrationGoals>
  );
}
