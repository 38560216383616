import styled from "styled-components";
import { darken } from "polished";

import NumberFormat from 'react-number-format';

export const Container = styled.section`
`;

export const Input = styled.input`
  width: 100%;
  padding: 8px;
  border: 1px solid #ddd;
  border-radius: 4px;
  font-size: 14px;
  height: 38px;

  &:focus {
    border-color: ${darken(0.1, "#ddd")};
  }

  &::placeholder {
    color: #ccc;
  }

  &:disabled {
    background-color: #f2f2f2;
    cursor: not-allowed;
    color: #6f767e;
    border: 1px solid hsl(0, 0%, 90%);
  }
`;

export const DeleteButton = styled.button`
   display: flex;
  align-items: center;
  color: #FF0000;
  transition: color 0.2s;
  border: none;
  background-color: transparent;
  font-size: 13px;
  font-weight: 400;
  letter-spacing: -0.21px;
  height: 38px;
  line-height: 35px;
  padding: 10px 15px;
  justify-content: center;
  cursor: pointer;
  text-decoration: underline;

  &:hover {
    color: ${darken(0.1, "#FF0000")};
  }

  &:disabled {
    cursor: not-allowed;
    color: #ccc;
  }
`;

export const ActionsContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: right;
  margin-bottom: 16px;
`;

export const HeaderSessionContainer = styled.div`
  margin-bottom: 20px;
`;

export const HeaderSessionTitle = styled.h2`
  color: #000;
  font-weight: 400;
  margin-bottom: 6px;
`;

export const HeaderSessionSubtitle = styled.h4`
  color: #6f767e;
  font-weight: 200;
`;

export const ConfigOptionsContainer = styled.div`
  display: flex;
  flex-direction: row;
  gap: 10px;
  margin-bottom: 20px;

  @media (max-width: 650px) {
    flex-direction: column;
  }
`;

export const ConfigOptionGroup = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
`;

export const ConfigLabel = styled.label`
  font-size: 12px;
  font-weight: 400;
  line-height: 24px;
  color: #6F767E;
`;

export const GoalsDailyContainer = styled.div`
  margin-top: 16px;
`;

export const GoalsDailyTablesContainer = styled.div`
  display: flex;
  flex-direction: row;
  gap: 20px;
  margin-top: 16px;

  @media (max-width: 877px) {
    flex-direction: column;
  }
`;

export const GoalsDailyTable = styled.table`
  width: 100%;
  border: 1px solid #ddd;
  border-radius: 8px;
`;

export const GoalsDailyTableHead = styled.thead``;

export const GoalsDailyTableTh = styled.th`
  border: none;
  padding: 8px;
  text-align: center;
  background-color: transparent;
  font-weight: 700;
  font-size: 15px;
  line-height: 24px;
  letter-spacing: -1px;
  color: #6F767E;
`;

export const GoalsDailyTableBody = styled.tbody``;

export const GoalsDailyTableTr = styled.tr``;

export const GoalsDailyTableTd = styled.td`
  border: none;
  padding: 8px;
  text-align: center;
  font-weight: 300;
  font-size: 15px;
  line-height: 24px;
  letter-spacing: -1px;
  color: #1A1D1F;
`;

export const GoalsDailyTableInput = styled(NumberFormat)`
  width: 100%;
  padding: 8px;
  border: 1px solid #ddd;
  border-radius: 4px;
  font-size: 14px;
  height: 38px;

  &:disabled {
    border: 1px solid hsl(0, 0%, 90%);
    background-color: #f2f2f2;
    cursor: not-allowed;
    color: #6f767e;
  }
`;

export const GoalsActionsButtonsContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  margin-top: 20px;
`;

export const GoalsDailyMetricControllerContainer = styled.div`
  display: flex;
  flex-direction: row;
  margin-top: 20px;
  margin-bottom: 20px;
  overflow: hidden;
  border-radius: 20px;

  @media (max-width: 877px) {
    flex-direction: column;
    border-radius: 20px;
  }
`;

export const GoalsDailyMetricControllerOption = styled.span`
  display: flex;
  align-items: center;
  color: ${({ isSelected }) => (isSelected ? '#FFFFFF' : '#222222')};
  transition: color 0.2s;
  border: none;
  background-color: ${({ isSelected }) => (isSelected ? '#FF0068' : '#FFFFFF')};
  font-size: 13px;
  font-weight: 400;
  height: 40px;
  line-height: 24px;
  padding: 10px 15px;
  justify-content: center;
  border-left: ${({ hasBorderLeft }) => (hasBorderLeft ? '1px solid #ddd' : 'none')};
  width: 100%;

  @media (max-width: 877px) {
    border-left: none;
    border-top: 1px solid #ddd;
  }
`;

export const InfoAutomaticGoalsContainer = styled.span`
  display: inline-flex;
  color: #FF0068;
  margin-left: 5px;
`;
