import { routes } from '../routes';
import { getCurrentUser } from '../services/UserService';
import { getConnectorsLastSyncDate } from '../services/dashboardApiService';

const hasToken = () => {
    const token = localStorage.getItem('token');
    if (token) {
        return true;
    }
    return false;
};

const getUserToken = () => {
    return localStorage.getItem('token');
}

const getUserInfo = () => {
    const user = localStorage.getItem('user');
    return user ? JSON.parse(user) : null;
}

async function setUserInfo() {
    if (!hasToken()) {
        return;
    }

    const userInfo = await getCurrentUser();

    localStorage.setItem(
        'user',
        JSON.stringify({
        id: userInfo.id,
        type: userInfo.account_type.toLowerCase(),
        username: userInfo.name || `${userInfo.first_name} ${userInfo.last_name}`,
        trello: userInfo.settings_trello.board_id ? true : false,
        dashboard: userInfo.dashboard_url,
        email: userInfo.email,
        dashboard_group: userInfo.dashboard_group,
        dashboards: userInfo.dashboards,
        enabled_customer_goals_feature: userInfo.enabled_customer_goals_feature,
        is_company_user: userInfo.account_type.toLowerCase() === 'company',
        })
    );
}

const sessionUserIsCompanyAdmin = () => {
    const user = JSON.parse(localStorage.getItem('user'));
    return user.type === 'company';
}

const handleSignOut = (savePreviousPath = true) => {
    localStorage.removeItem('token');
    localStorage.removeItem('user');
    localStorage.removeItem('features');

    const notAllowedRoutes = [routes.signIn, routes.googleOAuthAds, routes.googleOAuthAnalytics, routes.facebookOAuthAds]

    let currentPath = window.location.pathname;
    if (currentPath.endsWith('/')) {
        currentPath = currentPath.slice(0, -1);
    }

    if (savePreviousPath && !notAllowedRoutes.includes(currentPath)) {
        localStorage.setItem('previousPathV3', window.location.pathname);
    }
    
    window.location.href = '/'
};

const getPreviousPath = () => {
    return localStorage.getItem('previousPathV3') || null;
};

const removePreviousPath = () => {
    localStorage.removeItem('previousPathV3');
};

const companyHasIntelipostConnector = async () => {
    try {
        const response = await getConnectorsLastSyncDate('America/Sao_Paulo');
        return response.some(connector => connector.connector_module_name === 'Intelipost (Pedidos)');
    } catch (error) {
        return false;
    }
}

export { setUserInfo, handleSignOut, hasToken, sessionUserIsCompanyAdmin, getPreviousPath, removePreviousPath, companyHasIntelipostConnector, getUserInfo, getUserToken }