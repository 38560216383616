import React, { useState, useEffect } from 'react';
import { toast } from 'react-toastify';

import { useTranslation } from 'react-i18next';
import '../../../i18n';

import Loader from '../../Loader';

import { getModules, createUserModule } from '../../../services/AlertService';
import { testGoogleAdsAccountAccess } from '../../../services/validationAccountAccess';
import { 
  getAuthorizationUrl, exchangeCodeForToken, generateStateIdentifier 
} from '../../../services/OAuthService';

import { FaCheckCircle } from "react-icons/fa";

import * as alertModuleSetting from '../../../options/alertModuleSetting';

import * as S from './styled';

function hyphen_replace(text) {
  return text.replace(/-/g, "");
}

function CustomModalGoogleAds(props) {
  const { t } = useTranslation();

  const [awId, setAwId] = useState('');
  const [oAuthCompleted, setOauthCompleted] = useState(false);
  const [oAuthToken, setOAuthToken] = useState(null);
  const [oAuthSuccess, setOAuthSuccess] = useState(false);
  const [oAuthInProgress, setOAuthInProgress] = useState(false);
  const [savingInProgress, setSavingInProgress] = useState(false);

  const handleAwSaveConfig = async () => {
    if (awId && oAuthToken) {
      setSavingInProgress(true);

      const userModules = await getModules();
      const setting = userModules.filter((item) => item.name === alertModuleSetting.types.AW)[0];
      
      toast.info(t('toast.connection_start'));

      if(await testGoogleAdsAccountAccess(hyphen_replace(awId), oAuthToken)) {
        const data = {
          active: true,
          settings: {
            AW: {
              profiles: JSON.stringify([{ ID: hyphen_replace(awId), TOKEN: oAuthToken }]),
            }
          }
        };

        try {
          await createUserModule(setting.id, data);

          localStorage.removeItem('oAuthGoogleAdsToken')
          
          props.handleClose();
          props.handleGetUserSettings();
          toast.success(t('toast.configuration_saved'));
        } catch (error) {
          toast.error(t('toast.configuration_save_failed'));
        }
      } else {
        toast.error(t('toast.google_auth_access_check'));
      }

      setSavingInProgress(false);
    } else {
      toast.error(t('toast.google_account_id_required'));
    }
  };

  const initiateGoogleAdsOAuthFlow = async () => {
    try {
      setOAuthInProgress(true);
  
      const stateValue = generateStateIdentifier();
  
      const response = await getAuthorizationUrl('googleAds', stateValue);
      window.open(response.url, '_blank');
  
      const handleMessage = async (event) => {
        try {
          const data = event.data;
          if (data && data.source === 'oAuthGoogleAds') {
            const { completed, success, code, state } = data;
  
            if (completed) {
              window.removeEventListener('message', handleMessage);
  
              if (!success || state !== stateValue) {
                setOauthCompleted(true);
                setOAuthSuccess(false);
  
                throw new Error();
              }
  
              setOauthCompleted(true);
  
              const responseToken = await exchangeCodeForToken('googleAds', code);
              setOAuthToken(responseToken.token);
  
              localStorage.setItem('oAuthGoogleAdsToken', responseToken.token);
  
              setOAuthSuccess(true);
  
              toast.success(t('toast.auth_success'));
            }
          }
        } catch (error) {
          toast.error(t('toast.google_auth_failed'));
        } finally {
          setOAuthInProgress(false);
        }
      };
  
      window.addEventListener('message', handleMessage);
    } catch (error) {
      toast.error(t('toast.google_auth_initiate_failed'));
    }
  };

  useEffect(() => {
    const oAuthTokenResponse = localStorage.getItem('oAuthGoogleAdsToken')

    if (oAuthTokenResponse) {
      setOAuthToken(oAuthTokenResponse)
      setOAuthSuccess(true)
      setOauthCompleted(true)
    }
  }, []);

  return (
    <S.CustomModalGoogleAds {...props}>
      <div className="modal">
        <div className="modal__header">
          <p className="modal__title">{t('connectionAndChannel.google_ads_configuration')}</p>
          <div className="line" />
        </div>

        <div className="modal__body">
          <div className="modal__slider">
            <form className="modal__form">
              <div className="modal__step-by-step">
                <ul>
                  <li>1. {t('connectionAndChannel.start_access_authorization')} <button className='modal__form-btn is--auth is--demonstrative' type="button">{t('connectionAndChannel.authorize_access')}</button></li>
                  <li>2. {t('connectionAndChannel.redirect_google_page')}</li>
                  <li>3. {t('connectionAndChannel.after_login_redirect')}: <button className='modal__form-btn is--auth is--authenticated is--demonstrative' type="button"><FaCheckCircle style={{marginRight: '5px'}} size={13} color='#fff'/> {t('connectionAndChannel.authenticated')}</button>.</li>
                  <li>4. {t('connectionAndChannel.enter_google_ads_id')}</li>
                </ul>
              </div>
              <div className='model__auth-group'>
                <button
                  className={`modal__form-btn is--auth ${(oAuthCompleted && oAuthSuccess && oAuthToken) ? 'is--authenticated' : ''}`}
                  type="button"
                  onClick={initiateGoogleAdsOAuthFlow}
                  disabled={(oAuthCompleted && oAuthSuccess && oAuthToken) || oAuthInProgress}
                >
                  {(oAuthCompleted && oAuthSuccess && oAuthToken) ? <FaCheckCircle style={{marginRight: '5px'}} size={17} color='#fff'/> : ''}
                  {(oAuthCompleted && oAuthSuccess && oAuthToken) ? t('connectionAndChannel.authenticated') :t('connectionAndChannel.authorize_access')}
                  {oAuthInProgress && <Loader 
                      size={17}
                      color='#fff'
                      containerStyles={{width: "auto", height: "auto", marginLeft: 5}}
                  />}
                </button>
              </div>
              <div className="modal__form-group">
                <span className="modal__form-label">{t('connectionAndChannel.provide_id')}:</span>
                <input
                  type="text"
                  className="modal__form-input is--text"
                  placeholder={t('connectionAndChannel.view_id')}
                  onChange={(ev) => setAwId(ev.target.value)}
                  disabled={!(oAuthCompleted && oAuthSuccess && oAuthToken) || savingInProgress}
                />
              </div>
            </form>
          </div>
        </div>

        <div className="modal__footer">
          <button
            className="modal__form-btn is--cancel"
            type="button"
            onClick={props.handleClose}
            disabled={savingInProgress}
          >
            {t('common.cancel')}
          </button>
          <button
            className="modal__form-btn is--save"
            type="button"
            onClick={handleAwSaveConfig}
            disabled={!(oAuthCompleted && oAuthSuccess && oAuthToken) || savingInProgress}
          >
            {t('common.save')}
            {savingInProgress && <Loader 
                size={17}
                color='#fff'
                containerStyles={{width: "auto", height: "auto", marginLeft: 5}}
            />}
          </button>
        </div>
      </div>
    </S.CustomModalGoogleAds>
  );
}

export default CustomModalGoogleAds;
