import React, { useState, useEffect } from "react";
import {
    Table, 
    TableContainer, 
    TableHead, 
    TableRow,
    TableCell, 
    TableBody, 
    Paper, 
    TablePagination, 
    styled
} from '@mui/material';

import { useTranslation } from 'react-i18next';
import '../../i18n';

import GraphController from '../GraphController';
import { Container } from './styles';
import {
    formatValueToMoney,
    formatValueToPercentage,
    formatValueToNumber
} from '../../utils/metricsUtils';
import {
    getAgregatedKeyByDimension
} from '../../utils/dateUtils';
import { DIMENSIONS_OPTONS } from '../../options/filterOptions';

const ROWS_PER_PAGE_OPTIONS = [10, 25, 50, 100];
const DEFAULT_ROWS_PER_PAGE = 10;

const TableCampaign = ({
    data,
    selectedDimension,
    setSelectedDimension,
    showDimensionController,
    disabledDimensionController,
    showFullScreenOption = false,
    fullScreenEnabled = false,
    setFullScreenEnabled,
    mailMarketing = false,
    handleExportCSVReport,
    showExportCSVReportButton,
    loadingExportCSVReportButton,
    currency
}) => {
    const { t } = useTranslation();

    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(DEFAULT_ROWS_PER_PAGE);
    const [allData, setAllData] = useState([]);
    const [rows, setRows] = useState([]);
    const [total, setTotal] = useState(null);

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, DEFAULT_ROWS_PER_PAGE));
        setPage(0);
    };

    const campaignsGroupByDimension = (data, dimension) => {
        if (!data) { return null }

        const dataSorted = data.sort((a, b) => new Date(b.date) - new Date(a.date));
        const aggregatedData = {};

        dataSorted.forEach(entry => {
            const keyDimension = getAgregatedKeyByDimension(entry.date, dimension);
            const key = `${keyDimension}_${entry.campaign}_${entry.source_medium}`;
            if (!aggregatedData[key]) {
                aggregatedData[key] = {
                    date: keyDimension,
                    campaign: entry.campaign,
                    sourceMedium: entry.source_medium,
                    googleAnalyticsCapturedRevenue: 0.0,
                    ecommerceApprovedRevenue: 0.0,
                    googleAnalyticsConversionRate: 0.0,
                    transactions: 0,
                    sessions: 0,
                    users: 0,
                    newUsers: 0,
                    investment: 0.0,
                    clicks: 0,
                    impressions: 0,
                    ctr: 0.0,
                    cpt: 0.0,
                    cpc: 0.0,
                    roasPercentage: 0.0
                };
            }

            aggregatedData[key].googleAnalyticsCapturedRevenue += entry.google_analytics_captured_revenue;
            aggregatedData[key].ecommerceApprovedRevenue += entry.ecommerce_approved_revenue;
            aggregatedData[key].transactions += entry.transactions;
            aggregatedData[key].sessions += entry.sessions;
            aggregatedData[key].users += entry.users;
            aggregatedData[key].newUsers += entry.new_users;
            aggregatedData[key].investment += entry.investment;
            aggregatedData[key].clicks += entry.clicks;
            aggregatedData[key].impressions += entry.impressions;

            aggregatedData[key].googleAnalyticsConversionRate = entry.google_analytics_conversion_rate;
            aggregatedData[key].ctr = entry.ctr;
            aggregatedData[key].cpt = entry.cpt;
            aggregatedData[key].cpc = entry.cpc;
            aggregatedData[key].roasPercentage = entry.roas_percentage;
        });

        if (dimension !== DIMENSIONS_OPTONS.day) {
            Object.keys(aggregatedData).forEach(key => {
                const googleAnalyticsConversionRate = aggregatedData[key].sessions > 0 ? aggregatedData[key].transactions / aggregatedData[key].sessions : 0.0;
                const ctr = aggregatedData[key].impressions > 0 ? aggregatedData[key].clicks / aggregatedData[key].impressions : 0.0;
                const cpt = aggregatedData[key].transactions > 0 ? aggregatedData[key].investment / aggregatedData[key].transactions : 0.0;
                const cpc = aggregatedData[key].clicks > 0 ? aggregatedData[key].investment / aggregatedData[key].clicks : 0.0;
                const roas = aggregatedData[key].investment > 0 ? aggregatedData[key].googleAnalyticsCapturedRevenue / aggregatedData[key].investment : 0.0;

                aggregatedData[key].googleAnalyticsConversionRate = Number((googleAnalyticsConversionRate * 100).toFixed(2));
                aggregatedData[key].ctr = Number((ctr * 100).toFixed(2));
                aggregatedData[key].cpt = Number((cpt).toFixed(2));
                aggregatedData[key].cpc = Number((cpc).toFixed(2));
                aggregatedData[key].roasPercentage = Number((roas * 100).toFixed(2));

                aggregatedData[key].googleAnalyticsCapturedRevenue = Number((aggregatedData[key].googleAnalyticsCapturedRevenue).toFixed(2));
                aggregatedData[key].ecommerceApprovedRevenue = Number((aggregatedData[key].ecommerceApprovedRevenue).toFixed(2));
                aggregatedData[key].investment = Number((aggregatedData[key].investment).toFixed(2));
            })
        }

        return Object.values(aggregatedData);
    };

    const generateTotal = (data) => {
        const total = {
            googleAnalyticsCapturedRevenue: 0,
            ecommerceApprovedRevenue: 0,
            googleAnalyticsConversionRate: 0,
            transactions: 0,
            sessions: 0,
            users: 0,
            newUsers: 0,
            investment: 0,
            clicks: 0,
            impressions: 0,
            ctr: 0,
            cpt: 0,
            cpc: 0,
            roasPercentage: 0
        };

        if (data === null || data.length === 0) return total;

        data.forEach((row) => {
            total.googleAnalyticsCapturedRevenue += row.googleAnalyticsCapturedRevenue;
            total.ecommerceApprovedRevenue += row.ecommerceApprovedRevenue;
            total.googleAnalyticsConversionRate += row.googleAnalyticsConversionRate;
            total.transactions += row.transactions;
            total.sessions += row.sessions;
            total.users += row.users;
            total.newUsers += row.newUsers;
            total.investment += row.investment;
            total.clicks += row.clicks;
            total.impressions += row.impressions;
            total.ctr += row.ctr;
            total.cpt += row.cpt;
            total.cpc += row.cpc;
            total.roasPercentage += row.roasPercentage;
        });

        total.googleAnalyticsConversionRate = Number((total.googleAnalyticsConversionRate / data.length).toFixed(2));;
        total.ctr = Number((total.ctr / data.length).toFixed(2));
        total.cpt = Number((total.cpt / data.length).toFixed(2));
        total.cpc = Number((total.cpc / data.length).toFixed(2));
        total.roasPercentage = Number((total.roasPercentage / data.length).toFixed(2));

        return total;
    }

    useEffect(() => {
        setPage(0);
        setAllData(campaignsGroupByDimension(data, selectedDimension));
    }, [data, selectedDimension]);

    useEffect(() => {
        if (allData === null) return;

        const rows = allData.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage);
        setRows(rows);
        setTotal(generateTotal(rows));
    }, [allData, setRows, page, rowsPerPage]);

    const StyledTableRow = styled(TableRow)(({ theme }) => ({
        '&:nth-of-type(odd)': {
            backgroundColor: '#F4F4F4'
        }
    }));

    const stylesComponents = {
        mainContainerFullScreen: {
            position: 'fixed',
            top: 0,
            left: 0,
            width: '100%',
            height: '100vh',
            backgroundColor: 'white',
            zIndex: 1200
        },
        tableGroupFullScreen: {
            padding: '20px'
        },
        tableContainerFullScreen: {
            maxHeight: 'calc(100vh - 200px)',
        }
    }

    const defineStickyCellBackgroundColor = (index) => {
        return index % 2 === 0 ? '#F4F4F4' : '#fff';
    };

    return (
        <Container style={fullScreenEnabled ? stylesComponents.mainContainerFullScreen : {}}>
            <div className="tableGroup" style={fullScreenEnabled ? stylesComponents.tableGroupFullScreen : {}}>
                <GraphController
                    selectedDimension={selectedDimension}
                    setSelectedDimension={setSelectedDimension}
                    disabled={disabledDimensionController}
                    showDimensionController={showDimensionController}
                    showFullScreenOption={showFullScreenOption}
                    fullScreenEnabled={fullScreenEnabled}
                    setFullScreenEnabled={setFullScreenEnabled}
                    style={{ marginRight: '0px' }}
                    handleExportCSVReport={handleExportCSVReport}
                    showExportCSVReportButton={showExportCSVReportButton}
                    loadingExportCSVReportButton={loadingExportCSVReportButton}
                />
                <TableContainer
                    component={Paper}
                    className="tableContainer"
                    style={fullScreenEnabled ? stylesComponents.tableContainerFullScreen : {}}
                >
                    <Table className="table">
                    <TableHead className="stickyHeader">
                        <TableRow>
                        <TableCell className="firstTableCellHeader">{t('common.date')}</TableCell>
                        <TableCell className="stickyCell" style={{ minWidth: "250px", maxWidth: "400px", fontWeight: 'bold'}}>{t('common.campaign')}</TableCell>
                        <TableCell style={{ minWidth: "150px", maxWidth: "200px", fontWeight: 'bold' }}>{t('common.source_media')}</TableCell>
                        <TableCell style={{ minWidth: "200px", fontWeight: 'bold' }}>{t('paidMediaCampaign.captured_revenue_google_analytics')}</TableCell>
                        <TableCell style={{ minWidth: "200px", fontWeight: 'bold' }}>{t('paidMediaCampaign.approved_revenue_platform')}</TableCell>
                        <TableCell style={{ minWidth: "200px", fontWeight: 'bold' }}>{t('paidMediaCampaign.conversion_rate_google_analytics')}</TableCell>
                        <TableCell style={{ minWidth: "100px", fontWeight: 'bold' }}>{t('common.transactions')}</TableCell>
                        <TableCell style={{ minWidth: "100px", fontWeight: 'bold' }}>{t('common.sessions')}</TableCell>
                        {!mailMarketing &&
                                <>
                                    <TableCell style={{ minWidth: "100px", fontWeight: 'bold' }}>{t('common.users')}</TableCell>
                                    <TableCell style={{ minWidth: "100px", fontWeight: 'bold' }}>{t('common.new_users')}</TableCell>
                                    <TableCell style={{ minWidth: "200px", fontWeight: 'bold' }}>{t('common.investment')}</TableCell>
                                    <TableCell style={{ minWidth: "100px", fontWeight: 'bold' }}>{t('common.clicks')}</TableCell>
                                    <TableCell style={{ minWidth: "100px", fontWeight: 'bold' }}>{t('common.impressions')}</TableCell>
                                    <TableCell style={{ minWidth: "100px", fontWeight: 'bold' }}>{t('common.ctr')}</TableCell>
                                    <TableCell style={{ minWidth: "100px", fontWeight: 'bold' }}>CPT</TableCell>
                                    <TableCell style={{ minWidth: "100px", fontWeight: 'bold' }}>CPC</TableCell>
                                    <TableCell style={{ minWidth: "100px", fontWeight: 'bold' }}>{t('common.roas')}</TableCell>
                                </>
                        }
                        </TableRow>
                    </TableHead>
                    <TableBody className="tableBody">
                        {rows.length > 0 && rows.map((row, index) => (
                        <StyledTableRow role="checkbox" tabIndex={-1} key={index}>
                            <TableCell className="firstTableCell">{row.date}</TableCell>
                            <TableCell className="tableCell stickyCell" style={{ minWidth: "250px", maxWidth: "400px", overflow: "auto", wordWrap: "break-word", fontWeight: 500, backgroundColor: defineStickyCellBackgroundColor(index)}}>{row.campaign}</TableCell>
                            <TableCell className="tableCell" style={{ minWidth: "150px", maxWidth: "200px", overflow: "auto", wordWrap: "break-word"}}>{row.sourceMedium}</TableCell>
                            <TableCell className="tableCell" style={{ minWidth: "200px"}}>{formatValueToMoney(row.googleAnalyticsCapturedRevenue,  currency)}</TableCell>
                            <TableCell className="tableCell" style={{ minWidth: "200px"}}>{formatValueToMoney(row.ecommerceApprovedRevenue, currency)}</TableCell>
                            <TableCell className="tableCell" style={{ minWidth: "200px"}}>{formatValueToPercentage(row.googleAnalyticsConversionRate)}</TableCell>
                            <TableCell className="tableCell" style={{ minWidth: "100px"}}>{formatValueToNumber(row.transactions)}</TableCell>
                            <TableCell className="tableCell" style={{ minWidth: "100px"}}>{formatValueToNumber(row.sessions)}</TableCell>
                            {!mailMarketing &&
                                <>
                                    <TableCell className="tableCell" style={{ minWidth: "100px"}}>{formatValueToNumber(row.users)}</TableCell>
                                    <TableCell className="tableCell" style={{ minWidth: "100px"}}>{formatValueToNumber(row.newUsers)}</TableCell>
                                    <TableCell className="tableCell" style={{ minWidth: "200px"}}>{formatValueToMoney(row.investment, currency)}</TableCell>
                                    <TableCell className="tableCell" style={{ minWidth: "100px"}}>{formatValueToNumber(row.clicks)}</TableCell>
                                    <TableCell className="tableCell" style={{ minWidth: "100px"}}>{formatValueToNumber(row.impressions)}</TableCell>
                                    <TableCell className="tableCell" style={{ minWidth: "100px"}}>{formatValueToPercentage(row.ctr)}</TableCell>
                                    <TableCell className="tableCell" style={{ minWidth: "100px"}}>{formatValueToMoney(row.cpt, currency)}</TableCell>
                                    <TableCell className="tableCell" style={{ minWidth: "100px"}}>{formatValueToMoney(row.cpc, currency)}</TableCell>
                                    <TableCell className="tableCell" style={{ minWidth: "100px"}}>{formatValueToPercentage(row.roasPercentage)}</TableCell>
                                </>
                            }
                        </StyledTableRow>
                        ))}
                        {rows.length === 0 && (
                            <StyledTableRow role="checkbox" tabIndex={-1} key={0}>
                                <TableCell colSpan={17} style={{ textAlign: 'center' }}>{t('common.no_data')}</TableCell>
                            </StyledTableRow>
                        )}
                    </TableBody>
                    {total && (
                        <StyledTableRow className="stickyTotalRow">
                            <TableCell className="stickyCellTotalRowTitle" style={{ fontWeight: 'bold' }}>{t("common.total_page")}:</TableCell>
                            <TableCell className="stickyCellTotalRow" style={{ fontWeight: 'bold' }}></TableCell>
                            <TableCell className="stickyCellTotalRow" style={{ fontWeight: 'bold' }}></TableCell>
                            <TableCell style={{ fontWeight: 'bold' }}>{formatValueToMoney(total.googleAnalyticsCapturedRevenue, currency)}</TableCell>
                            <TableCell style={{ fontWeight: 'bold' }}>{formatValueToMoney(total.ecommerceApprovedRevenue, currency)}</TableCell>
                            <TableCell style={{ fontWeight: 'bold' }}>{formatValueToPercentage(total.googleAnalyticsConversionRate)}</TableCell>
                            <TableCell style={{ fontWeight: 'bold' }}>{formatValueToNumber(total.transactions)}</TableCell>
                            <TableCell style={{ fontWeight: 'bold' }}>{formatValueToNumber(total.sessions)}</TableCell>
                            {!mailMarketing &&
                                <>
                                    <TableCell style={{ fontWeight: 'bold' }}>{formatValueToNumber(total.users)}</TableCell>
                                    <TableCell style={{ fontWeight: 'bold' }}>{formatValueToNumber(total.newUsers)}</TableCell>
                                    <TableCell style={{ fontWeight: 'bold' }}>{formatValueToMoney(total.investment, currency)}</TableCell>
                                    <TableCell style={{ fontWeight: 'bold' }}>{formatValueToNumber(total.clicks)}</TableCell>
                                    <TableCell style={{ fontWeight: 'bold' }}>{formatValueToNumber(total.impressions)}</TableCell>
                                    <TableCell style={{ fontWeight: 'bold' }}>{formatValueToPercentage(total.ctr)}</TableCell>
                                    <TableCell style={{ fontWeight: 'bold' }}>{formatValueToMoney(total.cpt, currency)}</TableCell>
                                    <TableCell style={{ fontWeight: 'bold' }}>{formatValueToMoney(total.cpc, currency)}</TableCell>
                                    <TableCell style={{ fontWeight: 'bold' }}>{formatValueToPercentage(total.roasPercentage)}</TableCell>
                                </>
                            }
                        </StyledTableRow>
                    )}
                    </Table>
                </TableContainer>
                <TablePagination
                    labelRowsPerPage={t('common.lines_per_page')}
                    labelDisplayedRows={({ from, to, count }) => `${from}-${to} de ${count}`}
                    rowsPerPageOptions={ROWS_PER_PAGE_OPTIONS}
                    component="div"
                    count={allData ? allData.length : 0}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    onPageChange={handleChangePage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                />
            </div>
        </Container>
    );
};

export default TableCampaign;
