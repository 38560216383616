/* eslint-disable camelcase */
import React, { useState, useEffect } from 'react';
import { toast } from 'react-toastify';
import Slide from '@mui/material/Slide';
import { MdAdd, MdClose, MdFileDownload, MdFileUpload } from 'react-icons/md';
import * as S from './styles';
import BackgroundNew from '../../components/BackgroundNew';
import api from '../../services/api';
import UserMenu from '../../components/User/UserMenu';
import { AlertModal, CloseButton } from '../Alerts/styles';
import { readFileAsync, getFileLines } from '../../utils/fileUtils';
import CustomModal from '../../components/CustomModal/CustomModal';
import Loader from '../../components/Loader';

import { useTranslation } from 'react-i18next';
import '../../i18n';

function Users() {
  const { t } = useTranslation();

  const [show, setShow] = useState(false);
  const [showUploadUsersList, setShowUploadUsersList] = useState(false);
  const [showEdit, setShowEdit] = useState(false);
  const [showDelete, setShowDelete] = useState(false);
  const [userId, setUserId] = useState('');
  const [users, setUsers] = useState([]);
  const [first_name, setFirst_name] = useState('');
  const [last_name, setLast_name] = useState('');
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [username, setUsername] = useState('');
  const [usersListFile, setUsersListFile] = useState(null);
  const [enableSaveButton, setEnableSaveButton] = useState(true);
  const [openModalInformation, setOpenModalInformation] = useState(false);
  const [loading, setLoading] = useState(true);

  const handleCancel = () => {
    setShow(false);
    setShowEdit(false);
    setShowDelete(false);
    setShowUploadUsersList(false);
    setEnableSaveButton(true);

    setFirst_name('');
    setLast_name('');
    setEmail('');
    setPassword('');
    setConfirmPassword('');
    setUsername('');
    setUsersListFile(null);
  }

  const handleCreatePopup = () => setShow(!show);
  const handleShowPopupUploadUsersListFile = () => setShowUploadUsersList(!showUploadUsersList);

  const handleEditPopup = () => {
      setShowEdit(!showEdit);
  };

  const handleDeletePopup = () => {
      setShowDelete(!showDelete);
  };

  const getUserType = () => {
    const userInfo = JSON.parse(localStorage.getItem('user'));

    if (userInfo && userInfo.type) {
      return userInfo.type;
    }

    return null;
  };

  async function listUser() {
    setLoading(true);

    const token = await localStorage.getItem('token');

    const response = await api.get('/users/profiles', {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });

    setUsers(response.data);

    setLoading(false);
  };

  useEffect(() => {
    listUser();
  }, []);

  async function searchUser(id) {
    const token = await localStorage.getItem('token');

    const response = await api.get(`/users/profiles?profile_id=${id}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });

    response.data.map(function(user){
      setFirst_name(user.first_name);
      setLast_name(user.last_name);
      setEmail(user.email);
      setUsername(user.username);
      return true;
    });
  };

  const handleShow = (user, action) => {
    switch(action) {
      case 'delete':
        setDelete(user.id);
        break;

      case 'edit':
        setEdit(user.id);
        break;

      default:
        return false;
    }
  };

  async function handleUserStatus(userId, userStatus) {
    const token = await localStorage.getItem('token');
    const data = {
      status: userStatus,
    };

    await api.put(`/users/profile/${userId}`, data, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });

    listUser();
  };

  async function setDelete(id) {
    setUserId(id);
    handleDeletePopup();
  };

  async function setEdit(id) {
    await setUserId(id);
    await searchUser(id);
    await handleEditPopup();
  };

  async function handleCreateFromFile() {
    if (usersListFile === null) {
      toast.error(t('toast.select_file_import'));
      return;
    }

    setEnableSaveButton(false);

    let contentCSV = await readFileAsync(usersListFile);

    let linesCSVFile = getFileLines(contentCSV);
      
    linesCSVFile.shift(); // remove header

    if (linesCSVFile.length === 0) {
      toast.error(t('toast.invalid_file'));

      setEnableSaveButton(true);

      return;
    }

    let users = [];

    for (const line of linesCSVFile) {
      let lineSplited = line.split(',');

      if (lineSplited.length === 0 || lineSplited.length < 5) {
        break;
      }

      let data = {
        user: {
          username: lineSplited[3],
          password: lineSplited[4],
        },
        user_data: {
          profile: {
            email: lineSplited[2],
            department: 'Developer',
            first_name: lineSplited[0],
            last_name: lineSplited[1],
            phone: '',
          },
        },
      };

      if (isValidUser(data)) {
        users.push(data);
      } else {
        break;
      }
    }

    if (users.length === 0) {
      toast.error(t('toast.invalid_file'));

      setEnableSaveButton(true);

      return;
    }

    try {
      const token = await localStorage.getItem('token');

      let response = await api.post('/users/list', users, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      if (response.status !== 200) {
        throw new Error('');
      }

      toast.success(t('toast.users_created_success'));
      
      handleCancel();
      
      listUser();
    } catch {
      toast.error(
        t('toast.user_creation_error')
      );

      setEnableSaveButton(true);
    }
  };

  function isValidUser(user) {
    let result = true;

    var mailformat = /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(.\w{2,3})+$/;

    if (!user.user_data.profile.email.match(mailformat)) {
      result = false;
    }

    if (user.user.password.length < 8) {
      result = false;
    }

    if (user.user.username.length === 0) {
      result = false;
    }

    if (user.user_data.profile.first_name.length === 0) {
      result = false;
    }

    if (user.user_data.profile.last_name.length === 0) {
      result = false;
    }

    return result;
  }

  async function handleCreate() {
    if (password === confirmPassword && password.length >= 8) {
      try {
        const token = await localStorage.getItem('token');

        const data = {
          user: {
            username: username,
            password,
          },
          user_data: {
            profile: {
              email,
              department: 'Developer',
              first_name,
              last_name
            },
          },
        };

        const response = await api.post('/users/', data, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });

        if (response.status !== 200) {
          throw new Error('');
        }

        toast.success(t('toast.user_created_success'));
        handleCreatePopup();
        listUser();
        handleCancel();
      } catch {
        toast.error(
          t('toast.user_creation_error_again')
        );
      }
    } else {
      toast.error(
        t('toast.password_mismatch_error')
      );
    }
  };

  async function handleEdit() {
    if (password === confirmPassword && password.length >= 8) {
      try {
        const token = await localStorage.getItem('token');

        const data = {
            username: username,
            password,
            email,
            department: 'Developer',
            first_name,
            last_name
        };

        const response = await api.put(`/users/profile/${userId}`, data, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });

        if (response.status !== 200) {
          throw new Error('');
        }

        toast.success(t('toast.user_updated_success'));
        handleEditPopup();
        listUser();
        handleCancel();
      } catch {
        toast.error(
          t('toast.user_update_error')
        );
      }
    } else {
      toast.error(
        t('toast.password_mismatch_error')
      );
    }
  };

  async function handleDelete() {
    try {
      const token = await localStorage.getItem('token');

      await api.delete(`/users/profile/${userId}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      toast.success(t('toast.user_removed_success'));
      handleDeletePopup();
      listUser();
    } catch {
      toast.error(t('toast.user_remove_error'));
    }
  };
  return (
    <BackgroundNew 
      titlePage={t('appBar.manage_users')}
      subTitlePage={t('users.build_your_team')}
      showInformation={true}
      informationTooltipTitle={t('common.click_to_learn_more')}
      informationOnClick={() => setOpenModalInformation(true)}
    >
      <S.Container>
        <div className="header">
          <div className="group">
            {getUserType() === 'company' && (
              <button className="add_users_list" type="button" onClick={handleShowPopupUploadUsersListFile}>
                  {t('users.add_users_csv')}
                <MdFileUpload size={30} />
              </button>
            )}
            {getUserType() === 'company' && (
              <button type="button" onClick={handleCreatePopup}>
                {t('users.add_user')}
                <MdAdd size={22} />
              </button>
            )}
          </div>
        </div>

        <div className="workSpace">
          {(!loading && users && users.length > 0) && users.map((user, index) => (
            <Slide direction="up" in mountOnEnter unmountOnExit>
              <div className="option" key={index}>
                <div className="user">
                  <p className="word">
                    {user.first_name.substring(0, 1)}
                    {user.last_name.substring(0, 1)}
                  </p>

                  <div className="descriptionOption">
                    <p className="titleOption">
                      {user.first_name} {user.last_name}
                    </p>
                    <p>{user.username}</p>
                  </div>
                </div>

                <div className="functions">

                  {getUserType() === 'company' && (
                    <>
                      <S.OnOff checked={user.status} onChange={() => handleUserStatus(user.id, !user.status)} />
                      <UserMenu user={user} show={handleShow} />
                    </>
                  )}
                </div>
              </div>
            </Slide>
          ))}
          {!loading && users.length === 0 && (
            <div className="noUsers">
              <p>Nenhum usuário encontrado</p>
            </div>
          )}
          {loading && (
            <div className="loading">
              <Loader/>
            </div>
          )}
        </div>
      </S.Container>

      <S.NewRegister open={showUploadUsersList} onClose={handleCancel}>
        <div className="modal">
          <div className="modal__header">
            <div className='modal__header-inner-wrapper'>
              <p className="titleModal">{t('users.create_users_csv')}</p>
              <S.CloseButton
                type="button"
                title={t('common.close')}
                onClick={handleCancel}
              >
                <MdClose size={25} color="#000" />
              </S.CloseButton>
            </div>
            <div className="line" />
          </div>

          <div className="modal__body">
            <div className="group">
              <div className="input">
                <p>{t('common.upload_file')}*</p>
                <input
                  type="file"
                  id="usersList"
                  accept=".csv"
                  onChange={event => setUsersListFile(event.target.files[0])}
                />
              </div>
              <div className="modal__body__download__group">
                <p>{t('common.download_template_file')}</p>
                <a className='modal__body__download__group__button_download' href="https://drive.google.com/uc?export=download&id=1GqHplOmfOJOAZCOx5jBEVczedWeQe5vI" download="modelo_usuarios.csv">{t('common.download_file')} <MdFileDownload size={16} /></a>
              </div>
            </div>
          </div>

          <div className="modal__footer">
            <button
              className="btn btn--cancel"
              type="button"
              onClick={handleCancel}
            >
              {t('common.cancel')}
            </button>
            <button
              className="btn btn--save"
              type="button"
              onClick={handleCreateFromFile}
              disabled={!enableSaveButton}
            >
              {t('common.save')}
            </button>
          </div>
        </div>
      </S.NewRegister>

      <S.NewRegister open={show} onClose={handleCancel}>
        <div className="modal">
          <div className="modal__header">
            <div className='modal__header-inner-wrapper'>
              <p className="titleModal">{t('users.new_user')}</p>
              <S.CloseButton
                type="button"
                title={t('common.close')}
                onClick={handleCancel}
              >
                <MdClose size={25} color="#000" />
              </S.CloseButton>
            </div>
            <div className="line" />
          </div>

          <div className="modal__body">
            <div className="group">
              <div className="input">
                <p>{t('users.first_name')} *</p>
                <input
                  placeholder={t('users.first_name')}
                  value={first_name}
                  onChange={(value) => setFirst_name(value.target.value)}
                />
              </div>
              <div className="input">
                <p>{t('users.middle_name')} *</p>
                <input
                  placeholder={t('users.middle_name')}
                  value={last_name}
                  onChange={(value) => setLast_name(value.target.value)}
                />
              </div>
            </div>
            <div className="inputNormal">
              <p>{t('users.email')} *</p>
              <input
                placeholder={t('users.email')}
                type="email"
                value={email}
                onChange={(value) => setEmail(value.target.value)}
              />
            </div>
            <div className="inputNormal">
              <p>{t('users.user')} *</p>
              <input
                placeholder={t('users.user')}
                value={username}
                onChange={(value) => setUsername(value.target.value)}
              />
            </div>
            <div className="inputNormal">
              <p>{t('users.password')} *</p>
              <input
                placeholder={t('users.password')}
                type="password"
                value={password}
                onChange={(value) => setPassword(value.target.value)}
              />
            </div>
            <div className="inputNormal">
              <p>{t('users.confirm_password')} *</p>
              <input
                placeholder={t('users.confirm_password')}
                type="password"
                value={confirmPassword}
                onChange={(value) => setConfirmPassword(value.target.value)}
              />
            </div>
          </div>

          <div className="modal__footer">
            <button
              className="btn btn--cancel"
              type="button"
              onClick={handleCancel}
            >
              {t('common.cancel')}
            </button>
            <button
              className="btn btn--save"
              type="button"
              onClick={handleCreate}
            >
              {t('common.save')}
            </button>
          </div>
        </div>
      </S.NewRegister>

      <S.NewRegister open={showEdit} onClose={handleCancel}>
        <div className="modal">
          <div className="modal__header">
            <div className='modal__header-inner-wrapper'>
              <p className="titleModal">{t('users.edit_user')}</p>
              <S.CloseButton
                type="button"
                title={t('common.close')}
                onClick={handleCancel}
              >
                <MdClose size={25} color="#000" />
              </S.CloseButton>
            </div>
            <div className="line" />
          </div>

          <div className="modal__body">
            <div className="group">
              <div className="input">
                <p>{t('users.first_name')} *</p>
                <input
                  placeholder={t('users.first_name')}
                  value={first_name}
                  onChange={(value) => setFirst_name(value.target.value)}
                />
              </div>
              <div className="input">
                <p>{t('users.middle_name')} *</p>
                <input
                  placeholder={t('users.middle_name')}
                  value={last_name}
                  onChange={(value) => setLast_name(value.target.value)}
                />
              </div>
            </div>
            <div className="inputNormal">
              <p>{t('users.email')} *</p>
              <input
                placeholder={t('users.email')}
                type="email"
                value={email}
                onChange={(value) => setEmail(value.target.value)}
              />
            </div>
            <div className="inputNormal">
              <p>{t('users.user')} *</p>
              <input
                placeholder={t('users.user')}
                value={username}
                onChange={(value) => setUsername(value.target.value)}
              />
            </div>
            <div className="inputNormal">
              <p>{t('users.password')} *</p>
              <input
                placeholder={t('users.password')}
                type="password"
                value={password}
                onChange={(value) => setPassword(value.target.value)}
              />
            </div>
            <div className="inputNormal">
              <p>{t('users.confirm_password')} *</p>
              <input
                placeholder={t('users.confirm_password')}
                type="password"
                value={confirmPassword}
                onChange={(value) => setConfirmPassword(value.target.value)}
              />
            </div>
          </div>

          <div className="modal__footer">
            <button
              className="btn btn--cancel"
              type="button"
              onClick={handleCancel}
            >
              {t('common.cancel')}
            </button>
            <button
              className="btn btn--save"
              type="button"
              onClick={() => {handleEdit()}}
            >
              {t('alerts.edit')}
            </button>
          </div>
        </div>
      </S.NewRegister>

      <AlertModal open={showDelete} onClose={handleCancel}>
        <div className={`modal is--delete`}>
          <div className="modal__header">
            <div className="modal__header-inner-wrapper">
              <p className="titleModal">
                {t('users.delete_user')}
              </p>
              <CloseButton
                type="button"
                title={t('common.close')}
                onClick={handleCancel}
              >
                <MdClose size={25} color="#000" />
              </CloseButton>
            </div>
            <div className="line" />
          </div>
          <div className="modal__body">
            <p>
              {t('users.confirm_delete_user')} <br />
              <strong>{t('common.this_action_cannot_be_undone')}</strong>
            </p>
          </div>
          <div className="modal__footer">
            <button
              className="btn btn--cancel"
              type="button"
              onClick={handleCancel}
            >
              {t('common.cancel')}
            </button>
            <button
              className="btn btn--save"
              type="button"
              onClick={() => {handleDelete()}}
            >
              {t('common.confirm')}
            </button>
          </div>
        </div>
      </AlertModal>
      <CustomModal
        title={t('users.understand_users_feature')}
        open={openModalInformation} 
        handleClose={() => setOpenModalInformation(false)}
        hiddenConfirmButton={true}
        cancelButtonLabel={t('common.close')}
      >
        <div className="video">
          <iframe width="100%" height="315" src="https://www.youtube.com/embed/seSRxGOV6cc?si=E5m6ejlSGfBVPv3G" title="YouTube video player" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture;" allowFullScreen></iframe>
        </div>
      </CustomModal>
    </BackgroundNew>
  );
}

export default Users;
