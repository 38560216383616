import i18n from '../../../i18n';

export const monthDictionary = {
  JANUARY: 1,
  FEBRUARY: 2,
  MARCH: 3,
  APRIL: 4,
  MAY: 5,
  JUNE: 6,
  JULY: 7,
  AUGUST: 8,
  SEPTEMBER: 9,
  OCTOBER: 10,
  NOVEMBER: 11,
  DECEMBER: 12
}

const getCurrentYear = () => {
  const date = new Date();
  return date.getFullYear().toString();
}

const getNextYear = () => {
  const date = new Date();
  return (date.getFullYear() + 1).toString();
}

const getPastYear = () => {
  const date = new Date();
  return (date.getFullYear() - 1).toString();
}

export const getMonthName = (month) => {
  const monthNumber = parseInt(month);
  return months[monthNumber];
}

let months = {}
let yearOptions = []

const updateOptions = _ => { 
  months = {
    1: `${i18n.t('common.january')}`,
    2: `${i18n.t('common.february')}`,
    3: `${i18n.t('common.march')}`,
    4: `${i18n.t('common.april')}`,
    5: `${i18n.t('common.may')}`,
    6: `${i18n.t('common.june')}`,
    7: `${i18n.t('common.july')}`,
    8: `${i18n.t('common.august')}`,
    9: `${i18n.t('common.september')}`,
    10: `${i18n.t('common.october')}`,
    11: `${i18n.t('common.november')}`,
    12: `${i18n.t('common.december')}`
  };

  yearOptions = [{
    label: `${i18n.t('common.year')}`,
    options: [
      {
        value: getNextYear(),
        label: `${i18n.t('mediaPlanner.next_year')}/${getNextYear()}`
      },
      {
        value: getCurrentYear(),
        label: `${i18n.t('mediaPlanner.current_year')}/${getCurrentYear()}`
      },
      {
        value: getPastYear(),
        label: `${i18n.t('mediaPlanner.last_year')}/${getPastYear()}`
      }
    ]
  }];
}

i18n.on('languageChanged', (lng) => {
  updateOptions();
});

updateOptions();

export { months, yearOptions };