import styled from 'styled-components';

export const LaoderContainer = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  
  .icon__container {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 18px;

    @keyframes rotating {
      from {    
        transform: rotate(0deg);
      }
      to {    
        transform: rotate(360deg);
      }
    }
  
    svg {
      animation: rotating .5s linear infinite;
    }
  }

  .active__description {
    font-size: 13px;
    font-weight: 400;
    align-self: center;
    color: #2e3039;

    @media screen and (max-width: 768px) {
      font-size: 12px;
    }
  }
`;