import styled from 'styled-components';

export const CardGroupContainer = styled.div`  
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: left;
  border: none;
  color: #1A1D1F;
  margin-bottom: 20px;
  background: #transparent;

  .cardGroupContent {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    overflow-x: auto;
    scroll-behavior: smooth;

    .noData {
      font-size: 15px;
      font-weight: 200;
    }

    /* width */
      ::-webkit-scrollbar {
        width: 0px;
        height: 0px;
      }

      /* Track */
      ::-webkit-scrollbar-track {
        box-shadow: #f1f1f1; 
        border-radius: 10px;
        width: 5px;
      }
      
      /* Handle */
      ::-webkit-scrollbar-thumb {
        background: #DEDEDE; 
        border-radius: 10px;
      }

      /* Handle on hover */
      ::-webkit-scrollbar-thumb:hover {
        background: #C8C8C8; 
      }
  }
`;

export const Card = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    color: #1A1D1F;
    padding: 15px 25px;
    border-radius: 10px;
    margin: 0 10px;
    background: #FFF;
    min-width: 165px;
    max-height: 100px;
    min-height: 100px;

    .cardTitle {
      font-size: 15px;
      font-weight: normal;
      text-align: left;
      margin-bottom: 10px;
      display: flex;
      align-items: center;
      justify-content: left;

      .metricInfoIcon {
        margin-left: 5px;
        cursor: pointer;
      }
    }

    .totalValue {
      font-size: 18px;
      font-weight: bold;
      text-align: left;
      margin-bottom: 10px;
    }

    .comparativeGroup {
      display: flex;
      flex-direction: column;
      justify-content: left;
      width: 180px;

      .comparativeValue {
        padding: 5px 10px;
        background: #F4F4F4;
        font-size: 12px;
        font-weight: bold;
        border-radius: 5px;
        margin-bottom: 5px;

        .arrowCardValue {
          margin-right: 5px;
        }

        .comparativeText {
          color: #6F767E;
          font-weight: bold;
          font-size: 12px;
          margin-left: 5px;
        }
      }
    }
`;