import i18n from '../i18n'

export const additionalConfig = {
    additional_config_facebook_ads_search_level: 'campaign'
}

export const dimension = "date"

export const defaultComparedFrom = "today"

let metrics = []
let metricsFilter = {}
let dimensionsFilter = {}

const updateOptions = _ => { 
    metrics = [
        {
            label: i18n.t('alerts.campaign_metrics'),
            options: [
                { value: "wish_bid", label: "Wish Bib" },
                { value: "spend", label: "Spend" },
                { value: "social_spend", label: "Social Spend" },
                { value: "reach", label: "Reach" },
                { value: "qualifying_question_qualify_answer_rate", label: "Qualifying Question Qualify Answer Rate" },
                { value: "instant_experience_clicks_to_start", label: "Instant Experience Clicks To Start" },
                { value: "instant_experience_clicks_to_open", label: "Instant Experience Clicks To Open" },
                { value: "inline_post_engagement", label: "Inline Post Engagement" },
                { value: "inline_link_clicks", label: "Inline Link Clicks" },
                { value: "inline_link_click_ctr", label: "Inline Lick Click CTR" },
                { value: "impressions", label: "Impressions" },
                { value: "full_view_reach", label: "Full View Reach" },
                { value: "full_view_impressions", label: "Full View Impressions" },
                { value: "frequency", label: "Frequency" },
                { value: "frequency_value", label: "Frequency Value" },
                { value: "estimated_ad_recallers_upper_bound", label: "Estimated Ad Recallers Upper Bound" },
                { value: "estimated_ad_recallers_lower_bound", label: "Estimated Ad Recallers Lower Bound" },
                { value: "estimated_ad_recall_rate_upper_bound", label: "Estimated Ad Recall Rate Upper Bound" },
                { value: "estimated_ad_recall_rate_lower_bound", label: "Estimated Ad Recall Rate Lower Bound" },
                { value: "dda_countby_convs", label: "DDA Count By Convs" },
                { value: "ctr", label: "CTR" },
                { value: "cpp", label: "CPP" },
                { value: "cpm", label: "CPM" },
                { value: "cpc", label: "CPC" },
                { value: "cost_per_unique_inline_link_click", label: "Cost Per Unique Inline Link Click" },
                { value: "cost_per_unique_click", label: "Cost Per Unique Click" },
                { value: "cost_per_inline_post_engagement", label: "Cost Per Inline Post Engagement" },
                { value: "cost_per_inline_link_click", label: "Cost Per Inline Lick Click" },
                { value: "cost_per_dda_countby_convs", label: "Cost Per DDA Count By Convs" },
                { value: "clicks", label: "Clicks" },
                { value: "canvas_avg_view_time", label: "Canvas Avg View Time" },
                { value: "canvas_avg_view_percent", label: "Canvas Avg View Percent" },
                { value: "action_values", label: "Action Values" },
                { value: "actions", label: "Actions" },
                { value: "ad_click_actions", label: "Ad Click Actions" },
                { value: "ad_impression_actions", label: "Ad Impression Actions" },
                { value: "catalog_segment_actions", label: "Catalog Segment Actions" },
                { value: "catalog_segment_value", label: "Catalog Segment Value" },
                { value: "catalog_segment_value_mobile_purchase_roas", label: "Catalog Segment Value Mobile Purchase Roas" },
                { value: "catalog_segment_value_omni_purchase_roas", label: "Catalog Segment Value Omni Purchase Roas" },
                { value: "catalog_segment_value_website_purchase_roas", label: "Catalog Segment Value Website Purchase Roas" },
                { value: "conversion_values", label: "Conversion Values" },
                { value: "conversions", label: "Conversions" },
                { value: "converted_product_quantity", label: "Converted Product Quantity" },
                { value: "converted_product_value", label: "Converted Product Value" },
                { value: "cost_per_15_sec_video_view", label: "Cost Per 15 Sec Video View" },
                { value: "cost_per_2_sec_continuous_video_view", label: "Cost Per 2 Sec Continuous Video View" },
                { value: "cost_per_action_type", label: "Cost Per Action Type" },
                { value: "cost_per_ad_click", label: "Cost Per Ad Click" },
                { value: "cost_per_conversion", label: "Cost Per Conversion" },
                { value: "cost_per_outbound_click", label: "Cost Per Outbound Click" },
                { value: "cost_per_unique_action_type", label: "Cost Per Unique Action Type" },
                { value: "cost_per_unique_conversion", label: "Cost Per Unique Conversion" },
                { value: "cost_per_unique_outbound_click", label: "Cost Per Unique Outbound Click" },
                { value: "mobile_app_purchase_roas", label: "Mobile App Purchase Roas" },
                { value: "outbound_clicks", label: "Outbound Clicks" },
                { value: "outbound_clicks_ctr", label: "Outbound Clicks CTR" },
                { value: "purchase_roas", label: "Purchase Roas" },
                { value: "video_30_sec_watched_actions", label: "Video 30 Sec Watched Actions" },
                { value: "video_avg_time_watched_actions", label: "Video Avg Time Watched Actions" },
                { value: "video_continuous_2_sec_watched_actions", label: "Video Continuous 2 Sec Watched Actions" },
                { value: "video_p100_watched_actions", label: "Video P100 Watched Actions" },
                { value: "video_p25_watched_actions", label: "Video P25 Watched Actions" },
                { value: "video_p50_watched_actions", label: "Video P50 Watched Actions" },
                { value: "video_p75_watched_actions", label: "Video P75 Watched Actions" },
                { value: "video_p95_watched_actions", label: "Video P95 Watched Actions" },
                { value: "video_play_actions", label: "Video Play Actions" },
                { value: "video_time_watched_actions", label: "Video Time Watched Actions" },
                { value: "website_ctr", label: "Website CTR" },
                { value: "website_purchase_roas", label: "Website Purchase Roas" }
            ]
        }
    ]

    metricsFilter = {
        condition: [
            { value: '==', label: i18n.t('alerts.equal') },
            { value: '!=', label: i18n.t('alerts.different') },
            { value: '>', label: i18n.t('alerts.greater_than') },
            { value: '>=', label: i18n.t('alerts.greater_than_or_equal_to') },
            { value: '<', label: i18n.t('alerts.less_than') },
            { value: '<=', label: i18n.t('alerts.less_than_or_equal_to') }
        ],
        match: [
            { value: 'AND', label: 'E' }
        ],
        defaultMatch: 'AND',
        options: [
            {
                label: i18n.t('alerts.campaign_metrics'),
                options: [
                    { value: "wish_bid", label: "Wish Bib" },
                    { value: "spend", label: "Spend" },
                    { value: "social_spend", label: "Social Spend" },
                    { value: "reach", label: "Reach" },
                    { value: "qualifying_question_qualify_answer_rate", label: "Qualifying Question Qualify Answer Rate" },
                    { value: "instant_experience_clicks_to_start", label: "Instant Experience Clicks To Start" },
                    { value: "instant_experience_clicks_to_open", label: "Instant Experience Clicks To Open" },
                    { value: "inline_post_engagement", label: "Inline Post Engagement" },
                    { value: "inline_link_clicks", label: "Inline Link Clicks" },
                    { value: "inline_link_click_ctr", label: "Inline Lick Click CTR" },
                    { value: "impressions", label: "Impressions" },
                    { value: "full_view_reach", label: "Full View Reach" },
                    { value: "full_view_impressions", label: "Full View Impressions" },
                    { value: "frequency", label: "Frequency" },
                    { value: "frequency_value", label: "Frequency Value" },
                    { value: "estimated_ad_recallers_upper_bound", label: "Estimated Ad Recallers Upper Bound" },
                    { value: "estimated_ad_recallers_lower_bound", label: "Estimated Ad Recallers Lower Bound" },
                    { value: "estimated_ad_recall_rate_upper_bound", label: "Estimated Ad Recall Rate Upper Bound" },
                    { value: "estimated_ad_recall_rate_lower_bound", label: "Estimated Ad Recall Rate Lower Bound" },
                    { value: "dda_countby_convs", label: "DDA Count By Convs" },
                    { value: "ctr", label: "CTR" },
                    { value: "cpp", label: "CPP" },
                    { value: "cpm", label: "CPM" },
                    { value: "cpc", label: "CPC" },
                    { value: "cost_per_unique_inline_link_click", label: "Cost Per Unique Inline Link Click" },
                    { value: "cost_per_unique_click", label: "Cost Per Unique Click" },
                    { value: "cost_per_inline_post_engagement", label: "Cost Per Inline Post Engagement" },
                    { value: "cost_per_inline_link_click", label: "Cost Per Inline Lick Click" },
                    { value: "cost_per_dda_countby_convs", label: "Cost Per DDA Count By Convs" },
                    { value: "clicks", label: "Clicks" },
                    { value: "canvas_avg_view_time", label: "Canvas Avg View Time" },
                    { value: "canvas_avg_view_percent", label: "Canvas Avg View Percent" }
                ]
            }
        ]
    }

    dimensionsFilter = {
        condition: [
            { value: '==', label: i18n.t('alerts.equal') },
            { value: '!=', label: i18n.t('alerts.different') },
            { value: '=@', label: i18n.t('alerts.contains') },
            { value: '!@', label: i18n.t('alerts.does_not_contain') }
        ],
        match: [
            { value: 'AND', label: 'E' }
        ],
        defaultMatch: 'AND',
        options: [
            {
                label: i18n.t('alerts.campaign_dimensions'),
                options: [
                    { value: "account_currency", label: "Account Currency" },
                    { value: "account_id", label: "Account Id" },
                    { value: "account_name", label: "Account Name" },
                    { value: "ad_format_asset", label: "Ad Format Asset" },
                    { value: "ad_id", label: "Ad Id" },
                    { value: "ad_name", label: "Ad Name" },
                    { value: "adset_id", label: "Adset Id" },
                    { value: "adset_name", label: "Adset Name" },
                    { value: "age_targeting", label: "Age Targeting" },
                    { value: "buying_type", label: "Buying Type" },
                    { value: "campaign_id", label: "Campaign Id" },
                    { value: "campaign_name", label: "Campaign Name" },
                    { value: "country", label: "Country" },
                    { value: "created_time", label: "Created Time" },
                    { value: "date_start", label: "Date Start" },
                    { value: "date_stop", label: "Date Stop" },
                    { value: "device_platform", label: "Device Platform" },
                    { value: "dma", label: "Dma" },
                    { value: "gender_targeting", label: "Gender Targeting" },
                    { value: "hourly_stats_aggregated_by_advertiser_time_zone", label: "Hourly Stats Aggregated By Advertiser Time Zone" },
                    { value: "hourly_stats_aggregated_by_audience_time_zone", label: "Hourly Stats Aggregated By Audience Time Zone" },
                    { value: "impression_device", label: "Impression Device" },
                    { value: "impressions_dummy", label: "Impressions Dummy" },
                    { value: "labels", label: "Labels" },
                    { value: "location", label: "Location" },
                    { value: "objective", label: "Objective" },
                    { value: "optimization_goal", label: "Optimization Goal" },
                    { value: "platform_position", label: "Platform Position" },
                    { value: "product_id", label: "Product Id" },
                    { value: "publisher_platform", label: "Publisher Platform" },
                    { value: "user_segment_key", label: "User Segment Key" }
                ]
            }
        ]
    }
};

i18n.on('languageChanged', (lng) => {
    updateOptions();
});
  
updateOptions();

export { metrics, metricsFilter, dimensionsFilter } 
