import styled from 'styled-components';
import { darken } from 'polished';

export const AppBarContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px 20px;
  background-color: #FAFAFA;
  position: fixed;
  top: 0;
  left: ${({ isExpanded, expandedWidth, normalWidth, isMobile }) => (isExpanded ? `${!isMobile ? expandedWidth: 0}px` : `${!isMobile ? normalWidth: 0}px`)};
  width: ${({ isExpanded, expandedWidth, normalWidth, isMobile }) => (isExpanded ? `calc(100% - ${!isMobile ? expandedWidth : 0}px)`: `calc(100% - ${!isMobile ? normalWidth : 0}px)`)};
  transition: ${({ isMobile }) => (isMobile ? 'none' : 'left 0.3s ease-in-out, width 0.3s ease-in-out')};
  height: ${({ isMobile }) => (isMobile ? '60px' : '94px')};
  z-index: 900;
`;

export const SearchBar = styled.div`
  display: flex;
  align-items: center;
  background-color: #F4F4F4;
  width: 360px;
  height: 48px;
  border: none;
  border-radius: 12px;
  padding: 8px;
  margin-left: ${({ isExpanded }) => (isExpanded ? '0px' : '10px')};

  &:focus-within {
    border: 2px solid #FF0068;
  }
`;

export const SearchInput = styled.input`
  border: none;
  outline: none;
  background-color: #F4F4F4;
  padding: 5px;
  margin-left: 10px;
  width: 192px;
  height: 24px;
  font-size: 15px;
  font-weight: 300;
  line-height: 24px;
  flex: 1;
  caret-color: #FF0068;
`;

export const SearchContentResults = styled.div`
  position: absolute;
  width: 360px;
  background-color: #FFFFFF;
  border-radius: 12px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  padding: 10px;
  z-index: 1000;
  max-height: 405px;
  overflow-y: auto;

  /* width */
  ::-webkit-scrollbar {
    width: 0px;
    height: 0px;
  }

  /* Track */
  ::-webkit-scrollbar-track {
    box-shadow: #f1f1f1; 
    border-radius: 10px;
    width: 5px;
  }
  
  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: #DEDEDE; 
    border-radius: 10px;
  }

  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
    background: #C8C8C8; 
  }
`;

export const SearchContentResultsList = styled.ul`
  list-style: none;
  margin: 0;
  padding: 0;
`;

export const SearchContentResultsListDescription = styled.p`
  font-size: 13px;
  font-weight: 600;
  line-height: 16px;
  color: #6F767E;
  margin-top: 8px;
  margin-bottom: 8px;
  padding-left: 12px;
`;

export const SearchContentResultsItem = styled.li`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 8px 12px;
  cursor: pointer;
  border-radius: 12px;
  transition: background-color 0.2s;

  &:hover {
    background-color: #f0f0f0;
  }
    
`;

export const SearchContentResultsItemDescription = styled.span`
  color: #1A1D1F;
  font-size: 15px;
  font-weight: 300;
  line-height: 16px;
`;

export const SearchContentResultsItemOptionSubDescription = styled.p`
  font-size: 13px;
  font-weight: 600;
  line-height: 16px;
  color: #6F767E;
`;

export const ActionContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-left: ${({ isMobile }) => (isMobile ? '0' : '20px')};
`;

export const LastScreenButton = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #FF0068;
  color: #FCFCFC;
  border: none;
  border-radius: 30px;
  padding: 12px 20px 12px 20px;
  font-size: 15px;
  font-weight: 500;
  cursor: pointer;
  width: 160px;
  height: 48px;
  transition: background-color 0.2s;

  &:hover {
    background-color: ${darken(0.05, '#ff0068')};
  }

  &:disabled {
    background-color: #ccc;
    color: #F4F4F4;
    cursor: not-allowed;
  }
`;

export const IconContainer = styled.div`
  color: ${({ isSelected }) => (isSelected ? `${darken(0.05, '#6F767E')}` : '#6F767E')};
  cursor: pointer;
  display: flex;
  align-items: center;
  transition: color 0.2s, background-color 0.2s ease-in-out;
  margin-left: ${({ isMobile }) => (isMobile ? '8px' : '24px')};
  border-radius: 50%;
  background-color: ${({ isSelected }) => (isSelected ? `#F2F2F2` : 'transparent')};

  &:hover {
    color: ${darken(0.05, '#6F767E')};
    background-color: #F2F2F2;
  }
`;

export const IconImage = styled.img`
  width: ${({ isMobile }) => (isMobile ? '32px' : '48px')};
  height: ${({ isMobile }) => (isMobile ? '32px' : '48px')};
`;

export const Profile = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${({ isSelected }) => (isSelected ? `${darken(0.05, '#FF0068')}` : '#FF0068')};
  border-radius: 50%;
  width: 40px;
  height: 40px;
  margin-left: 24px;
  cursor: pointer;
  transition: background-color 0.2s;
  font-size: 15px;
  font-weight: 700;
  color: #FFFFFF;

  &:hover {
    background-color: ${darken(0.05, '#FF0068')};
  }
`;

export const SettingsMenu = styled.div`
  position: absolute;
  background: white;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  z-index: 1000;
  padding: 10px;

  &::before {
    content: '';
    position: absolute;
    top: -10px;
    left: 50%;
    transform: translateX(-50%);
    border-width: 0 10px 10px 10px;
    border-style: solid;
    border-color: transparent transparent white transparent;
  }
`;

export const SettingsMenuListItems = styled.ul`
  list-style: none;
  padding: 0;
  margin: 0;
`;

export const SettingsMenuItem = styled.li`
  padding: 8px 12px;
  cursor: pointer;
  color: ${({ isSelected }) => (isSelected ? '#FF0068' : '#1A1D1F')} ;
  font-size: 14px;
  font-weight: 300;
  line-height: 16px;
  border-radius: 12px;
  transition: background-color 0.2s;
  border: ${({ isSelected }) => (isSelected ? '1px solid #FF0068' : 'none')};
  margin-bottom: 8px;

  &:hover {
    background-color: #f0f0f0;
  }
`;

export const ButtonMobileMenu = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 24px;
  height: 24px;
  background-color: transparent;
  border: none;
  color: #6F767E;
`;

export const LogoMobileMenu = styled.img`
  height: 28px;
`;
