import React from 'react';

import { useTranslation } from 'react-i18next';
import '../../../i18n';

import CustomModal from '../CustomModal';

const ModalAnomalyExplanation = ({ open, handleClose }) => {
    const { t } = useTranslation();

    return (
        <CustomModal
            title={t('anomalies.understand_anomaly_detection')}
            open={open} 
            handleClose={handleClose}
            hiddenConfirmButton={true}
            cancelButtonLabel={t('common.close')}
        >
            <div style={{fontSize: "15px"}}>
                <h2 style={{fontSize: "16px", marginBottom: "10px"}}>{t('anomalies.how_is_anomaly_detection_performed')}</h2>
                <p>{t('anomalies.anomaly_detection_description')}</p>
                <ol>
                    <li>
                        <h2 style={{fontSize: "16px", marginBottom: "10px", marginTop: "10px"}}>{t('goalsOverview.model_training')}</h2>
                        <p>{t('goalsOverview.model_training_description')}</p>
                    </li>
                    <li>
                        <h2 style={{fontSize: "16px", marginBottom: "10px", marginTop: "10px"}}>{t('anomalies.how_to_interpret_results')}</h2>
                        <p style={{marginBottom: "5px"}}><strong>{t('goalsOverview.result_part1')}</strong> {t('goalsOverview.result_part2')}</p>
                        <p style={{marginBottom: "5px"}}><strong>{t('anomalies.anomaly')}:</strong> {t('anomalies.anomaly_description')}</p>
                        <p style={{marginBottom: "5px"}}><strong>{t('anomalies.tolerance_limits')}:</strong> {t('anomalies.tolerance_limits_description')}</p>  
                    </li>
                    <li>
                        <h2 style={{fontSize: "16px", marginBottom: "10px", marginTop: "10px"}}>{t('anomalies.importance_of_anomaly_detection')}</h2>
                        <p>{t('anomalies.importance_of_anomaly_detection_description')}</p>
                        <ul style={{marginTop: "10px", marginBottom: "20px"}}>
                            <li style={{marginBottom: "5px", marginLeft: "20px"}}>{t('anomalies.identification_of_operational_issues')}</li>
                            <li style={{marginBottom: "5px", marginLeft: "20px"}}>{t('goalsOverview.forecasting_impact_part4')}</li>
                        </ul>
                    </li>
                </ol>
                <p>{t('anomalies.summary_of_anomaly_detection')}</p>
            </div>
        </CustomModal>
    );
}

export default ModalAnomalyExplanation;