import i18n from "../i18n";

export const additionalConfig = null

export const dimension = "date"

export const defaultComparedFrom = "today"

let metrics = []
let metricsFilter = {}
let dimensionsFilter = {}

const updateOptions = _ => {
    metrics = [
        {
            label: i18n.t('alerts.metrics'),
            options: [
                { value: "active1DayUsers", label: "1 Day Active Users" },
                { value: "active28DayUsers", label: "28 Day Active Users" },
                { value: "active7DayUsers", label: "7 Day Active Users" },
                { value: "activeUsers", label: "Active Users" },
                { value: "addToCarts", label: "Add To Carts" },
                { value: "advertiserAdClicks", label: "Advertiser Ad Clicks" },
                { value: "advertiserAdCost", label: "Advertiser Ad Cost" },
                { value: "advertiserAdCostPerClick", label: "Advertiser Ad Cost Per Click" },
                { value: "advertiserAdCostPerConversion", label: "Advertiser Ad Cost Per Conversion" },
                { value: "advertiserAdImpressions", label: "Advertiser Ad Impressions" },
                { value: "averagePurchaseRevenue", label: "Average Purchase Revenue" },
                { value: "averagePurchaseRevenuePerUser", label: "Average Purchase Revenue Per User" },
                { value: "averageSessionDuration", label: "Average Session Duration" },
                { value: "bounceRate", label: "Bounce Rate" },
                { value: "checkouts", label: "Checkouts" },
                { value: "conversions", label: "Conversions" },
                { value: "ecommercePurchases", label: "Ecommerce Purchases" },
                { value: "engagedSessions", label: "Engaged Sessions" },
                { value: "engagementRate", label: "Engagement Rate" },
                { value: "eventCount", label: "Event Count" },
                { value: "eventCountPerUser", label: "Event Count Per User" },
                { value: "eventValue", label: "Event Value" },
                { value: "itemListClickEvents", label: "Item List Click Events" },
                { value: "itemListClickThroughRate", label: "Item List Click Through Rate" },
                { value: "itemListViewEvents", label: "Item List View Events" },
                { value: "itemPromotionClickThroughRate", label: "Item Promotion Click Through Rate" },
                { value: "itemRevenue", label: "Item Revenue" },
                { value: "itemsAddedToCart", label: "Items Added To Cart" },
                { value: "itemsCheckedOut", label: "Items Checked Out" },
                { value: "itemsClickedInList", label: "Items Clicked In List" },
                { value: "itemsPurchased", label: "Items Purchased" },
                { value: "itemsViewed", label: "Items Viewed" },
                { value: "itemsViewedInList", label: "Items Viewed In List" },
                { value: "itemsViewedInPromotion", label: "Items Viewed In Promotion" },
                { value: "itemViewEvents", label: "Item View Events" },
                { value: "newUsers", label: "New Users" },
                { value: "organicGoogleSearchClicks", label: "Organic Google Search Clicks" },
                { value: "organicGoogleSearchClickThroughRate", label: "Organic Google Search Click Through Rate" },
                { value: "organicGoogleSearchImpressions", label: "Organic Google Search Impressions" },
                { value: "promotionClicks", label: "Promotion Clicks" },
                { value: "promotionViews", label: "Promotion Views" },
                { value: "publisherAdClicks", label: "Publisher Ad Clicks" },
                { value: "publisherAdImpressions", label: "Publisher Ad Impressions" },
                { value: "purchaserConversionRate", label: "Purchaser Conversion Rate" },
                { value: "purchaseRevenue", label: "Purchase Revenue" },
                { value: "purchaseToViewRate", label: "Purchase To View Rate" },
                { value: "returnOnAdSpend", label: "Return On Ad Spend" },
                { value: "screenPageViews", label: "Views" },
                { value: "screenPageViewsPerSession", label: "Views Per Session" },
                { value: "screenPageViewsPerUser", label: "Views Per User" },
                { value: "sessionConversionRate", label: "Session Conversion Rate" },
                { value: "sessions", label: "Sessions" },
                { value: "sessionsPerUser", label: "Sessions Per User" },
                { value: "totalAdRevenue", label: "Total Ad Revenue" },
                { value: "totalPurchasers", label: "Total Purchasers" },
                { value: "totalRevenue", label: "Total Revenue" },
                { value: "totalUsers", label: "Total Users" },
                { value: "transactions", label: "Transactions" },
                { value: "transactionsPerPurchaser", label: "Transactions Per Purchaser" },
                { value: "userConversionRate", label: "User Conversion Rate" },
                { value: "userEngagementDuration", label: "User Engagement Duration" }
            ]
        }
    ]

    metricsFilter = {
        condition: [
            { value: '==', label: i18n.t('alerts.equal') },
            { value: '>', label: i18n.t('alerts.greater_than') },
            { value: '>=', label: i18n.t('alerts.greater_than_or_equal_to') },
            { value: '<', label: i18n.t('alerts.less_than') },
            { value: '<=', label: i18n.t('alerts.less_than_or_equal_to') }
        ],
        match: [
            { value: 'AND', label: 'E' },
            { value: 'OR', label: 'OU' },
        ],
        defaultMatch: 'AND',
        options: [
            {
                label: i18n.t('alerts.metrics'),
                options: [
                    { value: "active1DayUsers", label: "1 Day Active Users" },
                    { value: "active28DayUsers", label: "28 Day Active Users" },
                    { value: "active7DayUsers", label: "7 Day Active Users" },
                    { value: "activeUsers", label: "Active Users" },
                    { value: "addToCarts", label: "Add To Carts" },
                    { value: "advertiserAdClicks", label: "Advertiser Ad Clicks" },
                    { value: "advertiserAdCost", label: "Advertiser Ad Cost" },
                    { value: "advertiserAdCostPerClick", label: "Advertiser Ad Cost Per Click" },
                    { value: "advertiserAdCostPerConversion", label: "Advertiser Ad Cost Per Conversion" },
                    { value: "advertiserAdImpressions", label: "Advertiser Ad Impressions" },
                    { value: "averagePurchaseRevenue", label: "Average Purchase Revenue" },
                    { value: "averagePurchaseRevenuePerUser", label: "Average Purchase Revenue Per User" },
                    { value: "averageSessionDuration", label: "Average Session Duration" },
                    { value: "bounceRate", label: "Bounce Rate" },
                    { value: "checkouts", label: "Checkouts" },
                    { value: "conversions", label: "Conversions" },
                    { value: "ecommercePurchases", label: "Ecommerce Purchases" },
                    { value: "engagedSessions", label: "Engaged Sessions" },
                    { value: "engagementRate", label: "Engagement Rate" },
                    { value: "eventCount", label: "Event Count" },
                    { value: "eventCountPerUser", label: "Event Count Per User" },
                    { value: "eventValue", label: "Event Value" },
                    { value: "itemListClickEvents", label: "Item List Click Events" },
                    { value: "itemListClickThroughRate", label: "Item List Click Through Rate" },
                    { value: "itemListViewEvents", label: "Item List View Events" },
                    { value: "itemPromotionClickThroughRate", label: "Item Promotion Click Through Rate" },
                    { value: "itemRevenue", label: "Item Revenue" },
                    { value: "itemsAddedToCart", label: "Items Added To Cart" },
                    { value: "itemsCheckedOut", label: "Items Checked Out" },
                    { value: "itemsClickedInList", label: "Items Clicked In List" },
                    { value: "itemsPurchased", label: "Items Purchased" },
                    { value: "itemsViewed", label: "Items Viewed" },
                    { value: "itemsViewedInList", label: "Items Viewed In List" },
                    { value: "itemsViewedInPromotion", label: "Items Viewed In Promotion" },
                    { value: "itemViewEvents", label: "Item View Events" },
                    { value: "newUsers", label: "New Users" },
                    { value: "organicGoogleSearchClicks", label: "Organic Google Search Clicks" },
                    { value: "organicGoogleSearchClickThroughRate", label: "Organic Google Search Click Through Rate" },
                    { value: "organicGoogleSearchImpressions", label: "Organic Google Search Impressions" },
                    { value: "promotionClicks", label: "Promotion Clicks" },
                    { value: "promotionViews", label: "Promotion Views" },
                    { value: "publisherAdClicks", label: "Publisher Ad Clicks" },
                    { value: "publisherAdImpressions", label: "Publisher Ad Impressions" },
                    { value: "purchaserConversionRate", label: "Purchaser Conversion Rate" },
                    { value: "purchaseRevenue", label: "Purchase Revenue" },
                    { value: "purchaseToViewRate", label: "Purchase To View Rate" },
                    { value: "returnOnAdSpend", label: "Return On Ad Spend" },
                    { value: "screenPageViews", label: "Views" },
                    { value: "screenPageViewsPerSession", label: "Views Per Session" },
                    { value: "screenPageViewsPerUser", label: "Views Per User" },
                    { value: "sessionConversionRate", label: "Session Conversion Rate" },
                    { value: "sessions", label: "Sessions" },
                    { value: "sessionsPerUser", label: "Sessions Per User" },
                    { value: "totalAdRevenue", label: "Total Ad Revenue" },
                    { value: "totalPurchasers", label: "Total Purchasers" },
                    { value: "totalRevenue", label: "Total Revenue" },
                    { value: "totalUsers", label: "Total Users" },
                    { value: "transactions", label: "Transactions" },
                    { value: "transactionsPerPurchaser", label: "Transactions Per Purchaser" },
                    { value: "userConversionRate", label: "User Conversion Rate" },
                    { value: "userEngagementDuration", label: "User Engagement Duration" }
                ]
            }
        ]
    }

    dimensionsFilter = {
        condition: [
            { value: '==', label: i18n.t('alerts.equal') },
            { value: '=@', label: i18n.t('alerts.contains') }
        ],
        match: [
            { value: 'AND', label: 'E' },
            { value: 'OR', label: 'OU' },
        ],
        defaultMatch: 'AND',
        options: [
            {
                label: i18n.t('alerts.dimensions'),
                options: [
                    { value: "campaignName", label: "Campaign Name" },
                    { value: "city", label: "City" },
                    { value: "country", label: "Country" },
                    { value: "deviceCategory", label: "Device Category" },
                    { value: "deviceModel", label: "Device Model" },
                    { value: "eventName", label: "Event Name" },
                    { value: "googleAdsAdGroupId", label: "Google Ads Ad Group Id" },
                    { value: "googleAdsAdGroupName", label: "Google Ads Ad Group Name" },
                    { value: "googleAdsCampaignId", label: "Google Ads Campaign Id" },
                    { value: "googleAdsCampaignName", label: "Google Ads Campaign Name" },
                    { value: "googleAdsCampaignType", label: "Google Ads Campaign Type" },
                    { value: "googleAdsKeyword", label: "Google Ads Keyword Text" },
                    { value: "googleAdsQuery", label: "Google Ads Query" },
                    { value: "groupId", label: "Group Id" },
                    { value: "linkText", label: "Link Text" },
                    { value: "linkUrl", label: "Link Url" },
                    { value: "medium", label: "Medium" },
                    { value: "mobileDeviceModel", label: "Mobile Device Model" },
                    { value: "operatingSystem", label: "Operating System" },
                    { value: "pageLocation", label: "Page Location" },
                    { value: "pagePath", label: "Page Path" },
                    { value: "pagePathPlusQueryString", label: "Page Path + Query String" },
                    { value: "pageTitle", label: "Page Title" },
                    { value: "platform", label: "Platform" },
                    { value: "region", label: "Region" },
                    { value: "searchTerm", label: "Search Term" },
                    { value: "source", label: "Source" },
                    { value: "sourceMedium", label: "Source Medium" },
                    { value: "sourcePlatform", label: "Source Platform" },
                    { value: "transactionId", label: "Transaction Id" },
                    { value: "userGender", label: "Gender" },
                    { value: "userAgeBracket", label: "Age" },
                    { value: "userLanguage", label: "Language" },
                    { value: "visible", label: "Visible" },
                    { value: "sessionCampaignId", label: "Session Campaign Id" },
                    { value: "sessionCampaignName", label: "Session Campaign Name" },
                    { value: "sessionDefaultChannelGroup", label: "Session Default Channel Group" },
                    { value: "sessionGoogleAdsAdGroupId", label: "Session Google Ads Ad Group Id" },
                    { value: "sessionGoogleAdsAdGroupName", label: "Session Google Ads Ad Group Name" },
                    { value: "sessionGoogleAdsCampaignId", label: "Session Google Ads Campaign Id" },
                    { value: "sessionGoogleAdsCampaignName", label: "Session Google Ads Campaign Name" },
                    { value: "sessionGoogleAdsCampaignType", label: "Session Google Ads Campaign Type" },
                    { value: "sessionGoogleAdsKeyword", label: "Session Google Ads Keyword Text" },
                    { value: "sessionGoogleAdsQuery", label: "Session Google Ads Query" },
                    { value: "sessionManualAdContent", label: "Session Manual Ad Content" },
                    { value: "sessionManualTerm", label: "Session Manual Term" },
                    { value: "sessionMedium", label: "Session Medium" },
                    { value: "sessionSource", label: "Session Source" },
                    { value: "sessionSourceMedium", label: "Session Source Medium" },
                    { value: "sessionSourcePlatform", label: "Session Source Platform" },
                    { value: "sessionSa360AdGroupName", label: "Session SA360 Ad Group Name" },
                    { value: "sessionSa360CampaignId", label: "Session SA360 Campaign Id" },
                    { value: "sessionSa360CampaignName", label: "Session SA360 Campaign Name" }
                ]
            }
        ]
    }
}

i18n.on('languageChanged', (lng) => {
    updateOptions();
});
  
updateOptions();

export { metrics, metricsFilter, dimensionsFilter }