import styled from 'styled-components';
import { darken } from 'polished';
import { 
  Menu,
  MenuItem,
  IconButton,
} from '@mui/material';

import { BsThreeDotsVertical } from 'react-icons/bs';


export const UsersMore = styled(IconButton)``;

export const UsersMoreIcon = styled(BsThreeDotsVertical)``;

export const UsersMenu = styled(Menu)``;

export const UsersMenuItem = styled(MenuItem)``;

export const Container = styled.div`
  .header {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 10px;

    .group {
      display: flex;
      flex-direction: row;
      align-items: center;
      width: 100%;

      .description {
        width: 90%;
        display: flex;
        .title {
          color: #2e3039;          
          font-size: 20px;
          font-weight: 300
        }
      }
    }
  }

  .pagination {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 20px;

    .loadMoreNotification {
      height: 40px;
      min-width: 158px;
      -webkit-transition: all 0.2s;
      transition: all 0.2s;
      border-radius: 20px;
      background-color: #ff0068;
      font-size: 13px;
      font-weight: 500;
      border: 0;
      color: #ffffff;
      margin-left: 22px;
    }

    .loadMoreNotification:hover {
      background-color: ${darken(0.05, '#ff0068')};
    }
  }

  .workSpace {
    .noNotifications {
      display: flex;
      align-items: center;
      justify-content: center;

      p {
        color: #90a4ae;
        font-size: 15px;
      }
    }
    .option {
      display: flex;
      flex-direction: row;
      align-items: center;
      padding: 25px 15px 18px 15px;
      justify-content: space-between;
      min-height: 76px;
      position: relative;

      box-shadow: 0 0 20px rgba(215, 222, 227, 0.39);
      border-radius: 8px;
      background-color: #ffffff;

      &:not(:last-child) {
        margin-bottom: 20px;
      }

      .notificationDate {
        color: #90a4ae;
        position: absolute;
        font-size: 12px;
        right: 10px;
        top: 10px;
      }

      .notificationIcon {
        display: flex;
        flex-direction: row;
        align-items: center;
        width: 95%;
        overflow: hidden;

        .word {
          width: 42px;
          border-radius: 50%;
        }

        .descriptionOption {
          margin-left: 27px;
          width: calc(100% - 60px);

          .titleOption {            
            white-space: wrap;
            overflow: hidden;
            text-overflow: ellipsis;            
            color: #242e42;            
            font-size: 15px;
            font-weight: 700;  
            margin-bottom: 7px;
          }

          p {
            color: #90a4ae;
            font-size: 13px;
            white-space: wrap;
            overflow: hidden;
            text-overflow: ellipsis;
          }
        }
      }
    }
  }
`;
