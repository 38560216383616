import React from "react";

import { InputCustomized } from "./styles";

const Input = ({ 
   label, placeholder, type, value, onChange, customClass, disabled, maxLength
}) => {
    return (
        <InputCustomized
            className={customClass}
        >
            <label>{label}</label>
            <input
                type={type}
                placeholder={placeholder}
                value={value}
                onChange={onChange}
                disabled={disabled}
                maxLength={maxLength}
            />
        </InputCustomized>
    )
}

export default Input;