import React, { useState, useEffect, useCallback } from 'react';

import { toast } from 'react-toastify';

import { useTranslation } from 'react-i18next';
import '../../i18n';

import Grow from '@mui/material/Grow';

import Loader from '../../components/Loader';
import BackgroundNew from '../../components/BackgroundNew';

import { getPartnerList } from '../../services/partnerService';

import { Container } from './styles';

const ServiceMarketplace = () => {
    const { t } = useTranslation();

    const [partners, setPartners] = useState([]);
    const [isLoading, setIsLoading] = useState(true);

    const loadPartners = useCallback(async () => {
        setIsLoading(true);

        try {
            const response = await getPartnerList();
            setPartners(response);
        } catch (error) {
            toast.error(error.message);
            setPartners([])
        } finally {
            setIsLoading(false);
        }
    }, []);

    const handleClickCallToAction = (url) => {
        window.open(url, '_blank');
    };

    useEffect(() => {
        loadPartners();
    }, [loadPartners]);

    return (
        <BackgroundNew 
          titlePage={t('menu.service_marketplace')}
          subTitlePage={t('serviceMarketplace.priority_message')}
        >
            <Container>
                {!isLoading && partners.length > 0 && partners.map((partner, index) => (
                    <Grow
                        in
                        style={{ transformOrigin: '0 0 0' }}
                        timeout={1000}
                    >
                        <div className="partner" key={index}>
                            <div className="image">
                                <img src={partner.image_url} alt={partner.name} />
                            </div>
                            <div className="description">
                                <p className="name">{partner.name}</p>
                                <p className="small_description">{partner.description}</p>
                                <p className="expertise"><span className='key'>{t('serviceMarketplace.expertise')}:</span> {partner.expertise}</p>
                                <p className="commercial_benefit"><span className='key'>{t('serviceMarketplace.special_condition')}:</span> {partner.commercial_benefit}</p>
                            </div>
                            <div className='callToAction'>
                                <button className="buttonCallToAction" onClick={_ => handleClickCallToAction(partner.redirect)}>{t('serviceMarketplace.learn_more')}</button>
                            </div>
                        </div>
                    </Grow>
                ))}
                {isLoading && <Loader />}
                {!isLoading && partners.length === 0 && (
                    <div className="noPartners">
                        <p>Nenhum parceiro encontrado</p>
                    </div>
                )}
            </Container>
        </BackgroundNew>
    )
}

export default ServiceMarketplace;