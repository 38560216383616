import React, { useEffect, useCallback, useState, useContext } from 'react';
import { toast } from 'react-toastify';

import { useTranslation } from 'react-i18next';
import '../../i18n';

import CardGroup from '../../components/CardGroup';
import BackgroundNew from '../../components/BackgroundNew';
import FilterContainer from '../../components/FilterContainer';
import { getCRMCohortMetrics } from '../../services/dashboardApiService';
import { isValidAvailableFilter } from '../../utils/validationUtils';

import CustomerRetentionMatrix from './CustomerRetentionMatrix';
import ModalPageInformation from '../../components/CustomModal/ModalPageInformation';

import { AuthContext } from '../../contexts';

const Cohort = () => {
  const { t } = useTranslation();
  const { timezone } = useContext(AuthContext);

  const [isLoading, setIsLoading] = useState(true);
  const [data, setData] = useState(null);
  const [originsOptions, setOriginsOptions] = useState([]);
  const [salesChannelsOptions, setSalesChannelsOptions] = useState([]);
  const [vtexAffiliatesOptions, setVtexAffiliatesOptions] = useState([]);
  const [selectedOriginsOptions, setSelectedOriginsOptions] = useState([]);
  const [selectedSalesChannelsOptions, setSelectedSalesChannelsOptions] = useState([]);
  const [selectedVtexAffiliatesOptions, setSelectedVtexAffiliatesOptions] = useState([]);
  const [openModalInformation, setOpenModalInformation] = useState(false);

  const handleApplyFilter = async () => {
    const origins = selectedOriginsOptions.length > 0 ? [...selectedOriginsOptions] : null;
    const salesChannels = selectedSalesChannelsOptions.length > 0 ? [...selectedSalesChannelsOptions] : null;
    const vtexAffiliates = selectedVtexAffiliatesOptions.length > 0 ? [...selectedVtexAffiliatesOptions] : null;

    await getCRMDataMetrics(
      origins,
      salesChannels,
      vtexAffiliates
    );
  };

  const getCRMDataMetrics = useCallback(async (
    origins = null,
    salesChannels = null,
    vtexAffiliates = null
  ) => {
    setIsLoading(true);

    try {
      const response = await getCRMCohortMetrics(
        timezone,
        origins,
        salesChannels,
        vtexAffiliates
      );

      if (!response) {
        setData(null);
        setOriginsOptions([]);
        setSalesChannelsOptions([]);
        setVtexAffiliatesOptions([]);

        return;
      }

      setOriginsOptions(response.available_origins_to_filter);
      setSalesChannelsOptions(response.available_marketplace_channels_to_filter);

      if (isValidAvailableFilter(response.available_affiliates_to_filter)) {
        setVtexAffiliatesOptions(response.available_affiliates_to_filter);
      } else {
          setVtexAffiliatesOptions([])
      }

      setData(response.results)
    } catch (error) {
      toast.error(error.message);
      setData(null);
      setOriginsOptions([]);
      setSalesChannelsOptions([]);
      setVtexAffiliatesOptions([]);
    } finally {
      setIsLoading(false);
    }
  }, [timezone]);

  const getSecondaryFilters = () => {
    return [
        {
            title: `${t('common.source')}`,
            options: originsOptions,
            value: selectedOriginsOptions,
            setValue: setSelectedOriginsOptions
        },
        {
            title: t('common.sales_channel'),
            options: salesChannelsOptions,
            value: selectedSalesChannelsOptions,
            setValue: setSelectedSalesChannelsOptions
        },
        {
            title: `${t('common.affiliates_vtex')}`,
            options: vtexAffiliatesOptions,
            value: selectedVtexAffiliatesOptions,
            setValue: setSelectedVtexAffiliatesOptions
        }
    ]
  }

  const onRenderComponent = useCallback(async () => {
    await getCRMDataMetrics();
  }, [getCRMDataMetrics]);

  useEffect(() => {
    onRenderComponent();
  }, [onRenderComponent]);

  return (
    <BackgroundNew
      titlePage={t('menu.cohort')}
      showInformation={true}
      informationTooltipTitle={t('common.click_to_learn_more')}
      informationOnClick={() => setOpenModalInformation(true)}
    >
      <FilterContainer
        showDateRangeFilter={false}
        showSecondaryFilters={true}
        secondaryFilters={getSecondaryFilters()}
        onClickFilter={handleApplyFilter}
      />
      <CardGroup
        title={t('cohort.customer_retention_platform')}
        isLoading={isLoading}
      >
        <CustomerRetentionMatrix data={data} />
      </CardGroup>
      <ModalPageInformation
          title={t('cohort.understand_cohort_analysis')}
          open={openModalInformation}
          handleClose={() => setOpenModalInformation(false)}
          videoUrl={"https://www.youtube.com/embed/-7bUFBya7KI?si=SAf8dejFix11tYSH"}
      />
    </BackgroundNew>
  );
}

export default Cohort;
