import styled from 'styled-components';
import { darken } from 'polished';

export const ModalPageBisoAdvertiseContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;

    .banner {
        margin-bottom: 16px;
    }

    .button {
        display: flex;
        align-items: center;
        color: #fff;
        transition: background-color 0.2s;
        border: 0;
        box-shadow: 0 2px 20px rgba(255, 0, 104, 0.3);
        background-color: #ff0068;
        font-size: 13px;
        font-weight: 500;
        letter-spacing: -0.21px;
        border-radius: 20px;
        height: 35px;       
        padding: 0 15px;
        justify-content: center;
        cursor: pointer;
        margin-bottom: 20px;
        margin-top: 20px;
  
        &:hover {
          background-color: ${darken(0.05, '#ff0068')};
          color: #fff;
        }
    }
`;