import chroma from 'chroma-js';

export const generateGradientColor = (value, minValue, maxValue, personalizedMinColor = null, personalizedMaxColor = null) => {
    const minColor = !personalizedMinColor ? [255, 255, 255] : personalizedMinColor;
    const maxColor = !personalizedMaxColor ? [255, 0, 104] : personalizedMaxColor;

    const color = [];
    for (let i = 0; i < 3; i++) {
        color[i] = Math.round(
        (maxColor[i] - minColor[i]) * ((value - minValue) / (maxValue - minValue)) + minColor[i]
        );
    }

    return `rgb(${color[0]}, ${color[1]}, ${color[2]})`;
};

export const generateDynamicColors = (count) => {
    const colors = chroma.scale('Set1').colors(count);
    return colors.map(color => chroma(color).alpha(0.7).css());
};
