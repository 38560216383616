import styled from 'styled-components';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';

export const SetupWrapper = styled.div`
    margin-top: 16px;
`;

export const SetupResume = styled.button`
    display: flex;
    align-items: center;
    width: 100%;
    color: #1A1D1F;
    font-size: 14px;
    text-align: left;
    background: transparent;
    border: none;
`;

export const SetupResumeText = styled.div`
    margin-left: 16px;
`;

export const SetupResumeTitle = styled.span`
    display: block;
    font-size: 14px;
    font-weight: 700;
    line-height: 18px;
    color: #1A1D1F;
`;

export const SetupResumeSubtitle = styled.span`
    display: block;
    font-size: 14px;
    font-weight: 300;
    line-height: 18px;
    color: #1A1D1F;
`;

export const SetupMenu = styled(Menu)``;

export const SetupMenuItem = styled(MenuItem)`
    &.MuiMenuItem-root {
        font-size: 14px;
    }

    &.is--disabled {
        text-decoration: line-through;
        opacity: .5;
        pointer-events: none;
    }

    svg {
        margin-right: 8px;
    }
`;