import React from 'react';

import { useTranslation } from 'react-i18next';
import '../../../i18n';

import CustomModal from '../CustomModal';

const ModalPredictionExplanation = ({ open, handleClose }) => {
    const { t } = useTranslation();

    return (
        <CustomModal
            title={t('goalsOverview.understand_forecasting')}
            open={open} 
            handleClose={handleClose}
            hiddenConfirmButton={true}
            cancelButtonLabel={t('common.close')}
        >
            <div style={{fontSize: "15px"}}>
                <h2 style={{fontSize: "16px", marginBottom: "10px"}}>{t('goalsOverview.how_forecast_made')}</h2>
                <p>{t('goalsOverview.forecast_description')}</p>
                <ol>
                    <li>
                        <h2 style={{fontSize: "16px", marginBottom: "10px", marginTop: "10px"}}>{t('goalsOverview.model_training')}</h2>
                        <p>{t('goalsOverview.model_training_description')}</p>
                    </li>
                    <li>
                        <h2 style={{fontSize: "16px", marginBottom: "10px", marginTop: "10px"}}>{t('goalsOverview.factor_analysis')}</h2>
                        <p>{t('goalsOverview.factor_analysis_description')}</p>
                        <ul style={{marginTop: "10px", marginBottom: "10px"}}>
                            <li style={{marginBottom: "5px", marginLeft: "20px"}}><strong>{t('goalsOverview.seasonality_part1')}</strong> {t('goalsOverview.seasonality_part2')}</li>
                            <li style={{marginBottom: "5px", marginLeft: "20px"}}><strong>{t('goalsOverview.trend_part1')}</strong> {t('goalsOverview.trend_part2')}</li>
                            <li style={{marginBottom: "5px", marginLeft: "20px"}}><strong>{t('goalsOverview.outliers_part1')}</strong> {t('goalsOverview.outliers_part2')}</li>
                            <li style={{marginBottom: "5px", marginLeft: "20px"}}><strong>{t('goalsOverview.holidays_part1')}</strong> {t('goalsOverview.holidays_part2')}</li>
                        </ul>
                    </li>
                    <li>
                        <h2 style={{fontSize: "16px", marginBottom: "10px", marginTop: "10px"}}>{t('goalsOverview.interpret_forecast')}</h2>
                        <p style={{marginBottom: "5px"}}><strong>{t('goalsOverview.result_part1')}</strong> {t('goalsOverview.result_part2')}</p>
                        <p style={{marginBottom: "5px"}}><strong>{t('goalsOverview.forecasted')}:</strong> {t('goalsOverview.forecast_part2')}</p>
                        <p style={{marginBottom: "5px"}}><strong>{t('goalsOverview.deviations_part1')}</strong> {t('goalsOverview.deviations_part2')}</p>
                        <ul style={{marginTop: "10px", marginBottom: "10px"}}>
                            <li style={{marginBottom: "5px", marginLeft: "20px"}}><strong>{t('goalsOverview.minimum_value_part1')}</strong>  {t('goalsOverview.minimum_value_part2')}</li>
                            <li style={{marginBottom: "20px", marginLeft: "20px"}}><strong>{t('goalsOverview.maximum_value_part1')}</strong> {t('goalsOverview.maximum_value_part2')}</li>
                        </ul>   
                    </li>
                    <li>
                        <h2 style={{fontSize: "16px", marginBottom: "10px", marginTop: "10px"}}>{t('goalsOverview.why_large_deviations')}</h2>
                        <p style={{marginBottom: "5px"}}>{t('goalsOverview.why_large_deviations_description')}</p>
                        <ul style={{marginTop: "10px", marginBottom: "10px"}}>
                            <li style={{marginBottom: "5px", marginLeft: "20px"}}><strong>{t('goalsOverview.limited_data_part1')}</strong>  {t('goalsOverview.limited_data_part2')}</li>
                            <li style={{marginBottom: "20px", marginLeft: "20px"}}><strong>{t('goalsOverview.permanent_seasonal_changes_part1')}</strong> {t('goalsOverview.permanent_seasonal_changes_part2')}</li>
                        </ul>   
                    </li>
                    <li>
                        <h2 style={{fontSize: "16px", marginBottom: "10px", marginTop: "10px"}}>{t('goalsOverview.no_forecast_available_reason')}</h2>
                        <p style={{marginBottom: "5px"}}><strong>{t('goalsOverview.very_limited_data_part1')} </strong> {t('goalsOverview.very_limited_data_part2')}</p>
                        <p style={{marginBottom: "20px"}}><strong>{t('goalsOverview.recent_account_creation_part1')}</strong> {t('goalsOverview.recent_account_creation_part2')}</p>
                    </li>
                    <li>
                        <h2 style={{fontSize: "16px", marginBottom: "10px", marginTop: "10px"}}>{t('goalsOverview.importance_of_forecasting')}</h2>
                        <p>{t('goalsOverview.forecasting_impact_part1')}</p>
                        <ul style={{marginTop: "10px", marginBottom: "20px"}}>
                            <li style={{marginBottom: "5px", marginLeft: "20px"}}>{t('goalsOverview.forecasting_impact_part2')}</li>
                            <li style={{marginBottom: "5px", marginLeft: "20px"}}>{t('goalsOverview.forecasting_impact_part3')}</li>
                            <li style={{marginLeft: "20px"}}>{t('goalsOverview.forecasting_impact_part4')}</li>
                        </ul>
                    </li>
                </ol>
                <p>{t('goalsOverview.forecasting_impact_part5')}</p>
            </div>
        </CustomModal>
    );
}

export default ModalPredictionExplanation;