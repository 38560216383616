import styled from 'styled-components';

export const MatrixContainer = styled.div`
    overflow-x: auto;
    margin-bottom: 20px;
    border-radius: 8px;
    border: 1px solid #DEDEDE;

    /* width */
    ::-webkit-scrollbar {
        width: 5px;
        height: 4px;
    }

    /* Track */
    ::-webkit-scrollbar-track {
        box-shadow: #f1f1f1; 
        border-radius: 10px;
        width: 2px;
    }
    
    /* Handle */
    ::-webkit-scrollbar-thumb {
        background: #DEDEDE; 
        border-radius: 10px;
    }

    /* Handle on hover */
    ::-webkit-scrollbar-thumb:hover {
        background: #C8C8C8; 
    }

    table {
        border-collapse: collapse;
        width: 100%;

        th, td {
            border-bottom: 0.3px solid #ddd;
            border-right: 0.3px solid #ddd;
            padding: 8px;
            text-align: center;
        }

        tr{background-color: #fff;}
        
        th {
            padding-top: 12px;
            padding-bottom: 12px;
            background-color: #F4F4F4;
            color: #000;
            min-width: 118px;
        }

        .totalResult {
            background-color: #F4F4F4;
        }
    }
`;