import styled from 'styled-components';
import { darken } from 'polished';
import { Modal } from '@mui/material';
import Select from 'react-select';

export const CustomerModalRegistrationGoals = styled(Modal)`
  display: flex;
  justify-content: center;

  .model__header__group {
    @media screen and (max-width: 473px) {
      margin-bottom: 20px;
    }
  }

  .limit__height {
    height: 302px;
  }

  @media screen and (max-width: 473px) {
    .limit__height {
      height: 376px;
    }
  }

  .modal {
    display: flex;
    flex-direction: column;
    width: 60vw;
    max-width: 750px;
    padding: 16px 24px;
    background-color: #f9f9f9;
    border-radius: 8px;
    margin-top: 10vh;
    max-height: 85%;

    .disable-button {
      background: #ccc;
      cursor: not-allowed;
    }

    @media screen and (max-width: 1200px) {
      width: calc(100vw - 30px);
      overflow: auto;
      padding: 16px;
    }

    &__step-by-step{
      padding: 4px 16px;
      font-size: 16px;

      & h2{
        margin-bottom: 8px;
      }
      & ul{
        list-style-type: none;
      }
      & ul li {
        margin-bottom: 8px;
      }
    }
    &__header {
      margin-bottom: 15px;
      display: flex;
      justify-content: space-between;

      @media screen and (max-width: 473px) {
        flex-direction: column;
      }
    }

    &__sub-header {
      margin-bottom: 15px;
      width: 100%;
      font-size: 16px;
    }

    &__body {
      overflow: auto;
      padding-right: 8px;
      padding-bottom: 8px;

      &::-webkit-scrollbar {
        width: 8px;
      }

      &::-webkit-scrollbar-thumb {
        border: 1px solid rgba(255, 255, 255, 0.4);
        background-color: rgba(0, 0, 0, 0.3);
        border-radius: 5px;
      }
    }

    &__footer {
      display: flex;
      justify-content: center;
      margin-top: 16px;
    }

    &__title {
      font-size: 20px;
      font-weight: 600;
      color: #455a64;

      &:after {
        content: '';
        display: block;
        width: 60px;
        height: 4px;
        margin-top: 16px;
        background: #f90c68;
      }
    }

    .is--disabled {
      background: #ccc;
      cursor: not-allowed;
    }

    .margin-bottom-input-month {
      margin-bottom: 16px;
    }

    &__form {
      &-group {
        padding: 24px 16px;
        background: #fff;
        border-radius: 8px;
        box-shadow: 0px 1px 3px 0px rgba(142, 142, 142, 0.2),
          0px 1px 1px 0px rgba(243, 243, 243, 0.14),
          0px 2px 1px -1px rgba(204, 204, 204, 0.12);
      }

      &-label {
        display: block;
        margin-bottom: 8px;
        color: rgba(0, 0, 0, 0.75);
        font-size: 14px;
        font-weight: 700;
        text-transform: uppercase;

        &-month {
          color: #f90c68;
        }
      }

      &-input {
        margin-top: 8px;
        width: 100%;
        height: 38px;
        font-size: 14px;
        font-weight: 400;
        padding: 10px;
        background-color: #fff;
        border: 1px solid #ccc;
        border-radius: 4px;
        @media screen and (max-width: 1200px) {
          font-size: 16px;
        }
      }

      &-btn {
        margin: 0 8px;
        font-size: 13px;
        font-weight: 500;
        border: 0;

        &.is--save {
          height: 35px;
          padding: 0 16px;
          color: #fff;
          line-height: 35px;
          background: #f90c68;
          border-radius: 20px;
          &:hover {
            background: ${darken(0.08, '#f90c68')};
          }
          &:disabled {
            opacity: 0.5;
            pointer-events: none;
          }
        }

        &.is--cancel {
          height: 35px;
          color: #455a64;
          line-height: 35px;
          text-decoration: underline;
          background: transparent;
        }
      }
    }

    &__slider {
      &-item {
        min-height: 400px;
      }

      &-progress {
        margin-bottom: 8px;
      }

      &-btn {
        font-size: 13px;
        font-weight: 500;
        background: none;
        border: none;
        user-select: none;
      }

      img {
        display: block;
        max-width: 100%;
        max-height: 400px;
        margin: 0 auto;
      }

      .MuiMobileStepper-progress {
        margin: 0 8px;
      }

      .MuiLinearProgress {
        &-colorPrimary {
          background: #fbc3d8;
        }

        &-barColorPrimary {
          background: #f90b68;
        }
      }
    }
  }
`;

export const Option = styled(Select)`
  width: 1005;
  @media screen and (max-width: 1200px) {
    font-size: 16px;
  }
`;
