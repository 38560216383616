import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  justify-content: flex-end;

  .main {
    background-color: #F4F4F4;
    position: absolute;
    min-height: 100vh;
    left: 59px;
    right: 0;
    padding: 0 20px;
    padding-bottom: 60px;
    padding-top: 94px;

    @media (max-width: 1200px) {
      left: 0;
    }

    .titlePage {
      margin-bottom: 27px;
      margin-top: 50px;
      
      color: #242e42;      
      font-size: 36px;
      font-weight: 300;
    }

    .content {
      background-color: #292929;
      border-radius: 5px;
      width: 100%;
      padding: 16px;
      color: #fff;
      box-shadow: -1px 2px 15px -9px rgba(0, 0, 0, 0.65);

      box-shadow: 0 0 20px rgba(215, 222, 227, 0.39);
      border-radius: 8px;
      background-color: #ffffff;
      margin-bottom: 50px;
    }

    .contentLoading {
      width: 100%;
      height: 100vh;
      display: flex;
      align-items: center;
    }
  }
`;
