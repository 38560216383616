import React from "react";

import { useTranslation } from 'react-i18next';
import "../../../i18n";

import { ContainerDeliveryTimeQuarterTable } from "./styles";
import CustomModal from "../../../components/CustomModal/CustomModal";
import Loader from "../../../components/Loader";

const DeliveryTimeQuarterModal = ({
    open,
    handleClose,
    isLoading,
    data
}) => {
    const { t } = useTranslation();

    return (
        <CustomModal
            open={open}
            handleClose={handleClose}
            cancelButtonLabel={t('common.close')}
            hiddenConfirmButton={true}
            title={t('logisticIntelipostMostCommunDeliveryTime.neighborhoods')}
        >
            <ContainerDeliveryTimeQuarterTable>
                <table className="deliveryTimeQuarterTable">
                    <thead>
                        <tr>
                            <th>{t('logisticIntelipostMostCommunDeliveryTime.neighborhood')}</th>
                            <th>{t('logisticIntelipostMostCommunDeliveryTime.delivery_time')}</th>
                        </tr>
                    </thead>
                    <tbody>
                        {!isLoading && data.length > 0 && data.map((item, index) => (
                            <tr key={index}>
                                <td>{item.quarter}</td>
                                <td>{item.delivery_time}</td>
                            </tr>
                        ))}
                        {!isLoading && data.length === 0 && (
                            <tr>
                                <td colSpan="2">{t('common.no_data')}</td>
                            </tr>
                        )}
                        {isLoading && (
                            <tr>
                                <td colSpan="2">
                                    <Loader />
                                </td>
                            </tr>
                        )}
                    </tbody>
                </table>
            </ContainerDeliveryTimeQuarterTable>
        </CustomModal>
    )
}

export default DeliveryTimeQuarterModal;