import styled from 'styled-components';
import { darken } from 'polished';
import Select from 'react-select';

export const Container = styled.div`
  .header {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 10px;

    .group {
      display: flex;
      flex-direction: row;
      align-items: center;
      width: 100%;

      .description {
        width: 90%;
        display: flex;
        .title {
          color: #2e3039;          
          font-size: 20px;
          font-weight: 300
        }
      }
    }
  }

  .workSpace {
    .usageDetails {
      display: flex;
      flex-direction: column;
      padding: 18px 15px 18px 15px;
      justify-content: space-between;
      min-height: 76px;

      box-shadow: 0 0 20px rgba(215, 222, 227, 0.39);
      border-radius: 8px;
      background-color: #ffffff;

      .titleUsageDetails {
        color: #2e3039;
        font-size: 19px;
        font-weight: 300;
        margin-bottom: 20px;
      }
      
      .usageDetailsContent {
        display: flex;
        flex-direction: column;

        p {
          color: #2e3039;
          font-size: 15px;
          margin-bottom: 10px;
        }

        .usageValue {
          color: #ff0068;
        }
      }
    }

    .adTextCreator {
      display: flex;
      flex-direction: column;
      padding: 18px 15px 18px 15px;
      justify-content: space-between;
      min-height: 76px;

      box-shadow: 0 0 20px rgba(215, 222, 227, 0.39);
      border-radius: 8px;
      background-color: #ffffff;
      margin-top: 20px;

      .titleAdTextCreator {
        color: #2e3039;
        font-size: 19px;
        font-weight: 300;
        margin-bottom: 20px;
      }

      .buttonGroup {
        display: flex;
        align-items: center;
        justify-content: right;
        margin-top: 20px;

        .generateAdTextCreatorButton {
          height: 40px;
          width: 10%;
          -webkit-transition: all 0.2s;
          transition: all 0.2s;
          border-radius: 20px;
          background-color: #ff0068;
          font-size: 13px;
          font-weight: 500;
          border: none;
          color: #ffffff;
          margin-bottom: 20px;

          @media screen and (max-width: 768px) {
            width: 100%;
          }
        }
    
        .generateAdTextCreatorButton:hover {
          background-color: ${darken(0.05, '#ff0068')};
        }

        .disabled {
          background-color: hsl(0,0%,95%);
          border-color: hsl(0,0%,90%);
          cursor: not-allowed;
        }
  
        .disabled:hover {
          background-color: hsl(0,0%,95%);
          border-color: hsl(0,0%,90%);
          cursor: not-allowed;
        }
      }

      .group {
        .title {
          color: #2e3039;
          font-size: 15px;
          margin-bottom: 10px;
        }
  
        .input {
          display: flex;
          align-items: flex-start;
          flex-direction: column;
          border: 1px solid #ccc;
          border-radius: 4px;
          padding: 5px 7px;
          width: 49%;
          width: 100%;
          margin-bottom: 20px;
  
          p {
            color: #aaa;
            font-size: 12px;
            margin-bottom: 2px;
          }
  
          input, textarea {
            border: 0;
            color: #000;
            font-size: 14px;
            padding: 2px 8px;
            width: 100%;
            word-wrap: break-word;
            @media screen and (max-width: 1200px) {
              font-size: 16px;
            }
          }

          textarea {
            height: 60px;
          }
        }
      }

      .disabled {
        background-color: hsl(0,0%,95%);
          border-color: hsl(0,0%,90%);
      }
    }
  }
`;

export const MessageQuotaUsageLimitReached = styled.div`
  display: flex;
  padding: 20px;
  background-color: #ffd119;
  margin-bottom: 20px;
  font-size: 15px;
  color: #2e3039;
  font-weight: 500;
`

export const Option = styled(Select)`
  color: #2e3039;
  margin-bottom: 20px;
  @media screen and (max-width: 1200px) {
    font-size: 16px;
  }
`;
