import React, { useState, useEffect } from 'react';

import { useTranslation } from 'react-i18next';
import '../../../i18n';

import { InfoBar } from './../../InfoBar';
import { CustomModalAppToken } from '../../CustomModal';
import { getCurrentUser } from './../../../services/UserService';

function InfoBarInstallApp(props) {
  const { t } = useTranslation();

  const [openModal, setOpenModal] = useState(false);
  const [hasAppToken, setHasAppToken] = useState(true);
  
  const handleClick = () => {
    setOpenModal(true);
  };

  const closeModal = () => {
    setOpenModal(false);
  };

  
  useEffect(() => {
    const { type: userType } = JSON.parse(localStorage.getItem('user'));

    const validateAppToken = async () => {
      if (userType === 'profile') {
        const { app_token } = await getCurrentUser();
        setHasAppToken(!!app_token);
      }
    };
    validateAppToken();
  }, []);


  return (
    <>
      {!hasAppToken && (
        <>
          <InfoBar {...props} onClick={handleClick}>
            <span>{t('common.setup_device')} <strong>{t('common.click_here')}</strong> {t('common.learn_more')}</span>
          </InfoBar>
          <CustomModalAppToken open={openModal} handleClose={closeModal} />
        </>
      )}
    </>
  );
}

export default InfoBarInstallApp;
