export const readFileAsync = async (file) => {
    return new Promise((resolve, reject) => {
      let reader = new FileReader();
  
      reader.onload = () => {
        resolve(reader.result);
      };
  
      reader.onerror = reject;
  
      reader.readAsText(file, "UTF-8");
    })
  }

export const getFileLines = (contentCSV) => {
    return contentCSV.split(/\r\n|\n/);
}

