/* eslint-disable react/prop-types */
/* eslint-disable react/destructuring-assignment */
import React, { useEffect } from 'react';

import { Container } from './styles';

function BackgroundDashboard(props) {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const renderDashboards = (dashboards) => {
    return dashboards.map((dashboard, index) => {
      return (
        <>
          <h1 className="titlePage" index={index}>{dashboard.title}</h1>
          <div className="content" index={index}>{dashboard.content}</div>
        </>
      )
    })
  }

  return (
    <Container>
      {props.isLoading && 
      <div className="main">
          <div className="contentLoading">< props.loader/></div>
      </div>
      }
      {!props.isLoading && props.dashboards && 
        <div className="main">
          {props.dashboards && renderDashboards(props.dashboards)}
        </div>
      }
    </Container>
  );
}

export default BackgroundDashboard;
