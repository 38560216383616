import styled from 'styled-components';
import { darken } from 'polished';
import { Modal } from '@mui/material';

export const CustomModal = styled(Modal)`
  display: flex;
  justify-content: center;

  .modal {
    position: fixed;
    top: 5%;
    display: flex;
    flex-direction: column;
    width: 60vw;
    max-width: 750px;
    padding: 16px 24px;
    background-color: #f9f9f9;
    border-radius: 8px;

    @media screen and (max-width: 1200px) {
      top: 15px;
      width: calc(100vw - 30px);
      overflow: auto;
      margin-top: 15px;
      padding: 16px;
    }

    &__header {
      margin-bottom: 32px;
    }

    &__body {
      overflow-y: auto;
      max-height: calc(100vh - 230px);
      scroll-behavior: smooth;
      overflow-x: hidden;

      /* width */
      ::-webkit-scrollbar {
        width: 6px;
        height: 0px;
      }

      /* Track */
      ::-webkit-scrollbar-track {
        box-shadow: #f1f1f1; 
        border-radius: 10px;
        width: 5px;
      }
      
      /* Handle */
      ::-webkit-scrollbar-thumb {
        background: #DEDEDE; 
        border-radius: 10px;
      }

      /* Handle on hover */
      ::-webkit-scrollbar-thumb:hover {
        background: #C8C8C8; 
      }
    }

    &__footer {
      display: flex;
      justify-content: center;
      margin-top: 16px;
    }

    &__title {
      font-size: 20px;
      font-weight: 600;
      color: #455a64;

      &:after {
        content: '';
        display: block;
        width: 60px;
        height: 4px;
        margin-top: 16px;
        background: #f90c68;
      }
    }

    &__btn {
      display: flex;
      align-items: center;
      margin: 0 8px;
      font-size: 13px;
      font-weight: 500;
      border: 0;

      &.is--confirm {
        height: 35px;
        padding: 0 16px;
        color: #fff;
        line-height: 35px;
        background: #f90c68;
        border-radius: 20px;
        &:hover {
          background: ${darken(0.08, '#f90c68')};
        }
        &:disabled {
          background-color: #999;
          border-color: #999;
          color: #fff;
          cursor: not-allowed;
          box-shadow: none;

          &:hover {
              background-color: #999;
              border-color: #999;
              color: #fff;
          }
        }
      }

      &.is--cancel {
        height: 35px;
        color: #455a64;
        line-height: 35px;
        text-decoration: underline;
        background: transparent;
      }
    }
  }
`;