import React, { useState, useEffect } from 'react';

import {
    PieChart, Pie, Legend, ResponsiveContainer, Sector, Tooltip, Cell
} from 'recharts';

import { formatValueToPercentage } from '../../utils/metricsUtils';

import { AiTwotonePieChart } from 'react-icons/ai';

import GraphController from '../GraphController';

import { CustomGraphContainer, TooltipContainer, LegendContainer } from './styles';

import { generateDynamicColors } from '../../utils/colorUtils';

const GraphPie = ({
    data,
    formatData,
    formaterValue,
    dataKeyLabel,
    dataKeyValue,
    graphMarginConfig,
    height = '40vh',
    showDimensionController = false,
    disabledDimensionController = false,
    showFullScreenOption = false,
    fullScreenEnabled = false,
    setFullScreenEnabled,
    graphComponentRefForPDF,
    showExportPDFButton,
    forceMultiColor = false
}) => {
    const [formattedData, setFormattedData] = useState([]);
    const [activeIndex, setActiveIndex] = useState(0);

    useEffect(() => {
        const formattedData = formatData(data);
        setFormattedData(formattedData);
    }, [data, formatData]);

    const onPieEnter = (_, index) => {
        setActiveIndex(index);
    };

    const getPercent = (value) => {
        const total = formattedData.reduce((prev, current) => {
            return prev + current[dataKeyValue];
        }, 0);
        return total > 0 ? Number((value / total * 100).toFixed(2)) : 0;
    }


    const renderActiveShape = (props) => {
        const { cx, cy, innerRadius, outerRadius, startAngle, endAngle, fill } = props;

        return (
          <>
            <Sector
              cx={cx}
              cy={cy}
              innerRadius={innerRadius}
              outerRadius={outerRadius}
              startAngle={startAngle}
              endAngle={endAngle}
              fill={fill}
            />
            <Sector
              cx={cx}
              cy={cy}
              startAngle={startAngle}
              endAngle={endAngle}
              innerRadius={outerRadius + 6}
              outerRadius={outerRadius + 10}
              fill={fill}
            />
          </>
        );
      };

    const CustomTooltip = ({ active, payload }) => {
        if (active && payload && payload.length) {
            const data = payload[0];
            const dataPayload = payload[0].payload;

            return (
                <TooltipContainer>
                    <h3>{`${dataPayload[dataKeyLabel]}`}</h3>
                    <p>
                        <span className='tooltipValue' style={{color: data.color }}>
                            {formaterValue(data.value)}
                        </span>
                        {` (${getPercent(data.value)}%)`}
                    </p>
                </TooltipContainer>
            );
        }

        return null;
    };

    const CustomLegend = ({ payload }) => {
        return (
            <LegendContainer>
            {payload && payload.map((entry, index) => {
                const dataPayload = entry.payload;

                return (
                    <div key={`legend-${index}`} className='legendItem'>
                        <AiTwotonePieChart style={{ color: entry.color, marginRight: '5px', minWidth: "20px", maxHeight: "20px" }} size={20} />
                        <div className='legendItemNameAndPercentContainer'>
                            <p className='legendItemName'>
                                {dataPayload[dataKeyLabel]}
                            </p>
                            <p className='legendItemPercent'>{formatValueToPercentage(Number(dataPayload["percent"] * 100).toFixed(2))}</p>
                        </div>
                    </div>
                )
            })}
            </LegendContainer>
        );
    };

  const fixedColors = ['rgba(0, 155, 208, 0.4)', 'rgba(255, 0, 104, 0.5)'];
  const colors = formattedData.length <= 2 && !forceMultiColor ? fixedColors : generateDynamicColors(formattedData.length);

    const renderGraph = (data) => {
        return (
            <ResponsiveContainer height="100%" width="100%">
                <PieChart
                    margin={{
                        top: graphMarginConfig.top,
                        right: graphMarginConfig.right,
                        left: graphMarginConfig.left,
                        bottom: graphMarginConfig.bottom
                    }}
                >
                    <Pie
                        activeIndex={activeIndex}
                        activeShape={renderActiveShape}
                        dataKey={dataKeyValue}
                        data={data}
                        cx="50%"
                        cy="50%"
                        innerRadius={50}
                        fill="rgba(0, 155, 208, 0.4)"
                        onMouseEnter={onPieEnter}
                    >
                      {data && data.map((entry, index) => (
                        <Cell key={`cell-${index}`} fill={colors[index % colors.length]} />
                      ))}
                    </Pie>
                    <Tooltip content={CustomTooltip}/>
                    <Legend
                        content={<CustomLegend />}
                        layout="vertical"
                        align="right"
                        verticalAlign="middle"
                        width="40%"
                    />
                </PieChart>
            </ResponsiveContainer>
        );
    };

    const stylesComponents = {
        mainContainerFullScreen: {
            position: 'fixed',
            top: 0,
            left: 0,
            width: '100%',
            height: '100vh',
            backgroundColor: 'white',
            zIndex: 9999,
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center'
        },
        graphContainerFullScreen: {
            height: '80vh'
        }
    }

    return (
        <div style={fullScreenEnabled ? stylesComponents.mainContainerFullScreen : {}}>
            {showDimensionController && (
                <GraphController
                    showDimensionController={false}
                    disabled={disabledDimensionController}
                    showFullScreenOption={showFullScreenOption}
                    fullScreenEnabled={fullScreenEnabled}
                    setFullScreenEnabled={setFullScreenEnabled}
                    graphComponentRefForPDF={graphComponentRefForPDF}
                    showExportPDFButton={showExportPDFButton}
                />
            )}
            {fullScreenEnabled && (
                <CustomGraphContainer
                    style={stylesComponents.graphContainerFullScreen}
                >
                    {renderGraph(formattedData)}
                </CustomGraphContainer>
            )}
            {!fullScreenEnabled && (
                <CustomGraphContainer
                    style={{height: height}}
                >
                    {renderGraph(formattedData)}
                </CustomGraphContainer>
            )}
        </div>
    )

}

export default GraphPie;
