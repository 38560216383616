import styled from 'styled-components';

export const Container = styled.div`
  background-color: #ffffff;
  border: 1px solid #e5e5e5;
  border-radius: 5px;
  padding: 10px;
  font-size: 15px;
  color: #2e3039;
  font-weight: 400;

  h3 {
      font-size: 15px;
  }

  p {
      margin-top: 8px;
      .tooltipValue {
        font-weight: bold;
      }
  }

  .groupOfTooltipColumns {
      display: flex;
      flex-direction: row;
      justify-content: space-between;

      .tooltipColumn {
          display: flex;
          flex-direction: column;
          justify-content: flex-start;
          align-items: flex-start;
          margin-right: 15px;
      }
  }
`;
