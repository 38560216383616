import React from 'react';
import * as S from './styled';

function InfoBar(props) {

  return (
    <S.InfoBar {...props}>
      {props.children}
    </S.InfoBar>
  );
}

export default InfoBar;
