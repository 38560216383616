import styled from 'styled-components';

export const Container = styled.div`
    .userInfoActions {
        display: flex;
        justify-content: flex-end;
        margin-top: 20px;

        .cancelButton {
            color: #455a64;
            line-height: 35px;
            text-decoration: underline;
            background: transparent;
            border:none;
            box-shadow: none;
            &:hover {
                color: #546e7a;
            }
        }
    }

    .noUserInfo {
        display: flex;
        align-items: center;
        justify-content: center;

        p {
            color: #90a4ae;
            font-size: 15px;
        }
    }
`