import React, { useEffect, useState } from 'react';
import queryString from 'query-string';
import { toast } from 'react-toastify';

import { useTranslation } from 'react-i18next';
import '../../i18n';

import BackgroundNew from '../../components/BackgroundNew';
import { CustomModalGoogleAds, CustomModalFacebookAds, CustomModalVtex, CustomModalGoogleAnalytics4 } from '../../components/CustomModal';
import logoTrello from '../../assets/trello-logo.png';
import api from '../../services/api';
import TrelloService from '../../services/TrelloService';
import Loader from '../../components/Loader';

import { FaCheckCircle } from "react-icons/fa";

import { Container, SettingsModal, Option } from './styles';

function ConnectionAndChannel() {
  const { t } = useTranslation();

  const [settings, setSettings] = useState([]);
  const [userSettings, setUserSettings] = useState([]);

  const [activeModal, setActiveModal] = useState('');

  const [trelloBoards, setTrelloBoards] = useState([]);
  const [selectedTrelloBoard, setSelectedTrelloBoard] = useState('');

  const configurableModule = ['AW', 'FA', 'GAWA', 'VTEX', 'trello']
  
  const [loading, setLoading] = useState(true);

  async function getSettings() {
    setLoading(true);

    const token = localStorage.getItem('token');

    const response = await api.get('/alerts/modules', {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });

    setSettings(response.data);

    setLoading(false);
  }

  const getUserSettings = async () => {
    const token = localStorage.getItem('token');
    const response = await api.get('/alerts/user_modules', {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });

    setUserSettings(response.data);
  };

  const handleConfig = (setting) => {
    const { type, trello } = JSON.parse(localStorage.getItem('user'));
    if (type === 'company') {
      const userSetting = userSettings.filter((item) => item.module === setting.name);
  
      if (!userSetting.length) {
        switch (setting.name) {
          case 'AW':
            setActiveModal('googleAds');
            break;
          case 'FA':
            setActiveModal('facebookAds');
            break;
          case 'GAWA':
            setActiveModal('googleAnalytics4');
            break;
          case 'VTEX':
            setActiveModal('vtex');
            break;
          case 'trello':
            if (trello) {
              toast.success(t('toast.module_already_configured'));
            } else {
              window.location.href = TrelloService.getLoginUrl(window.location.href);
            }
            break;
          default:
            break;
        }
      }
    } else {
      toast.error(t('toast.action_allowed_admins'));
    }
  };  

  const isModuleConfigured = (moduleName) => {
    const userSetting = userSettings.filter((item) => item.module === moduleName);
    return userSetting.length;
  }

  const checkUrlToken = async () => {
    const { token } = queryString.parse(window.location.hash);
    if (token) {
      TrelloService.setToken(token);
      const userData = await TrelloService.getUserId();
      const userId = userData.data.id;
      const boardsData = await TrelloService.getUserBoards(userId);
      setTrelloBoards(
        boardsData.data.map((board) => {
          return {
            value: board.id,
            label: board.name,
          };
        })
      );
      setActiveModal('trello');
    }
  };

  const handleTrelloBoardChange = (select) =>
    setSelectedTrelloBoard(select.value);

  const handleCloseModal = () => setActiveModal('');

  const handleTrelloSaveConfig = async () => {
    if (!selectedTrelloBoard) {
      toast.error(t('toast.select_board'));
    } else {
      const { id } = JSON.parse(localStorage.getItem('user'));
      const token = localStorage.getItem('token');
      const data = {
        settings_trello: {
          key: TrelloService.key,
          token: TrelloService.token,
          board_id: selectedTrelloBoard,
        },
      };

      const response = await api.put(`/users/company/${id}`, data, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      if (response.status === 200) {
        const userInfo = JSON.parse(localStorage.getItem('user'));

        if (userInfo) {
          userInfo.trello = true;
          localStorage.setItem('user', JSON.stringify(userInfo));
        }

        setActiveModal('');
        toast.success(t('toast.configuration_saved'));
      }
    }
  };

  useEffect(() => {
    getSettings();
    getUserSettings();
    checkUrlToken();
  }, []);

  return (
    <BackgroundNew titlePage={t('appBar.connections_and_channels')}>
      <Container>
        <div className="header">
          <div className="description">
            <p className="title">{t('connectionAndChannel.manage_connections_channels')}</p>
          </div>
        </div>

        <div className="settings">
          {(!loading && settings && settings.length > 0) && (
            <>
              {settings.map((setting, index) => (
                <div className="setting" key={index}>
                  <img src={setting.image_url} alt={setting.prettier_name} />

                  <div className="descriptionSet">
                    <p className="titleSet">{setting.prettier_name}</p>
                    <p className="text">{setting.description}</p>
                  </div>
                  <button
                    type="button"
                    active={setting.status}
                    onClick={() => handleConfig(setting)}
                    className={
                      isModuleConfigured(setting.name) 
                        ? 'is--configured' 
                        : !configurableModule.includes(setting.name) 
                          ? 'not--configurable' 
                            : ''
                    }
                  >
                    {isModuleConfigured(setting.name) ? <FaCheckCircle style={{marginRight: '5px'}} size={17} color='#fff'/> : ''}
                    {isModuleConfigured(setting.name) ? t('connectionAndChannel.configured') : t('connectionAndChannel.set_up')}
                  </button>
                </div>
              ))}

              <div className="setting is--trello">
                <img src={logoTrello} alt="Trello" />
                <div className="descriptionSet">
                  <p className="titleSet">Trello</p>
                  <p className="text">
                    Automatize a criação de tarefas chaves em seu board do dia a
                    dia.
                  </p>
                </div>
                <button
                  type="button"
                  onClick={() => handleConfig({ name: 'trello' })}
                >
                  {t('connectionAndChannel.set_up')}
                </button>
              </div>
            </>
          )}
          {!loading && settings.length === 0 && (
            <div className="noSettings">
              <p>Nenhum módulo ou canal encontrado</p>
            </div>
          )}
          {loading && (
            <div className="loading">
              <Loader/>
            </div>
          )}
        </div>
      </Container>

      <SettingsModal open={activeModal === 'trello'} onClose={handleCloseModal}>
        <div className="modal">
          <div className="modal__header">
            <p className="modal__title">{t('firstStep.trello_configuration')}</p>
            <div className="line" />
          </div>

          <div className="modal__body">
            <form className="modal__form">
              <div className="modal__form-group">
                <span className="modal__form-label">{t('firstStep.select_board')}:</span>
                <Option
                  placeholder={t('common.select')}
                  options={trelloBoards}
                  onChange={handleTrelloBoardChange}
                />
              </div>
            </form>
          </div>

          <div className="modal__footer">
            <button
              className="modal__form-btn is--cancel"
              type="button"
              onClick={handleCloseModal}
            >
              {t('common.cancel')}
            </button>
            <button
              className="modal__form-btn is--save"
              type="button"
              onClick={handleTrelloSaveConfig}
            >
              {t('common.save')}
            </button>
          </div>
        </div>
      </SettingsModal>

      <CustomModalGoogleAds open={activeModal === 'googleAds'} handleClose={handleCloseModal} handleGetUserSettings={getUserSettings}/>
      <CustomModalFacebookAds open={activeModal === 'facebookAds'} handleClose={handleCloseModal} handleGetUserSettings={getUserSettings}/>
      <CustomModalVtex open={activeModal === 'vtex'} handleClose={handleCloseModal} handleGetUserSettings={getUserSettings}/>
      <CustomModalGoogleAnalytics4 open={activeModal === 'googleAnalytics4'} handleClose={handleCloseModal} handleGetUserSettings={getUserSettings}/>
    </BackgroundNew>
  );
}

export default ConnectionAndChannel;
