import styled from 'styled-components';
import { darken } from 'polished';

export const CardGroupContainer = styled.div`  
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: left;
  border: none;
  border-radius: 10px;
  color: #1A1D1F;
  margin-bottom: 20px;
  background: #fff;
  padding: 20px;

  .cardGroupHeader {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 1rem;

    @media (max-width: 689px) { 
      flex-direction: column;
      align-content: left;

      .cardGroupTitle {
        margin-bottom: 1rem;
        width: 100%;
      }

      .buttonGroup {
        width: 100%;
        justify-content: center;
      }
    }

    .cardGroupTitle {
      font-size: 1.4rem;
      font-weight: 200;
      line-height: 2rem;
  
      .tagIdentifier {
        border-radius: 4px;
        padding: 2px 6px;
        margin-right: 10px;
      }
    }

    .buttonGroup {
      display: flex;
      flex-direction: row;
      align-items: center;

      .exportPDFButton {
        background-color: #F4F4F4;
        border: none;
        color: #222222;
        font-size: 13px;
        font-weight: 500;
        padding: 10px 15px;
        border-radius: 20px;
        cursor: pointer;
        transition: background-color 0.3s;
        display: flex;
        align-items: center;
        margin-right: 10px;
        border: 2px solid #ff0068;

        .exportPDFButtonIcon {
          margin-right: 5px;
          color: #ff0068;
        }
  
        &:hover {
          background-color: ${darken(0.1, '#ff0068')};
          color: #ffffff;
          border-color: transparent;

          .exportPDFButtonIcon {
            color: #ffffff;
          }
        }

        &:disabled {
          background-color: #999;
          border-color: #999;
          color: #fff;
          cursor: not-allowed;
          box-shadow: none;

          .exportPDFButtonIcon {
            color: #fff;
          }
    
          &:hover {
              background-color: #999;
              border-color: #999;
              color: #fff;
          }
        }
      }

      .backButton {
        background-color: transparent;
        border: none;
        color: #222222;
        font-size: 13px;
        font-weight: 500;
        padding: 10px 15px;
        border-radius: 20px;
        border: 2px solid #DEDEDE;
        cursor: pointer;
        transition: background-color 0.3s;
        display: flex;
        align-items: center;
  
        .backButtonIcon {
          margin-right: 5px;
        }
  
        &:hover {
          background-color: ${darken(0.1, '#ff0068')};
          color: #ffffff;
          border-color: transparent;
        }
    }
  }

  .cardGroupContent {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    scroll-behavior: smooth;

    .cardContent {
      width: 100%;
    }

    .noData {
      font-size: 15px;
      font-weight: 200;
    }

    /* width */
      ::-webkit-scrollbar {
        width: 0px;
        height: 0px;
      }

      /* Track */
      ::-webkit-scrollbar-track {
        box-shadow: #f1f1f1; 
        border-radius: 10px;
        width: 5px;
      }
      
      /* Handle */
      ::-webkit-scrollbar-thumb {
        background: #DEDEDE; 
        border-radius: 10px;
      }

      /* Handle on hover */
      ::-webkit-scrollbar-thumb:hover {
        background: #C8C8C8; 
      }
  }
`;