import React, { useEffect, useMemo, useState } from 'react'

import { useTranslation } from 'react-i18next';
import '../../../i18n';

import * as S from './styles';
import { AiOutlineLoading3Quarters } from 'react-icons/ai'

const Loader = ({showDescription = false}) => {
  const { t } = useTranslation();

  const descriptions = useMemo(_ => [
    t('mediaPlanner.plan_ready_soon'),
    t('mediaPlanner.analyzing_data'),
    t('mediaPlanner.incorporate_intelligence'),
    t('mediaPlanner.achieve_goal')
  ], [t]);

  const [activeDescription, setActiveDescription] = useState(descriptions[0]);

  useEffect(() => {
    setTimeout(() => {
      let index = descriptions.indexOf(activeDescription);
      index = index === -1 ? 0 : index + 1;
      setActiveDescription(descriptions[index]);
    }, 3000);
  }, [activeDescription, descriptions])

  return (
      <S.LaoderContainer>
        <div className='icon__container'>
          <AiOutlineLoading3Quarters size={60} color="#ff0467" />
        </div>
        {showDescription && <span className='active__description'>{activeDescription}</span>}
      </S.LaoderContainer>
  )
};

export default Loader;
