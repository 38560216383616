import api from './api';

import i18n from '../i18n';

export const getCompanyFeatures = async () => {
  const token = localStorage.getItem('token');

  const response = await api.get(`/feature/company`, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });

  if (response && response.status === 204) {
    return null;
  }

  if (!response || response.status !== 200) {
    throw new Error(i18n.t('error.company_features_retrieve_failed'));
  }

  return response.data;
};

export const getCompanyFeatureUsageByFeatureId = async (featureId) => {
  const token = localStorage.getItem('token');

  const response = await api.get(`/feature/company/feature/${featureId}/usage/thismonth`, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });

  if (response && response.status === 204) {
    return null;
  }

  if (!response || response.status !== 200) {
    throw new Error(i18n.t('error.feature_usage_retrieve_failed'));
  }

  return response.data;
};
