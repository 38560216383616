import styled from 'styled-components';

export const Container = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    width: 100%;

    .cardPercentageApprovedOdersPerPaymentMethodRef {
        margin-right: 10px;
    }

    .cardPercentageApprovedOrdersPerInstallmentRef {
        margin-left: 10px;
    }

    @media (max-width: 829px) {
        flex-direction: column;

        .cardPercentageApprovedOdersPerPaymentMethodRef, .cardPercentageApprovedOrdersPerInstallmentRef {
            margin-right: 0px;
            margin-left: 0px;
        }
    }
`;