import dayjs from 'dayjs';
import customParseFormat from 'dayjs/plugin/customParseFormat';

import i18n from '../i18n';

import { DIMENSIONS_OPTONS} from '../options/filterOptions';

dayjs.extend(customParseFormat);

export const isValidDateRange = (startDate, endDate, maxDays) => {
    try {
      if (!startDate || !endDate) {
        return false;
      }
  
      if (startDate > endDate) {
        return false;
      }
  
      const dateDiffInDays = (endDate - startDate) / (1000 * 3600 * 24);
  
      if (dateDiffInDays > maxDays) {
        return false;
      }
  
      return true;
    } catch (error) {
      return false;
    }
}

export const convertDaysToMonths = (days) => {
  const daysPerMonth = 30.44; // Média de dias por mês
  const months = Math.round(days / daysPerMonth);
  
  return months;
}

export const getSelectedInitialDateRange = () => {
  const currentDateRange = getCurrentDateRange();

  if (currentDateRange) {
    return {
      initialDateFilter: dayjs(currentDateRange.initialDateFilter),
      finalDateFilter: dayjs(currentDateRange.finalDateFilter)
    };
  }

  return getDefaultDateRangeYesterday();
}

export const getSelectedGoalsOverviewAndEvolutionInitialDateRange = () => {
  const currentDateRange = getCurrentGoalsOverviewAndEvolutionDateRange();

  if (currentDateRange) {
    return {
      initialDateFilter: dayjs(currentDateRange.initialDateFilter),
      finalDateFilter: dayjs(currentDateRange.finalDateFilter)
    };
  }

  return getDefaultDateRangeYesterday();
}

export const getSelectedCustomerBehaviorInitialDateRange = () => {
  const currentDateRange = getCurrentCustomerBehaviorDateRange();

  if (currentDateRange) {
    return {
      initialDateFilter: dayjs(currentDateRange.initialDateFilter),
      finalDateFilter: dayjs(currentDateRange.finalDateFilter)
    };
  }

  return getDefaultDateRangeYesterday();
}

export const getDefaultDateRangeYesterday = () => {
    const today = dayjs(new Date());
    const yesterday = dayjs(new Date()).subtract(1, 'day');

    return {
        initialDateFilter: yesterday,
        finalDateFilter: today,
    }
}


export const parseDateToDayjs = (date) => {
    return dayjs(date);
}

export const formatDate = (date) => {
    return date.format('YYYY-MM-DD').toString();
}

export const formatDateDayjsToBrazil = (date) => {
    return date.format('DD/MM/YYYY').toString();
}

export const formatDateBrazil = (date) => {
  const dateObj = new Date(date);
  const formatter = new Intl.DateTimeFormat('pt-BR');

  return formatter.format(dateObj);
}

export const getAgregatedKeyByDimension = (date, dimension) => {
  if (dimension === DIMENSIONS_OPTONS.year) {
      return date.slice(0, 4);
  } else if (dimension === DIMENSIONS_OPTONS.month) {
      return date.slice(0, 7);
  } else {
      return date;
  }
}

export const setCurrentDateRange = (dateRange) => {
  localStorage.setItem('currentViewDateRangeSelectedV2', JSON.stringify({
    initialDateFilter: dateRange[0],
    finalDateFilter: dateRange[1]
  }));
}

export const setCurrentGoalsOverviewAndEvolutionDateRange = (dateRange) => {
  localStorage.setItem('currentViewGoalsOverviewAndEvolutionDateRangeSelectedV2', JSON.stringify({
    initialDateFilter: dateRange[0],
    finalDateFilter: dateRange[1]
  }));
}

export const setCurrentCustomerBehaviorDateRange = (dateRange) => {
  localStorage.setItem('currentViewCustomerBehaviorDateRangeSelectedV2', JSON.stringify({
    initialDateFilter: dateRange[0],
    finalDateFilter: dateRange[1]
  }));
}

export const getCurrentDateRange = () => {
  return JSON.parse(localStorage.getItem('currentViewDateRangeSelectedV2'));
}

export const getCurrentGoalsOverviewAndEvolutionDateRange = () => {
  return JSON.parse(localStorage.getItem('currentViewGoalsOverviewAndEvolutionDateRangeSelectedV2'));
}

export const getCurrentCustomerBehaviorDateRange = () => {
  return JSON.parse(localStorage.getItem('currentViewCustomerBehaviorDateRangeSelectedV2'));
}

export const getCurrentYear = () => {
  const date = new Date();
  return date.getFullYear();
};

export const monthDictionary = {
  JANUARY: 1,
  FEBRUARY: 2,
  MARCH: 3,
  APRIL: 4,
  MAY: 5,
  JUNE: 6,
  JULY: 7,
  AUGUST: 8,
  SEPTEMBER: 9,
  OCTOBER: 10,
  NOVEMBER: 11,
  DECEMBER: 12,
};

export const getMonthName = (month) => {
  const monthNumber = parseInt(month);
  return months[monthNumber];
};

export const isLeapYear = (year) => year % 400 === 0 || (year % 4 === 0 && year % 100 !== 0);

export const getNumberOfDaysInMonth = (month, leapYear = false) => {
  if ([1, 3, 5, 7, 8, 10, 12].includes(month)) {
    return 31;
  } else if ([4, 6, 9, 11].includes(month)) {
    return 30;
  } else if (month === 2) {
    if (leapYear) {
      return 29;
    }
    return 28;
  }
  return 0;
};

let months = {}

const updateOptions = _ => { 
  months = {
    1: `${i18n.t('common.january')}`,
    2: `${i18n.t('common.february')}`,
    3: `${i18n.t('common.march')}`,
    4: `${i18n.t('common.april')}`,
    5: `${i18n.t('common.may')}`,
    6: `${i18n.t('common.june')}`,
    7: `${i18n.t('common.july')}`,
    8: `${i18n.t('common.august')}`,
    9: `${i18n.t('common.september')}`,
    10: `${i18n.t('common.october')}`,
    11: `${i18n.t('common.november')}`,
    12: `${i18n.t('common.december')}`,
  };
}

i18n.on('languageChanged', (lng) => {
  updateOptions();
});

updateOptions();

export { months };