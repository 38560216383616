import React, { useEffect, useState, useCallback } from "react";

import { toast } from 'react-toastify';

import { useTranslation } from 'react-i18next';
import '../../i18n';

import Input from '../../components/Input';
import Button from '../../components/Button';
import Loader from '../../components/Loader';
import CardGroup from '../../components/CardGroup';
import BackgroundNew from '../../components/BackgroundNew';

import ChangePasswordModal from './ChangePasswordModal';

import { Container } from './styles';

import { getCurrentUser, editCompany, editUser } from '../../services/UserService';
import { setUserInfo } from '../../utils/loginUtils';

const Profile = () => {
    const { t } = useTranslation();

    const [user, setUser] = useState(null);
    const [isCompanyProfile, setIsCompanyProfile] = useState(false);
    const [loading, setLoading] = useState(true);
    const [editMode, setEditMode] = useState(false);
    const [editLoading, setEditLoading] = useState(false);
    const [showChangePasswordModal, setShowChangePasswordModal] = useState(false);
    
    const [companyName, setCompanyName] = useState('');
    const [firstName, setFirstName] = useState('');
    const [lastName, setLastName] = useState('');
    const [email, setEmail] = useState('');
    const [username, setUsername] = useState('');

    const handleCancel = () => {
        setUserProfile(user)
        setEditMode(false);
    };

    const setUserProfile = (user) => {
        setCompanyName(user.name || '');
        setFirstName(user.first_name || '');
        setLastName(user.last_name || '');
        setEmail(user.email);
        setUsername(user.username);
    }

    const isValidFieldsValues = () => {
        let result = true;
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        const spaceRegex = /^\s*$/;

        if (!email) {
            toast.error(t('toast.email_required'));
            result = false;
        }

        if (email && !emailRegex.test(email)) {
            toast.error(t('toast.email_invalid'));
            result = false;
        }

        if (isCompanyProfile) {
            if (!companyName) {
                toast.error(t('toast.company_name_required'));
                result = false;
            }

            if (companyName && spaceRegex.test(companyName)) {
                toast.error(t('toast.company_name_invalid'));
                result = false;
            }
        } else {
            if (!firstName) {
                toast.error(t('toast.name_required'));
                result = false;
            }

            if (firstName && spaceRegex.test(firstName)) {
                toast.error(t('toast.name_invalid'));
                result = false;
            }

            if (!lastName) {
                toast.error(t('toast.last_name_required'));
                result = false;
            }

            if (lastName && spaceRegex.test(lastName)) {
                toast.error(t('toast.last_name_invalid'));
                result = false;
            }
        }

        return result;
    }

    const getUser = useCallback(async () => {
        setLoading(true);
        try {
          const response = await getCurrentUser();

          setIsCompanyProfile(response.account_type.toLowerCase() === 'company');
          setUserProfile(response);
          setUser(response);
        } catch (error) {
          toast.error(t('toast.profile_fetch_error'));
        } finally {
          setLoading(false);
        }
    }, [t]);

    const handleUpdateProfile = async () => {
        if (!isValidFieldsValues()) {
            return;
        }

        setEditLoading(true);
        try {
            if (isCompanyProfile) {
                await editCompany(user.id, {
                    name: companyName,
                    email
                });
            } else {
                await editUser(user.id, {
                    first_name: firstName,
                    last_name: lastName,
                    email
                });
            }
            
            await setUserInfo();

            toast.success(t('toast.profile_updated_success'));
            setEditMode(false);
        } catch (error) {
            toast.error(t('toast.profile_save_error'));
        } finally {
            setEditLoading(false);
        }
    };

    useEffect(() => {
        getUser();
    }, [getUser]);

    return (
        <BackgroundNew 
        titlePage={t('menu.profile')}
        subTitlePage={t('profile.manage_your_profile')}
        >
            <Container> 
                <CardGroup
                    title={t('profile.profile_information')}
                    customClassName={'profileCardGroup'}
                >
                    {!loading && user &&
                        <div className="userInfoGroup">
                            {isCompanyProfile && (
                                <div className="userInfo">
                                    <Input
                                        label={t('profile.company_name')}
                                        value={companyName}
                                        onChange={(e) => setCompanyName(e.target.value)}
                                        disabled={!editMode || editLoading}
                                        type={"text"}
                                    />
                                </div>
                            )}
                            {!isCompanyProfile && (
                                <>
                                    <div className="userInfo">
                                        <Input
                                            label={t('profile.name')}
                                            value={firstName}
                                            onChange={(e) => setFirstName(e.target.value)}
                                            disabled={!editMode || editLoading}
                                            type={"text"}
                                        />
                                    </div>
                                    <div className="userInfo">
                                        <Input
                                            label={t('profile.last_name')}
                                            value={lastName}
                                            onChange={(e) => setLastName(e.target.value)}
                                            disabled={!editMode || editLoading}
                                            type={"text"}
                                        />
                                    </div>
                                </>
                            )}
                            <div className="userInfo">
                                <Input
                                    label="Email"
                                    value={email}
                                    onChange={(e) => setEmail(e.target.value)}
                                    disabled={!editMode || editLoading}
                                    type={"email"}
                                />
                            </div>
                            <div className="userInfo">
                                <Input
                                    label={t('users.user')}
                                    value={username}
                                    disabled={true}
                                />
                            </div>
                        </div>
                    }
                    {!loading && user &&
                        <div className="userInfoActions">
                            {!editMode && <Button
                                text={t('alerts.edit')}
                                customClass="editButton"
                                onClick={() => setEditMode(true)}
                                disabled={editLoading}
                            />}
                            {!editMode && <Button
                                text={t('profile.change_password')}
                                customClass="changePasswordButton"
                                onClick={() => setShowChangePasswordModal(true)}
                                disabled={editLoading}
                            />}
                            {editMode && <Button
                                text={t('common.cancel')}
                                customClass="cancelButton"
                                onClick={handleCancel}
                                disabled={editLoading}
                            />}
                            {editMode && <Button
                                text={t('common.save')}
                                customClass="saveButton"
                                onClick={handleUpdateProfile}
                                disabled={editLoading}
                                enableLoading={true}
                                isLoading={editLoading}
                            />}
                        </div>
                    }
                    {loading && <Loader />}
                    {!loading && !user && (
                        <div className="noUserInfo">
                            <p>Nenhuma informação disponível</p>
                        </div>
                    )}
                </CardGroup>
            </Container>
            <ChangePasswordModal
                isOpen={showChangePasswordModal}
                onClose={() => setShowChangePasswordModal(false)}
            />
        </BackgroundNew>
    );
};


export default Profile;
