import React, { useState, useEffect, useCallback, useContext } from 'react';

import { useTranslation } from 'react-i18next';
import '../../i18n';

import Loader from './Loader';
import BackgroundNew from '../../components/BackgroundNew';
import {
  Container,
  Option
} from './styles';

import {
  MdAdd
} from 'react-icons/md';

import {
  FaTrashAlt
} from 'react-icons/fa';

import CustomerModalRegistrationGoals from './CustomerModalRegistrationGoals';

import CustomerGoalsRows from './CustomerGoalsRows';
import CustomerGoalsRevenueYearRow from './CustomerGoalsRevenueYearRow';
import CustomerModalDeleteGoals from './CustomerModalDeleteGoals';
import CustomerGoalsModalInformation from './CustomerGoalsModalInformation';

import { 
  deleteProjectedCustomerGoals,
  getProjectedCustomerGoals,
} from '../../services/customerService';

import { yearOptions } from './utils/customerGoals';
import { formatValueToMoney, formatValueToNumber } from '../../utils/metricsUtils';

import { toast } from 'react-toastify';

import { AuthContext } from '../../contexts';

const MOBILE_BREAKPOINT = 1200

const MediaPlanner = () => {
    const { t } = useTranslation();

    const { user, currency } = useContext(AuthContext);

    const [showLoading, setShowLoading] = useState(true);
    const [showDescriptionLoading, setShowDescriptionLoading] = useState(false);
    const [mobileModeActive, setMobileModeActive] = useState(false);
    const [openCustomerRegistrationGoalsModal, setOpenCustomerRegistrationGoalsModal] = useState(false);
    const [openCustomerDeleteGoalsModal, setOpenCustomerDeleteGoalsModal] = useState(false);
    const [projectedCustomerGoals, setProjectedCustomerGoals] = useState(null);
    const [activeProjectedCustomerGoals, setActiveProjectedCustomerGoals] = useState(null);
    const [activeCustomerGoalYear, setActiveCustomerGoalYear] = useState(null);
    const [currentYear, setCurrentYear] = useState(null);
    const [selectedYearOption, setSelectedYearOption] = useState(null);
    const [openCustomerGoalsModalInformation, setOpenCustomerGoalsModalInformation] = useState(false);

    const getCurrentYear = () => {
      const date = new Date();
      return date.getFullYear();
    }

    const cleanFormatedCurrency = (value) => {
      return parseFloat(value.replace(/\./g, "").replace(/,/g, "."));
    }

    const handleChangeActiveYear = (select, action) => {
      setShowLoading(true);

      setSelectedYearOption(select)

      setActiveCustomerGoalYear(select.value);

      const yearNumber = parseInt(select.value);

      if(projectedCustomerGoals) {
        const activeProjectedCustomerGoalsMonth = projectedCustomerGoals.find((customerGoal) => customerGoal.year === yearNumber);

        if (activeProjectedCustomerGoalsMonth) {
          setActiveProjectedCustomerGoals(activeProjectedCustomerGoalsMonth);
        } else {
          setActiveProjectedCustomerGoals(null);
        }
      }

      setShowLoading(false);
    }

    const handleDeleteCustomerGoals = async () => {
      setOpenCustomerDeleteGoalsModal(false);
      setShowLoading(true);

      const year = parseInt(activeCustomerGoalYear);

      try {
        await deleteProjectedCustomerGoals(year);

        setProjectedCustomerGoals(null);

        toast.success(t('toast.plan_deleted_success'));

        await loadCustomerGoals(activeCustomerGoalYear);
      } catch (error) {
        toast.error(t('toast.plan_delete_failed'));
      } finally {
        setShowLoading(false);
      }
    }

    const handleCreateCustomerGoals = async () => {
      setOpenCustomerRegistrationGoalsModal(true);
    }

    const handleCloseCustomerRegistrationGoalsModal = () => {
      setOpenCustomerRegistrationGoalsModal(false);
    }

    const handleShowCustomerDeleteGoalsModal = async () => {
      setOpenCustomerDeleteGoalsModal(true);
    }

    const handleCloseCustomerDeleteGoalsModal = () => {
      setOpenCustomerDeleteGoalsModal(false);
    }

    const handleShowCustomerGoalsModalInformation = () => {
      setOpenCustomerGoalsModalInformation(true);
    }

    const handleCloseCustomerGoalsModalInformation = () => {
      setOpenCustomerGoalsModalInformation(false);
    }

    const delay = ms => new Promise(res => setTimeout(res, ms));

    const loadCustomerGoals = useCallback(async (activeCustomerGoalYear = null, delayLoading = false) => {
      setShowLoading(true);

      const currentYear = getCurrentYear();
      const pastYear = currentYear - 1;
      const nextYear = currentYear + 1;

      const currentYearString = currentYear.toString();

      setCurrentYear(currentYearString);

      let selectedYear = currentYear

      if (activeCustomerGoalYear === null) {
        setActiveCustomerGoalYear(currentYearString);

        const optionYear = yearOptions[0].options.find((option) => option.value === currentYearString);

        setSelectedYearOption(optionYear);
      } else {
        selectedYear = parseInt(activeCustomerGoalYear);
      }

      try {
        const response = await getProjectedCustomerGoals([pastYear, currentYear, nextYear]);

        setProjectedCustomerGoals(response);
        
        if (response) {
          const activeProjectedCustomerGoalsMonth = response.find((customerGoal) => customerGoal.year === selectedYear);

          if (activeProjectedCustomerGoalsMonth) {
            setActiveProjectedCustomerGoals(activeProjectedCustomerGoalsMonth);
          } else {
            setActiveProjectedCustomerGoals(null);
          }
        } else {
          setActiveProjectedCustomerGoals(null);
        }

        if(delayLoading) {
          await delay(10000);
        }

        setShowLoading(false);
        setShowDescriptionLoading(false);
      } catch (error) {
        setActiveProjectedCustomerGoals(null);

        toast.error(t('toast.plan_retrieve_failed'));
        
        setShowLoading(false);
        setShowDescriptionLoading(false);
      }
    }, [t]);

    useEffect(() => {
      setMobileModeActive(window.innerWidth < MOBILE_BREAKPOINT);
      window.addEventListener('resize', () => { setMobileModeActive(window.innerWidth < MOBILE_BREAKPOINT) });

      loadCustomerGoals();
    }, [loadCustomerGoals]);

    return (
        <BackgroundNew titlePage={t('menu.media_planner')}
          showInformation 
          informationTooltipTitle={t('common.click_to_learn_more')}
          informationTooltipPlacement="right-start"
          informationOnClick={handleShowCustomerGoalsModalInformation}
          subTitlePage={t('mediaPlanner.discover_goals_per_channel')}
        >
          <Container>
            <div className="header">
              {!showLoading && activeCustomerGoalYear !== null && 
                <div className="modal__form-group">
                  <Option
                      placeholder="Selecione o ano..."
                      options={yearOptions}
                      defaultValue={selectedYearOption}
                      onChange={handleChangeActiveYear}
                      theme={(theme) => ({
                        ...theme,
                        colors: {
                          ...theme.colors,
                          primary25: '#F4F4F4',
                          primary: '#ff0068'
                        },
                      })}
                  />
                  {user && user.type === 'company' &&
                    <>
                      {!showLoading && activeProjectedCustomerGoals === null && activeCustomerGoalYear >= currentYear && 
                        <button type="button" onClick={handleCreateCustomerGoals}>
                          {mobileModeActive ? '' : t('mediaPlanner.create_planning')}
                          <MdAdd size={22} />
                        </button>
                      }
    
                      {!showLoading && activeProjectedCustomerGoals !== null && activeCustomerGoalYear >= currentYear && 
                        <button type="button" onClick={handleShowCustomerDeleteGoalsModal}>
                          {mobileModeActive ? '' : t('mediaPlanner.delete_planning')}
                          <FaTrashAlt size={19} />
                        </button>
                      }
                    </>
                  }
                </div>
              }
            </div>
    
            <div className="workspace">
            {showLoading && 
              <div className="contentLoading"><Loader showDescription={showDescriptionLoading}/></div>
            }
            {!showLoading && activeProjectedCustomerGoals == null &&
              <div className="contentEmpty"> <p>{t('mediaPlanner.goal_not_set')}</p> </div>
            }
            {!showLoading && activeProjectedCustomerGoals !== null && 
              <CustomerGoalsRevenueYearRow revenueYear={formatValueToMoney(activeProjectedCustomerGoals.revenue_year, currency)} />
            }

            {!showLoading && activeProjectedCustomerGoals !== null && 
              <CustomerGoalsRows 
                data={activeProjectedCustomerGoals.projected_goals_month} 
                formateCurrecy={value => formatValueToMoney(value, currency)} 
                formateNumber={formatValueToNumber} 
              />
            }
            </div>
            </Container>
            <CustomerModalRegistrationGoals 
              open={openCustomerRegistrationGoalsModal} 
              handleClose={handleCloseCustomerRegistrationGoalsModal}
              loadCustomerGoals={loadCustomerGoals}
              cleanFormatedCurrency={cleanFormatedCurrency}
              activeCustomerGoalYear={activeCustomerGoalYear}
              setShowDescriptionLoading={() => setShowDescriptionLoading(true)}
            />
            <CustomerModalDeleteGoals 
              open={openCustomerDeleteGoalsModal} 
              deleteCustomerGoals={handleDeleteCustomerGoals} 
              handleClose={handleCloseCustomerDeleteGoalsModal}
            />
            <CustomerGoalsModalInformation 
              open={openCustomerGoalsModalInformation} 
              handleClose={handleCloseCustomerGoalsModalInformation}
            />
        </BackgroundNew>
    );
}

export default MediaPlanner;