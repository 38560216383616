import api from './api';

import i18n from '../i18n';

export const getSmartReportSettingsByFeatureId = async (featureId) => {
  const token = localStorage.getItem('token');

  const response = await api.get(`/smart_report/settings?feature_id=${featureId}`, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });

  if (response.status === 200 && Object.keys(response.data).length === 0) {
    return null;
  }

  if (!response || response.status !== 200) {
    throw new Error(i18n.t('error.smart_report_settings_retrieve_failed'));
  }

  return response.data;
};

export const getTopHundredProductsModel = async () => {
  const token = localStorage.getItem('token');

  const response = await api.get(`/smart_report/minimum_stock/model/top_hundred_products/csv`, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  })

  if (!response || response.status !== 200) {
    throw new Error(i18n.t('error.product_registration_model_retrieve_failed'));
  }

  return response;
}

export const deleteSmartReportSettings = async (smartreportSettingsId) => {
  const token = localStorage.getItem('token');

  const response = await api.delete(`/smart_report/settings/${smartreportSettingsId}`, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });

  if (!response || response.status !== 200) {
    throw new Error(i18n.t('error.smart_report_settings_deletion_failed'));
  }
}

export const createSmartReportMinimumStockSettings = async (data) => {
  const token = localStorage.getItem('token');

  const response = await api.post(`/smart_report/minimum_stock/settings`, data, {
    headers: {
      Authorization: `Bearer ${token}`
    }
  });

  if (!response || response.status !== 200) {
    throw new Error(i18n.t('error.smart_report_settings_creation_failed'));
  }
}
