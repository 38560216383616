import styled from 'styled-components';

export const RowContainer = styled.div`        
  display: flex;
  flex-direction: column;
  align-items: center;  
  box-shadow: 0 0 20px rgba(215, 222, 227, 0.39);
  border-radius: 8px;
  border: 1px solid transparent;
  background-color: #ffffff;
  padding: 11px 20px 20px 20px;
  flex-wrap: wrap;
  margin-bottom: 20px;

  .goal__year {
    &__container{
        display: flex;
        flex-direction: column;
        -webkit-justify-content: left;
        width: 100%;
    }

    &__title {
        font-size: 20px;
        font-weight: 300;
        color: #2e3039;
    }

    &__revenue {
        font-size: 24px;
        font-weight: 600;
        color: #e6005e;
        margin-top: 10px;
    }
  }
`;