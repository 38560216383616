import api from './api';

export const getPartnerList = async () => {
  const token = localStorage.getItem('token');

  const response = await api.get('/partner/', {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });

  if (!response || response.status !== 200) {
    return null;
  }

  return response.data;
};