import styled from 'styled-components';

export const ContainerDeliveryTimeQuarterTable = styled.div`
    .deliveryTimeQuarterTable {
        width: 100%;
        border-spacing: 0;
        border: 1px solid #ddd;
        border-radius: 8px;
        margin-bottom: 10px;

        th, td {
          text-align: left;
          padding: 16px;
        }

        tr:nth-child(even) {
          background-color: #f2f2f2;
        }
    }
`;