import React, { useEffect, useState, useCallback } from 'react';
import { Tooltip } from '@mui/material';
import './styles.css';

export const BisoTooltip = ({ 
  title, 
  placement, 
  children, 
  startOpen = false, 
  disabled = false, 
  manualOpen = false 
}) => {
  const [open, setOpen] = useState(false);

  const wait = (ms) => new Promise((resolve) => setTimeout(resolve, ms));

  const handleShow = useCallback(async () => {
    setOpen(true);
    await wait(3000);
    setOpen(false);
  }, []);

  useEffect(() => {
    if (startOpen || manualOpen) {
      handleShow()
    }

    if (disabled) {
      setOpen(false);
    }

  }, [handleShow, startOpen, manualOpen, disabled]);

  return (
    <Tooltip 
      title={title} 
      placement={placement} 
      open={open}
      onMouseEnter={_ => setOpen(true)}
      onClose={_ => setOpen(false)}
      arrow
    >
      {children}
    </Tooltip>
  );
}