import React from 'react';
import { v4 as uuidv4 } from 'uuid';

import { useTranslation } from 'react-i18next';
import '../../../i18n';

import * as S from './styles';

import { getMonthName } from '../utils/customerGoals';

import { BisoTooltip } from '../../../components/Tooltip';

export default function CustomerGoalsRows({ data, formateCurrecy, formateNumber }) {
  const { t } = useTranslation();

  const Table = ({ innerContent, title, bigNumbersContent }) => (
    <S.RowContainer>
      <div className="metric__name-wrapper">
        <h3 className="metric__name">{title}</h3>
      </div>
      <div className='big__numbers__container'>
        {bigNumbersContent}
      </div>
      <div className="metric__container">
        {innerContent}
      </div>
    </S.RowContainer>
  );

  const getGoalsByChannel = (goals) => {
    return goals.map(goal => (
        <div className='metric__wrapper' key={uuidv4()} >
          <p className='metric__range'>{goal.channel}</p>
          <div className='metric__value-wrapper'>
            <label className='metric__label'>{t('common.sessions')}</label>
            <BisoTooltip 
              title={t('mediaPlanner.focus_on_sessions')}
              placement="right-start"
            >
              <p className='metric__value sessions_value'>{formateNumber(goal.sessions)}</p>
            </BisoTooltip>
            <label className='metric__label'>{t('common.conversion_rate')}</label>
            <p className='metric__value'>{goal.conversion_rate}%</p>
            <label className='metric__label'>{t('common.transactions')}</label>
            <p className='metric__value'>{formateNumber(goal.transactions)}</p>
            <label className='metric__label'>{t('common.average_ticket')}</label>
            <p className='metric__value'>{formateCurrecy(goal.avg_ticket)}</p>
            <label className='metric__label'>{t('common.revenue')}</label>
            <p className='metric__value'> {formateCurrecy(goal.revenue)}</p>
          </div>
        </div>
    ))
  };

  const getBigNumbersByGoals = (goals) => {
    const revenueTotal = Math.round(goals.reduce((acc, goal) => acc + goal.revenue, 0));
    const transactionsTotal = goals.reduce((acc, goal) => acc + goal.transactions, 0);
    const sessionsTotal = goals.reduce((acc, goal) => acc + goal.sessions, 0);
    const avgTicket = transactionsTotal > 0 ? revenueTotal / transactionsTotal : 0;
    const conversionRate = sessionsTotal > 0 ? ((transactionsTotal / sessionsTotal) * 100).toPrecision(2) : 0;

    return (
        <div className='metric__wrapper big__numbers__content' key={uuidv4()} >
          <div className='big__numbers__row'>
            <label className='metric__label'>{t('mediaPlanner.revenue_per_month')}</label>
            <p className='metric__value'> {formateCurrecy(revenueTotal)}</p>
          </div>
          <div className='big__numbers__row'>
            <label className='metric__label'>{t('mediaPlanner.sessions_per_month')}</label>
            <p className='metric__value sessions_value'>{formateNumber(sessionsTotal)}</p>
          </div>
          <div className='big__numbers__row'>
            <label className='metric__label'>{t('mediaPlanner.conversion_per_month')}</label>
            <p className='metric__value'>{conversionRate}%</p>
          </div>
          <div className='big__numbers__row'>
            <label className='metric__label'>{t('mediaPlanner.average_ticket_per_month')}</label>
            <p className='metric__value'>{formateCurrecy(avgTicket)}</p>
          </div>
          <div className='big__numbers__row '>
            <label className='metric__label'>{t('mediaPlanner.transactions_per_month')}</label>
            <p className='metric__value'>{formateNumber(transactionsTotal)}</p>
          </div>
        </div>
    )
  };

  const getCustomerGoalsByMonth = (data) => {
    let goalsByMonth = {};

    data.forEach((goal) => {
      if (goalsByMonth.hasOwnProperty(goal.month)) {
        goalsByMonth[goal.month].push(goal);
      } else {
        goalsByMonth[goal.month] = [goal];
      }
    });

    return goalsByMonth;
  }

  const showMessageInvalidProjectedGoals = () => {
    return (
      <S.MessageProjectedGoalsNotValid className='project__goals__not__valid'>{t('mediaPlanner.goal_set_no_history')}</S.MessageProjectedGoalsNotValid>
    )
  }

  const renderCustomerGoalsRows = (data) => {
    const goalsByMonth = getCustomerGoalsByMonth(data);

    const rows = []

    let projectGoalsIsValid = true;

    Object.keys(goalsByMonth).forEach(month => {
      const goalsByChannel = getGoalsByChannel(goalsByMonth[month]);

      const bigNumbers = getBigNumbersByGoals(goalsByMonth[month]);

      const monthName = getMonthName(month);

      const year = goalsByMonth[month][0].year;

      if (goalsByMonth[month].length === 1 && goalsByMonth[month][0].transactions === 0) {
        projectGoalsIsValid = false;
      }

      rows.push(<Table innerContent={goalsByChannel} bigNumbersContent={bigNumbers} title={`${monthName}/${year}`} key={uuidv4()} />)
    })

    return (
      <>
        {!projectGoalsIsValid && showMessageInvalidProjectedGoals()}
        {rows}
      </>
    );
  }

  return renderCustomerGoalsRows(data);
};
