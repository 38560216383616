import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;

  .cardGroupContainerFirst {
    margin-right: 10px;
  }

  .cardGroupContainerSecond {
    margin-left: 10px;
  }

  @media (max-width: 1348px) {
    flex-direction: column;

    .cardGroupContainerFirst,
    .cardGroupContainerSecond {
      margin-right: 0px;
      margin-left: 0px;
    }
  }
`;
