import api from './api';

import i18n from '../i18n';

export const getCurrentUser = async () => {
  const token = localStorage.getItem('token');

  const user = await api.get('/users/user', {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });

  return user.data;
};

export const getUsers = async () => {
  const token = localStorage.getItem('token');
  
  const users = await api.get('/users/profiles', {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
  
  return users.data;
};

export const editCompany = async (id, data) => {
  const token = localStorage.getItem('token');

  const response = await api.put(`/users/company/${id}`, data, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });

  return response.data;
};

export const editUser = async (id, data) => {
  const token = localStorage.getItem('token');

  const response = await api.put(`/users/profile/${id}`, data, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });

  return response.data;
};

export const editUserPassword = async (data) => {
  const token = localStorage.getItem('token');

  const response = await api.put(`/users/user/change_password`, data, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });

  if (response.status === 400 || (response.response && response.response.status === 400)) {
    throw new Error(response.response.data.detail || i18n.t('error.user_password_change_error'));
  }

  if (response.status !== 200 || (response.response && response.response.status !== 200)) {
    throw new Error(i18n.t('error.user_password_change_error'));
  }
}