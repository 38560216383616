import React, { useState } from 'react';
import {
  TagContainer,
  TagWrapper,
  TagWrapperExportCSVButton,
  TagWrapperExportPDFButton,
  TagWrapperFullScreen,
} from './styles';

import { useTranslation } from 'react-i18next';
import '../../i18n';

import { DIMENSIONS_OPTONS } from '../../options/filterOptions';
import exportToPdf from '../../utils/pdfUtils';
import { FaRegFilePdf } from "react-icons/fa";
import { HiDocumentReport } from 'react-icons/hi';
import Loader from '../Loader';

function GraphController({
  selectedDimension, setSelectedDimension, disabled, showDimensionController = true, showFullScreenOption, fullScreenEnabled, setFullScreenEnabled,  
  handleExportCSVReport, showExportCSVReportButton, loadingExportCSVReportButton,
  showExportPDFButton, graphComponentRefForPDF, exportPDFAlignContentCenterPage = true, style = {}
}) {
  const { t } = useTranslation();

  const [exportingPDF, setExportingPDF] = useState(false);

  const isSelected = (dimension) => dimension === selectedDimension;

  const handleSelectDimension = (dimension) => {
    if (isSelected(dimension)) return;
    setSelectedDimension(dimension);
  }
  const handleExportPDF = () => {
    setExportingPDF(true);
    exportToPdf(graphComponentRefForPDF, exportPDFAlignContentCenterPage)
    setExportingPDF(false);
  };

  return (
    <TagContainer style={style} className="ignorePdf">
      {showExportPDFButton &&
        <TagWrapperExportPDFButton
          className={exportingPDF || disabled ? 'disabled' : ''}
          onClick={handleExportPDF}
          disabled={exportingPDF || disabled}
        >
          <FaRegFilePdf style={{marginRight: 5}} size={10}/>
          {t('common.pdf')}
          {exportingPDF && 
            <Loader
              size={10}
              color='#fff'
              containerStyles={{width: "auto", height: "auto", marginLeft: 5}}
            />  
          }
        </TagWrapperExportPDFButton>
      }
      {showExportCSVReportButton && (
        <TagWrapperExportCSVButton
          className={loadingExportCSVReportButton || disabled ? 'disabled' : ''}
          onClick={handleExportCSVReport}
          disabled={disabled}
        >
          <HiDocumentReport style={{marginRight: 5}} size={10}/>
          {t('common.export_csv')}
          {loadingExportCSVReportButton && 
            <Loader
              size={10}
              color='#fff'
              containerStyles={{width: "auto", height: "auto", marginLeft: 5}}
            />  
          }
        </TagWrapperExportCSVButton>
      )}
      {showFullScreenOption &&
      (
        <TagWrapperFullScreen
          onClick={() => setFullScreenEnabled(!fullScreenEnabled)}
        >
          {fullScreenEnabled ? `${t('common.minimize')}` : `${t('common.full_screen')}`}
        </TagWrapperFullScreen>
      )}
      {showDimensionController && (
        <>
          <TagWrapper
            className={isSelected(DIMENSIONS_OPTONS.year) || disabled ? 'disabled' : ''}
            disabled={isSelected(DIMENSIONS_OPTONS.year) || disabled}
            onClick={() => handleSelectDimension(DIMENSIONS_OPTONS.year)}
          >
            {t('common.year')}
          </TagWrapper>
          <TagWrapper
            className={isSelected(DIMENSIONS_OPTONS.month) || disabled ? 'disabled' : ''}
            disabled={isSelected(DIMENSIONS_OPTONS.month) || disabled}
            onClick={() => handleSelectDimension(DIMENSIONS_OPTONS.month)}
          >
            {t('common.month')}
          </TagWrapper>
          <TagWrapper
            className={isSelected(DIMENSIONS_OPTONS.day) || disabled ? 'disabled' : ''}
            disabled={isSelected(DIMENSIONS_OPTONS.day) || disabled}
            onClick={() => handleSelectDimension(DIMENSIONS_OPTONS.day)}
          >
            {t('common.day')}
          </TagWrapper>
        </>
      )}
    </TagContainer>
  );
}

export default GraphController;
