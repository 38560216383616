import React, { useEffect } from 'react';
import { ToastContainer } from 'react-toastify';

import { useTranslation } from 'react-i18next';
import './i18n';

import GlobalStyle from './styles/global';
import AppRoutes from './routes';

import { getCurrentUser, editUser } from './services/UserService';
import { setUserInfo } from './utils/loginUtils';

function App() {
  const { t } = useTranslation();

  useEffect(() => {
    setUserInfo();
  }, []);

  window.OneSignal = window.OneSignal || [];
  window.OneSignal.push(function() {
    window.OneSignal.init({
      appId: "1bc89fb5-fa29-467d-bfb0-6ef11cac9e69",
      notifyButton: {
        enable: true,
        text: {
          'tip.state.unsubscribed': t("onesignal.enable_notifications"),
          'tip.state.subscribed': t("onesignal.notifications_enabled"),
          'tip.state.blocked': t("onesignal.notifications_blocked"),
          'message.prenotify': t("onesignal.click_to_enable_notifications"),
          'message.action.subscribed': t("onesignal.thank_you_for_registering"),
          'message.action.resubscribed': t("onesignal.notifications_enabled"),
          'message.action.unsubscribed': t("onesignal.no_more_notifications"),
          'dialog.main.title': t("onesignal.manage_notifications"),
          'dialog.main.button.subscribe': t("onesignal.register"),
          'dialog.main.button.unsubscribe': t("onesignal.unregister"),
          'dialog.blocked.title': t("onesignal.unlock_notifications"),
          'dialog.blocked.message': t("onesignal.follow_instructions_to_enable_notifications"),
        }
      },
      promptOptions: {
        actionMessage: t("onesignal.we_would_like_to_send_notifications"),
        acceptButtonText: t("onesignal.allow"),
        cancelButtonText: t('common.cancel'),
      },
      welcomeNotification: {
        title: "Notificações ativadas",
        message: t("onesignal.be_the_first_to_know"),
      },
    });

    window.OneSignal.on('subscriptionChange', async (isSubscribed) => {
      const { id, account_type } = await getCurrentUser();
      const playerId = await window.OneSignal.getUserId();

      if (id && account_type === 'Profile') {
        await editUser(id, {
          web_token: isSubscribed ? playerId : '',
        });
      }
    });

  });
  return (
    <>
      <AppRoutes />
      <GlobalStyle />
      <ToastContainer autoClose={3000} />
    </> 
  );
}

export default App;
