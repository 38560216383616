import React, { useState } from 'react';
import { toast } from 'react-toastify';

import { useTranslation } from 'react-i18next';
import '../../../i18n';

import { getModules, createUserModule } from '../../../services/AlertService';
import { testVtexAccountAccess } from '../../../services/validationAccountAccess';
import * as S from './styled';

function CustomModalVtex(props) {
  const { t } = useTranslation();

  const [vtexApiKey, setVtexApiKey] = useState('');
  const [vtexToken, setVtexToken] = useState('');
  const [savingInProgress, setSavingInProgress] = useState(false);

  const handleVtexSaveConfig = async () => {
    if (vtexApiKey && vtexToken) {
      const userModules = await getModules();
      const setting = userModules.filter((item) => item.name === 'VTEX')[0];

      toast.info(t('toast.connection_start'));

      const vtexApiKeySplited = vtexApiKey.split("-")

      let vtexAccount = ""

      if (vtexApiKeySplited.length > 2) {
        vtexAccount = vtexApiKeySplited[1]
      }

      if(await testVtexAccountAccess(vtexApiKey, vtexToken, vtexAccount)) {
        const data = {
          active: true,
          settings: {
            VTEX: { api_key: vtexApiKey, token: vtexToken, account_name: vtexAccount },
          },
        };

        try {
          setSavingInProgress(true);

          await createUserModule(setting.id, data);
          
          props.handleClose();
          toast.success(t('toast.configuration_saved'));
        } catch (error) {
          toast.error(t('toast.configuration_save_failed'));
        } finally {
          setSavingInProgress(false);
        }
      } else {
        toast.error(t('toast.permissions_check'));
      }
    } else {
      toast.error(t('toast.api_key_token_required'));
    }
  };

  return (
    <S.CustomModalVtex {...props}>
      <div className="modal">
        <div className="modal__header">
          <p className="modal__title">{t('connectionAndChannel.vtex_configuration')}</p>
          <div className="line" />
        </div>

        <div className="modal__body">
          <div className="modal__slider">
            <form className="modal__form">
              <div className="modal__step-by-step">
                <ul>
                  <li>1. {t('connectionAndChannel.access_vtex_admin_panel')}</li>
                  <li>2. {t('connectionAndChannel.create_application_key_permission')} <strong>ADMIN</strong></li>
                  <li>3. {t('connectionAndChannel.enter_application_key_token')}</li>
                </ul>
              </div>
              <div className="modal__form-group">
                <span className="modal__form-label">{t('connectionAndChannel.enter_api_key')}:</span>
                <input
                  type="text"
                  className="modal__form-input is--text"
                  placeholder="API KEY"
                  onChange={(ev) => setVtexApiKey(ev.target.value)}
                  disabled={savingInProgress}
                />
              </div>
              <div className="modal__form-group vtex-token-field">
                <span className="modal__form-label">{t('connectionAndChannel.enter_token')}:</span>
                <input
                  type="text"
                  className="modal__form-input is--text"
                  placeholder="TOKEN"
                  onChange={(ev) => setVtexToken(ev.target.value)}
                  disabled={savingInProgress}
                />
              </div>
            </form>
          </div>
        </div>

        <div className="modal__footer">
          <button
            className="modal__form-btn is--cancel"
            type="button"
            onClick={props.handleClose}
            disabled={savingInProgress}
          >
            {t('common.cancel')}
          </button>
          <button
            className="modal__form-btn is--save"
            type="button"
            onClick={handleVtexSaveConfig}
            disabled={savingInProgress}
          >
            {t('common.save')}
          </button>
        </div>
      </div>
    </S.CustomModalVtex>
  );
}

export default CustomModalVtex;
