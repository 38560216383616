import React from "react";
import { useTranslation } from 'react-i18next';
import { Tooltip } from '@mui/material';

import { RiMessage3Fill } from "react-icons/ri";

import { SupportButtonContainer } from "./styles";

export const SupportButton = () => {
    const { t } = useTranslation();
    
    const FORMS_URL = 'https://forms.monday.com/forms/ea0d51bd0ef7a45914beb82827a700ea?r=use1';

    const handleClick = () => {
        window.open(FORMS_URL, '_blank');
    }

    return (
        <Tooltip title={t('common.contact_us')} placement="left" arrow>
            <SupportButtonContainer onClick={handleClick}>
                <RiMessage3Fill size={30} color="#fff" />
            </SupportButtonContainer>
        </Tooltip>
    );
}