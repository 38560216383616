import api from './api';

import i18n from '../i18n';

const OK = 200;

export const getUserModules = async () => {
  try {
    const token = localStorage.getItem('token');

    const response = await api.get('/alerts/user_modules', {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });

    if (!response || response.status !== OK) {
      throw new Error();
    }

    return response.data;
  } catch (error) {
    throw new Error(i18n.t('error.user_connections_retrieve_failed'));
  }
}