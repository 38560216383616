import { HttpStatusCode } from 'axios';
import api from './api';

export const validateToken = async () => {
    const response = await api.get('/users/user');

    const unauthenticatedStatus = [HttpStatusCode.Unauthorized, HttpStatusCode.Forbidden];

    if (response && unauthenticatedStatus.includes(response.status)) {
        throw new Error();
    }

    return
};