import React, { useEffect, useCallback, useState, useRef, useContext } from 'react';

import { toast } from 'react-toastify';

import { useTranslation } from 'react-i18next';
import '../../i18n';

import BackgroundNew from '../../components/BackgroundNew';
import FilterContainer from '../../components/FilterContainer';
import CardGroup from '../../components/CardGroup';
import CardIndicator from '../../components/CardIndicator';
import TableCampaign from '../../components/TableCampaign';
import ComposedGraphBarLine from '../../components/ComposedGraphBarLine';

import { DIMENSIONS_OPTONS, GOOGLE_ANALYTICS_CAMPAIGN_REPORT_TYPE } from '../../options/filterOptions';
import { getAgregatedKeyByDimension, getSelectedInitialDateRange, formatDate } from '../../utils/dateUtils';
import { formatValueToMoney, formatValueToPercentage, formatValueToNumber } from '../../utils/metricsUtils';

import {
  getMediaOverviewMetrics,
  getMediaCampaignsMetrics,
  sendReportToEmailMediaCampaignsMetrics,
} from '../../services/dashboardApiService';

import { AuthContext } from '../../contexts';

const AllChannel = () => {
    const { t } = useTranslation();
    const { timezone, currency } = useContext(AuthContext);

    const [dateRange, setDateRange] = useState();
    const [isLoading, setIsLoading] = useState(true);
    const [campaignOptions, setCampaignOptions] = useState([]);
    const [sourceMediumOptions, setSourceMediumOptions] = useState([]);
    const [selectedCampaignOptions, setSelectedCampaignOptions] = useState([]);
    const [selectedSourceMediumOptions, setSelectedSourceMediumOptions] = useState([]);
    const [selectedDimension, setSelectedDimension] = useState(DIMENSIONS_OPTONS.day);
    const [bigNumbersData, setBigNumbersData] = useState(null);
    const [campaignsData, setCampaignsData] = useState(null);
    const [channelOverviewData, setChannelOverviewData] = useState(null);

    const [revenueAndTransactionsGraphFullScreen, setRevenueAndTransactionsGraphFullScreen] = useState(false);
    const [sessionsAndConversionRateGraphFullScreen, setSessionsAndConversionRateGraphFullScreen] = useState(false);
    const [campaignsTableFullScreen, setCampaignsTableFullScreen] = useState(false);
    const [exportReportLoading, setExportReportLoading] = useState(false);

    const cardCapturedRevenueAndTransactionsRef = useRef()
    const cardSessionsAndConversionRef = useRef()


  const handleApplyFilter = async () => {
        const [startDate, endDate] = dateRange;
        const campaigns = selectedCampaignOptions.length > 0 ? [...selectedCampaignOptions] : null;
        const sourcesMedium = selectedSourceMediumOptions.length > 0 ? [...selectedSourceMediumOptions] : null;

        await getMediaDataMetrics(
            formatDate(startDate),
            formatDate(endDate),
            campaigns,
            sourcesMedium
        );
    };

    const handleExportReport = async() => {
      setExportReportLoading(true);
      try {
        toast.info(t('toast.wait_for_report'))

        const [startDate, endDate] = dateRange;
        const campaigns = selectedCampaignOptions.length > 0 ? [...selectedCampaignOptions] : null;
        const sourcesMedium = selectedSourceMediumOptions.length > 0 ? [...selectedSourceMediumOptions] : null;

        await sendReportToEmailMediaCampaignsMetrics(
          formatDate(startDate),
          formatDate(endDate),
          GOOGLE_ANALYTICS_CAMPAIGN_REPORT_TYPE.ALL_CHANNELS,
          timezone,
          campaigns,
          sourcesMedium
        )
        toast.success(t('toast.report_preparation'));
      } catch (error) {
        toast.error(t('toast.report_generation_failed'));
      } finally {
        setExportReportLoading(false);
      }
    }

    const getMediaCampaignsMetricsAllPages = async (filter) => {
        try {
            const response = await getMediaCampaignsMetrics(
                filter.initialDate,
                filter.finalDate,
                filter.timezone,
                filter.channels,
                filter.campaigns,
                filter.sourcesMedium,
                true,
                1
            );

            if (!response) { return null; }

            const totalPages = response.pagination.total_pages;

            const campaignsData = [...response.results];
            for (let i = 2; i <= totalPages; i++) {
                const response = await getMediaCampaignsMetrics(
                    filter.initialDate,
                    filter.finalDate,
                    filter.timezone,
                    filter.channels,
                    filter.campaigns,
                    filter.sourcesMedium,
                    true,
                    i
                );

                if (!response) { continue; }

                campaignsData.push(...response.results);
            }

            return campaignsData
        } catch(error) {
            throw new Error();
        }
    }

    const getMediaDataMetrics = useCallback(async (initialDate, finalDate, campaigns, sourcesMedium) => {
        setIsLoading(true);

        try {
            const responses = await Promise.all([
                getMediaOverviewMetrics(
                    initialDate,
                    finalDate,
                    timezone,
                    null,
                    campaigns,
                    sourcesMedium
                ),
                getMediaCampaignsMetricsAllPages({
                    initialDate,
                    finalDate,
                    timezone,
                    channels: null,
                    campaigns,
                    sourcesMedium
                })
            ]);

            const responseOverview = responses[0];
            const responseCampaigns = responses[1];

            if (!responseOverview || !responseCampaigns) {
                setBigNumbersData(null);
                setCampaignOptions([]);
                setSourceMediumOptions([]);
                setCampaignsData(null);
                setChannelOverviewData(null);

                return;
            }

            setBigNumbersData(responseOverview.big_numbers)
            setChannelOverviewData(responseOverview.results)
            setCampaignOptions(responseOverview.available_campaigns_to_filter)
            setSourceMediumOptions(responseOverview.available_sources_medium_to_filter)
            setCampaignsData(responseCampaigns)
        } catch (error) {
            toast.error(error.message);
            setBigNumbersData(null);
            setCampaignOptions([])
            setCampaignsData(null)
        } finally {
          setIsLoading(false);
        }
    }, [timezone]);

    const formatOverviewData = (data, dimension) => {
        if(!data) { return null}

        const aggregatedData = {};

        data.forEach(entry => {
            const key = getAgregatedKeyByDimension(entry.date, dimension);
            if (!aggregatedData[key]) {
                aggregatedData[key] = {
                    date: key,
                    capturedRevenue: 0,
                    sessions: 0,
                    transactions: 0,
                    conversionRate: 0,
                };
            }

            aggregatedData[key].capturedRevenue += entry.revenue;
            aggregatedData[key].sessions += entry.sessions;
            aggregatedData[key].transactions += entry.transactions;
            aggregatedData[key].conversionRate = entry.conversion_rate;
        });

        if (dimension !== DIMENSIONS_OPTONS.day) {
            Object.keys(aggregatedData).forEach(key => {
                const conversionRate = aggregatedData[key].sessions > 0 ? aggregatedData[key].transactions / aggregatedData[key].sessions : 0.0;

                aggregatedData[key].conversionRate = Number((conversionRate * 100).toFixed(2));
                aggregatedData[key].capturedRevenue = Number((aggregatedData[key].capturedRevenue).toFixed(2));
            })
        }

        return Object.values(aggregatedData);
    };

    const getCardIndicatorData = (data) => {
        return {
          keys: ["revenue", "sessions", "conversion_rate", "transactions", "avg_ticket"],
          titles: {
            revenue: t('common.captured_revenue'),
            sessions: t('common.sessions'),
            conversion_rate: t('common.conversion_rate'),
            transactions: t('common.transactions'),
            avg_ticket: t('common.average_ticket')
          },
          types: {
            revenue: 'currency',
            sessions: 'decimal',
            conversion_rate: 'percent',
            transactions: 'decimal',
            avg_ticket: 'currency'
          },
          displayFlexSpaceOcupation: {
            revenue: 2,
            sessions: 1,
            conversion_rate: 1,
            transactions: 1,
            avg_ticket: 2
          },
          data: data
        }
    }

    const getSecondaryFilters = () => {
        return [
            {
                title: t('common.source_media'),
                options: sourceMediumOptions,
                value: selectedSourceMediumOptions,
                setValue: setSelectedSourceMediumOptions
            },
            {
                title: t('common.campaigns'),
                options: campaignOptions,
                value: selectedCampaignOptions,
                setValue: setSelectedCampaignOptions
            },
        ]
    }

    const onRenderComponent = useCallback(async () => {
        const { initialDateFilter, finalDateFilter } = getSelectedInitialDateRange();
        setDateRange([initialDateFilter, finalDateFilter]);

        await getMediaDataMetrics(
          formatDate(initialDateFilter),
          formatDate(finalDateFilter)
        );
      }, [getMediaDataMetrics]);

      useEffect(() => {
        onRenderComponent();
      }, [onRenderComponent]);

    return (
        <BackgroundNew
            titlePage={t('menu.channels_summary')}
        >
            <FilterContainer
                selectedDateRange={dateRange}
                setDateRange={setDateRange}
                onClickFilter={handleApplyFilter}
                showSecondaryFilters={true}
                secondaryFilters={getSecondaryFilters()}
            />
            <CardIndicator
                data={getCardIndicatorData(bigNumbersData)}
                currency={currency}
                isLoading={isLoading}
            />
            <CardGroup
                title={t('allChannel.receita_capturada_vs_transacoes')}
                tagIdentifierColor="#00CCAE"
                isLoading={isLoading}
                ref={cardCapturedRevenueAndTransactionsRef}
            >
                <ComposedGraphBarLine
                    data={channelOverviewData}
                    dimension={selectedDimension}
                    showPrimaryBarYAxis={true}
                    showSecondaryLineYAxis={true}
                    primaryBarYAxisConfig={{
                        name: t('common.captured_revenue'),
                        dataKey: "capturedRevenue"
                    }}
                    secondaryLineYAxisConfig={{
                        name: t('common.transactions'),
                        dataKey: "transactions"
                    }}
                    formatData={formatOverviewData}
                    formaterBarValue={value => formatValueToMoney(value, currency)}
                    formaterLineValue={formatValueToNumber}
                    graphMarginConfig={{
                        top: 20,
                        right: 20,
                        left:64,
                        bottom: 20
                    }}
                    showDimensionController={true}
                    setSelectedDimension={setSelectedDimension}
                    disabledDimensionController={channelOverviewData === null || channelOverviewData.length === 0}
                    showFullScreenOption={true}
                    fullScreenEnabled={revenueAndTransactionsGraphFullScreen}
                    setFullScreenEnabled={setRevenueAndTransactionsGraphFullScreen}
                    showExportPDFButton={true}
                    graphComponentRefForPDF={cardCapturedRevenueAndTransactionsRef}
                />
            </CardGroup>
            <CardGroup
                title={t('allChannel.sessoes_vs_taxa_de_conversao')}
                tagIdentifierColor="#00CCAE"
                isLoading={isLoading}
                ref={cardSessionsAndConversionRef}
            >
                <ComposedGraphBarLine
                    data={channelOverviewData}
                    dimension={selectedDimension}
                    showPrimaryBarYAxis={true}
                    showSecondaryLineYAxis={true}
                    primaryBarYAxisConfig={{
                        name: t('common.sessions'),
                        dataKey: "sessions"
                    }}
                    secondaryLineYAxisConfig={{
                        name: t('common.conversion_rate'),
                        dataKey: "conversionRate"
                    }}
                    formatData={formatOverviewData}
                    formaterBarValue={formatValueToNumber}
                    formaterLineValue={formatValueToPercentage}
                    graphMarginConfig={{
                        top: 20,
                        right: 20,
                        left:64,
                        bottom: 20
                    }}
                    showDimensionController={true}
                    setSelectedDimension={setSelectedDimension}
                    disabledDimensionController={channelOverviewData === null || channelOverviewData.length === 0}
                    showFullScreenOption={true}
                    fullScreenEnabled={sessionsAndConversionRateGraphFullScreen}
                    setFullScreenEnabled={setSessionsAndConversionRateGraphFullScreen}
                    showExportPDFButton={true}
                    graphComponentRefForPDF={cardSessionsAndConversionRef}
                />
            </CardGroup>
            <CardGroup
                title={t('common.campaigns')}
                tagIdentifierColor="#00CCAE"
                isLoading={isLoading}
            >
                <TableCampaign
                    data={campaignsData}
                    selectedDimension={selectedDimension}
                    setSelectedDimension={setSelectedDimension}
                    showDimensionController={true}
                    disabledDimensionController={campaignsData === null || campaignsData.length === 0}
                    showFullScreenOption={true}
                    fullScreenEnabled={campaignsTableFullScreen}
                    setFullScreenEnabled={setCampaignsTableFullScreen}
                    handleExportCSVReport={handleExportReport}
                    showExportCSVReportButton={true}
                    loadingExportCSVReportButton={exportReportLoading}
                    disable={campaignsData === 0 || exportReportLoading}
                    currency={currency}
                />
            </CardGroup>
        </BackgroundNew>
    );
}

export default AllChannel;
