import React, { useState } from 'react';

import { useTranslation } from 'react-i18next';
import '../../../../i18n';

import { CustomTooltipContainer } from './styles';

import {
  ComposedChart,
  Line,
  XAxis,
  YAxis,
  Tooltip,
  Area,
  ResponsiveContainer,
  CartesianGrid,
} from 'recharts';

import GraphController from '../../../../components/GraphController';
import GraphContainer from '../../../../components/GraphContainer';

const CustomAnomalyTooltip = ({ active, payload, label }) => {
  const { t } = useTranslation();

  const categoryColors = {
    OK: '#00CCAE',
    ERROR: '#ff0068',
  };
  if (active) {
    return (
      <CustomTooltipContainer id="graph-tooltip">
        <p className="label">{label}</p>
        {payload.map((item, index) => {
          if (item.value) {
            let spanColor = item.color;
            let itemName = item.name;
            if (item.name === t('common.achieved')) {
              spanColor = item.payload.is_anomaly ? categoryColors.ERROR : categoryColors.OK;
              itemName = item.payload.is_anomaly ? t('anomalies.anomaly') : item.name;
            }
            return (
              <p className="item" key={index}>
                {`${itemName}: `}
                <span style={{ color: spanColor, fontWeight: 'bold' }}>
                  {item.formatter ? item.formatter(item.value) : item.value}
                </span>
              </p>
            );
          }
          return null;
        })}
      </CustomTooltipContainer>
    );
  }

  return null;
};

const CustomizedDot = ({ cx, cy, r, stroke, payload, value }) => {
  const categoryColors = {
    OK: '#00CCAE',
    ERROR: '#ff0068',
  };
  const color = payload.is_anomaly ? categoryColors.ERROR : categoryColors.OK;

  return <circle cx={cx} cy={cy} r={r} stroke={color} strokeWidth={1} fill={color} />;
};

const NoDot = ({ cx, cy, r, stroke, payload, value }) => {
  return null;
};

const GraphAnomaly = ({ formatter, marginLeftAxisX, graphRef, graphData = [] }) => {
  const { t } = useTranslation();
  
  const [fullScreenMode, setFullScreenMode] = useState(false);

  const VALUE_PADDING_PERCENTAGE = 0.2;

  const filteredData = graphData.map((item) => {
    let result = { date: item.date };

    result['deviations'] = [item.predicted_value_lower_bound, item.predicted_value_upper_bound];

    if (item.real_value > 0) {
      result['real_value'] = item.real_value;
    }
    result['is_anomaly'] = item.is_anomaly;
    result['explanation'] = item.explanation;

    return result;
  });
  const minValueY = 0;

  const maxValueY =
    Math.max(
      ...[
        ...graphData.map((item) => item.real_value),
        ...graphData.map((item) => item.predicted_value_upper_bound),
      ]
    ) *
    (1 + VALUE_PADDING_PERCENTAGE);

  const defaultStyles = {
    container: {},
    graph: {
      height: '40vh',
    },
    graphLegend: {
      width: '100%',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
    },
    graphLegendItem: {
      display: 'flex',
      alignItems: 'center',
      marginRight: '10px',
      fontSize: '13px',
      color: '#6F767E',
    },
    graphLegendDot: {
      width: '5px',
      height: '5px',
      borderRadius: '50%',
      marginRight: '5px',
    },
    graphLegendDash: {
      width: '15px',
      height: '3px',
      marginRight: '5px',
    },
  };

  const fullScreenStyles = {
    container: {
      ...defaultStyles.container,
      position: 'fixed',
      top: 0,
      left: 0,
      width: '100%',
      height: '100vh',
      backgroundColor: '#fff',
      zIndex: 99999,
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'end',
      margin: 0,
    },
    graph: {
      height: '80vh',
    },
    graphLegend: {
      ...defaultStyles.graphLegend,
      width: '100%',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
    },
    graphLegendItem: {
      ...defaultStyles.graphLegendItem,
    },
    graphLegendDot: {
      ...defaultStyles.graphLegendDot,
    },
    graphLegendDash: {
      ...defaultStyles.graphLegendDash,
    },
  };

  if (!graphData?.length) {
    return null;
  }

  const containerStyle = fullScreenMode ? fullScreenStyles.container : defaultStyles.container;
  const graphStyle = fullScreenMode ? fullScreenStyles.graph : defaultStyles.graph;
  const graphLegendStyle = fullScreenMode ? fullScreenStyles.graphLegend : defaultStyles.graphLegend;
  const graphlegendItemStyle = fullScreenMode
    ? fullScreenStyles.graphLegendItem
    : defaultStyles.graphLegendItem;
  const graphlegendDotStyle = fullScreenMode ? fullScreenStyles.graphLegendDot : defaultStyles.graphLegendDot;
  const graphlegendDashStyle = fullScreenMode
    ? fullScreenStyles.graphLegendDash
    : defaultStyles.graphLegendDash;

  const renderGraph = () => {
    return (
      <React.Fragment>
        <ResponsiveContainer height="100%">
          <ComposedChart
            data={filteredData}
            margin={{
              top: 20,
              right: fullScreenMode ? 30 : 10,
              left: marginLeftAxisX,
              bottom: 20,
            }}
          >
            {fullScreenMode && <CartesianGrid strokeDasharray="3 3" />}
            <XAxis dataKey="date" />
            <YAxis
              dataKey="real_value"
              yAxisId="line"
              domain={[minValueY, maxValueY]}
              tickFormatter={formatter}
            />
            <Tooltip content={CustomAnomalyTooltip} />
            <Area
              yAxisId="line"
              type="monotone"
              dataKey="deviations"
              fill="rgba(255, 0, 104, 0.2)"
              stroke="none"
              formatter={(value) => {
                return `${formatter(value[0])} | ${formatter(value[1])}`;
              }}
              name={t('anomalies.tolerance_limits')}
            />
            <Line
              yAxisId="line"
              type="monotone"
              dataKey="real_value"
              stroke="none"
              name={t('common.achieved')}
              formatter={formatter}
              dot={<CustomizedDot />}
              strokeWidth={2}
            />
            <Line
              yAxisId="line"
              type="monotone"
              dataKey="explanation"
              stroke="none"
              name={t('common.explanation')}
              dot={<NoDot/>}
              strokeWidth={2}
            />
          </ComposedChart>
        </ResponsiveContainer>
        <div className="graphLegend" style={graphLegendStyle}>
          <div className="legendItem" style={graphlegendItemStyle}>
            <div className="legendDot" style={{ ...graphlegendDotStyle, backgroundColor: '#00CCAE' }} />
            <span className="legendText" style={{ color: '#00CCAE' }}>
              {t('common.achieved')}
            </span>
            <div
              className="legendDot"
              style={{ ...graphlegendDotStyle, backgroundColor: '#FF0068', marginLeft: '10px' }}
            />
            <span className="legendText" style={{ color: '#FF0068' }}>
              {t('anomalies.anomaly')}
            </span>
            <div
              className="legendDash"
              style={{ ...graphlegendDashStyle, backgroundColor: '#ffe0ec', marginLeft: '10px' }}
            />
            <span className="legendText" style={{ color: '#ffe0ec' }}>
              {t('anomalies.tolerance_limits')}
            </span>
          </div>
        </div>
      </React.Fragment>
    );
  };

  return (
    <div style={containerStyle}>
      <GraphController
        showDimensionController={false}
        showFullScreenOption={true}
        fullScreenEnabled={fullScreenMode}
        setFullScreenEnabled={setFullScreenMode}
        showExportPDFButton
        graphComponentRefForPDF={graphRef}
      />
      {fullScreenMode && <GraphContainer style={graphStyle}>{renderGraph()}</GraphContainer>}
      {!fullScreenMode && <GraphContainer style={graphStyle}>{renderGraph()}</GraphContainer>}
    </div>
  );
};

export default GraphAnomaly;
